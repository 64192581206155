$container__max-width: 480px;
$container__min-width: 330px;
$container__margin: -2%;
$google__bg_color: #F64135;
$facebook__bg_color: #3A5999;
$twitter__bg_color: #06ABED;

// .account {
//     .account-nav-content {
//         .items {

//         }
//     }
// }

.customer-account-login {
    .page-main {
        width: 100%;
        max-width: inherit;
        margin: 0;
        padding: 0;
    }

    .page-header {
        display: none!important;
    }

    .column.main {
        position: relative;
        padding-bottom: 0;
    }
    .page-wrapper{
        padding-top: 0;
    }
    .page-title-wrapper {
        display: none;
    }
}

// .page-title-wrapper {
//     display: none;
// }

.page-title {
    color: #122948;
    font-size: 40px;
    line-height: 55px;
}

.customer-account-login .main-login-container {
    display: grid;
    grid-template-areas: "left right"
                        "bottom  right";
    grid-template-columns: minmax(62.1%, 1fr) 1fr;
    height: 100vh;

    &::after {
        display: none;
    }

    &::before {
        display: none;
    }

    .container-header {
        display: flex;
        align-items: center;
        align-self: center;
        margin-bottom: 12.5rem;
        margin-right: 31rem;

        .icon__left {
            margin-right: 76px;
            cursor: pointer;

            .icon {
                width: 18px;
                height: 12px;
            }
        }

        .icon__logo {
            cursor: pointer;

            .icon {
                width: 271px;
                height: 54px;
            }
        }
    }

    .block .block-title {
        border-bottom: unset;
        padding-bottom: 0;

        h1 {
            color: #122948;
            font-size: 40px;
            line-height: 55px;
            font-weight: bold;
            margin-bottom: 20px;
            margin-left: 2px;
        }
    }

    .primary {
        a,
        button {
            font-size: 14px;
            line-height: 1;
            font-weight: bold;
            background: #E6A532;
            border-radius: unset;
            border: unset;

            &:hover {
                background-color: #CB9012;
            }
        }
    }

    #block-customer-login-heading {
        color: #464646;
        font-size: 20px;
        line-height: 27px;
        font-weight: bold;
        margin-left: 2px;
        margin-top: -1px;
    }

    .block-new-customer,
    .main-customer-login {
        height: 100%;
        width: inherit;
    }

    .main-customer-login {
        grid-area: left;
        background-color: #EDF5F8;
        padding-top: 40px;

        .block-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .control {
                position: relative;

                > .icon {
                    display: flex;
                    align-items: center;
                    top: 0;
                    bottom: 0;
                    margin-left: 10px;
                    position: absolute;

                    &.lock__icon,
                    &.email__icon {
                        stroke: #B5B5B5;
                    }

                    &.email__icon {

                        .icon {
                            max-width: 18px;
                            max-height: 12px;
                        }
                    }

                    &.lock__icon {
                        .icon {
                            max-width: 18px;
                            height: 15px;
                        }
                    }
                }

                input {
                    color: #000000;
                    font-size: 14px;
                    line-height: 40px;
                    padding: 0 0 0 40px;
                    border: 1px solid #707070;
                    height: 40px;
                }
            }

            .block-title {
                padding-bottom: 57px;
                margin-left: 31%;
                width: 70%;
            }

            .form-login {
                max-width: $container__max-width;
                border-bottom: 1px solid #182846;
                padding: 1px 0;
                width: 100%;
                margin: -14px $container__margin -20px 1px;
                // min-width: $container__max-width;
            }

            .fieldset {
                margin-bottom: 1rem;

                > .filed {
                    margin: 0;
                }

                .email {
                    margin-bottom: 20px;
                }
                .password {
                    margin-bottom: 50px;
                }
            }

            .fieldset:after {
                display: none;
            }

            .actions-toolbar {
                margin-left: 0;
                padding-bottom: 39.5px;

                .remind {
                    color: #00ADE7;
                    text-decoration: underline;
                    font-size: 14px;
                    line-height: 18px;
                }

                .primary {
                    button {
                        height: 45px;
                        width: 162px;
                    }
                }

                .secondary {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .login {
                .choice {
                    display: none;
                }
            }
        }
    }

    .block-new-customer {
        grid-area: right;
        background-color: #122948;

        .block-title {
            margin-top: 41%;
            color: #FFFFFF;
            font-size: 20px;
            line-height: 27px;

            strong {
                font-weight: bold;
            }
        }

        .block-title,
        .block-content {
            margin-left: 19.4%;
        }

        p {
            color: #FFFFFF;
            font-size: 14px;
            line-height: 18px;
            width: 56%;
            min-width: 200px;
            margin: 34px 0 66px 0;
        }

        .actions-toolbar {
            margin-top: 40px;

            .primary {
                a {
                    font-size: 14px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 220px;

                    &:hover {
                        background-color: #CB9012;
                        border: unset;
                    }
                }
            }
        }
    }

    .mstSocialLogin__login {
        display: flex;
        align-items: center;
        flex-direction: column;
        grid-area: bottom;
        flex-wrap: nowrap;
        background-color: #EDF5F8;
        width: 100%;
        padding: 3px 0 0 0;


        &._loginAfter {
            margin-top: 0;
        }

        a {
            font-size: 20px;
            line-height: 27px;
            font-weight: bold;
            padding: 13px 0;
            border-radius: unset;
            margin: 3px $container__margin 13px 0;
            max-width: $container__max-width;
            min-width: $container__max-width;
            width: 30%;

            span {
                margin-left: -14px;
            }

            &::before {
                border: unset;
                margin: 0 26px 0 15px;
            }
        }

        ._google {
            background-color: $google__bg_color;

            &::before {
                margin: 0 29px 0 12px;
                background-color: $google__bg_color;
            }

            &:hover {
                background-color: $google__bg_color;
            }
        }

        ._facebook  {
            background-color: $facebook__bg_color;


            &::before {
                background-color: $facebook__bg_color;
                margin: 0 28px 0 13px;
            }

            &:hover {
                background-color: $facebook__bg_color;
            }
        }

        ._twitter  {
            background-color: $twitter__bg_color;

            &::before {
                background-color: $twitter__bg_color;
                background-size: 2.8rem;
            }

            &:hover {
                background-color: $twitter__bg_color;
            }
        }
    }
}


@include max-screen($screen__xl) {
    .customer-account-login .main-login-container {
        .main-customer-login {
            grid-area: left;
            background-color: #EDF5F8;
            padding-top: 40px;

            .container-header {
                margin-right: 8rem;
            }

            .block-content {
                .block-title {
                    text-align: center;
                    margin-left: 0;

                    h1 {
                        font-size: 30px;
                        line-height: 41px;
                    }
                }
            }

            .form-login {
                margin: -14px 24% -20px 1px;
            }
        }
    }
}

@include max-screen($screen_nav__xs) {
    .customer-account-login .main-login-container {
        grid-template-areas: "left"
                        "bottom"
                        "right";
        grid-template-columns: 1fr;

        .mstSocialLogin__login {
            width: auto;

            a {
                width: 100%;
                min-width: inherit;
                max-width: inherit;
                margin-right: 0;
            }
        }

        .main-customer-login {
            .container-header {
                margin-right: 0;
                margin-top: 32px;
                margin-bottom: 35px;
                width: 100%;
                justify-content: space-between;

                .icon__left {
                    margin-right: 0;
                }
            }

            .block-content {
                .form-login {
                    max-width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }

                .block-title {
                    text-align: inherit;
                    width: 100%;
                }
            }
        }


        .block-new-customer {
            min-height: 311px;
            max-height: 311px;

            .block-title {
                margin-top: 35px;
            }

            .block-title,
            .block-content {
                margin-left: 0;
            }
        }

        .mstSocialLogin__login,
        .block-new-customer,
        .main-customer-login {
            padding: 0 2rem 0 2rem;
        }

    }
}

@include max-screen($screen__xs) {
    .customer-account-login .main-login-container {
        background-color: #EDF5F8;

        .main-customer-login {
            .container-header {
                .icon__logo .icon {
                    width: 165px;
                    height: 33px;
                }
            }


            .container-header {
                margin-top: 32px;
                margin-bottom: 30px;
            }

            .block-content {
                .form-login {
                    padding: 0;
                    margin: 15px 0 -18px 0;

                    .actions-toolbar {
                        padding-bottom: 10px;

                        .primary {
                            button {
                                width: 100%;
                                height: 42px;
                            }
                        }

                        .secondary {
                            text-align: left;

                            .action {
                                color: #122948;
                            }
                        }
                    }
                }

                .block-title {
                    padding-bottom: 0;
                    margin-bottom: 0;

                    h1 {
                        margin-bottom: 16px;
                    }

                    strong {
                        width: 80%;
                    }
                }

                .fieldset {
                    margin-bottom: 6px;

                    .password {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .block-new-customer {
            p {
                width: 100%;
                margin: 0;
            }

            .block-title {
                strong {
                    margin-bottom: 8px;
                }
            }

            .actions-toolbar {
                margin-top: 33px;

                .primary {
                    a {
                        width: 100%;
                    }
                }
            }
        }


        .mstSocialLogin__login {
            &._loginAfter {
                margin: 0 0 20px 0;
            }

            a {
               height: 40px;
               font-size: 14px;
               padding: 0;
               margin: -2px 0 13px 0;

               &::before {
                    background-size: 2rem!important;
                    margin: 0 33px 0 8px;
                }
            }

            ._facebook, ._google  {
                &::before {
                    margin: 0 33px 0 8px;
                }
            }
        }
    }
}
