@mixin banners-title {
    background-color: #122948;
    color: #FFFFFF !important;
    text-align: left;
    text-transform: capitalize;
}

@mixin mob-menu__bold-text {
    font-weight: 700!important;
}

$level1__max-height: 468px;
$menu-title__margin-top: 27px;
$menu_text-shadow: 0.10px;

.nav-off-canvas div.nav-sections {
    display: none;
    left: -100%;
    width: calc(100% - 54px);
}

.nav-open .nav-off-canvas {
    .nav-toggle {
        &::before {
            display: none;
        }
    }
}

.title-menu-mob {
    display: none;
}

.nav-open .nav-off-canvas .nav-sections .nav-sections-item-title {
    display: none;
}

.page-wrapper.nav-off-canvas .nav-sections {
    .nav-sections-item-content {
        margin-top: 0;
        padding: 0;
    }

    .nav-sections-item-title {
        display: none;
    }

    .drilldown > .btn-drilldown {
        display: flex;
        align-items: center;
        justify-content: start;
        width: auto;
        margin: 0;
        padding: 5px 20px;

        .btn-drilldown__close-nav {
            border: unset;
            background: transparent;
            padding: 0;
            order: 2;

            &::after {
               content: "\2715";
               display: block;
               font-size: 25px;
            }
        }

        .btn-back {
            display: flex;
            align-items: baseline;
            width: 100%;
            @include mob-menu__bold-text;
            line-height: 16px;
            color: var(--color-dark_blue);
            order: 1;
            padding: 0;

            &:before {
                content: '';
            }
        }

        .icon__arrow-left {
            display: none;
        }

        .btn-back-icon .icon__arrow-left {
            display: block;
            width: 18px;
            height: 10px;
            padding-right: 5px;
        }
    }

    ul.level0 {
        li.mega .mega.has-child span.menu-title:after {
            content: '\e608';
        }
    }

    .active-childs {
        > .drilldown-sub {
            display: block;
        }

        > a,
        > span {
            span.menu-title:after {
                transform: rotate(270deg)!important;
            }
        }
    }

    .menu-login {
        .my-account-mob {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            span.menu-title:after {
                transform: rotate(90deg);
            }

            > .drilldown-sub {
                width: 100%;
                ul {
                    width: 100%;
                }


                li {
                    padding: 0;

                    > a {
                        padding-left: 40px;
                    }
                }
            }
        }

        .my-account-mob + li, .my-account-mob + li + li {
            display: none;
        }
    }

    .menu-logout {
        .my-account-mob {
            pointer-events: none;
            span.menu-title:after {
                display: none;
            }
        }
    }


    .drilldown-root {
        ul.level1 {
            li.mega .mega.has-child span.menu-title:after {
                transform: rotate(90deg);
            }
            // for an accordion menu
            > li.mega.has-child {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                > .drilldown-sub {
                    width: 100%;

                    li.mega {
                       padding: 0;

                       > a {
                           padding-left: 40px;
                       }
                    }
                }
            }
        }

        li.title-menu-mob {
            .my-account-mob,
            .klantenservice-mob {
                @include mob-menu__bold-text;
                padding: 40px 0 21px 20px!important;
            }
        }

        li.mega {
            padding: 0;

            li.mega:not(.has-child):not(.banner-item) {
                .title-menu-mob,
                > a.mega {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    &:after {
                        content: '\e608';
                        font-family: 'icons-blank-theme';
                        font-style: normal;
                        font-size: 50px;
                        width: 35px;
                        color: inherit;
                        line-height: inherit;
                        vertical-align: middle;
                        display: inline-block;
                        overflow: hidden;
                    }
                }
            }

            .mega.has-child span.menu-title:after {
                font-size: 50px;
                right: 3px;
            }

            .mega {
                @include menu-mobile-link;
                padding: 20px;
                text-transform: capitalize;
            }
        }

        .parent-item {
            display: none;
        }
    }
}

.sections.nav-sections ul.level0 > li.mega > .mega {
    padding: 16px 0 14px 0;
}

// ul.drilldown-root,
ul.level0.horizontal-root {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;
    white-space: nowrap;
    position: static!important;

    > li.mega.has-child .child-content {
        max-width: 1920px;
        overflow-y: auto;
        padding: 0;
        left: 0;
        right: 0;
        border: unset;
        border-bottom: 1px solid $color-white_light;

        margin-left: auto;
        margin-right: auto;
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;

        > div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            @include container;
            margin: 0 auto;
            padding: 0 20px !important;
            width: auto !important;
        }
    }

    .banners-area {
        display: grid;
        justify-content: end;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 37px;
        grid-row-gap: 30px;

        .banner-item {
            display: flex;
            flex-direction: column-reverse;

            .menu-desc + img {
                display: none;
            }
        }

        li.mega {
            display: flex;
            flex-direction: column-reverse;
            flex: 1 0 auto;
            max-width: 180px;
            height: 221px;

            a.mega {
                // @include banners-title;
                padding: 11px 0 11px 15px!important;
                width: initial !important;
                
            }

            .menu-title {
                line-height: initial;
                font-weight: bold;
                font-size: 14px;
            }

            div.menu-desc {
                display: block;
                position: static;
                max-width: 180px;
                max-height: 180px;

                > p {
                    margin-bottom: 0;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .level2.drilldown-sub {
        flex-wrap: wrap;
    }

    > li.mega.has-child.mega-hover > .mega {
        background: transparent;
    }

    li.mega:last-of-type {
        padding: 0;
    }

    .mega.menu-col {

        &-3 {
            ul.level1 {
                column-count: 3;
            }
        }

        &-4 {
            ul.level1 {
                column-count: 4;
            }
        }

        &-5 {
            ul.level1 {
                column-count: 5;
            }
        }

        &-6 {
            ul.level1 {
                column-count: 6;
            }
        }
        
    }

    ul.level1 {
        column-count: 4;
        padding: 27px 0 42px 0;


        > li.mega {
            text-align: start;
            padding: 0;

            &.menu-col-end {
                break-before: column;
            }

            .spacer,
            &.spacer {
                margin-top: 26px;
                display: inline-block;
            }

            &.title-items {
                .mega {
                    color: var(--color-sky_blue);
                    font-weight: 700;
                }
            }

            .mega {
                padding: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
                color: var(--color-dark_blue);

                &:focus {
                    background: transparent;
                }

                &:hover {
                    background-color: transparent;
                }

                &.ui-state-active,
                &:active,
                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    > li.mega {
        position: static!important;
        color: var(--color-dark_blue);

        > .mega:hover {
            background-color: transparent;
        }

        &.mega-hover {

            > .mega {
                border-bottom: 2px solid $bright-blue_color;
            }

            &.sale {
                > .mega {
                    border-bottom: 2px solid $red_color;
                }
            }

        }

        > .mega {
            padding: 16px 0 14px 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            border-bottom: 2px solid $light-blue-bright_color;
            cursor: pointer;


            .menu-title {
                display: flex!important;
                flex-direction: column;

                &::after {
                    // margin-top: 7px;
                    // font-size: 50px !important;
                    // margin-left: 0;
                    // margin-top: 0;
                    display: none !important;
                }
            }
        }
    }

    li.mega {
        padding: 0 5px 0 0;
        text-align: center;

        .menu-title {
            display: flex !important;
        }

        .mega-hover.child-content {
            display: block;
        }
    }
}

.drilldown {
    max-width: 100%!important;

    li.mega.has-child {
        cursor: pointer;
    }

    .drilldown-container {
        .banners-area {
            display: flex;
            padding: 0;

            > ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 36px;
                row-gap: 20px;
                width: 55%;

                li.mega {
                    display: flex;
                    flex-direction: column-reverse;
                    padding: 0;
                    min-width: 160px;

                    p {
                        margin-bottom: 0;
                        max-height: 160px;
                    }

                    img {
                        width: 160px;
                        height: 160px;
                        line-height: inherit;
                    }

                    .banner-item {
                        @include banners-title;
                        padding: 0!important;
                        border-bottom: unset;

                        .menu-title {
                            padding: 10px 14px;

                            &::after {
                                display: none;
                                content: '';
                            }
                        }
                    }

                }
            }
        }
    }
}

// #ub-mega-menu {
//     &-drill {
//         display: none;
//     }
//     &-1 {
//         display: none !important;
//     }
// }

// Mobile
@include max-screen($screen_nav__xxl) {

    // #ub-mega-menu {
    //     &-drill {
    //         display: block;
    //     }
    //     &-1 {
    //         display: none;
    //     }
    // }

    .title-menu-mob {
        display: inline-block;
    }

    .nav-off-canvas div.nav-sections {
        display: block;
        width: 100%;
        max-width: 380px;

        .drilldown .drilldown-container .banners-area > ul {
            grid-template-columns: repeat(2, 160px);
            justify-content: space-between;
            column-gap: 0;
            row-gap: 20px;
            width: 100%;
            padding: 20px;

            li {
                padding: 0!important;
            }
        }
    }
}

@media only screen and (min-width: (320px)) and (max-width: (379px))  {
    .drilldown .drilldown-container .banners-area > ul {
        padding: 0!important;
    }
}
