@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/opensans/light/opensans-300",
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/opensans/regular/opensans-400",
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/opensans/semibold/opensans-600",
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/opensans/bold/opensans-700",
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/opensans/italic/OpenSans-Italic",
    $font-style: italic
);

.text {
    &-10 {
        font-size: 10px;
    }

    &-12 {
        font-size: 12px;
    }

    &-14 {
        font-size: 14px;
    }

    &-16 {
        font-size: 16px;
    }

    &-18 {
        font-size: 18px;
    }

    &-20 {
        font-size: 20px;
    }

    &-22 {
        font-size: 22px;
    }
}

.fw-bold {
    font-weight: 700;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
