.main-content {
    .block-promo {
        margin-bottom: 0;
    }

    .main-block-promo-wrapper {
        display: grid;
        grid-template-columns: repeat(3, $cms_block_width);
        grid-template-rows: repeat(3, 32.8%);
        justify-content: center;
        grid-column-gap: 2.5rem;
        grid-row-gap: 2rem;
        margin-top: 27px;
        margin-bottom: -18px;

        a {
            position: relative;

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block; 
            }

            .content {
                top: auto;
                bottom: 9px;
                margin: 20px;
                overflow: visible;
                padding: 0;
                position: absolute;

                .info.color-black {
                    color: #122948;
                }

                .info {
                    color: #FFFFFF;
                    font-size: 40px;
                    font-weight: bold;
                    line-height: 55px;
                    margin: 0 0 19px 1px;
                }

                .action {
                    padding: 1rem 2rem;
                    color: #FFFFFF;
                    font-size: 18px;
                }
            }
        }

        .item_1 {
            grid-column-start: 1;
            grid-column-end: 1;
        }

        .item__2 {
            grid-column-start: 2;
            grid-column-end: 4;
        }

        .item__1, .item__2 {
            grid-row-start: 1;
            grid-row-end: 1;
        }

        .item__4 {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 2;
        }

        .item__3 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 2;
        }

        .item__5, .item__6, .item__9 {
            grid-column-start: auto;
            grid-column-end: auto;
            grid-row-start: 3;
            grid-row-end: 3;
        }
    }
}

@include max-screen($screen__xl) {
    .main-content {
        .main-block-promo-wrapper {
            grid-gap: 2.2rem;

            a {
                .content {
                    margin: 0 10px;

                    .info {
                        font-size: 30px;
                        line-height: 41px;
                    }

                    .action {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        line-height: 18px;
                        padding: 0;
                        width: 162px;
                        height: 45px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen_nav__m) {
    .main-content {
        .main-block-promo-wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-column-gap: 0;
            grid-row-gap: 20px;

            a {
                .content {
                    bottom: 12px;

                    .info {
                        margin: 0 0 19px 1px;
                    }
                }
            }

            .item__5, .item__6, .item__9 {
                margin-top: 0;
            }

            .item_1, .item_4 {
                margin-bottom: 12px;
            }

            .block-promo {
                height: 335px;
                grid-column-start: auto;
                grid-column-end: auto;
                grid-row-start: auto;
                grid-row-end: auto;

                // &:last-of-type {
                //     display: none;
                // }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .main-content {    
        .main-block-promo-wrapper {
            margin-top: -4px;
            margin-bottom: -86px;
        }
    }
}
