.cta {
    &-link {
        display: flex;
        align-items: center;
        
        &:before {
            content: '';
            width: 10px;
            height: 14px;
            display: inline-block;
            background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNi42NjciPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzOCIgZD0iTTAgMS42NjhWMTVhMS42NjggMS42NjggMCAwMDIuODQ1IDEuMTc4bDYuNjY3LTYuNjY1YTEuNjY3IDEuNjY3IDAgMDAwLTIuMzU3TDIuODQ1LjQ5QTEuNjY4IDEuNjY4IDAgMDAwIDEuNjY4eiIgZmlsbD0iIzAwYWRlNyIvPjwvc3ZnPg==');
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 5px;
        }
    }
}