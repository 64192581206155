//  Newsletter subscription
.block.newsletter {
    background-color: #B5B5B5;

    .form.subscribe {
        display: flex;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;
        max-width: 485px;
        width: 85%;
        min-width: 230px;

        .control {
            @include lib-icon-font(
                $icon-envelope,
                $_icon-font-size        : 35px,
                $_icon-font-line-height : 33px,
                $_icon-font-color       : $form-element-input-placeholder__color
            );
            display: block;
            width: 100%;

            &:before {
                display: none;
                position: absolute;
            }
        }

        input {
            height: 60px;
            padding-left: 22px;
            font: normal normal normal 18px/24px Open Sans;
        }
    }

    .title {
        display: block;
        max-width: 701px;
        margin-bottom: 0;
        text-align: left;
        font: normal normal bold 30px/41px Open Sans;
        letter-spacing: 0px;
        color: #122948;
        padding-right: 19px;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 26%;
        max-width: 170px;
        min-width: 120px;

        button {
            width: 100%;
            height: 60px;
            font: normal normal normal 18px/24px Open Sans;
            background-color: #00ADE7;
            color: #FFFFFF;
            border: unset;
            letter-spacing: 0px;
        }
    }

    .action.subscribe {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -1px;
    }
}

@include max-screen($screen__xs) {
    div.block.newsletter {
        .field {
            width: 100%;
            min-width: inherit;
        }

        .actions {
            width: 100%;
            max-width: inherit;
        }

        .content {
            .subscribe {
                margin-left: 0;
            }

            .form.subscribe {
                width: 100%;
                flex-direction: column;
            }
        }
    }
}


@include max-screen($screen_nav__xs) {
    .catalog-product-view {
        .page-main .block.newsletter {
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }

    div.block.newsletter {
        display: grid;
        position: relative;
        grid-template-columns: repeat(1, 1fr);
        max-height: 280px;
        margin-bottom: 18px;
        padding: 0 !important;

        .desc-v {
            display: none;
        }

        div.mage-error[generated] {
            margin-top: 0;
            position: absolute;
            bottom: 0;
        }

        .mobile-v {
            display: block;
        }

        .content,
        .title {
            padding-left: 13px!important;
            padding-right: 8px!important;
        }

        .title {
            font: normal normal bold 20px/27px Open Sans;
            max-width: inherit;
            padding: 15px 0;
        }

        .content {
            padding: 0 0 23px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .catalog-product-view {
        .page-main .newsletter {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .block.newsletter {
        .desc-v {
            display: block;
        }

        .mobile-v {
            display: none;
        }

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        height: 350px;
        margin-top: 68px;
        margin-bottom: 17px;
        padding: 0 3.9rem;

        .content {
            max-width: 660px;
            width: 100%;
        }
    }
}
