@mixin clear-children-margins {
    & > * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin container {
    margin: 0 auto;
    max-width: var(--container-width);
    box-sizing: border-box;
    // padding: 0;
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    div.custom-notify-block > div,
    div.header.content {
    }
}

@mixin menu-mobile-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $link_navigation_menu__color;
}

@mixin clear-margin {
    margin: 0;
}

@mixin type-italic {
    font-style: italic;
}

@mixin footer-link {
    font-size: 14px;
    line-height: 26px;
    color: $base-footer__link__color;
}
