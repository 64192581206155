.catalog-product-view {
    .fotorama__nav-wrap--vertical {
        &.fotorama__nav-wrap {
            right: 0;
            left: inherit;
        }

        .fotorama__stage__frame .fotorama__img {
            left: 20%;
        }

        .fotorama__nav__frame {
            margin-bottom: 10px;
        }

        .fotorama__thumb-border {
            display: none;
        }
    }
    .fotorama__stage {
        left: 0 !important;
    }

    .product-info-main {
        .page-title-wrapper {
            margin-top: 12px;
            margin-bottom: 12px;
            padding-left: 0;
        }
        .page-title {
            color: $color-main;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
        }
    }

    .product-propportions {
        color: $color-main;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .product.attribute {
        &.sku .value {
            color: $color-medium_gray;
            font-size: 14px;
            line-height: 20px;
        }

        &.overview {
            margin: 19px 0 17px;
            color: $color-main;
            font-size: 14px;
            line-height: 20px;
            p {
                margin-bottom: 0;
            }
        }
    }

    .product {
        &-stock {
            display: flex;
            padding-top: 18px;
            border-top: 1px solid $color-medium_gray;
            &__label {
                color: $color-main;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }

            &__value.in {
                margin-left: 52px;
                color: $color-green;
                font-size: 14px;
                line-height: 20px;
            }

            &__value.out {
                color: $color-red;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &-werkdagen {
            display: flex;
            &__label {
                color: $color-main;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }

            &__value {
                margin-left: 37px;
                color: $color-sky_blue;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .prices-tier {
        margin: 10px 0;
        padding: 18px;
        background: $color-main;
        .item {
            color: $color-white;
            font-size: 14px;
            .price {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }

    .price-box {
        display: flex;
        align-items: baseline;
        .price-final_price .price {
            color: $color-main;
            font-weight: 700;
            font-size: 40px;
            line-height: 55px;
        }

        .oldPrice .price {
            color: $color-medium_gray;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }

        .special-price {
            margin: 10px 10px 13px 0;
        }
    }

    .box-tocart {
        width: 100%;
        margin: 0 0 20px;
        .field.qty {
            padding-right: 10px;
        }

        .control {
            display: flex;
        }

        .increase,
        .decrease {
            color: $color-main;
            width: 40px;
            min-width: auto;
            height: auto;
            padding: 0;
            background-color: $color-white_light;
            border: 1px solid $color-medium_gray;
            font-size: 2.2rem;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

            svg {
                fill: $color-main;
                max-width: 16px;
                max-height: 16px;
            }

            &:hover {
                border-color: darken($color-medium_gray, 10%);
            }
            // display: inline-block;
            // width: 50px;
            // height: 50px;
            // background: $color-medium_gray;
            // color: $color-white;
            // font-size: 40px;
            // text-align: center;
            // &:hover {
            //     color: $color-white;
            // }
        }

        .increase {
            line-height: 48px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 0;

            &.disabled {
                pointer-events: none;
            }
        }

        .decrease {
            line-height: 48px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right: 0;
        }

        .input-text.qty {
            width: 90px;
            height: 50px;
            font-size: 20px;
            line-height: 27px;
        }

        .actions {
            width: 100%;
            padding-top: 0;
            .tocart {
                width: 100%;
                border: none;
                border-radius: 0;
                display: flex;
                justify-content: center;

                &:hover {
                    border: none;
                }
            }
        }
    }

    .product-video__title {
        margin-bottom: 21px;
        color: $color-main;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }

    .product-video__row {
        width: 320px;
        height: 192px;
        margin: 0 auto;
        border: 10px solid $color-white_light;
    }

    .meer-informatie {
        display: inline-block;
        margin-bottom: 52px;
        &__title {
            margin: 0 0 20px;
            color: $color-main;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
        }

        &__article {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .video-container {
        width: 100%;
        display: inline-block;
        margin-bottom: 52px;
    }

    .product-specificaties {
        margin-bottom: 36px;
    }

    .specificaties {
        &-title {
            margin-top: 0;
            margin-bottom: 22px;
            color: $color-main;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
        }

        &-item {
            display: flex;
            padding: 10px 20px;

            &__label {
                width: 50%;
                margin-bottom: 0;
                color: $color-main;
                font-size: 14px;
                line-height: 20px;
            }

            &__value {
                margin-bottom: 0;
                color: $color-main;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .block.related,
    .block.upsell {
        margin-bottom: 24px;
        .block {
            &-title {
                color: $color-main;
                font-weight: 700;
                text-align: center;
                strong {
                    margin: 0 auto;
                    font-size: 40px;
                    line-height: 55px;
                }
            }

            &-short-desc {
                color: $color-main;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
            }
        }

        .abs-product-link,
        .product-item-name,
        .product.name a {
            font-weight: 700;
        }

        .product-item-actions .actions-primary {
            display: block;
        }

        .image-wrap {
            border: 1px solid $color-gray-border;
            background-color: $color-light_gray;
        }

        .product-items {
            display: grid;
            grid-column-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            margin-left: 0;
        }

        .product-item {
            .product-item-details {
                margin-top: 27px;
            }

            &-name,
            &-size,
            &-stock {
                font-size: 14px;
                line-height: 15px;
            }

            &-name {
                height: 40px;
                a {
                    font-weight: 700;
                    color: $color-main;
                }
            }

            &-size {
                height: 15px;
                color: $color-medium_gray;
                font-weight: 400;
                margin-bottom: 13px;
            }

            &-stock {
                margin-bottom: 3px;
                font-weight: 400;
                &.in {
                    color: $color-green;
                }
                &.out {
                    color: $color-red;
                }
            }

            &-prices {
                display: flex;
                align-items: baseline;

                .price-final_price .price {
                    display: inline-block;
                    margin-right: 10px;
                    color: $color-red;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                }

                .oldPrice .price {
                    color: $color-main;
                    text-decoration: line-through;
                }

                .vanaf {
                    display: inline-block;
                    margin-bottom: 5px;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    color: $color-medium_gray;
                    white-space: nowrap;
                }
            }

            .price-box {
                margin: 9px 0 0;
                .special-price {
                    margin: 0;
                }
            }

            &-toCart {
                width: 100%;
            }
        }

        .product-item {
            .action.primary {
                width: 100%;
                height: 35px;
                background-color: $color-orange;
                border: 1px solid $color-orange;
                border-radius: 0;
                font-weight: 400;
                &:hover {
                    background: $color-orange;
                    border: 1px solid $color-orange;
                }
            }

            &-info:hover {
                .action.primary {
                    visibility: visible;
                }
            }
        }
    }

    button[title="Download PDF"] {
        width: 100%;
        margin-top: 10px;
        background-color: var(--color-orange);
        color: white;
        transition: var(--default-transition);

        &:hover {
            background-color: var(--color-dark_orange);
        }
    }
}

@media only screen and (min-width: 768px) {
    .catalog-product-view {
        .breadcrumbs {
            margin-top: 28px;
        }

        .product-video__row {
            width: 613px;
            height: 368px;
            border: 19px solid $color-white_light;
        }
    }

    .meer-informatie__article {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 40px;
        -moz-column-gap: 40px;
        column-gap: 40px;
    }

    .specificaties-items {
        display: grid;
        grid-column: 2;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
    }

    .item-background {
        background-color: $color-white_light;
    }

    .catalog-product-view {
        .block.related,
        .block.upsell {
            .product-item-info {
                width: 200px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .catalog-product-view {
        .block.related,
        .block.upsell {
            .product-items {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    .product-item {
        .action.primary {
            visibility: hidden;
        }

        &-info:hover {
            .action.primary {
                visibility: visible;
            }
        }
    }
}

@media only screen and (min-width: 1400px) {
    .catalog-product-view {
        .block.related,
        .block.upsell {
            .product-item-info {
                width: 335px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .catalog-product-view {
        .fotorama__stage {
            margin-bottom: 11px;
        }

        .fotorama__nav-wrap {
            margin-bottom: 8px;
        }

        .fotorama__thumb-border {
            display: none;
        }

        .product-info-main .page-title-wrapper {
            margin-bottom: 15px;
        }

        .product-propportions {
            margin-bottom: 16px;
        }

        .product.attribute.overview {
            margin: 14px 0 19px;
        }

        .prices-tier {
            margin: 9px 0;
        }

        .box-tocart {
            .field.qty {
                padding-right: 0;

                .input-text.qty {
                    width: 80%;
                }
            }
        }

        .info-price {
            flex-direction: column;
            margin-bottom: 14px;
            .right-info .item {
                padding-left: 70px;
                &:before {
                    left: 42px;
                }

                &-space {
                    padding-left: 71px;
                    margin-top: -12px;
                    margin-bottom: 10px;
                }
            }
        }

        .video-container {
            margin-bottom: 20px;
        }

        .product-video__title {
            margin-bottom: 11px;
            text-align: left;
        }

        .meer-informatie {
            margin-bottom: 45px;
        }

        .product-specificaties {
            margin-bottom: 23px;
        }

        .specificaties-item {
            line-height: 20px;
            &:nth-child(odd) {
                background-color: $color-white_light;
            }
        }

        .block.related,
        .block.upsell {
            .block-title {
                text-align: left;
                strong {
                    font-size: 20px;
                    line-height: 27px;
                }
            }

            .block-short-desc {
                text-align: left;
            }

            .products {
                margin: 23px 0 32px;
                .product-item-details {
                    margin-top: 7px;
                }
            }

            .product-item-actions {
                margin-bottom: 20px;
            }

            .product-item-info {
                width: 160px;
                max-width: none;
            }

            .product-item-name,
            .product-item-size,
            .product-item-stock {
                font-size: 12px;
                line-height: 17px;
            }

            .product-item-name {
                height: 75px;
            }

            .product-item .price-box {
                margin: 0;
                height: auto;
            }

            .product-item-prices {
                flex-wrap: wrap;
                height: 60px;
                margin: 9px 0 11px;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .catalog-product-view .block.upsell .product-item-info {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1449px) {
    .catalog-product-view {
        .column.main {
            padding: 0 20px 15px;
        }
    }
}
