.page-main {
    .fpcat {
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;

        &__container {
            @include container;
        }

        &__header {
            font-size: 12px;
            padding: 0 0 20px 0;

            .title {
                margin: 0;
            }
        }

        .fpcat-card {
            width: calc((100% - 50px) / 6);
            overflow: hidden;
            border-radius: var(--border-radius);

            &:hover {
                .fpcat-card__link {
                    text-decoration: underline;
                }
                .fpcat-card__image {
                    transform: scale(1.05);
                }
            }

            &__content {
                // margin-right: 10px;
                background-color: var(--color-light_gray);
            }

            &__link {
                color: $color-dark_blue;
                display: block;
            }

            &__media {
                overflow: hidden;
            }

            &__image {
                width: 100%;
                padding-bottom: 100%;
                background-size: cover;
                transition: var(--default-transition);
            }

            &__meta {
                width: 100%;
                text-align: center;
                padding: 20px 0;
            }

            &__title {
                font-size: 16px;
                font-weight: bold;
                margin: 0;
            }
        }
    }
}

@include max-screen($screen__xl) {
    .main-content {
        .fpcat {
            .fpcat-card {
                width: calc(100% / 5);
            }
        }
    }
}

@include max-screen($screen__l) {
    .main-content {
        .fpcat {
            .fpcat-card {
                width: calc(100% / 4);

                &__title {
                    font-size: 14px;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .main-content {
        .fpcat {
            .fpcat-card {
                width: calc(100% / 2.4);

                &__title {
                    font-size: 14px;
                }
            }
        }
    }
}
