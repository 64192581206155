$layout-column-main__sidebar-offset: 2% !default;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset !default;

.columns {
    // @include lib-layout-columns();
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 25px;

    .column.main {
        @extend .abs-add-box-sizing;
        @include lib-css(padding-bottom, $indent__xl);
        grid-column: span 12 / span 12;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-order(-1);
        grid-column: span 12 / span 12;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing;
        @include lib-vendor-prefix-flex-grow(1);
        @include lib-vendor-prefix-flex-basis(100%);
        @include lib-vendor-prefix-order(2);
    }
}

.page-main {
    // These flex properties should be combined to `flex: 1 0 auto`
    // sass-lint:disable no-css-comments
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    max-width: var(--container-width);
    width: 100%;
    padding-top: 20px;
    padding-right: 1rem;
    padding-bottom: 0;
    padding-left: 1rem;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    @include lib-vendor-prefix-flex-grow(1);
    @include lib-vendor-prefix-flex-shrink(0);
    @include lib-vendor-prefix-flex-basis(auto);
}

.navigation,
.page-header .header.panel,
.footer.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container {
    max-width: var(--container-width);
    width: calc(100% - 40px);
    padding: 0;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.page-layout-contentpage {
    .columns {
        .column.main {
            width: 100%;
        }
    }
}

.page-layout-contentpage-small {
    .columns {
        .column.main {
            width: 100%;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen_nav__xs) {
    .page-main {
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
}

//
//  Phone
//  _____________________________________________

@include min-screen($screen__m) {
    .page-main {
        padding-top: 1.5rem;
        padding-right: 1.5rem;
    }

    .catalog-product-view {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }
        }
    }

    .page-layout-contentpage {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }
        }
    }

    .page-layout-contentpagesmall {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }
        }
    }

    .columns {
        .column.main {
            grid-column: span 8 / span 8;
        }

        .sidebar-main {
            @include lib-vendor-prefix-order(-1);
            grid-column: span 4 / span 4;
        }
    }

    .checkout-index-index {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }

            .sidebar-main {
                @include lib-vendor-prefix-order(-1);
                grid-column: span 0 / span 0;
            }
        }
    }

    .cms-home,
    .page-layout-1column {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }
        }
    }

    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    // .header.content,
    .footer.content,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        // padding: 0 20px;
    }
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__l) {
    .page-layout-checkout,
    .page-layout-1column,
    .catalog-product-view {
        .columns {
            .column.main {
                grid-column: span 12 / span 12;
            }
        }
    }

    .page-layout-2columns-left {
        .columns {
            .column.main {
                grid-column: span 9 / span 9;
            }

            .sidebar-main {
                grid-column: span 3 / span 3;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen_nav__xs) {
    .page-main {
        .ie9 & {
            width: auto;
        }
    }

    // .columns {
    //     display: block;
    // }

    .column.main {
        // @extend .abs-add-box-sizing-desktop;
        // @include lib-layout-columns__main();
        // min-height: 300px;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        // @include lib-layout-columns__left();
        // padding-right: $layout-column-main__sidebar-offset;
    }

    .page-layout-2columns-right .sidebar-main {
        // padding-left: $layout-column-main__sidebar-offset;
        // padding-right: 0;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        // @include lib-layout-columns__right();
        clear: right;
        padding-left: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: $layout-column__additional-sidebar-offset;
        }
    }

    .panel.header {
        padding: 10px 20px;
    }
}
