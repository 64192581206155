//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

// for navigation menu
$screen_nav__xs: 993px !default;
$screen_nav__s  : 375px !default;
$screen_nav__m  : 768px !default;
$screen_nav__xl  : 1025px !default;
$screen_nav__xxl  : 1201px !default;

$screen__xs  : 576px !default;
$screen__s   : 640px !default;
$screen__m   : 768px !default;
$screen__l   : 992px !default;
$screen__xl  : 1440px !default;