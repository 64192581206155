.breadcrumbs {
    .item {
        color: $color-main;

        font-size: 14px;
        line-height: 15px;

        & a {
            color: $color-dark_blue;
            font-weight: 400;
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }

        &:not(:last-child):after {
            content: ">";
            margin-left: 10px;
            margin-right: 10px;
            color: $color-dark_blue;
            line-height: 14px;
        }
    }

    strong {
        font-weight: 700;
        margin-left: 1px;
    }
}

.filter-mobile {
    display: none;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    background-color: $color-dark_blue;
    border: 1px solid $color-dark_blue;
    color: $color-white;
    font-size: 14px;
    line-height: 23px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    z-index: 99;

    &:hover {
        background-color: $color-dark_blue;
        border: 1px solid $color-dark_blue;
        color: $color-white;
    }

    .icon__filter {
        width: 17px;
        height: 15px;
        margin-right: 10px;
    }
}

.widget__popular-products,
.catalogsearch-result-index,
.catalog-category-view {
    .top-container .block {
        margin: 0 0 17px;
    }

    .search.results {
        .block {
            display: none;
        }
    }

    .category-view {
        margin-bottom: 41px;
    }

    .abs-product-link,
    .product-item-name,
    .product.name a {
        line-height: calc(18 / 13);
        font-weight: 700;
    }

    .product-item-actions .actions-primary {
        display: block;
    }

    .image-wrap {
        // border: 1px solid $color-gray-border;
        // background-color: $color-light_gray;
    }

    .category-item {
        &-name,
        &-size,
        &-stock {
            font-size: 14px;
            line-height: 15px;
        }

        &-name {
            // height: 40px;
            a {
                font-weight: 700;
                color: $color-main;
            }
        }

        &-size {
            height: 15px;
            color: $color-medium_gray;
            font-weight: 400;
            margin-bottom: 13px;
        }

        &-stock {
            margin-bottom: 10px;
            font-weight: 400;
            &.in {
                color: $color-green;
            }
            &.out {
                color: $color-red;
            }
        }

        &-prices {
            display: flex;
            align-items: end;
            margin-bottom: 20px;
            .newPrice,
            .oldPrice {
                display: inline-block;
                margin-right: 10px;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
            }

            .newPrice {
                color: $color-red;
            }

            .oldPrice {
                color: $color-main;
                text-decoration: line-through;
            }

            .vanaf {
                display: inline-block;
                font-weight: 400;
                font-size: 11px;
                line-height: 13px;
                color: $color-medium_gray;
            }
        }

        &-toCart {
            width: 100%;
        }
    }

    .product-item {
        .action.primary {
            background-color: $color-orange;
            border: 1px solid $color-orange;
            border-radius: var(--border-radius);
            font-weight: 400;

            &:hover {
                background: #cb9013;
                border: 1px solid #cb9013;
            }
        }
    }

    div.pages {
        .action {
            width: 39px;
            height: 39px;
            border: 1px solid $color-light_blue;
            color: $color-light_blue;

            &:hover {
                color: $color-light_blue;
            }

            &.previous:before {
                margin: 3px 0 0 -3px;
                color: $color-light_blue;
            }

            &.next {
                margin-left: 0;
                &:before {
                    margin: 3px 0 0 -3px;
                    color: $color-light_blue;
                }
            }
        }

        .items {
            font-weight: 400;
            justify-content: end;
            &.full-width {
                justify-content: space-between;
            }
        }

        .item {
            width: 40px;
            height: 40px;
            font-size: 18px;
            line-height: 37px;
            margin: 0 9px 0 0;
            &:last-child {
                margin: 0;
            }
            &.current {
                width: 39px;
                height: 39px;
                background-color: $color-main;
                border: 1px solid $color-main;
            }
        }

        strong.page {
            color: $color-white;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
        }

        a.page {
            color: $color-sky_blue;
        }
    }

    .pages .action.previous:hover:before,
    .pages .action.next:hover:before {
        color: $color-sky_blue;
    }

    .page-bottom {
        h3 {
            margin: 0 0 34px;
            color: $color-main;
            font-weight: 700;
            font-size: 40px;
            line-height: 46px;
            letter-spacing: 1px;
        }

        p {
            color: $color-main;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            &last-child {
                margin-bottom: 25px;
            }
        }

        .pagebuilder-column-group {
            display: flex;
            justify-content: space-between;
            margin-top: 46px;
            .pagebuilder-column {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .page-main {
        > .block-static-block.widget {
            order: 1;

            [data-content-type="row"][data-appearance="contained"] {
                max-width: inherit;
            }
        }

        .block.newsletter {
            order: 2;
            margin: 1rem 0 2rem 0;

            .form.subscribe {
                align-items: center;
            }

            .action.subscribe {
                margin-left: 0;
            }
        }

        .home-video__cms-block {
            padding-bottom: 1rem;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .catalogsearch-result-index,
    .catalog-category-view {
        .product-items {
        }
    }
}

@media only screen and (min-width: 1280px) {
    .catalogsearch-result-index,
    .catalog-category-view,
    .catalog-product-view {
        [data-content-type="row"][data-appearance="contained"] {
            max-width: 1280px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .catalogsearch-result-index,
    .catalog-category-view {
        .top-container,
        .category-description {
            [data-content-type="row"][data-appearance="contained"] {
                max-width: 100%;
            }
        }
    }

    .page-wrapper > .page-bottom .top {
        max-width: 1280px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1450px) {
    .catalogsearch-result-index,
    .catalog-category-view,
    .catalog-product-view {
        .navigation,
        .breadcrumbs,
        .page-header .header.panel,
        .header.content,
        .page-wrapper > .widget,
        .page-wrapper > .page-bottom,
        .block.category.event,
        .top-container,
        .page-main,
        [data-content-type="row"][data-appearance="contained"] {
            max-width: var(--container-width);
            // padding-left: 0;
            // padding-right: 0;
        }

        .product-items {
            .item {
                width: 335px;
            }
        }
    }

    .catalogsearch-result-index .page-main > .page-title-wrapper .page-title,
    .catalog-category-view .page-main > .page-title-wrapper .page-title {
        margin-top: 7px;
    }

    .page-wrapper > .page-bottom .top {
        max-width: var(--container-width);
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }

    .page-wrapper > .page-bottom .bottom {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (min-width: 769px) {
    .catalogsearch-result-index,
    .catalog-category-view {
        .read-more {
            display: none;
        }

        .page-wrapper > .page-bottom,
        .footer.content {
            width: 100%;
            max-width: 100%;
            [data-content-type="row"][data-appearance="contained"] {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .category-view {
        position: relative;
    }

    .category-description {
        // height: 55px;
        // overflow: hidden;

        &.show {
            height: auto;
            overflow: visible;
        }
    }

    .read {
        &-wrap {
            position: absolute;
            width: 90px;
            right: 10px;
            bottom: -9px;
            background-color: $color-white;
        }

        &-more {
            visibility: hidden;
            color: $color-sky_blue;
            &:before {
                content: "... ";
                color: $color-main;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    body.filters-active {
        overflow: hidden;

        header {
            display: none;
        }

        .nav-sections__fixed {
            z-index: 1;
        }

        .block.filter.active {
            z-index: 2;
            .filters-overlay {
                background: rgba(0, 0, 0, 0.65);
                content: "";
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 100;
            }
        }
    }

    .sidebar.sidebar-main {
        .block.filter {
            .blocks {
                position: fixed;
                display: block;
                box-sizing: border-box;
                width: 70vw;
                height: 100vh;
                padding: 40px 20px 20px;
                top: 0;
                left: -70vw;
                transition: left 250ms;
                background-color: $color-white;
                z-index: 1002;
                overflow: auto;
            }
            .close-filters {
                position: absolute;
                right: 28px;
                top: 28px;
                width: 28px;
                height: 28px;
                opacity: 1;
                cursor: pointer;

                &:hover {
                    opacity: 0.3;
                }

                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: " ";
                    height: 28px;
                    width: 2px;
                    background-color: $color-black;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
            &.active {
                .blocks {
                    left: 0;

                    .close-filters {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .page-layout-2columns-left .column.main {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .top-container {
        padding-left: 0;
        padding-right: 0;
    }

    .breadcrumbs {
        .item {
            display: none;
            width: 0;

            &:nth-last-child(2) {
                display: flex;

                &:before {
                    content: "<";
                    margin-left: 0;
                    margin-right: 10px;
                    color: $color-dark_blue;
                    line-height: 14px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .filter-mobile {
        display: flex;
    }

    .catalogsearch-result-index,
    .catalog-category-view {
        .category-view {
            margin-bottom: 25px;
        }

        .category-item {
            &-name {
                height: auto;
            }
        }

        .product-items .item .price-box {
            // height: 60px;
        }

        .product-item .action.primary {
            font-size: 14px;
            line-height: 15px;
        }

        .columns .column.main {
            padding-bottom: 0;
        }

        .page-bottom h3 {
            margin: 0 0 16px;
        }

        .page-bottom .pagebuilder-column-group {
            margin-top: 90px;
        }

        div.pages {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: -31px;
                width: 100%;
                height: 1px;
                background-color: $color-gray;
            }

            .items {
                display: flex;
                justify-content: center;
            }

            .item {
                margin: 0;
            }
        }
    }

    .products {
        margin: 30px 0 32px;
    }
}

@media only screen and (max-width: 879px) {
    .page-title-wrapper .page-title,
    .catalogsearch-result-index .page-bottom h3,
    .catalog-category-view .page-bottom h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
    }

    .category-item-name {
        // margin-bottom: 20px;
    }

    .catalogsearch-result-index,
    .catalog-category-view {
        .page-bottom .pagebuilder-column-group .pagebuilder-column {
            margin-right: 0;
            margin-bottom: 18px;
        }
    }
}

@include max-screen($screen_nav__xs) {
    .widget__popular-products,
    .catalogsearch-result-index,
    .catalog-category-view {
        .page-main {
            .block.newsletter,
            .root_category-widget-block {
                margin: 0 2rem 0 2rem;

                .home-video__cms-block div[data-content-type="video"] iframe {
                    width: 100%;
                    padding: 0 0 2rem 0;
                }
            }

            .block.newsletter {
                margin-bottom: 2rem;
                margin-top: 0;
            }
        }
    }
}
