.contactpage {

    &__col {
        width: 100%;

        & > * {
            display: block !important;
        }
    }

    &__title {
        display: inline-block;
        margin-bottom: 13px;
        color: #122948;
        font-weight: 700;
        line-height: 1;
    }

    h2 {
        display: inline-block;
        margin-bottom: 13px;
        color: #122948;
        font-weight: 700;
        line-height: 1;

    }

    ul {
        list-style: none;
        margin: 0 0 20px 0;
        padding: 0;
    }

    .table-openingtimes {

        tr {
            &:nth-child(odd) {
                background-color: var(--color-light-gray-blue);
            }
        }

    }

}

@include min-screen($screen__l) {
    .contactpage {
        display: flex;
        column-gap: 25px;

        &__col {
            width: 50%;

            & > * {
                display: block !important;
            }
        }
    }
}