//custom variables for layout
$widgets-indent__bottom: 0;
$content__max-width: var(--container-width);
$content__padding_lr: 0 20px;
$layout__max-width: 1920px;

// the header cms blocks
$header-cms-block-top__height: 30px;
$header-cms-block-bottom__height: 40px;

// home cms blocks
$cms_block_width: 32.1%;
$home_cms_block_width: 74.5%;
// navigation menu (mega-menu)
$link_navigation_menu__color: var(--color-dark_blue);
$color__darkblue: #122948;
$link_navigation_menu__border-color: #CECECE;
$navigation_menu__font-size: 14px;


$light_blue_bright_color: #edf9fd;
$dark-blue_color: #122948;
$bright-blue_color: #00ADE7;
$red_color: #DD0707;

// base text
$link__color: $link_navigation_menu__color;
$link__hover__text-decoration: none;
$link__active__text-decoration: none;
$link__active__color: #00ADE7;
// $link__hover__color: #00ADE7;

//footer
$base-footer__bg-color    : #122948;
$base-footer__link__color : #FFFFFF;

:root {

    --container-width: 1440px;

}