.cat-product {
    position: relative;
    padding: 50px 0;

    &-border {
        &-bottom {
            border-bottom: 1px solid var(--color-light_gray);
            margin-bottom: 50px;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;

        .cat-product-card {
            width: calc((100% - 75px) / 6) !important;
            margin-right: 15px;
            margin-bottom: 15px;

            &:nth-child(6n) {
                margin-right: 0;
            }
        }
    }
}

.cat-product-card {
    width: calc((100% - 75px) / 6) !important;
    box-sizing: border-box;
    border: 1px solid var(--color-light_gray);
    transition: var(--default-transition);
    overflow: hidden;
    border-radius: var(--border-radius);

    &__link {
        display: block;
        color: var(--color-dark_blue);

        &:hover {
            color: var(--color-dark_blue);
            text-decoration: underline;
        }
    }

    &__picture {
        img {
            width: 100%;
        }
    }

    &__wrapper {
    }

    &__meta {
        text-align: center;
        padding: 20px 10px;
    }
}

// @include max-screen($screen__xl) {
// }

@include max-screen($screen__xl) {
    .cat-product {
        &__container {
            .cat-product-card {
                width: calc((100% - 45px) / 4) !important;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                &:nth-child(6n) {
                    margin-right: 15px;
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .cat-product-card {
        width: calc((100% - 45px) / 4) !important;
    }

    .cat-product {
        &__container {
            .cat-product-card {
                width: calc((100% - 30px) / 3) !important;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .cat-product-card {
        width: calc(100% / 1.6) !important;
    }
}

@include max-screen($screen__s) {
    .cat-product {
        &__container {
            .cat-product-card {
                width: calc((100% - 15px) / 2) !important;

                &:nth-child(3n) {
                    margin-right: 15px;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}
