a.action.primary,
button.action.primary {
    @include lib-css(border-radius, var(--border-radius));
    @include lib-css(background-color, $color-main);
    @include lib-css(color, $color-white);
    @include lib-css(font-size, 1.4rem);
    @include lib-css(font-weight, 300);
    @include lib-css(border, none);
    @include lib-css(padding, 1.5rem);
    @include lib-css(text-align, center);
    @include lib-css(transition, var(--default-transition));

    &:hover {
        @include lib-css(background-color, $color-sky_blue);
        @include lib-css(border, none);
        @include lib-css(color, $color-white);
    }
}
button {
    &:active {
        @include lib-css(box-shadow, $button__shadow);
    }
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {
    @include lib-css(transition, var(--default-transition));
    @include lib-button-primary(
        $_button-font-size: 1.4rem,
        $_button-font-weight: 300,
        $_button-line-height: 20rem,
        $_button-padding: 15px,
        $_button-color: $color-white,
        $_button-background: $color-green,
        $_button-border: none,
        $_button-background-hover: $color-light_green,
        $_button-border-hover: none // $_button-color-hover                 : $button-primary__hover__color,
        // $_button-gradient-color-start-hover  : $button-primary__hover__gradient-color-start,
        // $_button-gradient-color-end-hover    : $button-primary__hover__gradient-color-end,
        // $_button-color-active                : $button-primary__active__color,
        // $_button-background-active           : $button-primary__active__background,
        // $_button-border-active               : $button-primary__active__border,
    );
    // @include lib-css(font-size, 1.3rem);
    // @include lib-css(color, $color-white);
    // @include lib-css(font-weight, 300);
}

//
//  Button icon
//  ---------------------------------------------
.button-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    svg {
        max-width: 20px;
        max-height: 20px;
        fill: $color-white;
    }
}

@include min-screen($screen__l) {
    a.action.primary,
    button.action.primary {
        @include lib-css(min-width, 25rem);
    }
}
