//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;
$form__max-width: 680px;

.login-container {
    .block {
        &-new-customer {
            .actions-toolbar {
                margin-top: 25px;
            }
        }

        .block-title {
            @extend .abs-login-block-title;
            @include lib-font-size(18);
        }
    }

    .fieldset {
        @include lib-form-hasrequired(bottom);
        &:after {
            margin-top: 35px;
        }
    }
}

.block-addresses-list {
    .items.addresses {
        > .item {
            margin-bottom: $indent__base;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.form-address-edit {
    #region_id {
        display: none;
    }

    .actions-toolbar .action.primary {
        @extend .abs-button-l;
    }
}

.form-edit-account {
    .fieldset.password {
        display: none;
    }
}

.box-billing-address,
.box-shipping-address,
.box-information,
.box-newsletter {
    .box-content {
        line-height: 26px;
    }
}

//  Full name fieldset
.fieldset {
    .fullname {
        &.field {
            > .label {
                @extend .abs-visually-hidden;

                + .control {
                    width: 100%;
                }
            }
        }

        .field {
            @extend .abs-add-clearfix;
        }
    }
}

//
//  My account
//  ---------------------------------------------

.account.page-layout-2columns-left {
    .block.newsletter,
    .remove__notify_block {
        display: none;
    }

    .column.main {
        .actions-toolbar {
            .primary {
                max-width: 178px;
            }

            .secondary {
                display: block !important;
                color: #00ade7;

                a {
                    margin-top: 15px;
                    text-decoration: underline;
                    font-size: 14px;
                }
            }
        }
    }

    .my-address-edit {
        .fieldset > .legend {
            margin: 0 0 41px;
        }

        .field.additional {
            margin-top: 16px;
        }

        .field.street {
            margin-bottom: -12px;
        }

        .label span {
            font-weight: normal;
        }
    }

    .page-title-wrapper {
        display: none;
    }

    .block-dashboard-addresses {
        .block-title {
            a.action {
                display: none;
            }
        }
    }

    .copyright {
        span {
            padding: 4px 0px 0 0;
        }
    }

    .page-main {
        align-items: center;
    }

    .columns {
        justify-content: start;
        max-width: var(--container-width);
        width: 100%;
        margin-bottom: 71px;
    }

    .sidebar-main {
        padding-right: 0;
        order: inherit;
    }

    .column.main {
        .block .block-title-top,
        .block-title-top {
            margin: -13px 0 30px 0;

            > strong {
                color: #122948;
                font-size: 40px;
                line-height: 55px;
                font-weight: bold;
            }
        }

        .control {
            div.mage-error {
                color: #c91717;
                font-size: 14px;
                font-weight: bold;
            }

            input.mage-error {
                border-color: #b82e25 !important;
            }
        }

        .my-mage-error {
            label {
                span {
                    color: #c91717 !important;
                }

                &::after {
                    color: #c91717;
                }
            }
        }

        .block {
            h2 {
                margin-top: 0;
            }

            &.block-dashboard-info {
                margin-bottom: 60px;
            }

            .box-content p,
            .box-title > span {
                color: #464646;
                font-size: 14px;
            }

            .box-content {
                .box {
                    margin-bottom: 6px;
                }

                address {
                    font-size: 14px;
                }
            }

            .block-title {
                margin-bottom: 30px;

                > strong {
                    color: #464646;
                    font-size: 20px;
                    line-height: 27px;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                > span {
                    font-weight: bold;
                }
            }

            .box-title {
                margin-bottom: -6px;
                margin-top: 0px;
            }

            .box-actions a span,
            .box-actions a + a {
                color: #00ade7;
                font-size: 14px;
                text-decoration: underline;
            }

            .toolbar {
                text-align: center;
                .limiter-options {
                    width: auto;
                }
            }

            .limiter {
                > .label {
                    @extend .abs-visually-hidden;
                }
            }

            .block:not(.widget) {
                @extend .abs-account-blocks;
            }
        }
    }

    .sidebar-additional {
        margin-top: 40px;
    }

    .table-wrapper {
        &:last-child {
            margin-bottom: 0;
        }

        .action {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-return-items {
        .qty {
            .input-text {
                @extend .abs-input-qty;
            }
        }
    }
}

//  Checkout address (create shipping address)
.field.street {
    .field.additional {
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Account navigation
//  ---------------------------------------------

.account-nav {
    .title {
        @extend .abs-visually-hidden;
    }

    .content {
        background-color: #122948;
        padding: 20px 0 18px 0;
    }

    .item {
        margin: 0 0 20px 0;

        &:first-child {
            margin-top: 0;
        }

        a,
        > strong {
            @include lib-css(color, $account-nav-color);
            border-left: 5px solid transparent;
            display: block;
            padding: 0 18px 0 15px;
        }

        a {
            color: #ffffff;
            font-size: 14px;
            line-height: 18px;
            text-decoration: none;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &.current {
            strong {
                border-left: 6px solid #00ade7;
                color: #ffffff;
                text-transform: lowercase;
                font-weight: bold;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }

        .delimiter {
            border-top: 1px solid #ffffff;
            display: block;
            margin: $indent__s 2rem;
        }
    }
}

//
//  Blocks & Widgets
//  ---------------------------------------------

.block {
    // @extend .abs-margin-for-blocks-and-widgets;

    .column.main & {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: $indent__s;

        strong {
            @include lib-heading(h4);
            .column.main & {
                font-size: $h3__font-size;
            }
        }
    }

    p:last-child {
        margin: 0;
    }

    .box-actions {
        margin-top: $indent__xs;
    }
}

//
//  Password Strength Meter
//  ---------------------------------------------

.field.password {
    .control {
        position: relative;
        @include lib-vendor-prefix-display();
        @include lib-vendor-prefix-flex-direction();

        .mage-error {
            @include lib-vendor-prefix-order(2);
        }

        .input-text.register-password {
            @include lib-vendor-prefix-order(0);
            padding: 0 50px 0 20px !important;
        }

        .choice-pass {
            margin-bottom: 0;
            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"] + label:before {
                content: "";
                display: block;
                height: 20px;
                width: 30px;
                position: absolute;
                cursor: pointer;
                left: auto;
                right: 0;
                top: 16px;
                margin-right: 12px;
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDMwIDIwIj4KICA8cGF0aCBpZD0i0JrQvtC90YLRg9GAXzc0IiBkYXRhLW5hbWU9ItCa0L7QvdGC0YPRgCA3NCIgZD0iTTE1LDc1LjY2N0EzLjMzMywzLjMzMywwLDEsMCwxNSw2OWMtLjA1MiwwLS4xLjAxMi0uMTQ4LjAxNWEyLjQ3NCwyLjQ3NCwwLDAsMS0zLjE3LDMuMTdjMCwuMDUyLS4wMTUuMS0uMDE1LjE0OEEzLjMzMywzLjMzMywwLDAsMCwxNSw3NS42NjdaTTI5LjgxOSw3My4yNEExNi43LDE2LjcsMCwwLDAsMTUsNjQsMTYuNzA4LDE2LjcwOCwwLDAsMCwuMTgyLDczLjI0YTEuNjg1LDEuNjg1LDAsMCwwLDAsMS41MkExNi43LDE2LjcsMCwwLDAsMTUsODRhMTYuNzA4LDE2LjcwOCwwLDAsMCwxNC44MTktOS4yNEExLjY4NSwxLjY4NSwwLDAsMCwyOS44MTksNzMuMjRaTTE1LDY1LjY2N2E2LjY2Nyw2LjY2NywwLDEsMS02LjY2Nyw2LjY2N0E2LjY2Nyw2LjY2NywwLDAsMSwxNSw2NS42NjdabTAsMTYuNjY3QTE1LjAxMywxNS4wMTMsMCwwLDEsMS42NjcsNzRhMTUuMzUzLDE1LjM1MywwLDAsMSw2Ljc1OS02LjczNiw4LjMyLDguMzIsMCwxLDAsMTQuOTA3LDUuMDY5LDguMjU3LDguMjU3LDAsMCwwLTEuNzU5LTUuMDY5QTE1LjM1MywxNS4zNTMsMCwwLDEsMjguMzM0LDc0LDE1LjAxMywxNS4wMTMsMCwwLDEsMTUsODIuMzMzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC02NCkiIGZpbGw9IiNiNWI1YjUiLz4KPC9zdmc+Cg==")
                    no-repeat center;
            }
        }
    }
}

.password-strength-meter {
    background-color: #464646;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    position: relative;
    font-size: 14px;
    color: #ffffff;

    &:before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none & {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak & {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium & {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong & {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong & {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}

.control.captcha-image {
    @include lib-css(margin-top, $indent__s);

    .captcha-img {
        vertical-align: middle;
    }
}

.customer-account-create #maincontent {
    .page-title-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        .page-title {
            font-weight: bold;
            color: #122948;
            font-size: 40px;
            line-height: 55px;
            letter-spacing: 0;
            max-width: $form__max-width;
            min-width: 600px;
            width: 55%;
            margin: -3px 0 17px 0;
        }
    }

    form {
        margin: 0 auto;
        max-width: $form__max-width;
    }
}

.account #maincontent,
.customer-account-create #maincontent {
    .subtitle {
        font-weight: bold;
        color: #464646;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 54px;
    }

    form {
        .secondary {
            display: none;
        }

        .additional_info {
            margin: 0 0 30px;
        }

        .control {
            width: 100%;
        }

        .fieldset {
            &.create {
                margin: 0;
            }
        }

        .confirmation {
            margin-bottom: 0;
        }

        .field:not(.choice) {
            display: flex;
            flex-direction: column;

            input {
                color: #000000;
                font-size: 14px;
                line-height: 24px;
                padding: 0 10px;
                border-color: #707070;
                height: 40px;
            }

            .label {
                margin-bottom: 5px;
                padding: 0px 15px 0 0;
                white-space: nowrap;

                span {
                    font-size: 14px;
                    line-height: 18px;
                    color: #122948;
                }
            }
        }

        .choice:not(.choice-pass) {
            display: none;
        }

        .actions-toolbar {
            margin-left: 0;

            .primary {
                width: 100%;

                button {
                    border: unset;
                    border-radius: unset;
                    font-size: 15px;
                    font-weight: normal;
                    line-height: 20px;
                    padding: 12.5px 15px;
                    color: #ffffff;
                    background-color: #e6a532;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .account {
        .column.main,
        .sidebar-additional {
            margin: 0;
        }
    }

    .customer-account-create #maincontent {
        .page-title-wrapper {
            .page-title {
                font-size: 30px;
                line-height: 27px;
                min-width: fit-content;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 30px;
        }
    }
}

@include max-screen($screen__m) {
    .customer-account-create #maincontent {
        margin: 0 10px;
    }

    .block {
        .box-actions {
            margin-bottom: 2rem;
        }
    }

    .account.page-layout-2columns-left {
        .columns {
            .column.main {
                .actions-toolbar {
                    .primary {
                        max-width: inherit;
                    }
                }
            }
        }
    }

    .login-container {
        .fieldset {
            &:after {
                text-align: center;
            }
        }
    }

    .account {
        .column.main {
            padding: 0 2rem;
        }

        .page.messages {
            margin-bottom: $indent__xl;
        }

        .toolbar {
            @extend .abs-pager-toolbar-mobile;
        }
    }

    .control.captcha-image {
        .captcha-img {
            @include lib-css(margin-bottom, $indent__s);
            display: block;
        }
    }

    .customer-account-index {
        .page-title-wrapper {
            position: relative;
        }
    }

    .customer-address-form {
        .column.main {
            padding: 0 2rem;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include max-screen($screen_nav__xs) {
    .account.page-layout-2columns-left {
        .column.main {
            margin-right: 2rem;
        }
    }
}

@include min-screen($screen__m) {
    .login-container {
        @extend .abs-add-clearfix-desktop;

        .block {
            @extend .abs-blocks-2columns;
            &.login {
                .actions-toolbar {
                    > .primary {
                        margin-bottom: 0;
                        margin-right: $indent__l;
                    }

                    > .secondary {
                        float: left;
                    }
                }
            }
        }

        .fieldset {
            &:after {
                @extend .abs-margin-for-forms-desktop;
            }

            > .field {
                > .control {
                    width: 100%;
                }
            }
        }
    }

    //  Full name fieldset
    .fieldset {
        .fullname {
            .field {
                .label {
                    @include lib-css(margin, $form-field-type-label-inline__margin);
                    @include lib-css(padding, $form-field-type-label-inline__padding);
                    @include lib-css(text-align, $form-field-type-label-inline__align);
                    @include lib-css(width, $form-field-type-label-inline__width);
                    box-sizing: border-box;
                    float: left;
                }

                .control {
                    width: 100%;
                    float: left;
                }
            }
        }
    }

    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account {
        min-width: 600px;
        width: 50%;
    }

    //
    //  My account
    //  ---------------------------------------------

    .account.page-layout-2columns-left {
        .columns {
            margin-top: 50px;
        }
    }

    .account {
        .column.main {
            .block:not(.widget) {
                .block-content {
                    @extend .abs-add-clearfix-desktop;

                    .box {
                        @extend .abs-blocks-2columns;
                        width: 47.8%;
                    }
                }
            }
        }

        .toolbar {
            @extend .abs-pager-toolbar;
        }
    }

    .block-addresses-list {
        .items.addresses {
            @extend .abs-add-clearfix-desktop;
            font-size: 0;

            > .item {
                display: inline-block;
                font-size: $font-size__base;
                margin-bottom: $indent__base;
                vertical-align: top;
                width: 48.8%;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-child(even) {
                    margin-left: 2.4%;
                }
            }
        }
    }

    //
    //  Welcome block
    //  ---------------------------------------------

    .dashboard-welcome-toggler {
        @extend .abs-visually-hidden-desktop;
    }

    .control.captcha-image {
        .captcha-img {
            margin: 0 $indent__s $indent__s 0;
        }
    }
}
