// Colors
$color-black: #000000;
$color-blue: #013672;
$color-white: #ffffff;
$color-white_light: #f5f5f5;
$color-light_gray: #f7f7f7;
$color-gray: #8f8f8f;
$color-gray-border: #e5e5e5;
$color-main: #122948;
$color-light_gray: #a3a3a3;
$color-medium_gray: #b5b5b5;
$color-dark_gray: #333333;
$color-green: #00bb00;
$color-light_green: #1f9e1f;
$color-red: #c91717;
$color-orange: #e6a532;
$color-dark_orange: #cb9012;
$color-light_blue: #4daae2;
$color-sky_blue: #00ade7;
$color-dark_blue: #182846;

// Sizes
$font-size__xxl: 30px;
$font-size__xl: 21px;
$font-size__l: 18px;
$font-size__m: 16px;
$font-size__s: 12px;
$font-size__xs: 11px;

:root {
    --default-transition: all 250ms;

    --color-black: #000;
    --color-dark_blue: #182846;
    --color-white: #ffffff;
    --color-sky_blue: #00ade7;
    --color-gray: #8f8f8f;
    --color-light_gray: #f7f7f7;
    --color-light-gray-blue: #f2f7fc;
    --color-medium_gray: #b5b5b5;
    --color-dark_gray: #333333;
    --color-orange: #e6a532;
    --color-dark_orange: #cb9012;
    --color-red: #c91717;
    --color-green: #00bb00;

    --border-radius: 0;

    --header-height: 168px;
}
