.block-static-block,
.block-cms-link {
    &.widget {
        @include lib-css(margin-bottom, $widgets-indent__bottom);
        .links & {
            margin-bottom: 0;
        }
    }
}

.custom-notify-block > div {
    @include container;
    height: 100%;

    @include max-screen($screen_nav__xxl) {
        padding-left: 20px;
    }
}

.custom-notify-block.first-block {
    justify-content: center!important;

    @include max-screen($screen__s) {
        p, span {
            font-size: 10px;
            line-height: 14px;
        }

        p {
            padding-right: 25px;
        }
    }

    @include min-screen($screen__s ) {
        span {
            font-size: 12px;
            line-height: 17px;
        }
    }
}

.block-cms-link-inline {
    &.widget {
        margin: 0;
    }
}


// top notification area
body > div.block-static-block [data-content-type='row'] {
    position: relative;
    min-height: $header-cms-block-top__height;
    overflow: hidden;
    max-height: max-content;
}

body > div.block-static-block ~ div.block-static-block [data-content-type='row'] {
    height: $header-cms-block-bottom__height;
    overflow: hidden;
    max-height: 40px;
    font-size: 11px;
    line-height: 5px;

    div.custom-notify-column {
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
        height: 40px;
        
        figure {
            padding-right: 8.75px;
        }

        > div {
            padding-top: 2px;
        }
    }

    .custom-notify-column:nth-child(2) {
        justify-content: center!important;
    }

    .custom-notify-column:nth-last-child(-n+2) {
        justify-content: end!important;

        figure {
            padding-right: 10.95px;
        }
    }

    .custom-notify-column:last-of-type {
        figure {
            padding-right: 8px;
        }
    }

    @include max-screen($screen_nav__xxl) {
        display: none !important;
    }
}

.remove__notify_block {
    position: absolute;
    display: inline-block;
    background-color: transparent;
    border: none;
    right: 0;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);

    @include max-screen($screen_nav__xxl) {
        padding-right: 20px;
    }

    &::before {
        font-family: 'Font Awesome 5 Free';
        content: '\f00d';
        font-size: 10px;
    }

    &:hover {
        background-color: transparent;
        border: none;
    }
}
