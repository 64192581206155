.nav-tree {

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: none;

        &--sub {
            list-style-type: none;
            margin: 0 0 0 15px;
            padding: 0;
        }

    }

    &__item {
        margin: 0;
        line-height: 1.75;
    }

    &__link {
        color: $color-main;

        &:hover {
            text-decoration: underline;
            color: $color-main;
        }

        &:visited {
            color: var(--color-dark_blue);
        }

        &.active {
            font-weight: bold;
            &:hover {
                text-decoration: none;
            }
        }

    }
    

}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen_nav__m) {
    .nav-tree {

        &__list {
            display: block;     
        }   
    }
}