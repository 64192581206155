$footer__max-width: $content__max-width;

.page-footer {
    background-color: $base-footer__bg-color;
    padding: 0 2rem;

    .footer.links {
        display: grid;
        max-width: $footer__max-width;
        grid-column-gap: 23px;
        padding: 45px 0 48px 0;
        margin: 0 auto;
        list-style: none;
        color: white;
        line-height: 26px;

        li {
            white-space: nowrap;
            margin-bottom: 0;

            a {
                color: #ffffff;
            }
        }

        .col {
            padding: 0;
            list-style: none;
        }

        .title {
            padding: 4px 0 13px 0;
            font-weight: 800;
            @include footer-link;
        }

        .spacer {
            margin-top: 26px;
        }

        .item-images {
            display: flex;
            img {
                padding-right: 2rem;
            }
        }

        a {
            @include footer-link;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer {

    &__icon {
        
        &:before {
            content: '';
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 10px;
        }

        &--phone {

            &:before {
                background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjI5MS43MDZMMTEuMzM3LjAyM2EuOTE4LjkxOCAwIDAwLTEuMDQ5LjUzTDguOTI2IDMuNzMyYS45MTkuOTE5IDAgMDAuMjYxIDEuMDc0bDEuNTc5IDEuMjg3YTEwLjI1MiAxMC4yNTIgMCAwMS00LjY3NiA0LjY3Nkw0LjggOS4xOWEuOTE5LjkxOSAwIDAwLTEuMDctLjI2MUwuNTUzIDEwLjI5MmEuOTE0LjkxNCAwIDAwLS41MyAxLjA0NmwuNjggMi45NWEuOTE1LjkxNSAwIDAwLjg5NC43MTIgMTMuNCAxMy40IDAgMDAxMy40LTEzLjQuOTEuOTEgMCAwMC0uNzA2LS44OTR6TTEuNjE0IDE0LjA2MkwuOTQgMTEuMTQ0bDMuMTQ3LTEuMzQ4IDEuNzQzIDIuMTMzYTExLjMyIDExLjMyIDAgMDA2LjEtNi4xTDkuNzk0IDQuMDkgMTEuMTQxLjk0M2wyLjkxOC42NzRBMTIuNDU5IDEyLjQ1OSAwIDAxMS42MTQgMTQuMDYyeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
            }

        }

        &--mail {
            &:before {
                background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjEyNSAwSDEuODc1QTEuODc1IDEuODc1IDAgMDAwIDEuODc1djExLjI1QTEuODc1IDEuODc1IDAgMDAxLjg3NSAxNWgxNi4yNUExLjg3NSAxLjg3NSAwIDAwMjAgMTMuMTI1VjEuODc1QTEuODc1IDEuODc1IDAgMDAxOC4xMjUgMHpNMS44NzUgMS4yNWgxNi4yNWEuNjI3LjYyNyAwIDAxLjYyNS42MjV2MS42MTdjLS44NTUuNzIzLTIuMDc4IDEuNzE5LTUuODgzIDQuNzM4LS42Ni41MjMtMS45NjEgMS43ODUtMi44NjcgMS43Ny0uOTA2LjAxNi0yLjIxMS0xLjI0Ni0yLjg2Ny0xLjc3LTMuOC0zLjAyLTUuMDI3LTQuMDE2LTUuODgzLTQuNzM4VjEuODc1YS42MjcuNjI3IDAgMDEuNjI1LS42MjV6bTE2LjI1IDEyLjVIMS44NzVhLjYyNy42MjcgMCAwMS0uNjI1LS42MjVWNS4xMTdjLjg5MS43MyAyLjMgMS44NTkgNS4xMDUgNC4wOS44LjY0MSAyLjIxNSAyLjA1MSAzLjY0NSAyLjA0MyAxLjQyMi4wMTIgMi44MjQtMS4zODcgMy42NDUtMi4wNDMgMi44MDktMi4yMyA0LjIxNS0zLjM1OSA1LjEwNS00LjA5djguMDA4YS42MjcuNjI3IDAgMDEtLjYyNS42MjV6IiBmaWxsPSIjZmZmIi8+PC9zdmc+');
            }
        }
    }

}

.copyright {
    display: flex;
    align-items: center;
    padding: 0 30px;
    background-color: #FFFFFF;
    height: 100px;
    color: #707070;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;

    span {
        max-width: $footer__max-width;
        width: 100%;
        padding: 2px 7px 0 0;
        margin: 0 auto;
        white-space: nowrap;
    }
}

// Desctop
@include min-screen($screen_nav__xxl - 1) {
    .footer.links {
        grid-template-rows: 1fr;
        grid-template-columns: repeat(4, minmax(min-content, 327px));
    }
}

//Midsize
@media only screen and (min-width: ($screen__xs - 1)) and (max-width: $screen_nav__xxl)  {
    .footer.links {
        grid-template-rows: 2fr;
        grid-template-columns: repeat(2,  minmax(min-content, 1fr));
    }
   
}

// Mobile
@include max-screen($screen__xs) {
    .footer.links {
        grid-template-rows: repeat(4, auto);
        grid-row-gap: 36px;
        grid-template-columns: auto;
        padding: 23px 0 33px 0!important;

        .col {
            padding: 0 6px 0px;
        }

        > li:nth-last-child(2) {
            margin-top: -15px;
        }

        > li:last-child {
            .titie {
                margin-top: -4px;
            }
        }

        .title {
            padding: 0 0 12px 0!important;
        }
    }

    .copyright {
        align-items: start;
        padding: 21px 0 0 22px;
        height: 60px;
        line-height: 17px;

        span {
            display: block;
            width: 95%;
            white-space: initial;
            margin: 0;
        }
    }
}
