.product-banner {

    & > div {
        height: 100%;
    }
}

.banner-general {
    background: $color-white_light;
    color: $color-white;
    text-align: center;
    box-sizing: border-box;
    height: 100%;

    &__link {
        display: flex;
        align-items: end;
        position: relative;
        height: 100%;
        padding: 10px;
        color: $color-white;
        box-sizing: border-box;

        &:hover {
            color: $color-white;
        }
    }


    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-size: cover;
        background-position: center center;
    }

    &__meta {
        background-color: $color-blue;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
    }

}
