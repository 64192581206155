.header.content {
    margin: 0 auto;
    max-width: var(--container-width);
    position: relative;
    display: grid;
    // grid-template-columns: auto minmax(420px, 468px) repeat(3, auto);
    align-items: center;
    justify-content: start;
    padding: 10px 20px;
    box-sizing: border-box;

    .icon__mobile_logo {
        display: none;
    }

    .icon__login,
    .icon__cart {
        padding-bottom: 6px;
    }

    .logo {
        max-height: 54px;
        z-index: 5;

        .icon__desc_logo {
            width: 271px;
            height: 54px;
        }
    }

    .icon-text {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #000000;
    }

    .icon__login {
        width: 26.25px;
    }

    .icon__cart {
        width: 33.75px;
    }

    .header__last-block {
        position: absolute;
        display: flex;
        justify-content: space-between;

        .icon__login,
        .icon__cart {
            height: 30px;
            padding: 0 3px 6px 0;
        }

        .minicart-wrapper {
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .authorization-link {
            .mobile-auth-icon {
                display: none;
            }

            .icon-text {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
        }

        .authorization-link,
        .action.showcart {
            display: flex;
            cursor: pointer;
            flex-direction: column;
            align-items: center;
            white-space: nowrap;
            padding: 0 0;
            &:before {
                display: none;
            }
        }

        .authorization-link {
            padding-right: 15px;
        }

        .counter.qty {
            position: absolute;
            top: 0;
            right: 20px;
            visibility: visible;
            background-color: $color-light_blue;
            color: $color-white;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 10px;
            text-align: center;
            line-height: 18px;
            padding: 0;
            margin: 0;

            &.empty {
                display: none;
            }
        }
    }
}

.checkout-index-index {
    .header.content {
        grid-template-columns: auto;
        justify-content: center;
        padding: 10px 0;
    }
}

.customer-header {
    position: relative;
    padding-right: 10px;
    padding-left: 0;
    margin: 0;
    > li {
        list-style: none;
        margin: 0;
    }
    .customer-welcome {
        .customer-name {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .greet.welcome {
            padding: 5px;
            margin: -5px;
            display: inline-block;
            &:hover {
                background: #f2fbfe;
            }

            > a {
                display: flex;
                cursor: pointer;
                flex-direction: column;
                align-items: center;
                white-space: nowrap;
            }

            svg.icon.icon__login {
                display: inline-block;
                vertical-align: top;
                padding-right: 0;
            }
        }

        &.active {
            & + .customer-menu {
                display: block;
            }

            .greet.welcome {
                background: #00ade7;
                position: relative;
                z-index: 105;

                .icon-text {
                    color: #fff;
                }
                .icon__login {
                    filter: invert(1);
                }
            }
        }

        &.active:hover {
            .greet.welcome {
                background: #00ade7;
                .icon-text {
                    color: #fff;
                }
                .icon__login {
                    filter: invert(1);
                }
            }
        }
        &:hover {
            .greet.welcome {
                background: #f2fbfe;
            }
        }
    }

    .customer-menu {
        display: none;
        position: absolute;
        background: #fff;
        padding: 20px 20px 10px;
        border-top: 13px solid #00ade7;
        z-index: 100;
        right: 5px;
        top: calc(100% + 5px);
        white-space: nowrap;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);

        .header.links {
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            > li {
                list-style: none;
                order: 1;
                &.link.authorization-link {
                    align-items: flex-start;
                    order: 2;
                }
                a:visited {
                    color: #000;
                }
            }
        }
    }
}

.minicart-wrapper {
    .block-minicart {
        padding: 10px 20px;
        border: none;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
        &:after {
            border-color: transparent transparent #ddd transparent;
        }

        .block-title {
            display: block;
            strong {
                margin-bottom: 10px;
                color: #122948;
                font-size: 1.8rem;

                .qty {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    border-radius: 100%;
                    background: #02ace7;
                    color: #fff;
                    text-align: center;
                    line-height: 22px;
                    font-size: 1.4rem;
                }
            }
        }

        .subtotal {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .minicart-widgets {
        margin: 0;
    }

    .action.showcart {
        &::before {
            content: "";
            display: none;
        }
        .counter.qty .loader > img {
            width: 18px;
            height: 18px;
        }
    }
}

.minicart-items {
    .product-item {
        padding: 10px 0;
        border-bottom-color: #ccc;

        &:not(:first-child) {
            border-top: none;
        }

        .wrap {
            display: flex;
            position: relative;
        }
        .product-item-photo {
            padding-right: 20px;
        }
        .in-cart {
            position: absolute;
            right: -10px;
            top: -5px;
        }
        .product-item-details {
            padding: 0;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            .details-qty {
                margin-top: 5px;
            }
        }
        .product-item-name {
            height: auto;
            margin-bottom: 5px;
            margin-right: 5px;

            a {
                color: #00ade7;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.minicart-top {
    .minicart-items-wrapper {
        border: none;
        padding-bottom: 0;
    }
    .buttons {
        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
        }
        .action.primary {
            min-width: auto;
            background: #f1a200;
            color: #fff;
            font-weight: 600;
        }
        .action.viewcart {
            color: #00ade7;
        }
    }
}

.block-minicart .subtotal {
    margin: 10px;
    display: flex;
    align-items: baseline;
    gap: 10px;
    justify-content: flex-end;
}

.address-autofill-formatted-output {
    background-color: var(--color-light_gray);
    font-weight: bold;
    padding: 30px;
}

@include max-screen($screen__m) {
    .minicart-items {
        .product-item {
            .product-image-container {
                max-width: 50px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen_nav__xs) {
    .icon-text {
        display: none;
    }

    div.header.content {
        padding: 10px 20px;

        div.header__last-block {
            justify-content: space-between;
            right: 19px;
            top: 13px;
            z-index: 999;

            .authorization-link {
                .mobile-auth-icon {
                    display: block;
                }

                .mobile-auth-icon + .icon-text {
                    display: none;
                }
            }
        }

        .icon__mobile_logo {
            display: block;
            width: 91px;
            height: 55px;
        }
    }
    .header.content .header__last-block .counter.qty {
        right: 8px;
    }
}

@include max-screen($screen_nav__xl) {
    .header.content {
        .icon__desc_logo {
            display: none;
        }

        .icon__mobile_logo {
            display: block;
            width: 60px;
            height: 54px;
        }
    }
}

@include max-screen($screen_nav__xxl) {
    .header.content {
        grid-template-columns: repeat(2, auto) 420px 0.5fr auto;
        padding: 10px 20px;

        div.header__last-block {
            right: 38px;
            // top: 3px;
        }

        .logo {
            .icon__desc_logo {
                padding-left: 4px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen_nav__xxl) {
    .header.content {
        grid-template-columns: auto 1fr repeat(2, auto);

        div.header__last-block {
            justify-content: space-between;
            right: 20px;
            // top: 2px;

            .authorization-link {
                .mobile-auth-icon {
                    display: none;
                }
            }

            .icon__login {
                width: 26.25px;
            }

            .icon__cart {
                width: 33.75px;
            }

            .counter.qty {
                right: 10px;
            }
        }

        .logo {
            .icon__desc_logo {
                padding-left: 0;
                margin-left: -2px;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .header.content {
        .header__last-block {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.nav-sections__fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 1000;

    &.is-hidden {
        opacity: 0;
        transform: translate(0, -60px);
        transition:
            -webkit-transform 0.2s,
            background 0.3s,
            color 0.3s,
            opacity 0 0.3s;
        background-color: #ffffff;
    }

    &.is-visible {
        opacity: 1;
        transform: translate(0, 0);
        transition:
            -webkit-transform 0.2s,
            background 0.3s,
            color 0.3s;
        background-color: #ffffff;
        margin-top: 0px;
    }
}
