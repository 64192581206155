.font-bold {
    font-weight: bold;
    font-size: 14px;
}

.font-italic {
    font-style: italic;
}

.font-size60 {
    font-size: 60px;
    line-height: calc(27 / 20);
}

.font-size50 {
    font-size: 50px;
    line-height: calc(27 / 20);
}

.font-size30 {
    font-size: 30px;
    line-height: calc(27 / 20);
}

.font-size40 {
    font-size: 40px;
    line-height: calc(27 / 20);
}

.font-size20 {
    font-size: 20px;
    line-height: calc(27 / 20);
}

.font-size18 {
    font-size: 18px;
    line-height: calc(27 / 20);
}

a.type-btn,
.type-btn {
    padding: 1rem 2rem;
    color: #ffffff;
    overflow: hidden;
    border-radius: var(--border-radius);
}

.type-btn__yellow {
    background-color: #e6a532;

    &:hover {
        background-color: #cb9012;
    }
}

.type-btn__blue {
    background-color: #00ade7;

    &:hover {
        background-color: $color__darkblue;
    }
}

a.type-btn__darkblue,
.type-btn__darkblue {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: $color__darkblue;
}
// !*******

.main-content {
    > .row-full-width-inner {
        display: flex;
        flex-direction: column;
    }

    .pagebuilder-slider {
        position: relative;
        margin-bottom: 20px;

        .pagebuilder-slide-wrapper {
            .pagebuilder-poster-content {
                position: relative;
            }

            .pagebuilder-overlay {
                padding: 16.7%;
                height: 318px;
            }
        }

        .root-text-area {
            position: absolute;
            top: -136px;
            left: -10px;
            background-color: #122948 !important;
            width: 362px;
            max-height: 320px;
            opacity: 0.85;

            .banner-text-area {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                padding: 14px 16px 20px 21px;
                height: 90%;
            }

            h1 {
                letter-spacing: 0px;
                color: #ffffff;
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 25px;
            }

            p {
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0px;
                margin-bottom: 0;

                &:first-of-type {
                    max-width: 206px;
                    max-height: 81px;
                    color: #ffffff;
                }

                &:last-of-type {
                    max-width: 198px;
                    max-height: 54px;
                    color: #b5b5b5;
                    margin: 19px 0px;
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 18px;
                width: 162px;
                height: 45px;
            }
        }
    }

    .pagebuilder-slide-wrapper {
        height: 100%;
    }

    .slick-dots {
        position: absolute;
        top: auto;
        bottom: 0;
        padding: 18px 0;

        li {
            width: 10px;
            height: 10px;
            margin: 0;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }

            &.slick-active {
                width: 20px;

                button {
                    border-color: #00ade7;
                    background-color: #00ade7;
                }
            }

            button {
                border: 1px solid #b5b5b5;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@include max-screen($screen_nav__xl) {
    .main-content {
        .pagebuilder-slider .root-text-area {
            width: 265px;
            margin-top: 20px;
            margin-left: -40px;

            h1 {
                font-size: 1.6rem;
            }

            p {
                font-size: 1.7rem;
            }

            a {
                font-size: 1.3rem;
            }
        }
    }
}

@include max-screen($screen__s) {
    .main-content {
        .main-slider-dits {
            min-height: 460px !important;
            margin: 0 10px;

            .slick-dots {
                bottom: auto;
                top: 44%;
                padding: 17px 0;
            }
        }

        .pagebuilder-slider .pagebuilder-slide-wrapper {
            min-height: 230px !important;
            background-position: center center !important;

            .pagebuilder-poster-content {
                position: static;
            }

            [data-element="content"] {
                min-height: inherit;
            }

            .pagebuilder-overlay {
                padding: 0;
                min-height: 230px !important;
                height: 230px !important;
            }
        }

        .pagebuilder-slider .root-text-area {
            top: auto;
            bottom: 0;
            left: auto;
            height: 230px;
            width: 100%;
            margin-top: 0;
            margin-left: 0;
            opacity: 0.85;

            .banner-text-area {
                height: 100%;
                max-width: 355px;
                padding: 29px 16px 20px 20px;
            }

            h1 {
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 10px;
            }

            p {
                font-size: 12px;
                line-height: 17px;
                max-width: inherit !important;

                &:last-of-type {
                    margin: 10px 0px;
                }
            }

            a {
                font-size: 12px;
                line-height: 17px;
                height: 35px;
            }

            .type-btn__yellow {
                width: 162px;
                height: 45px;
                font-size: 18px;
                line-height: 24px;
                margin-top: 30px;
            }
        }
    }
}
