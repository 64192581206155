// .headroom--pinned {
//     display: block;
// }
// .headroom--unpinned {
//     display: none;
// }

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}
.headroom--not-top.headroom--pinned {
    // box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
    border-bottom: 1px solid $color-white_light;
}
