.customerlogin {
    background-color: white;
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    border-left: 10px solid var(--color-dark_blue);
    width: 300px;
    z-index: 999;
    transform: translateX(300px);
    transition: var(--default-transition);

    &.js-active {
        transform: translateX(0);

        .customerlogin__toggle-btn {
            svg {
                transform: rotate(0);
            }
        }
    }

    &__controls {
        // transform: translateX(-50px) translateY(20px);
        width: 50px;
        height: 50px;
        background-color: var(--color-dark_blue);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: absolute;
        left: -60px;
        top: 20px;
    }

    &__toggle-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        width: 50px;
        height: 50px;

        &:hover,
        &:focus {
            background: none;
            border: none;
        }

        svg {
            max-width: 50px;
            max-height: 50px;
            transform: rotate(-180deg);
            transition: var(--default-transition);
        }
    }

    &__form {
        width: 100%;

        & > * {
            margin-bottom: 5px;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__input {
        width: 100%;
        border: none;
        padding: 10px;
        box-sizing: border-box;
        background: var(--color-light_gray);

        &:focus,
        &:hover {
            border: none;
            outline: none;
            box-shadow: none !important;
        }
    }

    &__button {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 0;
        border: none;
        background: var(--color-orange);
        color: white;
        font-weight: 400;
        text-transform: uppercase;

        &:hover,
        &:focus {
            border: none;
            background: var(--color-dark_orange);
            color: white;
        }

        &--pin {
            background: var(--color-dark_blue);
            margin-top: 10px;

            &:hover,
            &:focus {
                background: var(--color-dark_blue);
                opacity: 0.8;
            }
        }
    }

    &__wrapper {
        display: none;
    }

    &__header {
        width: 100%;
        padding: 20px;
        position: absolute;
        box-sizing: border-box;
        z-index: 2;
        // background-color: var(--color-dark_blue);
        background-color: white;
    }

    &__results {
        padding: 0 20px 20px 20px;
        margin-top: 170px;
        overflow: scroll;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    a,
    a:link,
    a:hover,
    a:visited {
        color: black;
    }

    &__auth {
        padding: 20px;

        &--numbers {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            & > *:nth-child(3),
            & > *:nth-child(6),
            & > *:nth-child(9),
            & > *:nth-child(11) {
                margin-right: 0;
            }
        }

        &--nr {
            width: calc(33.333% - 4px);
            height: 50px;
            font-size: 24px;
            margin-right: 6px;
            margin-bottom: 6px;
            border: none;
            border-radius: 0;
            background-color: var(--color-light_gray);

            &:hover {
                box-shadow: none !important;
            }

            &:focus {
                box-shadow: none !important;
                background-color: var(--color-medium_gray);
            }
        }

        &--header {
            margin-bottom: 10px;
        }

        &--code {
            width: 100% !important;
            height: auto !important;
            padding: 20px !important;
            font-size: 32px !important;
            letter-spacing: 5px !important;
            text-align: center !important;
            border: none !important;

            &:focus {
                box-shadow: none !important;
                border: none;
            }
        }

        &--submit {
            padding: 20px !important;
            font-size: 18px !important;
            font-weight: 300px !important;
        }

        &--error {
            background-color: var(--color-red);
            padding: 10px;
            text-align: center;
            font-size: 20px;
            color: white;
            display: block;
            margin-top: 10px;
            opacity: 0;
            transition: var(--default-transition);

            &-active {
                opacity: 1;
            }
        }
    }
}

.customerlogin-card {
    border: 1px solid var(--color-medium_gray);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    transition: var(--default-transition);

    &:hover {
        border: 1px solid var(--color-light_gray);
        background-color: var(--color-light_gray);
    }

    &__link {
        color: black;

        &:visited,
        &:hover,
        &:focus {
            color: black;
        }
    }

    &__addresses {
        & > * {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h3 {
            color: var(--color-dark_blue);
            font-weight: bold;
        }
    }
}

.customerlogin-addresses {
    &__submit {
        &--address {
            background-color: var(--color-sky_blue);
            width: 100%;
            font-weight: bold;
            text-align: center;
            color: white;

            &:hover {
                background-color: var(--color-dark_blue);
                color: white;
            }
        }
    }
}

.customerlogin-address {
    &__modal {
        position: fixed;
        top: 120px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-dark_blue);
        align-items: center;
        justify-content: center;
        display: none;
        padding: 10px;

        &.is-active {
            display: flex;
        }

        &--inner {
            background-color: white;
            padding: 30px;

            & > * {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            button {
                background-color: var(--color-orange);
                width: 100%;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 1.5;
                text-align: center;
                color: white;

                &:hover {
                    background-color: var(--color-dark_orange);
                }
            }
        }
    }
}
