//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : none !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : false !default;

//
//  Header
//  ---------------------------------------------

.cookie-status-message {
    display: none;
}

.sale {
    color: #DD0707!important;
    font-weight: bold!important;
}

.header__contact_area {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 0;
    align-items: center;
    margin: 0;

    > div {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 10px;
    }

    .icon__email {
        width: 20px;
        height: 20px;
    }

    .icon__phone {
        width: 19px;
        height: 15px;
        padding-bottom: 9px;
    }

    .icon__phone + a {
        padding-bottom: 5px;
    }

    a {
        color: #000000;
        opacity: 0.5;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
    }
}

@include min-screen($screen__m) {
    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
                margin-bottom: 13px;
                color: $color-main;
                font-weight: 700;
                line-height: 1;
                font-size: 3rem;
            }
        }
    }
}

@include max-screen($screen_nav__xs) {
    div.header__contact_area {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen_nav__xl) {
    div.header__contact_area {        
        top: 4px;
    }
}


@include max-screen($screen_nav__xxl) {
    .header__contact_area {
        align-items: center;
        height: 100%;
        right: 183px;
        top: 4px;

        > div {
            gap: 16px;
        }

        .contact_phone {
            padding-bottom: 6px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen_nav__xxl) {
    .header__contact_area {
        row-gap: 0;
        right: 167px;
    }
}
