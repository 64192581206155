.black-friday {
    background-color: black;
    max-width: 300px;
    text-align: center;

    p {
        color: white;
    }

    &__inner {
        padding: 20px;
    }

    &__code {
        background-color: white;
        padding: 10px;
        margin-bottom: 10px;

        &--coupon {
            font-size: 30px;
            font-weight: bold;
        }

        p {
            color: black;
            margin-bottom: 0;
        }
    }
}
