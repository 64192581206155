.customer-account-login {
    .page-wrapper {
        padding-top: 0;
    }

    .page-main {
        padding: 0;
    }
}


.page-layout-checkout {
    .page-wrapper {
        padding-top: 100px;
    }
}

.page-wrapper {
    padding-top: 95px;
}

@include min-screen($screen__l) {

    .page-layout-checkout {
        .page-wrapper {
            padding-top: 100px;
        }
    }

    .page-wrapper {
        padding-top: 150px;
    }
}

@include min-screen($screen_nav__xxl) {

    .page-layout-checkout {
        .page-wrapper {
            padding-top: 100px;
        }
    }

    .page-wrapper {
        padding-top: 170px;
    }
}