.main-content {
    .main-meta__cms-block {
        height: 350px;
        position: relative;
        margin-top: 3.7%;
        margin-bottom: 23px;
    
        img {
            position: absolute;
            max-width: $content__max-width;
            height: 350px;
            left: 50%;
            transform: translateX(-50%);
            width: 96.5%;
        }
    
        .home-meta-block__content {
            display: flex;
            position: absolute;
            height: 250px;
            top: 50px;
            left: 0;
            right: 0;
            margin: auto;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            padding: 0;
            
            .font-italic {
                font-size: 14px;
                line-height: 18px;
                margin: 4px 0 9px 0;
            }

            .font-size60 {
                margin: -31px 0;
            }

            .font-size20 {
                text-align: center;
            }

            li {
                z-index: 1;
                margin-bottom: 0;
                color: #122948;

                &:last-of-type {
                    display: flex;

                    .type-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
    
                        &:first-of-type {
                            width: 232px;
                            height: 45px;
                            margin-right: 10px;
                        }
    
                        &:last-of-type {
                            width: 170px;
                            height: 45px;
                            margin-left: 10px;
                        }
                    }
                }
            }    
        }
    }
}

@include max-screen($screen_nav__m) {
    .main-content {
        .main-meta__cms-block {
            margin-top: 15%;
            margin-bottom: 150px;

            img {
                width: 100%;
                height: 491px;
            }

            .home-meta-block__content {
                top: 30px;
                height: 491px;
                justify-content: start;

                .font-size60 {
                    margin: -18px -4px -23px 0;
                }

                .font-size20 {
                    margin-top: 10px;
                    width: 65%;
                }

                .font-size60 + .font-size20 + .font-size20 {
                    width: 70%;
                    margin: 19px 0 16px 0;
                }

                .font-italic {
                    margin: 2px 0 31px 0;
                }

                li {
                    &:last-of-type {
                        width: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .type-btn {
                            &:first-of-type {
                                width: 62%;
                                height: 45px;
                                margin: -1px -1px 22px 0;
                            }

                            &:last-of-type {
                                width: 45%;
                                height: 45px;
                                margin: 5px 0 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs ) {
    .main-content {
        .main-meta__cms-block {
            margin-top: 19%;
            margin-bottom: 141px;
        }
    }
}