.cat-card {
    color: var(--color-dark_blue);
    box-sizing: border-box;
    overflow: hidden;
    border-radius: var(--border-radius);

    &__link {
        display: block;
    }

    &-sm {
        background-color: var(--color-light_gray);
        height: calc(100% - 1.5rem);
        transition: var(--default-transition);
        margin-bottom: 1.5rem;
        overflow: hidden;
        border-radius: var(--border-radius);

        &:hover {
            .cat-card__image {
                transform: scale(1.05);
            }
        }

        .cat-card {
            &__link {
                color: var(--color-dark_blue);

                &:hover {
                    color: var(--color-dark_blue);
                }
            }

            &__picture {
                overflow: hidden;
            }

            &__image {
                transition: var(--default-transition);
            }

            &__meta {
                padding: 15px;

                & > *:last-child {
                    margin-bottom: 0;
                }
            }

            &__title {
                margin: 0 0 5px 0;
                color: var(--color-sky_blue);
            }
        }
    }

    &-lg {
        height: calc(100% - 3rem);
        transition: var(--default-transition);
        margin-bottom: 3rem;
        overflow: hidden;
        border-radius: var(--border-radius);

        .cat-card {
            &__link {
                color: var(--color-dark_blue);

                &:hover {
                    color: var(--color-dark_blue);
                }
            }

            &__picture {
                overflow: hidden;
                border-radius: var(--border-radius);
            }

            &__image {
                transition: var(--default-transition);
            }

            &__meta {
                & > *:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: var(--color-sky_blue);

                    &:hover {
                        color: var(--color-sky_blue);
                        text-decoration: underline;
                    }
                }
            }

            &__title {
                margin: 15px 0 15px 0;
                color: var(--color-dark_blue);
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    &-xxl {
        height: calc(100% - 1.5rem);
        transition: var(--default-transition);
        margin-bottom: 1.5rem;
        position: relative;
        overflow: hidden;
        border-radius: var(--border-radius);

        .cat-card {
            &__link {
                color: var(--color-dark_blue);
                height: 100%;

                &:hover {
                    color: var(--color-dark_blue);
                }
            }

            &__wrapper {
                height: 100%;
            }

            &__picture {
                overflow: hidden;
                width: 100%;
                height: 100%;
                position: relative;
            }

            &__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }

            &__slogan {
                position: absolute;
                left: 25px;
                bottom: 25px;
                background-color: var(--color-sky_blue);
                padding: 25px;
                pointer-events: none;
                overflow: hidden;
                border-radius: var(--border-radius);

                & > * {
                    display: block;
                    margin: 0;
                    padding: 0;
                    color: white;
                }

                &--title {
                    font-size: 24px;
                    font-weight: 600;
                }

                &--sub {
                    margin-top: 5px;
                }
            }
        }
    }
}
// @include max-screen($screen__xl) {
// }

@include max-screen($screen__l) {
}

@include max-screen($screen__m) {
    .cat-card {
        &-xxl {
            height: 100vw;
        }
    }
}

@include max-screen($screen__xs) {
    .cat-card {
        &-sm {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--color-light_gray);
            background-color: transparent;
            height: auto;

            &:hover {
                .cat-card__image {
                    transform: scale(1.05);
                }
            }

            .cat-card {
                &__wrapper {
                    display: flex;
                }

                &__media {
                    width: 40%;
                }

                &__picture {
                    height: 100%;
                }

                &__image {
                    height: 100%;
                    object-fit: cover;
                }

                &__meta {
                    padding: 15px;
                    width: 60%;
                    box-sizing: border-box;
                }

                &__title {
                    margin: 0 0 5px 0;
                    color: var(--color-sky_blue);
                }
            }
        }
    }
}
