.header-contact {
    width: 100%;
    background-color: var(--color-sky_blue);
    // margin-bottom: 16px;
    display: none;

    &__container {
        @include container;
        padding: 0 20px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        margin: 0;
        padding: 0;
    }

    &__link {
        line-height: 20px;
        font-size: 11px;
        color: var(--color-white);
        padding-top:10px;
        padding-bottom: 10px;
        display: flex;
        align-items:center;

        &:hover {
            color: var(--color-dark_blue);
            
            .header-contact__icon {
                svg {
                    fill: var(--color-dark_blue);
                }
            }
        }

        &:visited {
            color: var(--color-white);
        }
    }

    &__icon {
        max-height: 24px;
        margin-right: 10px;

        svg {
            height: 12px;
            fill: #fff;
        }
    }
}

@include min-screen($screen__l) {
    .header-contact {
        display: block;
    }

}

@include min-screen($screen_nav__xxl) {
    .header-contact {
        display: block;

        &__link {
            font-size: 12px;
        }

        &__icon {

            svg {
                height: 24px;
            }
        }
    }

}