$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
    }
}

.toolbar {
    // @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    position: relative;
    margin: 0;
    padding: 0 20px 0 0;
    margin-right: 20px;
    font-weight: 400;
    font-size: 14px;
    color: $color-main;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    // @extend .abs-add-clearfix;
    margin-bottom: 35px;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    padding: 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }

    &__text {
        display: inline-block;
        margin-right: 5px;
        font-weight: 400;
        font-size: 14px;
        color: $color-main;
    }
}

.sorter-options {
    margin: 0;
    padding: 10px 36px 10px 15px;
    width: 350px;
    height: 40px;
    border: 1px solid $color-light_gray;
    color: $color-main;
    font-weight: 400;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1Ljg4MiAxLjIxN2wtLjIzOS0uMjM5YS40LjQgMCAwMC0uNTcyIDBsLTYuNTcgNi41NzNMMS45MjUuOTc5YS40LjQgMCAwMC0uNTcyIDBsLS4yMzkuMjM5YS40LjQgMCAwMDAgLjU3Mmw3LjEgNy4wOTVhLjQuNCAwIDAwLjU3MSAwbDcuMDk0LTcuMWEuNC40IDAgMDAuMDAzLS41Njh6IiBmaWxsPSIjMTgyODQ2Ii8+PC9zdmc+');
    background-position: center right 20px;
    background-repeat: no-repeat;
    appearance: none;

    &:focus {
        border-color: $color-light_blue;
        box-shadow: none !important;
    }
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter {
    .sort-desc {
        &:before {
            content: $icon-arrow-down;
        }
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            // z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: right;
        }
    }

    // .toolbar-amount {
    //     float: left;
    // }


    .modes {
        display: block;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $text__color__muted;
        border: 1px solid $border-color__base;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 1px solid $border-color__base;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .toolbar-amount {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .sorter {
        float: left;
        &-options {
            // width: 190px;
            height: 30px;
            padding: 4px 7px 7px;
        }
    }
}

@media only screen and (min-width: 767px) and (max-width: 1279px) {
    .sorter {
        float: right;
        &-options {
            // width: 190px;
            height: 40px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .sorter {
        margin-left: 20px;
    }

    .catalog-category-view div.pages {
        width: 335px;

        .items {
            display: flex;
            justify-content: space-between;
        }
    }
}
