.not-found {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        max-width: 800px;
    }

    &__title {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 20px;
        padding: 0;
    }

    &__link {
        display: inline-block;
    }

    &__actions {
        margin-top: 20px;
    }

}