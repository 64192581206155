.main-content {
    .home-info-block {
        display: grid;
        grid-template-columns: repeat(2, minmax(49%, 450px));
        column-gap: 25px;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        list-style: none;
        margin: 0 0 17px 0;
        padding: 0;

        &__left {
            width: 50%;
        }

        &__right {
            width: 50%;
        }

        .text_block__1,
        .text_block__2,
        .col1 .text_block__1,
        .col2 .text_block__2 {
            display: flex;
            position: relative;
            flex-direction: column;
            text-align: center;
            align-items: center;
            height: 200px;
            justify-content: center;
            // background-color: #F5F5F5;
            background-color: var(--color-sky_blue);
            color: white;
            overflow: hidden;
            border-radius: var(--border-radius);
            padding: 10px 0;

            p {
                color: white;
            }
        }

        .text_block__1,
        .col1 .text_block__1 {
            > div:first-of-type {
                // padding: 0 15px 4px 22px;
                // height: 80px;

                > p:first-of-type {
                    margin-top: 9px;
                }

                > p:last-of-type {
                    margin-top: 5px;
                }
            }

            > div:first-of-type + div {
                display: flex;
                justify-content: center;
                height: 36px;
                padding: 5px 0 7px 0;

                p {
                    width: 55%;
                }
            }

            > div:last-of-type {
                display: flex;
                justify-content: center;
                padding-bottom: 3px;

                p {
                    width: 67%;
                }
            }
        }

        .text_block__2,
        .col3 .text_block__2 {
            white-space: nowrap;

            > div {
                margin-bottom: 10px;
            }

            .phone {
                margin: 0 0 10px 0;

                > div:first-of-type {
                    height: 30px;
                    display: flex;
                    align-items: center;

                    .phone-icon {
                        width: 20px;
                        height: 20px;
                        padding-right: 12px;
                        display: inline-block;
                    }
                }

                .address {
                    width: 100%;
                    font-style: italic;
                }
            }

            .email {
                display: flex;
                justify-content: center;
                align-items: center;
                // position: absolute;
                // top: auto;
                // bottom: 81px;
                margin-right: 5px;

                .email-icon {
                    width: 26.6px;
                    height: 20px;
                    padding-right: 12px;
                    display: inline-block;
                }
            }
        }

        p,
        ul > li {
            margin-bottom: 0 !important;
        }

        p:not([class*="font"]),
        a,
        .address,
        .font-italic {
            font-size: 14px;
            line-height: 18px;
        }

        p,
        a,
        span {
            color: white;
        }
    }

    .home-rating {
        &__link {
            display: flex;
            justify-content: center;
            align-items: baseline;
            margin: 0 auto;
            color: #333;
        }

        &__icon {
            margin-right: 10px;
            max-height: 20px;

            & > img {
                width: auto;
                max-height: 20px;
                display: block;
                margin-top: 2px;
            }
        }

        &__rating {
            margin-right: 10px;
            color: var(--color-sky_blue);

            .nr {
                font-size: 18px;
                font-weight: bold;
            }
        }

        &__amount {
            p {
                margin: 0;
            }
        }
    }
}

// @include max-screen($screen__l) {
//     .main-content {
//         .home-info-block {
//             grid-template-columns: repeat(3, 32%);
//             column-gap: 5px;
//         }
//     }
// }

@include min-screen($screen__m) {
    .main-content {
        .root-home-info-block {
        }
    }
}

@include max-screen($screen__m) {
    .page-main,
    .main-content {
        .root_home-info-block {
            margin: 0;
            align-self: inherit;
        }
        .root-home-info-block {
            margin: 0 10px;
        }
        .home-info-block {
            grid-template-columns: repeat(1, minmax(100%, 450px));
        }
        .home-rating {
            &__link {
                flex-wrap: wrap;
            }

            &__amount {
                width: 100%;
                text-align: center;
            }
        }
    }
}
