//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

div.block-search {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 468px;
    padding-bottom: 2px;
    left: 48.9%;
    transform: translateX(-50%);
    z-index: 4;
    margin-bottom: 0;
    padding-left: 0;

    .block {
        &-title {
            display: none;
        }
    }

    input {
        @include lib-input-placeholder($form-element-input-placeholder__color);
        width: 100%;
        height: 40px;
        margin: 0;
        padding-right: 35px;
        position: static;
        border: 1px solid #B5B5B5;
        border-radius: 3px;
    }

    .block-content {
        width: 100%;
        margin: 0; // 8px 0 0 -34px;

        .search__icon {
            position: absolute;
            top: 12px;
            left: auto;
            right: 13px;

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    form {
        position: relative;
        line-height: 0;
    }

    .actions {
        display: none;
    }

    .label {
        @include abs-visually-hidden();
    }
    
    .control {
        border-top: 0;
        margin: 0;
        padding: 0;
    }

    .nested {
        display: block;
        padding-top: 5px;
        position: absolute;
    }

    .action.search {
        display: inline-block;
        @include lib-button-icon(
            $_icon-font-content   : $icon-search,
            $_icon-font-text-hide : true,
            $_icon-font-color     : $header-icons-color
        );
        @include lib-button-reset();
        padding: 10px 0;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen_nav__xl) {
    html body div.block-search {
        right: 0;
        left: 380px;

        .block-content {
            width: 420px;
        }
    }
}

@include max-screen(1145px) {
    body div.block-search {
        .block-content {
            width: 300px;
        }
    }
}

@include max-screen($screen_nav__xxl) {
    div.block-search {
        right: 0;
        left: 50%;
        padding-bottom: 3px;

        .block-content {
            width: 420px;
            margin: 0 0 3px 21px;
        }
    }
}

@include max-screen($screen_nav__xs) {
    html body div.block-search {
        top: 13px;
        width: 100%;
        left: auto;
        right: 0;
        transform: none;

        .label {
            left: auto;
            right: 122px;
            top: 0;
            clip: inherit;
            height: auto;
            width: auto;
            overflow: visible;

            .search_mobile__icon {
                cursor: pointer;
                width: 30px;
                height: 30px;
            }
        }


        .block-content {
            width: 100%;
            margin: 0;

            .search__icon {
                position: absolute;
                top: 12px;
                left: auto;
                right: 13px;
                display: none;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .control {
            border-top: none;
            position: absolute;
            top: 0;
            left: auto;
            z-index: 9999;
            overflow: hidden;
        }

        input {
            left: -300%;
            margin: auto;
            position: absolute;
            border-color: #B5B5B5;
            border-radius: 3px;
        }
    

        .active {
            height: 0px;
            background-color: #ffffff;

            .control {
                overflow: visible;
                right: 0;
                left: 0;
                margin: auto;
                width: 40%;
            }

            .search__icon {
                display: block;
                top: 14px;
                right: 14px;
                z-index: 9999;
            }

            input {
                left: 0;
                right: 0;
                padding: 0 14px;

            }
        }

        .label:before {
            color: #000000;
            font-size: 42px;
            width: 55px;
        }
    }
}

@include max-screen($screen__s) {
    html body div.block-search {
        .label {
            top: 2px;
        }

        .control {
            top: 70px;
        }

        .active {
            height: 149px;

            .control {
                width: 344px;
            }
        }
    }
}