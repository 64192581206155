.home-descriprions-container {
    padding-top: 9px;

    p {
        color: #122948;
    }

    .font-size40 {
        line-height: initial;
    }

    p:not(:first-of-type) {
        color: #122948;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2rem;
    }
    
    > p:first-of-type {
        padding-bottom: 19px;
    }
}

@include max-screen($screen__s) {
    .home-descriprions-container {
        // padding: 54px 5% 0 6%;

        > p:first-of-type {
            padding-bottom: 6px;
        }

        .font-size40 {
            font-size: 20px;
            line-height: 27px;
        }
    }
}

@include max-screen($screen__xs) {
    .home-descriprions-container {
        margin-top: 2rem;
        margin-bottom: 2rem
    }
}