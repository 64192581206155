.mp-productalerts-product-detail {
    width: 100%;
    float: none !important;
    height: auto !important;

    .mp-productalerts-link-container {
        width: 100%;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 45px;
        padding: 10px 10px;
        box-sizing: border-box;
        line-height: 1.2;
        color: white;
        background: var(--color-orange);
        transition: var(--default-transition);

        &:hover {
            background: var(--color-dark_orange);
            color: white;
        }
    }
}
