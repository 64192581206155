@charset "UTF-8";
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select {
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  margin: 0; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .cart.table-wrapper .actions-toolbar > .action:-moz-focusring, .action-gift:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

.font-12 {
  font-size: 12px; }

.font-14 {
  font-size: 14px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px;
  line-height: calc(27/20); }

.font-20 {
  font-size: 20px;
  line-height: calc(27/20); }

.font-30 {
  font-size: 30px;
  line-height: calc(27/20); }

.font-40 {
  font-size: 40px;
  line-height: calc(27/40); }

.font-white {
  color: var(--color-white); }

.font-dark_blue {
  color: var(--color-dark_blue); }

.fw-regular {
  font-weight: regular; }

.fw-bold {
  font-weight: bold; }

.text-upper {
  text-transform: uppercase; }

/*!
   * Bootstrap Grid v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 6rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 6rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 6rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 6rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 6rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 6rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 6rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 6rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 6rem; } }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-6 {
  margin: 6rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-6 {
  margin-top: 6rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-6 {
  margin-right: 6rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-6 {
  margin-bottom: 6rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-6 {
  margin-left: 6rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.p-6 {
  padding: 6rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pt-6 {
  padding-top: 6rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pe-6 {
  padding-right: 6rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pb-6 {
  padding-bottom: 6rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.ps-6 {
  padding-left: 6rem !important; }

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-6 {
    margin-top: 6rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-6 {
    margin-right: 6rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-6 {
    margin-bottom: 6rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-6 {
    margin-left: 6rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pt-sm-6 {
    padding-top: 6rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pe-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pb-sm-6 {
    padding-bottom: 6rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .ps-sm-6 {
    padding-left: 6rem !important; } }

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-6 {
    margin-top: 6rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-6 {
    margin-right: 6rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-6 {
    margin-bottom: 6rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-6 {
    margin-left: 6rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pt-md-6 {
    padding-top: 6rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pe-md-6 {
    padding-right: 6rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pb-md-6 {
    padding-bottom: 6rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .ps-md-6 {
    padding-left: 6rem !important; } }

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-6 {
    margin-top: 6rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-6 {
    margin-right: 6rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-6 {
    margin-bottom: 6rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-6 {
    margin-left: 6rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pt-lg-6 {
    padding-top: 6rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pe-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pb-lg-6 {
    padding-bottom: 6rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .ps-lg-6 {
    padding-left: 6rem !important; } }

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-6 {
    margin-top: 6rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-6 {
    margin-right: 6rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-6 {
    margin-bottom: 6rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-6 {
    margin-left: 6rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pt-xl-6 {
    padding-top: 6rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pe-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pb-xl-6 {
    padding-bottom: 6rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .ps-xl-6 {
    padding-left: 6rem !important; } }

@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-6 {
    margin: 6rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-6 {
    margin-top: 6rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-6 {
    margin-right: 6rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-6 {
    margin-bottom: 6rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-6 {
    margin-left: 6rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .p-xxl-6 {
    padding: 6rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pt-xxl-6 {
    padding-top: 6rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pe-xxl-6 {
    padding-right: 6rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pb-xxl-6 {
    padding-bottom: 6rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .ps-xxl-6 {
    padding-left: 6rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

:root {
  --container-width: 1440px; }

:root {
  --default-transition: all 250ms;
  --color-black: #000;
  --color-dark_blue: #182846;
  --color-white: #ffffff;
  --color-sky_blue: #00ade7;
  --color-gray: #8f8f8f;
  --color-light_gray: #f7f7f7;
  --color-light-gray-blue: #f2f7fc;
  --color-medium_gray: #b5b5b5;
  --color-dark_gray: #333333;
  --color-orange: #e6a532;
  --color-dark_orange: #cb9012;
  --color-red: #c91717;
  --color-green: #00bb00;
  --border-radius: 0;
  --header-height: 168px; }

body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

nav ul,
nav ol {
  list-style: none none; }

img {
  border: 0; }

img,
object,
video,
embed {
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

html {
  font-size: 62.5%;
  font-size-adjust: 100%; }

body {
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }

p {
  margin-top: 0; }

p {
  margin-bottom: 1rem; }

abbr[title] {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help; }

b,
strong {
  font-weight: 700; }

em,
i {
  font-style: italic; }

mark {
  background: #f0f0f0;
  color: black; }

small,
.small {
  font-size: 12px; }

hr {
  border: 0;
  border-top: 1px solid #d1d1d1;
  margin-bottom: 20px;
  margin-top: 20px; }

sub,
sup {
  font-size: 78.57143%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dfn {
  font-style: italic; }

h1 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 0;
  margin-bottom: 2rem; }

h2 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

h3 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

h4 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h5 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h6 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }

a,
.alink {
  color: var(--color-dark_blue);
  text-decoration: none; }
  a:visited,
  .alink:visited {
    color: #00ade7;
    text-decoration: none; }
  a:hover,
  .alink:hover {
    color: #00ade7;
    text-decoration: none; }
  a:active,
  .alink:active {
    color: #00ADE7;
    text-decoration: none; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 2.5rem; }
  ul > li,
  ol > li {
    margin-top: 0;
    margin-bottom: 1rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

dl {
  margin-bottom: 20px;
  margin-top: 0; }

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0; }

dd {
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 0; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 1.2rem;
  white-space: nowrap; }

kbd {
  background: #f0f0f0;
  color: #111111;
  padding: 2px 4px;
  font-size: 1.2rem; }

pre {
  background: #f0f0f0;
  border: 1px solid #d1d1d1;
  color: #111111;
  line-height: 1.42857;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 1.2rem;
  display: block;
  word-wrap: break-word; }
  pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap; }

blockquote {
  border-left: 0 solid #d1d1d1;
  margin: 0 0 20px 40px;
  padding: 0;
  color: #333;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    color: #333;
    line-height: 1.42857;
    font-size: 1rem;
    display: block; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "— "; }
  blockquote cite {
    font-style: normal; }

blockquote:before, blockquote:after {
  content: ''; }

q {
  quotes: none; }
  q:before, q:after {
    content: '';
    content: none; }

cite {
  font-style: normal; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }
  table th {
    text-align: left; }
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: bottom; }
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    padding: 8px 10px; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  background-image: none;
  background: #f2f2f2;
  border: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle; }
  button:focus, .cart.table-wrapper .actions-toolbar > .action:focus, .action-gift:focus, button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action-gift:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }
  button.disabled, .cart.table-wrapper .actions-toolbar > .disabled.action, .disabled.action-gift, button[disabled], .cart.table-wrapper .actions-toolbar > .action[disabled], .action-gift[disabled],
  fieldset[disabled] button,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .action,
  fieldset[disabled] .action-gift {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner {
    border: 0;
    padding: 0; }

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:disabled,
  input[type="password"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="search"]:disabled,
  input[type="number"]:disabled,
  input[type="datetime"]:disabled,
  input[type="email"]:disabled {
    opacity: 0.5; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="datetime"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder {
    color: #c2c2c2; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="datetime"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="datetime"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="number"] {
  -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"] {
  -webkit-appearance: none; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none; }

select {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 5px 10px 4px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  select:disabled {
    opacity: 0.5; }

select[multiple="multiple"] {
  height: auto;
  background-image: none; }

textarea {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: auto;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  resize: vertical; }
  textarea:disabled {
    opacity: 0.5; }
  textarea::-moz-placeholder {
    color: #c2c2c2; }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2; }
  textarea:-ms-input-placeholder {
    color: #c2c2c2; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #ed8380; }

div.mage-error[generated] {
  color: #e02b27;
  font-size: 1.2rem; }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

address {
  font-style: normal; }

*:focus {
  box-shadow: none;
  outline: 0; }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 768px) {
  .actions-toolbar:before, .actions-toolbar:after {
    content: '';
    display: table; }
  .actions-toolbar:after {
    clear: both; }
  .actions-toolbar .primary {
    float: left; }
  .actions-toolbar .secondary {
    float: right; }
  .actions-toolbar .primary,
  .actions-toolbar .secondary {
    display: inline-block; }
    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
      display: inline-block; }
  .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .actions-toolbar > .secondary,
  .actions-toolbar > .primary {
    margin-bottom: 0; }
    .actions-toolbar > .secondary .action,
    .actions-toolbar > .primary .action {
      margin-bottom: 0;
      width: auto; } }

.breadcrumbs {
  background-color: #f9fafd;
  margin: 0 0 20px 0;
  padding: 20px; }
  .breadcrumbs ul.items {
    display: flex; }

a.action.primary,
button.action.primary,
.cart.table-wrapper .actions-toolbar > .action.primary,
.action.primary.action-gift {
  border-radius: var(--border-radius);
  background-color: #122948;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 300;
  border: none;
  padding: 1.5rem;
  text-align: center;
  transition: var(--default-transition); }
  a.action.primary:hover,
  button.action.primary:hover,
  .cart.table-wrapper .actions-toolbar > .action.primary:hover,
  .action.primary.action-gift:hover {
    background-color: #00ade7;
    border: none;
    color: #ffffff; }

button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
  box-shadow: none; }

a.action.primary {
  display: inline-block;
  text-decoration: none; }
  a.action.primary:hover, a.action.primary:active, a.action.primary:focus {
    text-decoration: none; }

.action.primary {
  transition: var(--default-transition);
  background-image: none;
  background: #00bb00;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 2rem;
  box-sizing: border-box;
  vertical-align: middle; }
  .action.primary:focus, .action.primary:active {
    background: #00ade7;
    border: none;
    color: #fff; }
  .action.primary:hover {
    background: #1f9e1f;
    border: none;
    color: #fff; }
  .action.primary.disabled, .action.primary[disabled],
  fieldset[disabled] .action.primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.button-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  margin-right: 10px; }
  .button-icon svg {
    max-width: 20px;
    max-height: 20px;
    fill: #ffffff; }

@media only screen and (min-width: 992px) {
  a.action.primary,
  button.action.primary,
  .cart.table-wrapper .actions-toolbar > .action.primary,
  .action.primary.action-gift {
    min-width: 25rem; } }

.product-banner > div {
  height: 100%; }

.banner-general {
  background: #f5f5f5;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  height: 100%; }
  .banner-general__link {
    display: flex;
    align-items: end;
    position: relative;
    height: 100%;
    padding: 10px;
    color: #ffffff;
    box-sizing: border-box; }
    .banner-general__link:hover {
      color: #ffffff; }
  .banner-general__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-position: center center; }
  .banner-general__meta {
    background-color: #013672;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 2; }

.abs-reset-list, .bundle-options-container .block-bundle-summary .bundle.items, .product-items, .prices-tier, .cart-container .checkout-methods-items, .opc-wrapper .opc, .opc-progress-bar, .checkout-agreements-items, .items, .block-wishlist-management .wishlist-select-items, .paypal-review .items-qty, .order-details-items .items-qty, .block-banners .banner-items,
.block-banners-inline .banner-items, .block-event .slider-panel .slider {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .abs-reset-list > li, .bundle-options-container .block-bundle-summary .bundle.items > li, .product-items > li, .prices-tier > li, .cart-container .checkout-methods-items > li, .opc-wrapper .opc > li, .opc-progress-bar > li, .checkout-agreements-items > li, .items > li, .block-wishlist-management .wishlist-select-items > li, .paypal-review .items-qty > li, .order-details-items .items-qty > li, .block-banners .banner-items > li, .block-banners-inline .banner-items > li, .block-event .slider-panel .slider > li {
    margin: 0; }

.abs-action-link-button, .abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist, .block-event .slider-panel .slider .item .action.event {
  background-image: none;
  background: #f2f2f2;
  border: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  border-radius: 0; }
  .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }
  .abs-action-link-button.disabled, .disabled.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .disabled.action, .product-info-main .disabled.action.tocompare,
  .product-options-bottom .disabled.action.tocompare, .product-info-main .disabled.action.towishlist,
  .product-options-bottom .disabled.action.towishlist,
  .block-bundle-summary .disabled.action.towishlist, .block-event .slider-panel .slider .item .disabled.action.event, .abs-action-link-button[disabled], .abs-action-addto-product[disabled], .bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled], .product-info-main .action.tocompare[disabled],
  .product-options-bottom .action.tocompare[disabled], .product-info-main .action.towishlist[disabled],
  .product-options-bottom .action.towishlist[disabled],
  .block-bundle-summary .action.towishlist[disabled], .block-event .slider-panel .slider .item .action.event[disabled],
  fieldset[disabled] .abs-action-link-button,
  fieldset[disabled] .abs-action-addto-product,
  fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
  .bundle-options-container .block-bundle-summary fieldset[disabled] .product-addto-links > .action,
  fieldset[disabled] .product-info-main .action.tocompare,
  .product-info-main fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-options-bottom .action.tocompare,
  .product-options-bottom fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-info-main .action.towishlist,
  .product-info-main fieldset[disabled] .action.towishlist,
  fieldset[disabled] .product-options-bottom .action.towishlist,
  .product-options-bottom fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-bundle-summary .action.towishlist,
  .block-bundle-summary fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
  .block-event .slider-panel .slider .item fieldset[disabled] .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active, .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none; }

.abs-product-options-list dt, .cart.table-wrapper .item-options dt, .block-giftregistry-shared-items .product .item-options dt, .multicheckout .item-options dt {
  clear: left;
  float: left;
  margin: 0 10px 5px 0; }
  .abs-product-options-list dt:after, .cart.table-wrapper .item-options dt:after, .block-giftregistry-shared-items .product .item-options dt:after, .multicheckout .item-options dt:after {
    content: ": "; }

.abs-product-options-list dd, .cart.table-wrapper .item-options dd, .block-giftregistry-shared-items .product .item-options dd, .multicheckout .item-options dd {
  display: inline-block;
  float: left;
  margin: 0 0 5px; }

@media only screen and (min-width: 768px) {
  .abs-product-options-list-desktop dt, .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0; }
    .abs-product-options-list-desktop dt:after, .block-giftregistry-shared .item-options dt:after {
      content: ": "; }
  .abs-product-options-list-desktop dd, .block-giftregistry-shared .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px; } }

.abs-button-responsive, .actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .abs-button-desktop {
    width: auto; } }

@media only screen and (max-width: 767px) {
  .abs-button-responsive-smaller, .product-info-main .box-tocart .actions .action.tocart {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .abs-blocks-2columns, .column .block-addbysku .block-content .box, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    width: 48.8%; }
    .abs-blocks-2columns:nth-child(odd), .column .block-addbysku .block-content .box:nth-child(odd), .login-container .block:nth-child(odd), .account .column.main .block:not(.widget) .block-content .box:nth-child(odd), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(odd), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns:nth-child(even), .column .block-addbysku .block-content .box:nth-child(even), .login-container .block:nth-child(even), .account .column.main .block:not(.widget) .block-content .box:nth-child(even), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(even), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
      float: right; } }

@media only screen and (min-width: 640px) {
  .abs-blocks-2columns-s {
    width: 48.8%; }
    .abs-blocks-2columns-s:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns-s:nth-child(even) {
      float: right; } }

.abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
  height: auto;
  padding: 0 !important; }
  .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
    position: static; }

.abs-adaptive-images {
  display: block;
  height: auto;
  max-width: 100%; }

.abs-adaptive-images-centered, .product.media .product.photo .photo.image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%; }

.abs-login-block-title, .login-container .block .block-title {
  font-size: 1.8rem;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
  padding-bottom: 12px; }
  .abs-login-block-title strong, .login-container .block .block-title strong {
    font-weight: 500; }

.abs-block-title, .abs-account-blocks .block-title, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .block-title, .multicheckout .block-title, .paypal-review .block .block-title, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title, .sales-guest-view .column.main .block:not(.widget) .block-title, .block-compare .block-title, .block-giftregistry-results .block-title, .block-giftregistry-shared-items .block-title, .block-wishlist-search-results .block-title, .block-reorder .block-title, .block-wishlist .block-title {
  margin-bottom: 15px; }
  .abs-block-title > strong, .abs-account-blocks .block-title > strong, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .block-title > strong, .multicheckout .block-title > strong, .paypal-review .block .block-title > strong, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong, .sales-guest-view .column.main .block:not(.widget) .block-title > strong, .block-compare .block-title > strong, .block-giftregistry-results .block-title > strong, .block-giftregistry-shared-items .block-title > strong, .block-wishlist-search-results .block-title > strong, .block-reorder .block-title > strong, .block-wishlist .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }

.abs-account-blocks .block-title > .action, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .block-title > .action, .multicheckout .block-title > .action, .paypal-review .block .block-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action, .sales-guest-view .column.main .block:not(.widget) .block-title > .action {
  margin-left: 15px; }

.abs-account-blocks .box-title, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .box-title, .multicheckout .box-title, .paypal-review .block .box-title, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title, .sales-guest-view .column.main .block:not(.widget) .box-title {
  display: inline-block;
  margin: 0 0 5px; }
  .abs-account-blocks .box-title > span, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .box-title > span, .multicheckout .box-title > span, .paypal-review .block .box-title > span, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span, .sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .abs-account-blocks .box-title > .action, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .box-title > .action, .multicheckout .box-title > .action, .paypal-review .block .box-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action, .sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px; }

.abs-account-blocks .block-content p:last-child, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .block-content p:last-child, .multicheckout .block-content p:last-child, .paypal-review .block .block-content p:last-child, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content p:last-child, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child, .sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
  margin-bottom: 0; }

.abs-account-blocks .block-content .box, .account.page-layout-2columns-left .column.main .block .block:not(.widget) .block-content .box, .multicheckout .block-content .box, .paypal-review .block .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box, .sales-guest-view .column.main .block:not(.widget) .block-content .box {
  margin-bottom: 20px; }

.abs-dropdown-simple, .giftregisty-dropdown, .wishlist-dropdown {
  display: inline-block;
  position: relative; }
  .abs-dropdown-simple:before, .giftregisty-dropdown:before, .wishlist-dropdown:before, .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    content: '';
    display: table; }
  .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    clear: both; }
  .abs-dropdown-simple .action.toggle, .giftregisty-dropdown .action.toggle, .wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .abs-dropdown-simple .action.toggle:after, .giftregisty-dropdown .action.toggle:after, .wishlist-dropdown .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 5px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-dropdown-simple .action.toggle:hover:after, .giftregisty-dropdown .action.toggle:hover:after, .wishlist-dropdown .action.toggle:hover:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle:active:after, .giftregisty-dropdown .action.toggle:active:after, .wishlist-dropdown .action.toggle:active:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle.active, .giftregisty-dropdown .action.toggle.active, .wishlist-dropdown .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-dropdown-simple .action.toggle.active:after, .giftregisty-dropdown .action.toggle.active:after, .wishlist-dropdown .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0 0 0 5px;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-dropdown-simple .action.toggle.active:hover:after, .giftregisty-dropdown .action.toggle.active:hover:after, .wishlist-dropdown .action.toggle.active:hover:after {
        color: inherit; }
      .abs-dropdown-simple .action.toggle.active:active:after, .giftregisty-dropdown .action.toggle.active:active:after, .wishlist-dropdown .action.toggle.active:active:after {
        color: inherit; }
  .abs-dropdown-simple ul.dropdown, .giftregisty-dropdown ul.dropdown, .wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-dropdown-simple ul.dropdown li, .giftregisty-dropdown ul.dropdown li, .wishlist-dropdown ul.dropdown li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .abs-dropdown-simple ul.dropdown li:hover, .giftregisty-dropdown ul.dropdown li:hover, .wishlist-dropdown ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before, .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      left: 10px;
      top: -12px; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      left: 9px;
      top: -14px; }
  .abs-dropdown-simple.active, .active.giftregisty-dropdown, .active.wishlist-dropdown {
    overflow: visible; }
    .abs-dropdown-simple.active ul.dropdown, .active.giftregisty-dropdown ul.dropdown, .active.wishlist-dropdown ul.dropdown {
      display: block; }

.abs-input-qty, .column .block-addbysku .qty .qty, .bundle-options-container .input-text.qty, .cart.table-wrapper .col.qty .input-text, .account.page-layout-2columns-left .table-return-items .qty .input-text, .table-giftregistry-items .field.qty .qty, .block-giftregistry-shared-items .qty .qty, .table.grouped .control .qty, .block-wishlist-info-items .input-text.qty, .multicheckout .table-wrapper .col .qty .input-text, .account .table-wrapper .data.table.wishlist .box-tocart .qty, .products-grid.wishlist .product-item .box-tocart input.qty, .sidebar .block-addbysku .fieldset .fields .field.qty .qty {
  text-align: center;
  width: 47px; }

.abs-margin-for-blocks-and-widgets, .block-banners,
.block-banners-inline {
  margin-bottom: 40px; }

.abs-remove-button-for-blocks, .sidebar .action.delete, .table-comparison .cell.remove .action.delete, .block-compare .action.delete, .filtered .item .action.remove {
  display: inline-block;
  text-decoration: none; }
  .abs-remove-button-for-blocks > span, .sidebar .action.delete > span, .table-comparison .cell.remove .action.delete > span, .block-compare .action.delete > span, .filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-remove-button-for-blocks:before, .sidebar .action.delete:before, .table-comparison .cell.remove .action.delete:before, .block-compare .action.delete:before, .filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-remove-button-for-blocks:hover:before, .sidebar .action.delete:hover:before, .table-comparison .cell.remove .action.delete:hover:before, .block-compare .action.delete:hover:before, .filtered .item .action.remove:hover:before {
    color: #303030; }
  .abs-remove-button-for-blocks:active:before, .sidebar .action.delete:active:before, .table-comparison .cell.remove .action.delete:active:before, .block-compare .action.delete:active:before, .filtered .item .action.remove:active:before {
    color: #303030; }

.abs-product-link, .product-item-name, .product.name a {
  font-weight: 400; }
  .abs-product-link > a, .product-item-name > a, .product.name a > a {
    color: #333;
    text-decoration: none; }
    .abs-product-link > a:visited, .product-item-name > a:visited, .product.name a > a:visited {
      color: #333;
      text-decoration: underline; }
    .abs-product-link > a:hover, .product-item-name > a:hover, .product.name a > a:hover {
      color: #333;
      text-decoration: underline; }
    .abs-product-link > a:active, .product-item-name > a:active, .product.name a > a:active {
      color: #333;
      text-decoration: underline; }

.abs-reset-left-margin, .form.giftregistry.items .actions-toolbar, .form-new-agreement .actions-toolbar, .fieldset .review-legend.legend, .form.wishlist.items .actions-toolbar {
  margin-left: 0; }

@media only screen and (min-width: 768px) {
  .abs-reset-left-margin-desktop, .bundle-options-container .legend.title, .column.main .cart-summary .actions-toolbar, .cart.table-wrapper .item-actions .actions-toolbar, .gift-summary .actions-toolbar,
  .cart.table-wrapper .gift-summary .actions-toolbar, .column.main .block-giftregistry-shared-items .actions-toolbar, .form-new-agreement .fieldset .legend, .form-new-agreement .actions-toolbar, .column.main .paypal-review .actions-toolbar, .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
    margin-left: 0; } }

@media only screen and (min-width: 640px) {
  .abs-reset-left-margin-desktop-s, .column:not(.sidebar-main) .multicheckout .actions-toolbar, .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
    margin-left: 0; } }

.abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove {
  left: 10px;
  margin-left: 70%;
  position: absolute;
  top: 31px;
  width: auto; }

@media only screen and (min-width: 768px) {
  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form-giftregistry-share .fieldset .additional .action.remove, .form-giftregistry-edit .fieldset .additional .action.remove, .form-add-invitations .fieldset .additional .action.remove, .form-create-return .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 75.8%;
    top: 6px; } }

.abs-add-fields .fieldset, .form-giftregistry-share .fieldset, .form-giftregistry-edit .fieldset, .form-add-invitations .fieldset, .form-create-return .fieldset, .form.send.friend .fieldset {
  margin-bottom: 50px; }
  .abs-add-fields .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%; }
  .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left; }
    .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
      margin-top: 30px; }
  .abs-add-fields .fieldset .fields .actions-toolbar, .form-giftregistry-share .fieldset .fields .actions-toolbar, .form-giftregistry-edit .fieldset .fields .actions-toolbar, .form-add-invitations .fieldset .fields .actions-toolbar, .form-create-return .fieldset .fields .actions-toolbar, .form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0; }

.abs-add-fields .message.notice, .form-giftregistry-share .message.notice, .form-giftregistry-edit .message.notice, .form-add-invitations .message.notice, .form-create-return .message.notice, .form.send.friend .message.notice {
  margin: 30px 0 0; }

.abs-add-fields .additional, .form-giftregistry-share .additional, .form-giftregistry-edit .additional, .form-add-invitations .additional, .form-create-return .additional, .form.send.friend .additional {
  margin-top: 55px;
  position: relative; }

@media only screen and (min-width: 768px) {
  .abs-add-fields-desktop .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 50%; } }

@media only screen and (min-width: 768px) {
  .abs-margin-for-forms-desktop, .column:not(.sidebar-additional) form .actions-toolbar,
  .column:not(.sidebar-main) form .actions-toolbar, .fieldset .legend, .login-container .fieldset:after {
    margin-left: 25.8%; } }

@media only screen and (min-width: 640px) {
  .abs-margin-for-forms-desktop-s {
    margin-left: 25.8%; } }

.abs-hidden, .order-details-items .order-items .order-gift-message:not(.expanded-content) {
  height: 0;
  visibility: hidden; }

.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .product-item-actions .actions-secondary > .action span, .table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span, .cart.table-wrapper .col.qty .label, .checkout-index-index .page-title-wrapper, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .minicart-wrapper .action.showcart .text, .minicart-wrapper .action.showcart .counter-label, .fieldset .fullname.field > .label, .account.page-layout-2columns-left .column.main .block .limiter > .label, .field.street .field.additional .label, .account-nav .title, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label, .filter-options-content .filter-count-label, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .page-multiple-wishlist .page-title-wrapper .page-title, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label, .products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media only screen and (max-width: 639px) {
  .abs-visually-hidden-mobile, .table-giftregistry-items .col.product:before {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (max-width: 767px) {
  .abs-visually-hidden-mobile-m {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 640px) {
  .abs-visually-hidden-desktop-s {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 768px) {
  .abs-visually-hidden-desktop, .modes-label, .dashboard-welcome-toggler {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.abs-visually-hidden-reset, .shipping-policy-block.field-tooltip .field-tooltip-action span {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):before, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):before, .form-create-return .fieldset .actions-toolbar:not(:first-child):before, .form.send.friend .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .sidebar .product-items-images:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .checkout-container:before, .checkout-onepage-success:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .minicart-items .product-item > .product:before, .fieldset .fullname .field:before, .page-product-downloadable .product-add-form:before, .gift-options:before, .gift-item-block .content:before, .order-details-items .order-gift-message:before,
.order-details-items .block-order-details-gift-message:before, .block-giftregistry-shared-items .product .item-options:before, .gift-wrapping-list:before, .opc-wrapper .data.table .gift-wrapping:before,
.table-order-items .gift-wrapping:before,
.table-order-review .gift-wrapping:before, .order-options .product:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .customer-review .product-details:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .checkout-container:after, .checkout-onepage-success:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .minicart-items .product-item > .product:after, .fieldset .fullname .field:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  content: '';
  display: table; }

.abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .checkout-container:after, .checkout-onepage-success:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .minicart-items .product-item > .product:after, .fieldset .fullname .field:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  clear: both; }

@media only screen and (min-width: 768px) {
  .abs-add-clearfix-desktop:before, .abs-pager-toolbar:before, .account .toolbar:before, .toolbar-giftregistry-results:before, .toolbar-wishlist-results:before, .column .block-addbysku .block-content:before, .block-cart-failed .block-content:before, .cart-container:before, .login-container:before, .account .column.main .block:not(.widget) .block-content:before, .block-addresses-list .items.addresses:before, .block-giftregistry-shared .item-options:before, .gift-wrapping .nested:before, .table .gift-wrapping .content:before, .block-wishlist-management:before, .paypal-review .block-content:before, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
  .sales-guest-view .column.main .block:not(.widget) .block-content:before, .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    clear: both; } }

@media only screen and (min-width: 640px) {
  .abs-add-clearfix-desktop-s:before, .multicheckout .block-billing:before, .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    clear: both; } }

@media only screen and (max-width: 639px) {
  .abs-add-clearfix-mobile:before, .abs-checkout-order-review tbody tr:before, .multicheckout.order-review .data.table tbody tr:before, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before, .table-giftregistry-items .col.product:before, .account .toolbar:before, .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .abs-add-clearfix-mobile-m:before, .form-create-return .fieldset > .actions-toolbar:before, .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile-m:after, .form-create-return .fieldset > .actions-toolbar:after {
    clear: both; } }

.abs-add-box-sizing, .abs-field-date .control, .field.date .control, .abs-field-tooltip, .field .control._with-tooltip, .columns .column.main, .columns .sidebar-main, .columns .sidebar-additional, .column .block-addbysku .sku, .bundle-options-container .block-bundle-summary, .product-item, .sidebar .product-items-images .product-item, .search-autocomplete, .cart-summary, .opc-wrapper .shipping-address-item, .shipping-policy-block.field-tooltip .field-tooltip-content, .opc-block-summary, .sidebar .block-addbysku .fieldset .fields .field.sku, .block-event .slider-panel .slider .item .action.show {
  box-sizing: border-box; }

@media only screen and (min-width: 993px) {
  .abs-add-box-sizing-desktop, .sidebar-main, .sidebar-additional {
    box-sizing: border-box; } }

@media only screen and (min-width: 640px) {
  .abs-add-box-sizing-desktop-s, .multicheckout .block-shipping .box, .multicheckout .block-billing .box-billing-address, .multicheckout .block-billing .box-billing-method {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop-m, .opc-wrapper {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-revert-field-type-desktop .fieldset > .field, .product-add-form .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field,
  .product-add-form .fieldset .fields > .field {
    margin: 0 0 20px; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .product-add-form .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .product-add-form .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .abs-revert-field-type-desktop .fieldset > .field > .label, .product-add-form .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before, .product-add-form .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .product-add-form .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
      display: none; }
    .abs-revert-field-type-desktop .fieldset > .field:not(:first-child):last-of-type, .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
      margin-bottom: 0; } }

.abs-navigation-icon {
  display: block;
  text-decoration: none; }
  .abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-navigation-icon:after {
    position: absolute;
    right: 5px;
    top: 0; }

.abs-split-button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .abs-split-button:before, .abs-split-button:after {
    content: '';
    display: table; }
  .abs-split-button:after {
    clear: both; }
  .abs-split-button .action.split {
    float: left;
    margin: 0; }
  .abs-split-button .action.toggle {
    float: right;
    margin: 0; }
  .abs-split-button button.action.split, .abs-split-button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .abs-split-button .actions-toolbar > .action.split, .abs-split-button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .abs-split-button button + .action.toggle, .abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .abs-split-button .actions-toolbar > .action + .action.toggle, .abs-split-button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none; }
    .abs-split-button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .abs-split-button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-split-button .action.toggle:hover:after {
      color: inherit; }
    .abs-split-button .action.toggle:active:after {
      color: inherit; }
    .abs-split-button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-split-button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .abs-split-button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-split-button .action.toggle.active:hover:after {
        color: inherit; }
      .abs-split-button .action.toggle.active:active:after {
        color: inherit; }
  .abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-split-button .items li {
      margin: 0;
      padding: 3px 5px; }
      .abs-split-button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-split-button .items:before, .abs-split-button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-split-button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-split-button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-split-button .items:before {
      right: 10px;
      top: -12px; }
    .abs-split-button .items:after {
      right: 9px;
      top: -14px; }
  .abs-split-button.active {
    overflow: visible; }
    .abs-split-button.active .items {
      display: block; }

.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem; }

.abs-actions-addto-gridlist, .product-item-actions .actions-secondary > .action, .table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split, .product-items .action.towishlist,
.products.list.items .action.towishlist,
.table-comparison .action.towishlist {
  display: inline-block;
  text-decoration: none; }
  .abs-actions-addto-gridlist > span, .product-item-actions .actions-secondary > .action > span, .table-comparison .wishlist.split.button > .action.split > span, .product-items .wishlist.split.button > .action.split > span, .product-items .action.towishlist > span, .products.list.items .action.towishlist > span, .table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-actions-addto-gridlist:before, .product-item-actions .actions-secondary > .action:before, .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before, .product-items .action.towishlist:before,
  .products.list.items .action.towishlist:before,
  .table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    line-height: 24px;
    color: #333;
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
  .product-items .wishlist.split.button > .action.split:hover:before, .product-items .action.towishlist:hover:before,
  .products.list.items .action.towishlist:hover:before,
  .table-comparison .action.towishlist:hover:before {
    color: #00ade7; }

.abs-button-l, .bundle-actions .action.primary, .bundle-options-container .block-bundle-summary .box-tocart .action.primary, .methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
.block-authentication .action.action-login, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary, .block-minicart .block-content > .actions > .primary .action.primary, .form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem; }

.abs-action-button-as-link, .abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove, .cart.table-wrapper .action.help.map, .opc-wrapper .edit-address-link, .opc-block-shipping-information .shipping-information-title .action-edit, .action-auth-toggle, .checkout-payment-method .checkout-billing-address .action-cancel, .checkout-agreements-block .action-show, .cart-summary .block.giftcard .action.check, .gift-options .actions-toolbar .action-cancel, .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action, .paypal-button-widget .paypal-button {
  line-height: 1.42857;
  padding: 0;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  border-radius: 0;
  font-size: inherit;
  font-weight: 400; }
  .abs-action-button-as-link:visited, .abs-action-remove:visited, .abs-add-fields .action.remove:visited, .form-giftregistry-share .action.remove:visited, .form-giftregistry-edit .action.remove:visited, .form-add-invitations .action.remove:visited, .form-create-return .action.remove:visited, .form.send.friend .action.remove:visited, .cart.table-wrapper .action.help.map:visited, .opc-wrapper .edit-address-link:visited, .opc-block-shipping-information .shipping-information-title .action-edit:visited, .action-auth-toggle:visited, .checkout-payment-method .checkout-billing-address .action-cancel:visited, .checkout-agreements-block .action-show:visited, .cart-summary .block.giftcard .action.check:visited, .gift-options .actions-toolbar .action-cancel:visited, .table-comparison .wishlist.split.button > .action:visited,
  .product-items .wishlist.split.button > .action:visited, .paypal-button-widget .paypal-button:visited {
    color: #00ade7;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #00ade7;
    text-decoration: none; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active {
    color: #00ADE7;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #00ade7; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover, .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:focus, .abs-action-remove:focus, .abs-add-fields .action.remove:focus, .form-giftregistry-share .action.remove:focus, .form-giftregistry-edit .action.remove:focus, .form-add-invitations .action.remove:focus, .form-create-return .action.remove:focus, .form.send.friend .action.remove:focus, .cart.table-wrapper .action.help.map:focus, .opc-wrapper .edit-address-link:focus, .opc-block-shipping-information .shipping-information-title .action-edit:focus, .action-auth-toggle:focus, .checkout-payment-method .checkout-billing-address .action-cancel:focus, .checkout-agreements-block .action-show:focus, .cart-summary .block.giftcard .action.check:focus, .gift-options .actions-toolbar .action-cancel:focus, .table-comparison .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:focus, .paypal-button-widget .paypal-button:focus {
    background: none;
    border: 0; }
  .abs-action-button-as-link.disabled, .disabled.abs-action-remove, .abs-add-fields .disabled.action.remove, .form-giftregistry-share .disabled.action.remove, .form-giftregistry-edit .disabled.action.remove, .form-add-invitations .disabled.action.remove, .form-create-return .disabled.action.remove, .form.send.friend .disabled.action.remove, .cart.table-wrapper .disabled.action.help.map, .opc-wrapper .disabled.edit-address-link, .opc-block-shipping-information .shipping-information-title .disabled.action-edit, .disabled.action-auth-toggle, .checkout-payment-method .checkout-billing-address .disabled.action-cancel, .checkout-agreements-block .disabled.action-show, .cart-summary .block.giftcard .disabled.action.check, .gift-options .actions-toolbar .disabled.action-cancel, .table-comparison .wishlist.split.button > .disabled.action,
  .product-items .wishlist.split.button > .disabled.action, .paypal-button-widget .disabled.paypal-button, .abs-action-button-as-link[disabled], .abs-action-remove[disabled], .abs-add-fields .action.remove[disabled], .form-giftregistry-share .action.remove[disabled], .form-giftregistry-edit .action.remove[disabled], .form-add-invitations .action.remove[disabled], .form-create-return .action.remove[disabled], .form.send.friend .action.remove[disabled], .cart.table-wrapper .action.help.map[disabled], .opc-wrapper .edit-address-link[disabled], .opc-block-shipping-information .shipping-information-title .action-edit[disabled], .action-auth-toggle[disabled], .checkout-payment-method .checkout-billing-address .action-cancel[disabled], .checkout-agreements-block .action-show[disabled], .cart-summary .block.giftcard .action.check[disabled], .gift-options .actions-toolbar .action-cancel[disabled], .table-comparison .wishlist.split.button > .action[disabled],
  .product-items .wishlist.split.button > .action[disabled], .paypal-button-widget .paypal-button[disabled],
  fieldset[disabled] .abs-action-button-as-link,
  fieldset[disabled] .abs-action-remove,
  fieldset[disabled] .abs-add-fields .action.remove,
  .abs-add-fields fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-share .action.remove,
  .form-giftregistry-share fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-edit .action.remove,
  .form-giftregistry-edit fieldset[disabled] .action.remove,
  fieldset[disabled] .form-add-invitations .action.remove,
  .form-add-invitations fieldset[disabled] .action.remove,
  fieldset[disabled] .form-create-return .action.remove,
  .form-create-return fieldset[disabled] .action.remove,
  fieldset[disabled] .form.send.friend .action.remove,
  .form.send.friend fieldset[disabled] .action.remove,
  fieldset[disabled] .cart.table-wrapper .action.help.map,
  .cart.table-wrapper fieldset[disabled] .action.help.map,
  fieldset[disabled] .opc-wrapper .edit-address-link,
  .opc-wrapper fieldset[disabled] .edit-address-link,
  fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
  .opc-block-shipping-information .shipping-information-title fieldset[disabled] .action-edit,
  fieldset[disabled] .action-auth-toggle,
  fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
  .checkout-payment-method .checkout-billing-address fieldset[disabled] .action-cancel,
  fieldset[disabled] .checkout-agreements-block .action-show,
  .checkout-agreements-block fieldset[disabled] .action-show,
  fieldset[disabled] .cart-summary .block.giftcard .action.check,
  .cart-summary .block.giftcard fieldset[disabled] .action.check,
  fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
  .gift-options .actions-toolbar fieldset[disabled] .action-cancel,
  fieldset[disabled] .table-comparison .wishlist.split.button > .action,
  .table-comparison fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .product-items .wishlist.split.button > .action,
  .product-items fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .paypal-button-widget .paypal-button,
  .paypal-button-widget fieldset[disabled] .paypal-button {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:not(:focus), .abs-action-remove:not(:focus), .abs-add-fields .action.remove:not(:focus), .form-giftregistry-share .action.remove:not(:focus), .form-giftregistry-edit .action.remove:not(:focus), .form-add-invitations .action.remove:not(:focus), .form-create-return .action.remove:not(:focus), .form.send.friend .action.remove:not(:focus), .cart.table-wrapper .action.help.map:not(:focus), .opc-wrapper .edit-address-link:not(:focus), .opc-block-shipping-information .shipping-information-title .action-edit:not(:focus), .action-auth-toggle:not(:focus), .checkout-payment-method .checkout-billing-address .action-cancel:not(:focus), .checkout-agreements-block .action-show:not(:focus), .cart-summary .block.giftcard .action.check:not(:focus), .gift-options .actions-toolbar .action-cancel:not(:focus), .table-comparison .wishlist.split.button > .action:not(:focus),
  .product-items .wishlist.split.button > .action:not(:focus), .paypal-button-widget .paypal-button:not(:focus) {
    box-shadow: none; }

.abs-revert-secondary-color, .block-compare .action.compare, .cart-summary .form.giftregistry .fieldset .action.primary, .paypal-review .block .actions-toolbar .action.primary {
  background: #f2f2f2;
  border: none;
  color: #333; }
  .abs-revert-secondary-color:focus, .block-compare .action.compare:focus, .cart-summary .form.giftregistry .fieldset .action.primary:focus, .paypal-review .block .actions-toolbar .action.primary:focus, .abs-revert-secondary-color:active, .block-compare .action.compare:active, .cart-summary .form.giftregistry .fieldset .action.primary:active, .paypal-review .block .actions-toolbar .action.primary:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  .abs-revert-secondary-color:hover, .block-compare .action.compare:hover, .cart-summary .form.giftregistry .fieldset .action.primary:hover, .paypal-review .block .actions-toolbar .action.primary:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }

.abs-revert-secondary-size, .cart-summary .form.giftregistry .fieldset .action.primary {
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 15px; }

.abs-box-tocart, .bundle-actions {
  margin: 10px 0; }

.abs-adjustment-incl-excl-tax .price-including-tax, .bundle-options-container .price-notice .price-including-tax, .product-options-wrapper .field .price-notice .price-including-tax, .cart-summary .price-including-tax, .gift-summary .price-including-tax, .gift-options .price-including-tax,
.abs-adjustment-incl-excl-tax .price-excluding-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.cart-summary .price-excluding-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-excluding-tax,
.abs-adjustment-incl-excl-tax .weee,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .weee,
.cart-summary .weee,
.gift-summary .weee,
.gift-options .weee {
  font-size: 1.4rem;
  display: inline-block;
  white-space: nowrap; }

.abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax, .cart-summary .price-including-tax + .price-excluding-tax, .gift-summary .price-including-tax + .price-excluding-tax, .gift-options .price-including-tax + .price-excluding-tax {
  display: inline-block;
  font-size: 1.1rem; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:before, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before, .cart-summary .price-including-tax + .price-excluding-tax:before, .gift-summary .price-including-tax + .price-excluding-tax:before, .gift-options .price-including-tax + .price-excluding-tax:before {
    content: "(" attr(data-label) ": "; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:after, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after, .cart-summary .price-including-tax + .price-excluding-tax:after, .gift-summary .price-including-tax + .price-excluding-tax:after, .gift-options .price-including-tax + .price-excluding-tax:after {
    content: ")"; }

.abs-tax-total, .price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px; }
  .abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
  .price-excluding-tax .cart-tax-total-expanded:after {
    content: ""; }

.abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
  content: ""; }

.abs-methods-shipping-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 15px; }

@media only screen and (max-width: 639px) {
  .abs-checkout-order-review tbody tr:not(:last-child), .multicheckout.order-review .data.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #d1d1d1; }
  .abs-checkout-order-review tbody tr .col.item:before, .multicheckout.order-review .data.table tbody tr .col.item:before {
    display: none; }
  .abs-checkout-order-review tbody tr .col.qty, .multicheckout.order-review .data.table tbody tr .col.qty, .abs-checkout-order-review tbody tr .col.price, .multicheckout.order-review .data.table tbody tr .col.price, .abs-checkout-order-review tbody tr .col.subtotal, .multicheckout.order-review .data.table tbody tr .col.subtotal {
    box-sizing: border-box;
    float: left;
    text-align: center;
    white-space: nowrap;
    width: 33%; }
    .abs-checkout-order-review tbody tr .col.qty:before, .multicheckout.order-review .data.table tbody tr .col.qty:before, .abs-checkout-order-review tbody tr .col.price:before, .multicheckout.order-review .data.table tbody tr .col.price:before, .abs-checkout-order-review tbody tr .col.subtotal:before, .multicheckout.order-review .data.table tbody tr .col.subtotal:before {
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
      padding-bottom: 10px; }
  .abs-checkout-order-review tbody tr .product-item-name, .multicheckout.order-review .data.table tbody tr .product-item-name {
    margin: 0; } }

.abs-colon:after, .block-minicart .subtotal .label:after, .minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after, .order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
  content: ": "; }

.abs-icon-add, .abs-dropdown-items-new .action.new, .wishlist.split.button .action.new, .wishlist-dropdown .action.new {
  display: inline-block;
  text-decoration: none; }
  .abs-icon-add:before, .abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

@media only screen and (max-width: 767px) {
  .abs-icon-add-mobile, .block-wishlist-management .wishlist-add.item .add {
    display: block;
    text-decoration: none; }
    .abs-icon-add-mobile:before, .block-wishlist-management .wishlist-add.item .add:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 10px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 5px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; } }

.abs-dropdown-items-new .items .item:last-child:hover, .wishlist.split.button .items .item:last-child:hover, .wishlist-dropdown .items .item:last-child:hover {
  background: #e8e8e8; }

.abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
  margin-left: -17px;
  margin-right: 5px; }

@media only screen and (max-width: 767px) {
  .abs-toggling-title-mobile, .block-wishlist-management .wishlist-select .wishlist-name {
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: inherit;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after {
      position: absolute;
      right: 10px;
      top: 0; }
    .abs-toggling-title-mobile.active:after, .block-wishlist-management .wishlist-select .active.wishlist-name:after {
      content: ""; } }

.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .sidebar .subtitle, .cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br, .minicart-items .product-item-details .weee[data-label] .label, .page-product-giftcard .fieldset.giftcard > .legend + br, .order-details-items .order-items .order-gift-message .action.close, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle {
  display: none; }

@media only screen and (max-width: 639px) {
  .abs-no-display-s, .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th, .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none; } }

@media only screen and (min-width: 768px) {
  .abs-no-display-desktop, .opc-estimated-wrapper, .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; } }

.abs-status, .return-status, .order-status {
  display: inline-block;
  margin-bottom: 20px; }

@media only screen and (max-width: 767px) {
  .abs-pager-toolbar-mobile .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-wishlist-results .toolbar-amount,
  .abs-pager-toolbar-mobile .limiter,
  .account .toolbar .limiter,
  .toolbar-wishlist-results .limiter,
  .abs-pager-toolbar-mobile .pages,
  .account .toolbar .pages,
  .toolbar-wishlist-results .pages {
    float: none;
    margin-bottom: 25px; } }

@media only screen and (max-width: 639px) {
  .abs-pager-toolbar-mobile-s .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount,
  .abs-pager-toolbar-mobile-s .limiter,
  .toolbar-giftregistry-results .limiter,
  .abs-pager-toolbar-mobile-s .pages,
  .toolbar-giftregistry-results .pages {
    margin-bottom: 25px; } }

@media only screen and (min-width: 768px) {
  .abs-pager-toolbar, .account .toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results {
    margin-bottom: 20px;
    position: relative; }
    .abs-pager-toolbar .limiter, .account .toolbar .limiter, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .limiter {
      float: right;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
      float: left;
      line-height: normal;
      padding: 7px 0 0;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-giftregistry-results .pages, .toolbar-wishlist-results .pages {
      position: absolute;
      width: 100%;
      z-index: 0; } }

.abs-block-items-counter, .block-compare .counter, .block-wishlist .counter {
  color: #7d7d7d;
  font-size: 1.2rem;
  white-space: nowrap; }

.abs-shopping-cart-items .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
  border-radius: var(--border-radius);
  min-width: 25rem;
  text-align: center;
  transition: var(--default-transition);
  background-image: none;
  background: #e6a532;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 15px 15px 15px 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }
  .abs-shopping-cart-items .action.continue:before, .block-cart-failed .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.continue:hover:before, .block-cart-failed .action.continue:hover:before, .cart-container .form-cart .action.continue:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:active:before, .block-cart-failed .action.continue:active:before, .cart-container .form-cart .action.continue:active:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }
  .abs-shopping-cart-items .action.continue.disabled, .block-cart-failed .action.continue.disabled, .cart-container .form-cart .action.continue.disabled, .abs-shopping-cart-items .action.continue[disabled], .block-cart-failed .action.continue[disabled], .cart-container .form-cart .action.continue[disabled],
  fieldset[disabled] .abs-shopping-cart-items .action.continue,
  fieldset[disabled] .block-cart-failed .action.continue,
  fieldset[disabled] .cart-container .form-cart .action.continue,
  .cart-container fieldset[disabled] .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #cb9012;
    color: #ffffff;
    border: none; }
  .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    box-shadow: none; }

.abs-shopping-cart-items .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update {
  display: inline-block;
  text-decoration: none;
  padding-left: 5px; }
  .abs-shopping-cart-items .action.update:before, .block-cart-failed .action.update:before, .cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.update:hover:before, .block-cart-failed .action.update:hover:before, .cart-container .form-cart .action.update:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.update:active:before, .block-cart-failed .action.update:active:before, .cart-container .form-cart .action.update:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .abs-shopping-cart-items-mobile .actions, .cart-container .form-cart .actions {
    text-align: center; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.continue, .cart-container .form-cart .action.continue, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    margin: 0 auto 10px; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    display: block; } }

@media only screen and (min-width: 768px) {
  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart, .cart-container .cart-gift-item {
    float: left;
    position: relative;
    width: 73%; }
    .abs-shopping-cart-items-desktop .actions, .block-cart-failed .actions, .cart-container .form-cart .actions, .cart-container .cart-gift-item .actions {
      text-align: right; }
    .abs-shopping-cart-items-desktop .action.clear, .block-cart-failed .action.clear, .cart-container .form-cart .action.clear, .cart-container .cart-gift-item .action.clear, .abs-shopping-cart-items-desktop .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update, .cart-container .cart-gift-item .action.update {
      margin-left: 10px; }
    .abs-shopping-cart-items-desktop .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue, .cart-container .cart-gift-item .action.continue {
      float: left; } }

.abs-field-date .control, .field.date .control {
  position: relative; }

.abs-field-date-input, .abs-field-date input, .field.date input, .form-giftregistry-search .fields-specific-options .datetime-picker {
  margin-right: 10px;
  width: calc(100% - 50px); }

.abs-field-tooltip, .field .control._with-tooltip {
  position: relative; }
  .abs-field-tooltip input, .field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 36px); }

.abs-checkout-tooltip-content-position-top, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content, .shipping-policy-block.field-tooltip .field-tooltip-content {
  right: -10px;
  top: 40px;
  left: auto; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999; }
  .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px; }

@media only screen and (max-width: 767px) {
  .abs-checkout-tooltip-content-position-top-mobile, .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      margin-top: -21px;
      right: 10px;
      left: auto;
      top: 0; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before {
      border-bottom-color: #999; }
    .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border-bottom-color: #f4f4f4;
      top: 1px; } }

.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #333;
  font-weight: 300;
  font-size: 2.6rem; }

.abs-sidebar-totals .mark, .cart-totals .mark, .opc-block-summary .table-totals .mark {
  font-weight: 400;
  padding-left: 4px; }
  .abs-sidebar-totals .mark strong, .cart-totals .mark strong, .opc-block-summary .table-totals .mark strong {
    font-weight: 400; }

.abs-sidebar-totals .amount, .cart-totals .amount, .opc-block-summary .table-totals .amount {
  padding-right: 4px;
  text-align: right;
  white-space: nowrap; }
  .abs-sidebar-totals .amount strong, .cart-totals .amount strong, .opc-block-summary .table-totals .amount strong {
    font-weight: 400; }

.abs-sidebar-totals .grand .mark, .cart-totals .grand .mark, .opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-top: 20px; }


.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-right: 4px;
  text-align: right; }
  .abs-sidebar-totals .grand .amount strong, .cart-totals .grand .amount strong, .opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700; }

.abs-sidebar-totals .msrp, .cart-totals .msrp, .opc-block-summary .table-totals .msrp {
  margin-bottom: 10px; }

.abs-sidebar-totals .totals-tax-summary .mark, .cart-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .mark,
.abs-sidebar-totals .totals-tax-summary .amount,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .amount {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer; }

.abs-sidebar-totals .totals-tax-summary .amount .price, .cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price {
  display: block;
  text-decoration: none;
  padding-right: 25px;
  position: relative; }
  .abs-sidebar-totals .totals-tax-summary .amount .price > span, .cart-totals .totals-tax-summary .amount .price > span, .opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px; }

.abs-sidebar-totals .totals-tax-summary.expanded .mark, .cart-totals .totals-tax-summary.expanded .mark, .opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.abs-sidebar-totals .totals-tax-summary.expanded .amount,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
  border-bottom: 0; }

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after, .cart-totals .totals-tax-summary.expanded .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
  content: ""; }

.abs-sidebar-totals .totals-tax-details, .cart-totals .totals-tax-details, .opc-block-summary .table-totals .totals-tax-details {
  display: none;
  border-bottom: 1px solid #d1d1d1; }
  .abs-sidebar-totals .totals-tax-details.shown, .cart-totals .totals-tax-details.shown, .opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row; }

.abs-discount-block > .title, .cart-summary .block > .title, .paypal-review-discount .block > .title {
  cursor: pointer;
  font-weight: 600;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 7px 30px 7px 5px;
  position: relative; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-discount-block > .title:after, .cart-summary .block > .title:after, .paypal-review-discount .block > .title:after {
    position: absolute;
    right: -5px;
    top: -5px; }
  .column.main .abs-discount-block > .title strong, .column.main .cart-summary .block > .title strong, .cart-summary .column.main .block > .title strong, .column.main .paypal-review-discount .block > .title strong, .paypal-review-discount .column.main .block > .title strong {
    font-size: 1.8rem;
    font-weight: 400; }

.abs-discount-block > .content, .cart-summary .block > .content, .paypal-review-discount .block > .content {
  display: none; }

.abs-discount-block.active > .title:after, .cart-summary .active.block > .title:after, .paypal-review-discount .active.block > .title:after {
  content: ""; }

.abs-discount-block.active > .content, .cart-summary .active.block > .content, .paypal-review-discount .active.block > .content {
  display: block; }

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
  letter-spacing: -0.31em; }
  .fieldset > * {
    letter-spacing: normal; }
  .fieldset > .legend {
    margin: 0 0 25px;
    padding: 0;
    box-sizing: border-box;
    float: left;
    line-height: 1.2;
    font-size: 2rem; }
    .fieldset > .legend + br {
      clear: both;
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset > .field,
  .fieldset > .fields > .field {
    margin: 0 0 20px; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
      margin-bottom: 0; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      font-weight: 700; }
      .fieldset > .field > .label + br,
      .fieldset > .fields > .field > .label + br {
        display: none; }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
      vertical-align: top; }
    .fieldset > .field .fields.group:before, .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:after {
      content: '';
      display: table; }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
      clear: both; }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
      box-sizing: border-box;
      float: left; }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
      width: 50% !important; }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
      width: 33.3% !important; }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
      width: 25% !important; }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
      width: 20% !important; }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0;
      width: 100%; }
      .fieldset > .field .addon textarea,
      .fieldset > .field .addon select,
      .fieldset > .field .addon input,
      .fieldset > .fields > .field .addon textarea,
      .fieldset > .fields > .field .addon select,
      .fieldset > .fields > .field .addon input {
        order: 2;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .field .addon .addafter,
      .fieldset > .fields > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addafter {
        background: #fff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto; }
        .fieldset > .field .addon .addbefore:disabled,
        .fieldset > .field .addon .addafter:disabled,
        .fieldset > .fields > .field .addon .addbefore:disabled,
        .fieldset > .fields > .field .addon .addafter:disabled {
          opacity: 0.5; }
        .fieldset > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .field .addon .addafter::-moz-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .field .addon .addafter::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .field .addon .addafter:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
          color: #c2c2c2; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addbefore {
        float: left;
        order: 1; }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
      margin-top: 10px; }
    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
      content: 'verplicht';
      color: #B5B5B5;
      font-style: italic;
      font-size: 1.2rem;
      margin: 0 0 0 5px; }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
      font-size: 1.2rem;
      margin: 3px 0 0;
      padding: 0;
      display: inline-block;
      text-decoration: none; }
      .fieldset > .field .note:before,
      .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 12px;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal; }
    .column:not(.main) .fieldset > .field .label, .column:not(.main)
    .fieldset > .fields > .field .label {
      font-weight: normal; }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
      margin-bottom: 10px; }
      .fieldset > .field .field.choice:last-child,
      .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0; }
    .fieldset > .field input[type=file],
    .fieldset > .fields > .field input[type=file] {
      margin: 5px 0; }

.legend + .fieldset,
.legend + div {
  clear: both; }

.legend strong {
  margin-left: 5px; }

fieldset.field {
  border: 0;
  padding: 0; }

.field.date .time-picker {
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap; }

.field .message.warning {
  margin-top: 10px; }

.field-error,
div.mage-error[generated] {
  margin-top: 7px; }

.field-error {
  color: #e02b27;
  font-size: 1.2rem; }

.field .tooltip {
  position: relative; }
  .field .tooltip .tooltip-content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .field .tooltip .tooltip-content:after {
      border-width: 5px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:before {
      border-width: 6px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      right: 100%; }
    .field .tooltip .tooltip-content:after {
      border-right-color: #fff;
      margin-top: -5px;
      top: 15px; }
    .field .tooltip .tooltip-content:before {
      border-right-color: #bbb;
      margin-top: -6px;
      top: 15px; }
  .field .tooltip .tooltip-toggle {
    cursor: help; }
  .field .tooltip .tooltip-toggle:hover + .tooltip-content,
  .field .tooltip .tooltip-toggle:focus + .tooltip-content,
  .field .tooltip:hover .tooltip-content {
    display: block; }
  .field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal; }

input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
  display: block; }

._has-datepicker ~ .ui-datepicker-trigger {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: block;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus, ._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger.disabled, ._has-datepicker ~ .ui-datepicker-trigger[disabled],
  fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  ._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  ._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #7d7d7d;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0; }

.sidebar .fieldset {
  margin: 0; }
  .sidebar .fieldset > .field:not(.choice):not(:last-child),
  .sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px; }
  .sidebar .fieldset > .field:not(.choice) .label,
  .sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%; }
  .sidebar .fieldset > .field:not(.choice) .control,
  .sidebar .fieldset .fields > .field .control {
    width: 100%; }

@media only screen and (min-width: 768px) {
  .fieldset > .field {
    margin: 0 0 20px;
    box-sizing: border-box;
    margin: 0 0 12px; }
    .fieldset > .field > .label {
      margin: 0; }
    .fieldset > .field:before, .fieldset > .field:after {
      content: '';
      display: table; }
    .fieldset > .field:after {
      clear: both; }
    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
      padding: 6px 15px 0 0;
      width: 25.8%;
      box-sizing: border-box;
      content: ' ';
      float: left;
      height: 1px; }
    .fieldset > .field .description {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .label {
      padding: 6px 15px 0 0;
      text-align: start;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .control {
      width: 100%;
      float: left; } }

@font-face {
  font-family: "icons-blank-theme";
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot");
  src: url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.eot?#iefix") format("embedded-opentype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2") format("woff2"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff") format("woff"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.ttf") format("truetype"), url("../fonts/Blank-Theme-Icons/Blank-Theme-Icons.svg#icons-blank-theme") format("svg");
  font-weight: normal;
  font-style: normal; }

.columns {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 25px; }
  .columns .column.main {
    padding-bottom: 40px;
    grid-column: span 12 / span 12; }
  .columns .sidebar-main {
    order: -1;
    grid-column: span 12 / span 12; }
  .columns .sidebar-additional {
    flex-grow: 1;
    flex-basis: 100%;
    order: 2; }

.page-main {
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  max-width: var(--container-width);
  width: 100%;
  padding-top: 20px;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-left: 1rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto; }

.navigation,
.page-header .header.panel,
.footer.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container {
  max-width: var(--container-width);
  width: calc(100% - 40px);
  padding: 0;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto; }

.page-layout-contentpage .columns .column.main {
  width: 100%; }

.page-layout-contentpage-small .columns .column.main {
  width: 100%; }

@media only screen and (max-width: 992px) {
  .cms-privacy-policy .page-main {
    padding-top: 41px;
    position: relative; } }

@media only screen and (min-width: 768px) {
  .page-main {
    padding-top: 1.5rem;
    padding-right: 1.5rem; }
  .catalog-product-view .columns .column.main {
    grid-column: span 12 / span 12; }
  .page-layout-contentpage .columns .column.main {
    grid-column: span 12 / span 12; }
  .page-layout-contentpagesmall .columns .column.main {
    grid-column: span 12 / span 12; }
  .columns .column.main {
    grid-column: span 8 / span 8; }
  .columns .sidebar-main {
    order: -1;
    grid-column: span 4 / span 4; }
  .checkout-index-index .columns .column.main {
    grid-column: span 12 / span 12; }
  .checkout-index-index .columns .sidebar-main {
    order: -1;
    grid-column: span 0 / span 0; }
  .cms-home .columns .column.main,
  .page-layout-1column .columns .column.main {
    grid-column: span 12 / span 12; } }

@media only screen and (min-width: 992px) {
  .page-layout-checkout .columns .column.main,
  .page-layout-1column .columns .column.main,
  .catalog-product-view .columns .column.main {
    grid-column: span 12 / span 12; }
  .page-layout-2columns-left .columns .column.main {
    grid-column: span 9 / span 9; }
  .page-layout-2columns-left .columns .sidebar-main {
    grid-column: span 3 / span 3; } }

@media only screen and (min-width: 993px) {
  .ie9 .page-main {
    width: auto; }
  .sidebar-additional {
    clear: right;
    padding-left: 2%; }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%; }
  .panel.header {
    padding: 10px 20px; } }

.headroom {
  will-change: transform;
  transition: transform 200ms linear; }

.headroom--pinned {
  transform: translateY(0%); }

.headroom--unpinned {
  transform: translateY(-100%); }

.headroom--not-top.headroom--pinned {
  border-bottom: 1px solid #f5f5f5; }

.load.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute; }
  .load.indicator:before {
    background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .load.indicator > span {
    display: none; }

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5); }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  .loading-mask .loader > p {
    display: none; }

body > .loading-mask {
  z-index: 9999; }

._block-content-loading {
  position: relative; }

.message.info {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: #00ade7; }
    .message.info a:hover {
      color: #00ade7; }
    .message.info a:active {
      color: #00ade7; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fae5e5;
  color: #e02b27;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: #00ade7; }
    .message.error a:hover {
      color: #00ade7; }
    .message.error a:active {
      color: #00ade7; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: #00ade7; }
    .message.warning a:hover {
      color: #00ade7; }
    .message.warning a:active {
      color: #00ade7; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: #00ade7; }
    .message.notice a:hover {
      color: #00ade7; }
    .message.notice a:active {
      color: #00ade7; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #e5efe5;
  color: #006400;
  padding-left: 40px;
  position: relative; }
  .message.success a {
    color: #00ade7; }
    .message.success a:hover {
      color: #00ade7; }
    .message.success a:active {
      color: #00ade7; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: "";
    font-family: "icons-blank-theme";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.navigation-bar {
  background-color: var(--color-light-gray-blue); }

.ub-bg-overlay {
  background-color: #122948;
  opacity: 0;
  transition-delay: 500ms; }

body.nav-active .ub-bg-overlay {
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background-color: #122948;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: opacity 250ms ease-in-out; }

.nav-open .page-header {
  height: 100%; }

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #ffffff; }

@media only screen and (max-width: 992px) {
  .page-header {
    background-color: #ffffff; } }

.panel.header .links,
.panel.header .switcher {
  display: none; }

.nav-toggle {
  display: flex;
  position: static;
  cursor: pointer;
  font-size: 0;
  z-index: 14;
  height: 100%;
  width: 40px;
  padding-right: 30px; }
  .nav-toggle .icon__bg_menu {
    max-height: 33px;
    width: 100%;
    display: inline-block; }
  .nav-toggle:before {
    content: "";
    display: none; }

@media only screen and (max-width: 992px) {
  span.nav-toggle {
    padding-right: 0; }
    span.nav-toggle span {
      top: 42px;
      left: 22px; } }

@media only screen and (max-width: 1200px) {
  .navigation {
    padding: 0; }
    .navigation .parent .level-top {
      position: relative;
      display: block;
      text-decoration: none; }
      .navigation .parent .level-top:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .navigation .parent .level-top:after {
        position: absolute;
        right: 7px;
        top: -8px; }
      .navigation .parent .level-top.ui-state-active:after {
        content: ""; }
  .nav-toggle {
    padding-right: 25px; }
    .nav-toggle span {
      font-size: 14px;
      position: absolute;
      top: 40px;
      left: 22px; }
  .nav-sections {
    display: none;
    -webkit-overflow-scrolling: touch;
    background: #ffffff;
    transition: left 250ms;
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0; }
    .nav-sections .switcher {
      border-top: 1px solid #d1d1d1;
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0;
      padding: 0.8rem 3.5rem 0.8rem 2rem; }
      .nav-sections .switcher .label {
        display: block;
        margin-bottom: 5px; }
      .nav-sections .switcher:last-child {
        border-bottom: 1px solid #d1d1d1; }
    .nav-sections .switcher-trigger strong {
      position: relative;
      display: block;
      text-decoration: none; }
      .nav-sections .switcher-trigger strong:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .nav-sections .switcher-trigger strong:after {
        position: absolute;
        right: -40px;
        top: -16px; }
    .nav-sections .switcher-trigger.active strong:after {
      content: ""; }
    .nav-sections .switcher-dropdown {
      margin: 0;
      padding: 0;
      list-style: none none;
      padding: 10px 0; }
    .nav-sections .header.links {
      margin: 0;
      padding: 0;
      list-style: none none;
      border-bottom: 1px solid #d1d1d1; }
      .nav-sections .header.links li {
        font-size: 1.6rem;
        margin: 0; }
        .nav-sections .header.links li.greet.welcome {
          border-top: 1px solid #d1d1d1;
          font-weight: 700;
          padding: 0.8rem 20px; }
        .nav-sections .header.links li > a {
          border-top: 1px solid #d1d1d1; }
      .nav-sections .header.links a,
      .nav-sections .header.links a:hover {
        color: #575757;
        text-decoration: none;
        display: block;
        font-weight: 700;
        padding: 0.8rem 20px; }
      .nav-sections .header.links .header.links {
        border: 0; }
  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%; }
    .nav-before-open .page-wrapper {
      transition: left 250ms;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: relative; }
    .nav-before-open body {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%; }
  .nav-open .nav-sections {
    display: block;
    box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
    left: 0;
    z-index: 99; }
  .nav-open .nav-toggle:after {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1; }
  .nav-sections-items {
    position: relative;
    z-index: 1; }
    .nav-sections-items:before, .nav-sections-items:after {
      content: '';
      display: table; }
    .nav-sections-items:after {
      clear: both; }
  .nav-sections-item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CECECE;
    box-sizing: border-box;
    padding: 20px; }
    .nav-sections-item-title .icon__close-mobile-menu {
      width: 20px;
      height: 20px;
      cursor: pointer; }
    .nav-sections-item-title a {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--color-dark_blue);
      font-weight: 700; }
    .nav-sections-item-title .nav-sections-item-switch:hover {
      text-decoration: none; }
  .nav-sections-item-content {
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    width: 100%;
    padding: 25px 0; }
    .nav-sections-item-content:before, .nav-sections-item-content:after {
      content: '';
      display: table; }
    .nav-sections-item-content:after {
      clear: both; }
    .nav-sections-item-content.active {
      display: block; }
    .nav-sections-item-content ul {
      list-style: none;
      padding: 0; }
      .nav-sections-item-content ul li:first-of-type {
        padding-top: 0; }
      .nav-sections-item-content ul li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #CECECE;
        padding: 20px;
        margin: 0; }
        .nav-sections-item-content ul li a {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: var(--color-dark_blue); }
  .navigation {
    background: whitesmoke;
    box-sizing: border-box; }
    .navigation ul {
      margin: 0;
      padding: 0; }
    .navigation li {
      margin: 0; }
    .navigation a {
      display: block;
      padding: 10px 0 10px 15px; }
    .navigation a,
    .navigation a:hover {
      color: #575757;
      text-decoration: none; }
    .navigation .level0 {
      border-top: 1px solid #d1d1d1;
      font-size: 1.6rem; }
      .navigation .level0 > .level-top {
        font-weight: 700;
        padding: 8px 40px 8px 15px;
        text-transform: uppercase;
        word-wrap: break-word; }
      .navigation .level0.active .all-category .ui-state-focus {
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px;
        padding-left: 7px;
        display: inline-block; }
      .navigation .level0 > .level1 {
        font-weight: 600; }
      .navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active) {
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px; }
        .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
          margin-left: -8px; }
    .navigation li.level0:last-child {
      border-bottom: 1px solid #d1d1d1; }
    .navigation .submenu > li {
      word-wrap: break-word; }
    .navigation .submenu:not(:first-child) {
      font-weight: 400;
      line-height: 1.3;
      left: auto !important;
      overflow-x: hidden;
      padding: 0;
      position: relative;
      top: auto !important;
      transition: left 0.3s ease-out; }
      .navigation .submenu:not(:first-child) > li > a {
        padding-left: 15px; }
      .navigation .submenu:not(:first-child) > li:last-child {
        margin-bottom: 0; }
      .navigation .submenu:not(:first-child) ul {
        display: block;
        padding-left: 15px; }
        .navigation .submenu:not(:first-child) ul > li {
          margin: 0; }
          .navigation .submenu:not(:first-child) ul > li a {
            color: #575757;
            display: block;
            line-height: normal; }
      .navigation .submenu:not(:first-child).expanded {
        display: block !important;
        padding-right: 0;
        top: 0 !important; }
      .navigation .submenu:not(:first-child) .active > a {
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 0 8px;
        padding-left: 7px; }
      .navigation .submenu:not(:first-child) .level1.active > a {
        padding-left: 7px; } }

@media only screen and (min-width: 1201px) {
  ul.level0.horizontal-root {
    display: flex !important;
    flex-wrap: wrap; }
  .drilldown,
  .nav-toggle {
    display: none !important; }
  .drilldown {
    overflow: hidden; }
  .nav-sections {
    flex-shrink: 0;
    flex-basis: auto;
    margin: 0 auto;
    max-width: var(--container-width);
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box; }
  .nav-sections-item-title {
    display: none; }
  div.nav-sections-item-content {
    display: block !important;
    padding: 0; }
  .nav-sections-item-content > .navigation {
    display: block; }
  .navigation {
    background: whitesmoke;
    font-weight: 700;
    height: inherit;
    left: auto;
    overflow: inherit;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3; }
    .navigation:empty {
      display: none; }
    .navigation ul {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      position: relative; }
    .navigation li.level0 {
      border-top: none; }
    .navigation .level0 {
      margin: 0 10px 0 0;
      display: inline-block;
      position: relative; }
      .navigation .level0:last-child {
        margin-right: 0;
        padding-right: 0; }
      .navigation .level0 > .level-top {
        color: #575757;
        line-height: 53px;
        padding: 0 12px;
        text-decoration: none;
        box-sizing: border-box;
        position: relative; }
        .navigation .level0 > .level-top:hover, .navigation .level0 > .level-top.ui-state-focus {
          color: #333;
          text-decoration: none; }
      .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top {
        border-color: #ff5501;
        border-style: solid;
        border-width: 0 0 3px;
        color: #333;
        text-decoration: none;
        display: inline-block; }
      .navigation .level0.parent:hover > .submenu {
        overflow: visible !important; }
      .navigation .level0 .submenu {
        background: #fff;
        border: 1px solid #d1d1d1;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
        font-weight: 700;
        min-width: 230px;
        padding: 15px 0;
        display: none;
        left: 0;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        z-index: 1;
        margin-top: 11px; }
        .navigation .level0 .submenu > ul {
          margin-top: 11px; }
          .navigation .level0 .submenu > ul:before, .navigation .level0 .submenu > ul:after {
            content: '';
            display: block;
            overflow: hidden;
            position: absolute; }
          .navigation .level0 .submenu > ul:before {
            color: #fff;
            left: 20px;
            top: -20px;
            border: 10px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #fff;
            z-index: 4; }
          .navigation .level0 .submenu > ul:after {
            border: 11px solid transparent;
            height: 0;
            width: 0;
            border-bottom-color: #d1d1d1;
            color: #d1d1d1;
            left: 19px;
            top: -22px;
            z-index: 3; }
        .navigation .level0 .submenu a {
          display: block;
          line-height: inherit;
          color: #575757;
          padding: 8px 20px; }
          .navigation .level0 .submenu a:hover, .navigation .level0 .submenu a.ui-state-focus {
            background: #e8e8e8;
            color: #333;
            text-decoration: none; }
        .navigation .level0 .submenu .active > a {
          border-color: #ff5501;
          border-style: solid;
          border-width: 0 0 0 3px; }
        .navigation .level0 .submenu .submenu {
          top: 0 !important;
          left: 100% !important; }
        .navigation .level0 .submenu .submenu-reverse {
          left: auto !important;
          right: 100%; }
      .navigation .level0.more {
        position: relative;
        display: inline-block;
        text-decoration: none; }
        .navigation .level0.more:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 26px;
          line-height: inherit;
          color: inherit;
          content: "";
          font-family: "icons-blank-theme";
          vertical-align: middle;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center; }
        .navigation .level0.more:before {
          display: none; }
        .navigation .level0.more:after {
          cursor: pointer;
          padding: 8px 12px;
          position: relative;
          z-index: 1; }
        .navigation .level0.more:hover > .submenu {
          overflow: visible !important; }
        .navigation .level0.more li {
          display: block; }
  .panel.header .links,
  .panel.header .switcher {
    display: inline-block; } }

.pages > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 700; }

.pages .item {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  margin: 0 2px 0 0;
  display: inline-block; }
  .pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.pages a.page {
  color: #00ade7;
  display: inline-block;
  padding: 0 4px;
  text-decoration: none; }
  .pages a.page:visited {
    color: #00ade7; }
  .pages a.page:hover {
    color: #00ade7;
    text-decoration: none; }
  .pages a.page:active {
    color: #ff5501; }

.pages strong.page {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  color: #333;
  display: inline-block;
  font-weight: 700;
  padding: 0 4px; }

.pages .action {
  border: 1px solid #d1d1d1;
  color: #7d7d7d;
  display: inline-block;
  padding: 0;
  text-decoration: none; }
  .pages .action:visited {
    color: #7d7d7d; }
  .pages .action:hover {
    color: #7d7d7d;
    text-decoration: none; }
  .pages .action:active {
    color: #7d7d7d; }
  .pages .action.next {
    display: inline-block;
    text-decoration: none; }
    .pages .action.next:visited:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
    .pages .action.next > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.next:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.next:hover:before {
      color: #7d7d7d; }
    .pages .action.next:active:before {
      color: #7d7d7d; }
  .pages .action.previous {
    display: inline-block;
    text-decoration: none; }
    .pages .action.previous:visited:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }
    .pages .action.previous > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.previous:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.previous:hover:before {
      color: #7d7d7d; }
    .pages .action.previous:active:before {
      color: #7d7d7d; }

.pages .action {
  width: 34px; }
  .pages .action.previous {
    margin-right: 12px; }
  .pages .action.next {
    margin-left: 12px; }

.ui-dialog.popup .action.close {
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1001;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .ui-dialog.popup .action.close:hover:before {
    color: inherit; }
  .ui-dialog.popup .action.close:active:before {
    color: inherit; }
  .ui-dialog.popup .action.close:focus, .ui-dialog.popup .action.close:active {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close:hover {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close.disabled, .ui-dialog.popup .action.close[disabled],
  fieldset[disabled] .ui-dialog.popup .action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.popup-pointer {
  margin-left: -14px;
  position: absolute;
  top: -14px; }
  .popup-pointer:before, .popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid; }
  .popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #fff transparent;
    z-index: 99; }
  .popup-pointer:after {
    left: 1px;
    top: 0;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98; }

@media only screen and (min-width: 640px) {
  .ui-dialog.popup {
    width: 420px; } }

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
  display: inline; }

.price-including-tax,
.price-excluding-tax {
  display: block;
  font-size: 1.8rem;
  line-height: 1; }
  .price-including-tax .price,
  .price-excluding-tax .price {
    font-weight: 700; }

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
  display: block;
  font-size: 1.8rem; }
  .price-including-tax + .price-excluding-tax:before,
  .weee[data-label]:before {
    content: attr(data-label) ": ";
    font-size: 1.1rem; }
  .price-including-tax + .price-excluding-tax .price,
  .weee[data-label] .price {
    font-size: 1.1rem; }

.product.data.items {
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }
  .product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .product.data.items > .item.title > .switch {
      display: block; }
  .product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .product.data.items > .item.content:before, .product.data.items > .item.content:after {
      content: '';
      display: table; }
    .product.data.items > .item.content:after {
      clear: both; }
    .product.data.items > .item.content.active {
      display: block; }
  .product.data.items > .item.title {
    margin: 0 0 5px; }
  .product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #7d7d7d;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #7d7d7d;
    text-decoration: none; }
    .product.data.items > .item.title > .switch:visited {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:hover {
      color: #7d7d7d;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:active {
      color: #333;
      text-decoration: none; }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .product.data.items > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 768px) {
  .product.data.items {
    position: relative;
    z-index: 1; }
    .product.data.items:before, .product.data.items:after {
      content: '';
      display: table; }
    .product.data.items:after {
      clear: both; }
    .product.data.items > .item.title {
      float: left;
      width: auto; }
      .product.data.items > .item.title > .switch {
        height: 20px;
        display: block;
        position: relative;
        z-index: 2; }
    .product.data.items > .item.content {
      margin-top: 20px;
      box-sizing: border-box;
      float: right;
      margin-left: -100%;
      width: 100%; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title {
      margin: 0 5px 0 0; }
      .product.data.items > .item.title > .switch {
        color: #7d7d7d;
        font-weight: 600;
        line-height: 20px;
        font-size: 1.4rem;
        color: #7d7d7d;
        text-decoration: none;
        background: #f0f0f0;
        border: 1px solid #d1d1d1;
        border-bottom: none;
        height: 20px;
        padding: 5px 20px 5px 20px; }
        .product.data.items > .item.title > .switch:visited {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:hover {
          color: #7d7d7d;
          text-decoration: none; }
        .product.data.items > .item.title > .switch:active {
          color: #333;
          text-decoration: none; }
      .product.data.items > .item.title:not(.disabled) > .switch:focus,
      .product.data.items > .item.title:not(.disabled) > .switch:hover {
        background: #fcfcfc; }
      .product.data.items > .item.title:not(.disabled) > .switch:active,
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        background: white;
        color: #333; }
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title.active > .switch:focus,
      .product.data.items > .item.title.active > .switch:hover {
        padding-bottom: 6px; }
    .product.data.items > .item.content {
      background: white;
      margin-top: 31px;
      padding: 20px 20px 20px 20px;
      border: 0;
      border-top: 1px solid #d1d1d1; } }

@media only screen and (max-width: 767px) {
  .product.data.items {
    margin: 0;
    padding: 0; }
    .product.data.items > .item.title {
      box-sizing: border-box;
      float: none;
      width: 100%; }
      .product.data.items > .item.title > .switch {
        display: block; }
    .product.data.items > .item.content {
      box-sizing: border-box;
      display: block;
      float: none;
      margin: 0; }
      .product.data.items > .item.content:before, .product.data.items > .item.content:after {
        content: '';
        display: table; }
      .product.data.items > .item.content:after {
        clear: both; }
      .product.data.items > .item.content.active {
        display: block; }
    .product.data.items > .item.title {
      margin: 0 0 5px; }
    .product.data.items > .item.title > .switch {
      background: #f0f0f0;
      border-bottom: 1px solid #d1d1d1;
      border-left: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      border-top: 1px solid #d1d1d1;
      height: 40px;
      padding: 5px 20px 5px 20px;
      color: #7d7d7d;
      font-weight: 600;
      line-height: 40px;
      font-size: 1.8rem;
      color: #7d7d7d;
      text-decoration: none; }
      .product.data.items > .item.title > .switch:visited {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:hover {
        color: #7d7d7d;
        text-decoration: none; }
      .product.data.items > .item.title > .switch:active {
        color: #333;
        text-decoration: none; }
    .product.data.items > .item.title:not(.disabled) > .switch:focus,
    .product.data.items > .item.title:not(.disabled) > .switch:hover {
      background: #fcfcfc; }
    .product.data.items > .item.title:not(.disabled) > .switch:active,
    .product.data.items > .item.title.active > .switch,
    .product.data.items > .item.title.active > .switch:focus,
    .product.data.items > .item.title.active > .switch:hover {
      background: white;
      padding-bottom: 5px; }
    .product.data.items > .item.content {
      background: white;
      border: 1px solid #d1d1d1;
      margin: 0 0 5px;
      padding: 20px 20px 20px 20px; }
    .product.data.items .data.item {
      display: block; } }

.table-wrapper {
  margin-bottom: 20px; }

table > caption {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.table:not(.cart):not(.totals) {
  border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td,
  .table:not(.cart):not(.totals) > tbody > tr > th,
  .table:not(.cart):not(.totals) > tbody > tr > td,
  .table:not(.cart):not(.totals) > tfoot > tr > th,
  .table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #d1d1d1; }
  .table:not(.cart):not(.totals) tfoot > tr:first-child th,
  .table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #d1d1d1;
    padding-top: 20px; }
  .table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right; }

@media only screen and (max-width: 639px) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative; }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
      border: none;
      display: block; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block; }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
          display: block; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 0; }
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #111111;
              font-weight: 700; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px; }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
      display: block; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block; }
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
          padding-top: 20px; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.nav-tree__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none; }
  .nav-tree__list--sub {
    list-style-type: none;
    margin: 0 0 0 15px;
    padding: 0; }

.nav-tree__item {
  margin: 0;
  line-height: 1.75; }

.nav-tree__link {
  color: #122948; }
  .nav-tree__link:hover {
    text-decoration: underline;
    color: #122948; }
  .nav-tree__link:visited {
    color: var(--color-dark_blue); }
  .nav-tree__link.active {
    font-weight: bold; }
    .nav-tree__link.active:hover {
      text-decoration: none; }

@media only screen and (min-width: 768px) {
  .nav-tree__list {
    display: block; } }

.tooltip.wrapper {
  position: relative; }
  .tooltip.wrapper .tooltip.content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .tooltip.wrapper .tooltip.content:after {
      border-width: 5px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:before {
      border-width: 6px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      bottom: 100%; }
    .tooltip.wrapper .tooltip.content:after {
      border-bottom-color: #fff;
      left: 15px;
      margin-left: -5px; }
    .tooltip.wrapper .tooltip.content:before {
      border-bottom-color: #bbb;
      left: 15px;
      margin-left: -6px; }
  .tooltip.wrapper .tooltip.toggle {
    cursor: help; }
  .tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
  .tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
  .tooltip.wrapper:hover .tooltip.content {
    display: block; }
  .tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0; }
  .tooltip.wrapper .tooltip.content dd {
    white-space: normal; }
  .tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; }
  .tooltip.wrapper .tooltip.content .label {
    margin-top: 10px; }
    .tooltip.wrapper .tooltip.content .label:first-child {
      margin-top: 0; }
  .tooltip.wrapper .tooltip.content .values {
    margin: 0; }

.ui-tooltip {
  position: absolute;
  z-index: 9999; }

@media only screen and (max-width: 575px) {
  .tooltip.wrapper .tooltip.content {
    min-width: 10rem; } }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/light/opensans-300.eot");
  src: url("../fonts/opensans/light/opensans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/light/opensans-300.woff2") format("woff2"), url("../fonts/opensans/light/opensans-300.woff") format("woff"), url("../fonts/opensans/light/opensans-300.ttf") format("truetype"), url("../fonts/opensans/light/opensans-300.svg#Open Sans") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/regular/opensans-400.eot");
  src: url("../fonts/opensans/regular/opensans-400.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/regular/opensans-400.woff2") format("woff2"), url("../fonts/opensans/regular/opensans-400.woff") format("woff"), url("../fonts/opensans/regular/opensans-400.ttf") format("truetype"), url("../fonts/opensans/regular/opensans-400.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/semibold/opensans-600.eot");
  src: url("../fonts/opensans/semibold/opensans-600.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/semibold/opensans-600.woff2") format("woff2"), url("../fonts/opensans/semibold/opensans-600.woff") format("woff"), url("../fonts/opensans/semibold/opensans-600.ttf") format("truetype"), url("../fonts/opensans/semibold/opensans-600.svg#Open Sans") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/bold/opensans-700.eot");
  src: url("../fonts/opensans/bold/opensans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/bold/opensans-700.woff2") format("woff2"), url("../fonts/opensans/bold/opensans-700.woff") format("woff"), url("../fonts/opensans/bold/opensans-700.ttf") format("truetype"), url("../fonts/opensans/bold/opensans-700.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/italic/OpenSans-Italic.eot");
  src: url("../fonts/opensans/italic/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/italic/OpenSans-Italic.woff2") format("woff2"), url("../fonts/opensans/italic/OpenSans-Italic.woff") format("woff"), url("../fonts/opensans/italic/OpenSans-Italic.ttf") format("truetype"), url("../fonts/opensans/italic/OpenSans-Italic.svg#Open Sans") format("svg");
  font-weight: normal;
  font-style: italic; }

.text-10 {
  font-size: 10px; }

.text-12 {
  font-size: 12px; }

.text-14 {
  font-size: 14px; }

.text-16 {
  font-size: 16px; }

.text-18 {
  font-size: 18px; }

.text-20 {
  font-size: 20px; }

.text-22 {
  font-size: 22px; }

.fw-bold {
  font-weight: 700; }

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    margin-bottom: 40px; } }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

.page-footer {
  background-color: #122948;
  padding: 0 2rem; }
  .page-footer .footer.links {
    display: grid;
    max-width: var(--container-width);
    grid-column-gap: 23px;
    padding: 45px 0 48px 0;
    margin: 0 auto;
    list-style: none;
    color: white;
    line-height: 26px; }
    .page-footer .footer.links li {
      white-space: nowrap;
      margin-bottom: 0; }
      .page-footer .footer.links li a {
        color: #ffffff; }
    .page-footer .footer.links .col {
      padding: 0;
      list-style: none; }
    .page-footer .footer.links .title {
      padding: 4px 0 13px 0;
      font-weight: 800;
      font-size: 14px;
      line-height: 26px;
      color: #FFFFFF; }
    .page-footer .footer.links .spacer {
      margin-top: 26px; }
    .page-footer .footer.links .item-images {
      display: flex; }
      .page-footer .footer.links .item-images img {
        padding-right: 2rem; }
    .page-footer .footer.links a {
      font-size: 14px;
      line-height: 26px;
      color: #FFFFFF;
      cursor: pointer;
      display: flex;
      align-items: center; }
      .page-footer .footer.links a:hover {
        text-decoration: underline; }

.footer__icon:before {
  content: '';
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px; }

.footer__icon--phone:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjI5MS43MDZMMTEuMzM3LjAyM2EuOTE4LjkxOCAwIDAwLTEuMDQ5LjUzTDguOTI2IDMuNzMyYS45MTkuOTE5IDAgMDAuMjYxIDEuMDc0bDEuNTc5IDEuMjg3YTEwLjI1MiAxMC4yNTIgMCAwMS00LjY3NiA0LjY3Nkw0LjggOS4xOWEuOTE5LjkxOSAwIDAwLTEuMDctLjI2MUwuNTUzIDEwLjI5MmEuOTE0LjkxNCAwIDAwLS41MyAxLjA0NmwuNjggMi45NWEuOTE1LjkxNSAwIDAwLjg5NC43MTIgMTMuNCAxMy40IDAgMDAxMy40LTEzLjQuOTEuOTEgMCAwMC0uNzA2LS44OTR6TTEuNjE0IDE0LjA2MkwuOTQgMTEuMTQ0bDMuMTQ3LTEuMzQ4IDEuNzQzIDIuMTMzYTExLjMyIDExLjMyIDAgMDA2LjEtNi4xTDkuNzk0IDQuMDkgMTEuMTQxLjk0M2wyLjkxOC42NzRBMTIuNDU5IDEyLjQ1OSAwIDAxMS42MTQgMTQuMDYyeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg=="); }

.footer__icon--mail:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjEyNSAwSDEuODc1QTEuODc1IDEuODc1IDAgMDAwIDEuODc1djExLjI1QTEuODc1IDEuODc1IDAgMDAxLjg3NSAxNWgxNi4yNUExLjg3NSAxLjg3NSAwIDAwMjAgMTMuMTI1VjEuODc1QTEuODc1IDEuODc1IDAgMDAxOC4xMjUgMHpNMS44NzUgMS4yNWgxNi4yNWEuNjI3LjYyNyAwIDAxLjYyNS42MjV2MS42MTdjLS44NTUuNzIzLTIuMDc4IDEuNzE5LTUuODgzIDQuNzM4LS42Ni41MjMtMS45NjEgMS43ODUtMi44NjcgMS43Ny0uOTA2LjAxNi0yLjIxMS0xLjI0Ni0yLjg2Ny0xLjc3LTMuOC0zLjAyLTUuMDI3LTQuMDE2LTUuODgzLTQuNzM4VjEuODc1YS42MjcuNjI3IDAgMDEuNjI1LS42MjV6bTE2LjI1IDEyLjVIMS44NzVhLjYyNy42MjcgMCAwMS0uNjI1LS42MjVWNS4xMTdjLjg5MS43MyAyLjMgMS44NTkgNS4xMDUgNC4wOS44LjY0MSAyLjIxNSAyLjA1MSAzLjY0NSAyLjA0MyAxLjQyMi4wMTIgMi44MjQtMS4zODcgMy42NDUtMi4wNDMgMi44MDktMi4yMyA0LjIxNS0zLjM1OSA1LjEwNS00LjA5djguMDA4YS42MjcuNjI3IDAgMDEtLjYyNS42MjV6IiBmaWxsPSIjZmZmIi8+PC9zdmc+"); }

.copyright {
  display: flex;
  align-items: center;
  padding: 0 30px;
  background-color: #FFFFFF;
  height: 100px;
  color: #707070;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px; }
  .copyright span {
    max-width: var(--container-width);
    width: 100%;
    padding: 2px 7px 0 0;
    margin: 0 auto;
    white-space: nowrap; }

@media only screen and (min-width: 1200px) {
  .footer.links {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, minmax(min-content, 327px)); } }

@media only screen and (min-width: 575px) and (max-width: 1201px) {
  .footer.links {
    grid-template-rows: 2fr;
    grid-template-columns: repeat(2, minmax(min-content, 1fr)); } }

@media only screen and (max-width: 575px) {
  .footer.links {
    grid-template-rows: repeat(4, auto);
    grid-row-gap: 36px;
    grid-template-columns: auto;
    padding: 23px 0 33px 0 !important; }
    .footer.links .col {
      padding: 0 6px 0px; }
    .footer.links > li:nth-last-child(2) {
      margin-top: -15px; }
    .footer.links > li:last-child .titie {
      margin-top: -4px; }
    .footer.links .title {
      padding: 0 0 12px 0 !important; }
  .copyright {
    align-items: start;
    padding: 21px 0 0 22px;
    height: 60px;
    line-height: 17px; }
    .copyright span {
      display: block;
      width: 95%;
      white-space: initial;
      margin: 0; } }

.header-contact {
  width: 100%;
  background-color: var(--color-sky_blue);
  display: none; }
  .header-contact__container {
    margin: 0 auto;
    max-width: var(--container-width);
    box-sizing: border-box;
    padding: 0 20px; }
  .header-contact__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0; }
  .header-contact__item {
    margin: 0;
    padding: 0; }
  .header-contact__link {
    line-height: 20px;
    font-size: 11px;
    color: var(--color-white);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center; }
    .header-contact__link:hover {
      color: var(--color-dark_blue); }
      .header-contact__link:hover .header-contact__icon svg {
        fill: var(--color-dark_blue); }
    .header-contact__link:visited {
      color: var(--color-white); }
  .header-contact__icon {
    max-height: 24px;
    margin-right: 10px; }
    .header-contact__icon svg {
      height: 12px;
      fill: #fff; }

@media only screen and (min-width: 992px) {
  .header-contact {
    display: block; } }

@media only screen and (min-width: 1201px) {
  .header-contact {
    display: block; }
    .header-contact__link {
      font-size: 12px; }
    .header-contact__icon svg {
      height: 24px; } }

.usp-payments {
  width: 100%;
  background-color: #f5f5f5;
  color: #122948;
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: var(--border-radius); }
  .usp-payments .usp__list {
    margin: 0;
    padding: 0;
    list-style: none; }
    .usp-payments .usp__list > *:last-child {
      margin-bottom: 0; }
  .usp-payments .usp__item {
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 0 30px; }
    .usp-payments .usp__item:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: inline-block;
      width: 20px;
      height: 15px;
      background: url("../images/icons/arrow-check.svg") no-repeat; }
  .usp-payments .payments {
    margin-top: 20px; }
    .usp-payments .payments__list {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      list-style: none; }
      .usp-payments .payments__list > *:last-child {
        margin-right: 0; }
    .usp-payments .payments__item {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 40px;
      margin-right: 10px; }
      .usp-payments .payments__item.ideal {
        width: 44px;
        background-image: url("../images/icons/payment-ideal.svg"); }
      .usp-payments .payments__item.visa {
        width: 60px;
        background-image: url("../images/icons/payment-visa.svg"); }
      .usp-payments .payments__item.mastercard {
        width: 60px;
        background-image: url("../images/icons/payment-mastercard.svg"); }
      .usp-payments .payments__item.maestro {
        width: 60px;
        background-image: url("../images/icons/payment-maestro.svg"); }

.cart-container .usp-payments {
  display: block !important;
  background-color: transparent; }

.product-info-main .usp-payments {
  padding: 3rem; }

.product-info-main .usp__list {
  column-count: 2; }

.customer-account-login .page-wrapper {
  padding-top: 0; }

.customer-account-login .page-main {
  padding: 0; }

.page-layout-checkout .page-wrapper {
  padding-top: 100px; }

.page-wrapper {
  padding-top: 95px; }

@media only screen and (min-width: 992px) {
  .page-layout-checkout .page-wrapper {
    padding-top: 100px; }
  .page-wrapper {
    padding-top: 150px; } }

@media only screen and (min-width: 1201px) {
  .page-layout-checkout .page-wrapper {
    padding-top: 100px; }
  .page-wrapper {
    padding-top: 170px; } }

.mp-productalerts-product-detail {
  width: 100%;
  float: none !important;
  height: auto !important; }
  .mp-productalerts-product-detail .mp-productalerts-link-container {
    width: 100%; }
  .mp-productalerts-product-detail a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    padding: 10px 10px;
    box-sizing: border-box;
    line-height: 1.2;
    color: white;
    background: var(--color-orange);
    transition: var(--default-transition); }
    .mp-productalerts-product-detail a:hover {
      background: var(--color-dark_orange);
      color: white; }

.box-slogan {
  background-color: var(--color-sky_blue);
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  padding: 20px;
  display: block; }

.main-content .gcswiper:after,
.column.main .gcswiper:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1; }

.main-content .gcswiper__wrapper,
.column.main .gcswiper__wrapper {
  width: 100%;
  padding-bottom: 40px; }

.main-content .gcswiper__ctr--scrollbar,
.column.main .gcswiper__ctr--scrollbar {
  height: 2px !important;
  position: absolute;
  background-color: #b5b5b5; }
  .main-content .gcswiper__ctr--scrollbar .swiper-scrollbar-drag,
  .column.main .gcswiper__ctr--scrollbar .swiper-scrollbar-drag {
    border-radius: unset;
    height: 6px;
    background-color: #464646;
    margin-top: -2px; }

.main-content .gcswiper__ctr--prev, .main-content .gcswiper__ctr--next,
.column.main .gcswiper__ctr--prev,
.column.main .gcswiper__ctr--next {
  height: 50px;
  width: 50px;
  color: #ffffff;
  background-color: #122948;
  border-radius: var(--border-radius); }
  .main-content .gcswiper__ctr--prev:after, .main-content .gcswiper__ctr--next:after,
  .column.main .gcswiper__ctr--prev:after,
  .column.main .gcswiper__ctr--next:after {
    font-size: 30px; }
  .main-content .gcswiper__ctr--prev.swiper-button-disabled, .main-content .gcswiper__ctr--next.swiper-button-disabled,
  .column.main .gcswiper__ctr--prev.swiper-button-disabled,
  .column.main .gcswiper__ctr--next.swiper-button-disabled {
    display: none; }

.main-content .gcswiper__ctr--prev,
.column.main .gcswiper__ctr--prev {
  left: -15px; }

.main-content .gcswiper__ctr--next,
.column.main .gcswiper__ctr--next {
  right: -15px; }

@media only screen and (max-width: 1439px) {
  .main-content .gcswiper__ctr--scrollbar,
  .column.main .gcswiper__ctr--scrollbar {
    margin-left: 0;
    left: 1% !important; }
  .main-content .gcswiper__ctr--prev,
  .column.main .gcswiper__ctr--prev {
    left: -15px; }
  .main-content .gcswiper__ctr--next,
  .column.main .gcswiper__ctr--next {
    right: -15px; } }

.black-friday {
  background-color: black;
  max-width: 300px;
  text-align: center; }
  .black-friday p {
    color: white; }
  .black-friday__inner {
    padding: 20px; }
  .black-friday__code {
    background-color: white;
    padding: 10px;
    margin-bottom: 10px; }
    .black-friday__code--coupon {
      font-size: 30px;
      font-weight: bold; }
    .black-friday__code p {
      color: black;
      margin-bottom: 0; }

a.btn,
button.btn,
.cart.table-wrapper .actions-toolbar > .btn.action,
.btn.action-gift {
  border-radius: 0;
  border: 0;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  color: var(--color-white) !important;
  font-weight: normal;
  line-height: 45px;
  background-color: var(--color-dark_blue) !important;
  outline: 0;
  transition: var(--default-transition);
  border-radius: var(--border-radius); }
  a.btn:hover,
  button.btn:hover,
  .cart.table-wrapper .actions-toolbar > .btn.action:hover,
  .btn.action-gift:hover {
    border: none; }
  a.btn-orange,
  button.btn-orange,
  .cart.table-wrapper .actions-toolbar > .btn-orange.action,
  .btn-orange.action-gift {
    background-color: var(--color-orange) !important; }
    a.btn-orange:hover,
    button.btn-orange:hover,
    .cart.table-wrapper .actions-toolbar > .btn-orange.action:hover,
    .btn-orange.action-gift:hover {
      background-color: var(--color-dark_orange) !important; }
  a.btn-l,
  button.btn-l,
  .cart.table-wrapper .actions-toolbar > .btn-l.action,
  .btn-l.action-gift {
    font-size: 18px; }

.cta-link {
  display: flex;
  align-items: center; }
  .cta-link:before {
    content: '';
    width: 10px;
    height: 14px;
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNi42NjciPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzOCIgZD0iTTAgMS42NjhWMTVhMS42NjggMS42NjggMCAwMDIuODQ1IDEuMTc4bDYuNjY3LTYuNjY1YTEuNjY3IDEuNjY3IDAgMDAwLTIuMzU3TDIuODQ1LjQ5QTEuNjY4IDEuNjY4IDAgMDAwIDEuNjY4eiIgZmlsbD0iIzAwYWRlNyIvPjwvc3ZnPg==");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 35, 47, 0.85);
  z-index: 1000;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: var(--default-transition); }
  .modal.show {
    visibility: visible;
    opacity: 1; }
  .modal__inner {
    width: auto;
    max-height: calc(100vh - 20px);
    max-width: var(--container-width);
    background-color: white;
    position: relative;
    margin: 10px;
    overflow: auto;
    position: relative; }
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    display: inline-block;
    background: 0;
    border: none;
    cursor: pointer;
    padding: 5px;
    background-color: white; }
    .modal__close svg {
      width: 100%;
      height: 100%; }
    .modal__close:hover {
      background-color: white; }
    .modal__close:focus {
      outline: 0; }

.picture {
  display: block; }
  .picture-image {
    display: block; }

.font-bold {
  font-weight: bold;
  font-size: 14px; }

.font-italic {
  font-style: italic; }

.font-size60 {
  font-size: 60px;
  line-height: calc(27 / 20); }

.font-size50 {
  font-size: 50px;
  line-height: calc(27 / 20); }

.font-size30 {
  font-size: 30px;
  line-height: calc(27 / 20); }

.font-size40 {
  font-size: 40px;
  line-height: calc(27 / 20); }

.font-size20 {
  font-size: 20px;
  line-height: calc(27 / 20); }

.font-size18 {
  font-size: 18px;
  line-height: calc(27 / 20); }

a.type-btn,
.type-btn {
  padding: 1rem 2rem;
  color: #ffffff;
  overflow: hidden;
  border-radius: var(--border-radius); }

.type-btn__yellow {
  background-color: #e6a532; }
  .type-btn__yellow:hover {
    background-color: #cb9012; }

.type-btn__blue {
  background-color: #00ade7; }
  .type-btn__blue:hover {
    background-color: #122948; }

a.type-btn__darkblue,
.type-btn__darkblue {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #122948; }

.main-content > .row-full-width-inner {
  display: flex;
  flex-direction: column; }

.main-content .pagebuilder-slider {
  position: relative;
  margin-bottom: 20px; }
  .main-content .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-poster-content {
    position: relative; }
  .main-content .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
    padding: 16.7%;
    height: 318px; }
  .main-content .pagebuilder-slider .root-text-area {
    position: absolute;
    top: -136px;
    left: -10px;
    background-color: #122948 !important;
    width: 362px;
    max-height: 320px;
    opacity: 0.85; }
    .main-content .pagebuilder-slider .root-text-area .banner-text-area {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 14px 16px 20px 21px;
      height: 90%; }
    .main-content .pagebuilder-slider .root-text-area h1 {
      letter-spacing: 0px;
      color: #ffffff;
      font-weight: bold;
      font-size: 40px;
      margin-bottom: 25px; }
    .main-content .pagebuilder-slider .root-text-area p {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0px;
      margin-bottom: 0; }
      .main-content .pagebuilder-slider .root-text-area p:first-of-type {
        max-width: 206px;
        max-height: 81px;
        color: #ffffff; }
      .main-content .pagebuilder-slider .root-text-area p:last-of-type {
        max-width: 198px;
        max-height: 54px;
        color: #b5b5b5;
        margin: 19px 0px; }
    .main-content .pagebuilder-slider .root-text-area a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 18px;
      width: 162px;
      height: 45px; }

.main-content .pagebuilder-slide-wrapper {
  height: 100%; }

.main-content .slick-dots {
  position: absolute;
  top: auto;
  bottom: 0;
  padding: 18px 0; }
  .main-content .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0;
    margin-right: 5px; }
    .main-content .slick-dots li:last-child {
      margin-right: 0; }
    .main-content .slick-dots li.slick-active {
      width: 20px; }
      .main-content .slick-dots li.slick-active button, .main-content .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .main-content .slick-dots li.slick-active .actions-toolbar > .action, .main-content .slick-dots li.slick-active .action-gift {
        border-color: #00ade7;
        background-color: #00ade7; }
    .main-content .slick-dots li button, .main-content .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .main-content .slick-dots li .actions-toolbar > .action, .main-content .slick-dots li .action-gift {
      border: 1px solid #b5b5b5;
      width: 100%;
      height: 100%; }

@media only screen and (max-width: 1024px) {
  .main-content .pagebuilder-slider .root-text-area {
    width: 265px;
    margin-top: 20px;
    margin-left: -40px; }
    .main-content .pagebuilder-slider .root-text-area h1 {
      font-size: 1.6rem; }
    .main-content .pagebuilder-slider .root-text-area p {
      font-size: 1.7rem; }
    .main-content .pagebuilder-slider .root-text-area a {
      font-size: 1.3rem; } }

@media only screen and (max-width: 639px) {
  .main-content .main-slider-dits {
    min-height: 460px !important;
    margin: 0 10px; }
    .main-content .main-slider-dits .slick-dots {
      bottom: auto;
      top: 44%;
      padding: 17px 0; }
  .main-content .pagebuilder-slider .pagebuilder-slide-wrapper {
    min-height: 230px !important;
    background-position: center center !important; }
    .main-content .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-poster-content {
      position: static; }
    .main-content .pagebuilder-slider .pagebuilder-slide-wrapper [data-element="content"] {
      min-height: inherit; }
    .main-content .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
      padding: 0;
      min-height: 230px !important;
      height: 230px !important; }
  .main-content .pagebuilder-slider .root-text-area {
    top: auto;
    bottom: 0;
    left: auto;
    height: 230px;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    opacity: 0.85; }
    .main-content .pagebuilder-slider .root-text-area .banner-text-area {
      height: 100%;
      max-width: 355px;
      padding: 29px 16px 20px 20px; }
    .main-content .pagebuilder-slider .root-text-area h1 {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 10px; }
    .main-content .pagebuilder-slider .root-text-area p {
      font-size: 12px;
      line-height: 17px;
      max-width: inherit !important; }
      .main-content .pagebuilder-slider .root-text-area p:last-of-type {
        margin: 10px 0px; }
    .main-content .pagebuilder-slider .root-text-area a {
      font-size: 12px;
      line-height: 17px;
      height: 35px; }
    .main-content .pagebuilder-slider .root-text-area .type-btn__yellow {
      width: 162px;
      height: 45px;
      font-size: 18px;
      line-height: 24px;
      margin-top: 30px; } }

.page-main .fpcat {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative; }
  .page-main .fpcat__container {
    margin: 0 auto;
    max-width: var(--container-width);
    box-sizing: border-box; }
  .page-main .fpcat__header {
    font-size: 12px;
    padding: 0 0 20px 0; }
    .page-main .fpcat__header .title {
      margin: 0; }
  .page-main .fpcat .fpcat-card {
    width: calc((100% - 50px) / 6);
    overflow: hidden;
    border-radius: var(--border-radius); }
    .page-main .fpcat .fpcat-card:hover .fpcat-card__link {
      text-decoration: underline; }
    .page-main .fpcat .fpcat-card:hover .fpcat-card__image {
      transform: scale(1.05); }
    .page-main .fpcat .fpcat-card__content {
      background-color: var(--color-light_gray); }
    .page-main .fpcat .fpcat-card__link {
      color: #182846;
      display: block; }
    .page-main .fpcat .fpcat-card__media {
      overflow: hidden; }
    .page-main .fpcat .fpcat-card__image {
      width: 100%;
      padding-bottom: 100%;
      background-size: cover;
      transition: var(--default-transition); }
    .page-main .fpcat .fpcat-card__meta {
      width: 100%;
      text-align: center;
      padding: 20px 0; }
    .page-main .fpcat .fpcat-card__title {
      font-size: 16px;
      font-weight: bold;
      margin: 0; }

@media only screen and (max-width: 1439px) {
  .main-content .fpcat .fpcat-card {
    width: calc(100% / 5); } }

@media only screen and (max-width: 991px) {
  .main-content .fpcat .fpcat-card {
    width: calc(100% / 4); }
    .main-content .fpcat .fpcat-card__title {
      font-size: 14px; } }

@media only screen and (max-width: 767px) {
  .main-content .fpcat .fpcat-card {
    width: calc(100% / 2.4); }
    .main-content .fpcat .fpcat-card__title {
      font-size: 14px; } }

.page-main .fptop,
.main-content .fptop {
  padding-bottom: 20px; }
  .page-main .fptop__container,
  .main-content .fptop__container {
    margin: 0 auto;
    max-width: var(--container-width);
    box-sizing: border-box; }
  .page-main .fptop__row,
  .main-content .fptop__row {
    display: flex; }

.page-main .fptop-banner__link,
.main-content .fptop-banner__link {
  display: block; }

.page-main .fptop-banner__container,
.main-content .fptop-banner__container {
  display: flex; }

.page-main .fptop-banner__information,
.main-content .fptop-banner__information {
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--color-dark_blue);
  box-sizing: border-box; }

.page-main .fptop-banner__image,
.main-content .fptop-banner__image {
  width: 50%; }
  .page-main .fptop-banner__image img,
  .main-content .fptop-banner__image img {
    display: block;
    height: 200px;
    width: 100%;
    object-fit: contain;
    object-position: bottom right; }

.page-main .fptop-banner__title,
.main-content .fptop-banner__title {
  margin-top: 0;
  font-weight: bold;
  color: var(--color-sky_blue); }

.page-main .fptop-usp__swiper,
.main-content .fptop-usp__swiper {
  margin-left: 0;
  display: none; }

.page-main .fptop-usp__static,
.main-content .fptop-usp__static {
  display: flex; }

.page-main .fptop-usp-customerservice__nav,
.main-content .fptop-usp-customerservice__nav {
  cursor: pointer;
  font-weight: bold;
  color: var(--color-orange); }
  .page-main .fptop-usp-customerservice__nav:hover,
  .main-content .fptop-usp-customerservice__nav:hover {
    color: var(--color-dark_orange); }

.page-main .fptop-usp-customerservice__list,
.main-content .fptop-usp-customerservice__list {
  list-style: none;
  margin: 0;
  padding: 0; }

.page-main .fptop-usp-customerservice__item,
.main-content .fptop-usp-customerservice__item {
  position: relative; }
  .page-main .fptop-usp-customerservice__item.js-open > .fptop-usp-customerservice-child__list,
  .main-content .fptop-usp-customerservice__item.js-open > .fptop-usp-customerservice-child__list {
    display: block; }
  .page-main .fptop-usp-customerservice__item.js-open > .fptop-usp-customerservice__nav,
  .main-content .fptop-usp-customerservice__item.js-open > .fptop-usp-customerservice__nav {
    color: var(--color-dark_orange); }

.page-main .fptop-usp-customerservice-child__list,
.main-content .fptop-usp-customerservice-child__list {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 99;
  background-color: black;
  background-color: var(--color-light-gray-blue);
  text-align: left;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  display: none;
  min-width: 160px; }
  .page-main .fptop-usp-customerservice-child__list:before,
  .main-content .fptop-usp-customerservice-child__list:before {
    content: "";
    position: absolute;
    right: 10px;
    top: -8px;
    z-index: 98;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: var(--color-light-gray-blue);
    border-radius: 2px; }

.page-main .fptop-usp-customerservice-child__item,
.main-content .fptop-usp-customerservice-child__item {
  margin: 0; }
  .page-main .fptop-usp-customerservice-child__item a,
  .main-content .fptop-usp-customerservice-child__item a {
    padding: 2px 10px;
    display: block; }

.page-main .fptop-usp-card,
.main-content .fptop-usp-card {
  width: auto;
  margin-right: 25px;
  font-size: 13px;
  line-height: 22px;
  display: flex; }
  .page-main .fptop-usp-card__link,
  .main-content .fptop-usp-card__link {
    color: var(--color-black); }
    .page-main .fptop-usp-card__link strong,
    .main-content .fptop-usp-card__link strong {
      color: var(--color-sky_blue); }
    .page-main .fptop-usp-card__link:hover,
    .main-content .fptop-usp-card__link:hover {
      color: var(--color-sky_blue); }
      .page-main .fptop-usp-card__link:hover strong,
      .main-content .fptop-usp-card__link:hover strong {
        color: var(--color-sky_blue); }
    .page-main .fptop-usp-card__link-noicon,
    .main-content .fptop-usp-card__link-noicon {
      color: var(--color-dark_blue);
      font-weight: bold; }
      .page-main .fptop-usp-card__link-noicon:hover,
      .main-content .fptop-usp-card__link-noicon:hover {
        color: var(--color-dark_blue);
        text-decoration: underline; }
    .page-main .fptop-usp-card__link--customer-service,
    .main-content .fptop-usp-card__link--customer-service {
      color: var(--color-sky_blue); }
      .page-main .fptop-usp-card__link--customer-service:hover,
      .main-content .fptop-usp-card__link--customer-service:hover {
        color: var(--color-sky_blue); }
  .page-main .fptop-usp-card__icon,
  .main-content .fptop-usp-card__icon {
    width: auto;
    height: 16px;
    display: inline-block; }
    .page-main .fptop-usp-card__icon > svg,
    .main-content .fptop-usp-card__icon > svg {
      width: auto;
      height: 16px; }
  .page-main .fptop-usp-card__right,
  .main-content .fptop-usp-card__right {
    margin-left: auto;
    margin-right: 0;
    min-width: 300px;
    text-align: right;
    justify-content: flex-end; }
    .page-main .fptop-usp-card__right > *,
    .main-content .fptop-usp-card__right > * {
      margin-left: 5px; }

.page-main .fptop-hero__swiper,
.main-content .fptop-hero__swiper {
  margin-bottom: 10px;
  width: 100%; }
  .page-main .fptop-hero__swiper--pagination,
  .main-content .fptop-hero__swiper--pagination {
    display: none;
    bottom: 0 !important; }
    .page-main .fptop-hero__swiper--pagination .swiper-pagination-bullet,
    .main-content .fptop-hero__swiper--pagination .swiper-pagination-bullet {
      width: calc((100% / 2));
      height: 4px;
      border-radius: 0;
      position: relative;
      overflow: hidden;
      margin: 0;
      background: var(--color-gray); }
      .page-main .fptop-hero__swiper--pagination .swiper-pagination-bullet::before,
      .main-content .fptop-hero__swiper--pagination .swiper-pagination-bullet::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
    .page-main .fptop-hero__swiper--pagination .swiper-pagination-bullet-active,
    .main-content .fptop-hero__swiper--pagination .swiper-pagination-bullet-active {
      background: var(--color-gray); }
      .page-main .fptop-hero__swiper--pagination .swiper-pagination-bullet-active::before,
      .main-content .fptop-hero__swiper--pagination .swiper-pagination-bullet-active::before {
        background-color: var(--color-dark_blue);
        animation: slide-progress 2s cubic-bezier(0.3, 0, 0.3, 1) forwards;
        animation-duration: 5000ms; }
        .swiper-paused .page-main .fptop-hero__swiper--pagination .swiper-pagination-bullet-active::before, .swiper-paused
        .main-content .fptop-hero__swiper--pagination .swiper-pagination-bullet-active::before {
          animation-play-state: paused; }

@keyframes slide-progress {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

.page-main .fptop-info__swiper,
.main-content .fptop-info__swiper {
  width: 100%; }

.page-main .fptop-hero-card,
.main-content .fptop-hero-card {
  width: calc((100% / 2) - 5px);
  height: 340px;
  border-radius: var(--border-radius);
  overflow: hidden; }
  .page-main .fptop-hero-card:hover .fptop-hero-card__bg,
  .main-content .fptop-hero-card:hover .fptop-hero-card__bg {
    transform: scale(1.05); }
  .page-main .fptop-hero-card__bg,
  .main-content .fptop-hero-card__bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: var(--default-transition); }
  .page-main .fptop-hero-card__link,
  .main-content .fptop-hero-card__link {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;
    z-index: 1; }
  .page-main .fptop-hero-card__content,
  .main-content .fptop-hero-card__content {
    width: 100%;
    height: 100%; }
  .page-main .fptop-hero-card__meta,
  .main-content .fptop-hero-card__meta {
    color: #ffffff;
    background-color: rgba(24, 40, 70, 0.85);
    width: 45%;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .page-main .fptop-hero-card__meta-diap,
    .main-content .fptop-hero-card__meta-diap {
      background-color: rgba(255, 255, 255, 0.85); }
      .page-main .fptop-hero-card__meta-diap .fptop-hero-card__title,
      .page-main .fptop-hero-card__meta-diap .fptop-hero-card__subtitle,
      .page-main .fptop-hero-card__meta-diap .fptop-hero-card__text,
      .main-content .fptop-hero-card__meta-diap .fptop-hero-card__title,
      .main-content .fptop-hero-card__meta-diap .fptop-hero-card__subtitle,
      .main-content .fptop-hero-card__meta-diap .fptop-hero-card__text {
        color: var(--color-dark_blue); }
  .page-main .fptop-hero-card__title,
  .main-content .fptop-hero-card__title {
    margin: 0;
    word-wrap: normal; }
  .page-main .fptop-hero-card__subtitle,
  .main-content .fptop-hero-card__subtitle {
    margin: 0; }
  .page-main .fptop-hero-card__text,
  .main-content .fptop-hero-card__text {
    color: var(--color-light_gray);
    font-size: 16px;
    line-height: calc(27 / 20); }

.page-main .fptop-info-card,
.main-content .fptop-info-card {
  width: calc((100% / 3) - (20px / 3));
  box-sizing: border-box;
  background: var(--color-light_gray);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: var(--border-radius); }

@media only screen and (max-width: 1439px) {
  .page-main .fptop-usp__swiper,
  .main-content .fptop-usp__swiper {
    display: block; }
  .page-main .fptop-usp__static,
  .main-content .fptop-usp__static {
    display: none; }
  .page-main .fptop-usp-card,
  .main-content .fptop-usp-card {
    font-size: 11px;
    width: 33.333%;
    margin-right: 0; }
  .page-main .fptop-hero-card__meta,
  .main-content .fptop-hero-card__meta {
    width: 60%;
    padding: 20px 0;
    overflow: hidden;
    border-radius: var(--border-radius); }
  .page-main .fptop-hero-card__title, .page-main .fptop-hero-card__subtitle, .page-main .fptop-hero-card__text, .page-main .fptop-hero-card__button,
  .main-content .fptop-hero-card__title,
  .main-content .fptop-hero-card__subtitle,
  .main-content .fptop-hero-card__text,
  .main-content .fptop-hero-card__button {
    transform: scale(0.9); }
  .page-main .fptop-hero-card .fptop-info-card,
  .main-content .fptop-hero-card .fptop-info-card {
    height: auto; } }

@media only screen and (max-width: 991px) {
  .page-main .fptop-usp-card,
  .main-content .fptop-usp-card {
    width: 50%;
    margin-right: 0;
    text-align: center;
    font-size: 14px; }
    .page-main .fptop-usp-card__right,
    .main-content .fptop-usp-card__right {
      display: none; }
  .page-main .fptop-hero-card__meta,
  .main-content .fptop-hero-card__meta {
    width: 70%;
    padding: 20px 0; }
  .page-main .fptop-hero-card__title, .page-main .fptop-hero-card__subtitle, .page-main .fptop-hero-card__text, .page-main .fptop-hero-card__button,
  .main-content .fptop-hero-card__title,
  .main-content .fptop-hero-card__subtitle,
  .main-content .fptop-hero-card__text,
  .main-content .fptop-hero-card__button {
    transform: scale(0.8); }
  .page-main .fptop-info-card,
  .main-content .fptop-info-card {
    width: calc(100% / 2.2); }
  .page-main .fptop-banner,
  .main-content .fptop-banner {
    font-size: 12px; }
    .page-main .fptop-banner__title,
    .main-content .fptop-banner__title {
      font-size: 13px; } }

@media only screen and (max-width: 767px) {
  .page-main .fptop-usp-card,
  .main-content .fptop-usp-card {
    width: 100%;
    margin-right: 0;
    text-align: center; }
    .page-main .fptop-usp-card__right,
    .main-content .fptop-usp-card__right {
      display: none; }
  .page-main .fptop-hero__swiper,
  .main-content .fptop-hero__swiper {
    padding-bottom: 20px; }
    .page-main .fptop-hero__swiper--pagination,
    .main-content .fptop-hero__swiper--pagination {
      display: flex; }
  .page-main .fptop-hero-card,
  .main-content .fptop-hero-card {
    width: 100%; }
    .page-main .fptop-hero-card__meta,
    .main-content .fptop-hero-card__meta {
      width: 75%;
      padding: 20px; }
    .page-main .fptop-hero-card__title, .page-main .fptop-hero-card__subtitle, .page-main .fptop-hero-card__text, .page-main .fptop-hero-card__button,
    .main-content .fptop-hero-card__title,
    .main-content .fptop-hero-card__subtitle,
    .main-content .fptop-hero-card__text,
    .main-content .fptop-hero-card__button {
      transform: scale(0.85); }
  .page-main .fptop-info__swiper,
  .main-content .fptop-info__swiper {
    padding-top: 20px;
    padding-bottom: 20px; }
  .page-main .fptop-info-card,
  .main-content .fptop-info-card {
    width: calc(100% / 1.2); } }

.main-content .home-info-block {
  display: grid;
  grid-template-columns: repeat(2, minmax(49%, 450px));
  column-gap: 25px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  list-style: none;
  margin: 0 0 17px 0;
  padding: 0; }
  .main-content .home-info-block__left {
    width: 50%; }
  .main-content .home-info-block__right {
    width: 50%; }
  .main-content .home-info-block .text_block__1,
  .main-content .home-info-block .text_block__2,
  .main-content .home-info-block .col1 .text_block__1,
  .main-content .home-info-block .col2 .text_block__2 {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 200px;
    justify-content: center;
    background-color: var(--color-sky_blue);
    color: white;
    overflow: hidden;
    border-radius: var(--border-radius);
    padding: 10px 0; }
    .main-content .home-info-block .text_block__1 p,
    .main-content .home-info-block .text_block__2 p,
    .main-content .home-info-block .col1 .text_block__1 p,
    .main-content .home-info-block .col2 .text_block__2 p {
      color: white; }
  .main-content .home-info-block .text_block__1 > div:first-of-type > p:first-of-type,
  .main-content .home-info-block .col1 .text_block__1 > div:first-of-type > p:first-of-type {
    margin-top: 9px; }
  .main-content .home-info-block .text_block__1 > div:first-of-type > p:last-of-type,
  .main-content .home-info-block .col1 .text_block__1 > div:first-of-type > p:last-of-type {
    margin-top: 5px; }
  .main-content .home-info-block .text_block__1 > div:first-of-type + div,
  .main-content .home-info-block .col1 .text_block__1 > div:first-of-type + div {
    display: flex;
    justify-content: center;
    height: 36px;
    padding: 5px 0 7px 0; }
    .main-content .home-info-block .text_block__1 > div:first-of-type + div p,
    .main-content .home-info-block .col1 .text_block__1 > div:first-of-type + div p {
      width: 55%; }
  .main-content .home-info-block .text_block__1 > div:last-of-type,
  .main-content .home-info-block .col1 .text_block__1 > div:last-of-type {
    display: flex;
    justify-content: center;
    padding-bottom: 3px; }
    .main-content .home-info-block .text_block__1 > div:last-of-type p,
    .main-content .home-info-block .col1 .text_block__1 > div:last-of-type p {
      width: 67%; }
  .main-content .home-info-block .text_block__2,
  .main-content .home-info-block .col3 .text_block__2 {
    white-space: nowrap; }
    .main-content .home-info-block .text_block__2 > div,
    .main-content .home-info-block .col3 .text_block__2 > div {
      margin-bottom: 10px; }
    .main-content .home-info-block .text_block__2 .phone,
    .main-content .home-info-block .col3 .text_block__2 .phone {
      margin: 0 0 10px 0; }
      .main-content .home-info-block .text_block__2 .phone > div:first-of-type,
      .main-content .home-info-block .col3 .text_block__2 .phone > div:first-of-type {
        height: 30px;
        display: flex;
        align-items: center; }
        .main-content .home-info-block .text_block__2 .phone > div:first-of-type .phone-icon,
        .main-content .home-info-block .col3 .text_block__2 .phone > div:first-of-type .phone-icon {
          width: 20px;
          height: 20px;
          padding-right: 12px;
          display: inline-block; }
      .main-content .home-info-block .text_block__2 .phone .address,
      .main-content .home-info-block .col3 .text_block__2 .phone .address {
        width: 100%;
        font-style: italic; }
    .main-content .home-info-block .text_block__2 .email,
    .main-content .home-info-block .col3 .text_block__2 .email {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px; }
      .main-content .home-info-block .text_block__2 .email .email-icon,
      .main-content .home-info-block .col3 .text_block__2 .email .email-icon {
        width: 26.6px;
        height: 20px;
        padding-right: 12px;
        display: inline-block; }
  .main-content .home-info-block p,
  .main-content .home-info-block ul > li {
    margin-bottom: 0 !important; }
  .main-content .home-info-block p:not([class*="font"]),
  .main-content .home-info-block a,
  .main-content .home-info-block .address,
  .main-content .home-info-block .font-italic {
    font-size: 14px;
    line-height: 18px; }
  .main-content .home-info-block p,
  .main-content .home-info-block a,
  .main-content .home-info-block span {
    color: white; }

.main-content .home-rating__link {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 0 auto;
  color: #333; }

.main-content .home-rating__icon {
  margin-right: 10px;
  max-height: 20px; }
  .main-content .home-rating__icon > img {
    width: auto;
    max-height: 20px;
    display: block;
    margin-top: 2px; }

.main-content .home-rating__rating {
  margin-right: 10px;
  color: var(--color-sky_blue); }
  .main-content .home-rating__rating .nr {
    font-size: 18px;
    font-weight: bold; }

.main-content .home-rating__amount p {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .page-main .root_home-info-block,
  .main-content .root_home-info-block {
    margin: 0;
    align-self: inherit; }
  .page-main .root-home-info-block,
  .main-content .root-home-info-block {
    margin: 0 10px; }
  .page-main .home-info-block,
  .main-content .home-info-block {
    grid-template-columns: repeat(1, minmax(100%, 450px)); }
  .page-main .home-rating__link,
  .main-content .home-rating__link {
    flex-wrap: wrap; }
  .page-main .home-rating__amount,
  .main-content .home-rating__amount {
    width: 100%;
    text-align: center; } }

.main-content .block-promo {
  margin-bottom: 0; }

.main-content .main-block-promo-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 32.1%);
  grid-template-rows: repeat(3, 32.8%);
  justify-content: center;
  grid-column-gap: 2.5rem;
  grid-row-gap: 2rem;
  margin-top: 27px;
  margin-bottom: -18px; }
  .main-content .main-block-promo-wrapper a {
    position: relative; }
    .main-content .main-block-promo-wrapper a > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
    .main-content .main-block-promo-wrapper a .content {
      top: auto;
      bottom: 9px;
      margin: 20px;
      overflow: visible;
      padding: 0;
      position: absolute; }
      .main-content .main-block-promo-wrapper a .content .info.color-black {
        color: #122948; }
      .main-content .main-block-promo-wrapper a .content .info {
        color: #FFFFFF;
        font-size: 40px;
        font-weight: bold;
        line-height: 55px;
        margin: 0 0 19px 1px; }
      .main-content .main-block-promo-wrapper a .content .action {
        padding: 1rem 2rem;
        color: #FFFFFF;
        font-size: 18px; }
  .main-content .main-block-promo-wrapper .item_1 {
    grid-column-start: 1;
    grid-column-end: 1; }
  .main-content .main-block-promo-wrapper .item__2 {
    grid-column-start: 2;
    grid-column-end: 4; }
  .main-content .main-block-promo-wrapper .item__1, .main-content .main-block-promo-wrapper .item__2 {
    grid-row-start: 1;
    grid-row-end: 1; }
  .main-content .main-block-promo-wrapper .item__4 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2; }
  .main-content .main-block-promo-wrapper .item__3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2; }
  .main-content .main-block-promo-wrapper .item__5, .main-content .main-block-promo-wrapper .item__6, .main-content .main-block-promo-wrapper .item__9 {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-start: 3;
    grid-row-end: 3; }

@media only screen and (max-width: 1439px) {
  .main-content .main-block-promo-wrapper {
    grid-gap: 2.2rem; }
    .main-content .main-block-promo-wrapper a .content {
      margin: 0 10px; }
      .main-content .main-block-promo-wrapper a .content .info {
        font-size: 30px;
        line-height: 41px; }
      .main-content .main-block-promo-wrapper a .content .action {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        padding: 0;
        width: 162px;
        height: 45px; } }

@media only screen and (max-width: 767px) {
  .main-content .main-block-promo-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 20px; }
    .main-content .main-block-promo-wrapper a .content {
      bottom: 12px; }
      .main-content .main-block-promo-wrapper a .content .info {
        margin: 0 0 19px 1px; }
    .main-content .main-block-promo-wrapper .item__5, .main-content .main-block-promo-wrapper .item__6, .main-content .main-block-promo-wrapper .item__9 {
      margin-top: 0; }
    .main-content .main-block-promo-wrapper .item_1, .main-content .main-block-promo-wrapper .item_4 {
      margin-bottom: 12px; }
    .main-content .main-block-promo-wrapper .block-promo {
      height: 335px;
      grid-column-start: auto;
      grid-column-end: auto;
      grid-row-start: auto;
      grid-row-end: auto; } }

@media only screen and (max-width: 575px) {
  .main-content .main-block-promo-wrapper {
    margin-top: -4px;
    margin-bottom: -86px; } }

.main-content .widget__popular-products .content-heading::before {
  display: none; }

.main-content .widget__popular-products .content-heading .title {
  font-size: 40px;
  line-height: 55px;
  font-weight: bold;
  color: #122948; }

.main-content .widget__popular-products .content-heading .info {
  color: #122948;
  font-size: 14px;
  line-height: 27px; }

.main-content .widget__popular-products .block-content {
  margin: 0 auto; }

.main-content .widget__popular-products .product-items {
  display: grid;
  justify-content: space-between; }
  .main-content .widget__popular-products .product-items .product-item-info {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  .main-content .widget__popular-products .product-items li.product-item {
    width: 100% !important;
    margin: 0 !important;
    padding-bottom: 20px; }
    .main-content .widget__popular-products .product-items li.product-item .product-item-name {
      margin: 5px 0;
      max-width: 273px;
      max-height: 36px;
      overflow: hidden; }
      .main-content .widget__popular-products .product-items li.product-item .product-item-name a {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #122948; }
    .main-content .widget__popular-products .product-items li.product-item .price-box {
      margin: 10px 0 0;
      height: auto;
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      align-items: baseline; }
      .main-content .widget__popular-products .product-items li.product-item .price-box .newPrice, .main-content .widget__popular-products .product-items li.product-item .price-box .oldPrice {
        margin: 0; }
      .main-content .widget__popular-products .product-items li.product-item .price-box .minimal-price {
        margin-bottom: 0; }
      .main-content .widget__popular-products .product-items li.product-item .price-box .price-msrp_price,
      .main-content .widget__popular-products .product-items li.product-item .price-box .price:not(.vanaf) {
        font-weight: normal;
        margin: 0;
        font-size: 18px;
        line-height: 24px; }
    .main-content .widget__popular-products .product-items li.product-item .minimal-price-link.vanaf {
      margin-left: 1px;
      font-style: italic;
      line-height: 15px; }
    .main-content .widget__popular-products .product-items li.product-item .product-image-container {
      width: 100% !important; }
    .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper {
      padding-bottom: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #e5e5e5;
      background-color: #F7F7F7; }
      .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper img {
        mix-blend-mode: multiply; }
    .main-content .widget__popular-products .product-items li.product-item .product-item-details {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start; }

@media only screen and (min-width: 576px) {
  .main-content .widget__popular-products .product-items {
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(160px, 335px)); }
    .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper {
      max-width: 335px;
      margin-bottom: 20px;
      height: 100%; }
      .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper img {
        position: static;
        width: 100%;
        height: 335px;
        object-fit: cover; }
    .main-content .widget__popular-products .product-items li.product-item .product-item-actions {
      margin: 0; } }

@media only screen and (max-width: 1439px) {
  .main-content .widget__popular-products .block-content {
    width: 100%; }
  .main-content .widget__popular-products .product-items {
    grid-template-columns: repeat(auto-fill, minmax(200px, 23.5%)); }
    .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper {
      width: 100%;
      max-height: 235px;
      max-width: 235px; }
      .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper img {
        width: auto; } }

@media only screen and (max-width: 575px) {
  .main-content .widget__popular-products {
    padding-top: 0; }
    .main-content .widget__popular-products .content-heading .title {
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      margin: 0 0 5px -11px;
      color: #122948; }
    .main-content .widget__popular-products .content-heading .info {
      margin-right: 13px; }
    .main-content .widget__popular-products .product-items {
      grid-template-columns: repeat(2, 160px);
      grid-column-gap: 15px;
      grid-row-gap: 20px; }
      .main-content .widget__popular-products .product-items a {
        max-height: 160px; }
      .main-content .widget__popular-products .product-items li.product-item p,
      .main-content .widget__popular-products .product-items li.product-item .product-item-name,
      .main-content .widget__popular-products .product-items li.product-item .product-item-subtitle {
        font-size: 12px;
        line-height: 17px;
        color: #a3a3a3; }
      .main-content .widget__popular-products .product-items li.product-item .price-box {
        display: flex;
        flex-wrap: wrap; }
      .main-content .widget__popular-products .product-items li.product-item .product-item-name {
        min-height: 68px;
        overflow: hidden;
        margin: 17px 0 0px 0; }
      .main-content .widget__popular-products .product-items li.product-item .minimal-price-link.vanaf {
        margin-left: 0; }
      .main-content .widget__popular-products .product-items li.product-item .product-item-subtitle {
        height: 17px;
        margin: 10px 0;
        color: #a3a3a3; }
      .main-content .widget__popular-products .product-items li.product-item .category-item-prices {
        margin-bottom: 10px; }
      .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper {
        max-height: 160px;
        width: 158px;
        height: 158px;
        margin-top: -2px;
        margin-bottom: -14px; }
        .main-content .widget__popular-products .product-items li.product-item .product-image-wrapper img {
          width: 100%; } }

.main-content .main-meta__cms-block {
  height: 350px;
  position: relative;
  margin-top: 3.7%;
  margin-bottom: 23px; }
  .main-content .main-meta__cms-block img {
    position: absolute;
    max-width: var(--container-width);
    height: 350px;
    left: 50%;
    transform: translateX(-50%);
    width: 96.5%; }
  .main-content .main-meta__cms-block .home-meta-block__content {
    display: flex;
    position: absolute;
    height: 250px;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0; }
    .main-content .main-meta__cms-block .home-meta-block__content .font-italic {
      font-size: 14px;
      line-height: 18px;
      margin: 4px 0 9px 0; }
    .main-content .main-meta__cms-block .home-meta-block__content .font-size60 {
      margin: -31px 0; }
    .main-content .main-meta__cms-block .home-meta-block__content .font-size20 {
      text-align: center; }
    .main-content .main-meta__cms-block .home-meta-block__content li {
      z-index: 1;
      margin-bottom: 0;
      color: #122948; }
      .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type {
        display: flex; }
        .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type .type-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0; }
          .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type .type-btn:first-of-type {
            width: 232px;
            height: 45px;
            margin-right: 10px; }
          .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type .type-btn:last-of-type {
            width: 170px;
            height: 45px;
            margin-left: 10px; }

@media only screen and (max-width: 767px) {
  .main-content .main-meta__cms-block {
    margin-top: 15%;
    margin-bottom: 150px; }
    .main-content .main-meta__cms-block img {
      width: 100%;
      height: 491px; }
    .main-content .main-meta__cms-block .home-meta-block__content {
      top: 30px;
      height: 491px;
      justify-content: start; }
      .main-content .main-meta__cms-block .home-meta-block__content .font-size60 {
        margin: -18px -4px -23px 0; }
      .main-content .main-meta__cms-block .home-meta-block__content .font-size20 {
        margin-top: 10px;
        width: 65%; }
      .main-content .main-meta__cms-block .home-meta-block__content .font-size60 + .font-size20 + .font-size20 {
        width: 70%;
        margin: 19px 0 16px 0; }
      .main-content .main-meta__cms-block .home-meta-block__content .font-italic {
        margin: 2px 0 31px 0; }
      .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type .type-btn:first-of-type {
          width: 62%;
          height: 45px;
          margin: -1px -1px 22px 0; }
        .main-content .main-meta__cms-block .home-meta-block__content li:last-of-type .type-btn:last-of-type {
          width: 45%;
          height: 45px;
          margin: 5px 0 0 5px; } }

@media only screen and (max-width: 575px) {
  .main-content .main-meta__cms-block {
    margin-top: 19%;
    margin-bottom: 141px; } }

.root_home-video_cms-block {
  margin-top: 20px; }

.home-video__cms-block {
  margin: 0 auto;
  padding-top: 35px;
  padding-bottom: 58px; }
  .home-video__cms-block .pagebuilder-column-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .home-video__cms-block .pagebuilder-column {
    width: 32.1% !important; }
  .home-video__cms-block .pagebuilder-video-container {
    padding-top: 0 !important;
    width: 100%;
    height: 270px; }
  .home-video__cms-block div[data-content-type='video'] {
    position: static; }

@media only screen and (max-width: 767px) {
  .root_home-video_cms-block {
    margin-top: 20px; }
  .home-video__cms-block {
    width: 100%;
    padding: 2rem 0 0; }
    .home-video__cms-block .pagebuilder-video-container {
      height: 201px;
      display: flex;
      justify-content: center; }
    .home-video__cms-block div[data-content-type='video'] iframe {
      position: static;
      width: 98%;
      padding: 0 0 2rem 0; } }

@media only screen and (max-width: 575px) {
  .home-video__cms-block div[data-content-type='video'] iframe {
    position: static;
    width: 93%;
    padding: 0 9px 2rem 6px; } }

.home-descriprions-container {
  padding-top: 9px; }
  .home-descriprions-container p {
    color: #122948; }
  .home-descriprions-container .font-size40 {
    line-height: initial; }
  .home-descriprions-container p:not(:first-of-type) {
    color: #122948;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2rem; }
  .home-descriprions-container > p:first-of-type {
    padding-bottom: 19px; }

@media only screen and (max-width: 639px) {
  .home-descriprions-container > p:first-of-type {
    padding-bottom: 6px; }
  .home-descriprions-container .font-size40 {
    font-size: 20px;
    line-height: 27px; } }

@media only screen and (max-width: 575px) {
  .home-descriprions-container {
    margin-top: 2rem;
    margin-bottom: 2rem; } }

.main-content div.gc-swipe-scrollbar {
  height: 2px;
  position: absolute;
  background-color: #b5b5b5;
  left: 0; }
  .main-content div.gc-swipe-scrollbar .swiper-scrollbar-drag {
    border-radius: unset;
    height: 6px;
    background-color: #464646;
    margin-top: -2px; }

.main-content .gc-swipe-btn_prev,
.main-content .gc-swipe-btn_next {
  height: 50px;
  width: 50px;
  color: #ffffff;
  background-color: #122948;
  overflow: hidden;
  border-radius: var(--border-radius); }
  .main-content .gc-swipe-btn_prev:after,
  .main-content .gc-swipe-btn_next:after {
    font-size: 30px; }
  .main-content .gc-swipe-btn_prev.swiper-button-disabled,
  .main-content .gc-swipe-btn_next.swiper-button-disabled {
    display: none; }

.main-content .home-highlights {
  padding-bottom: 60px; }
  .main-content .home-highlights__wrapper {
    width: 100%;
    justify-content: space-between; }
    .main-content .home-highlights__wrapper .item-card {
      max-width: 450px;
      height: auto; }
      .main-content .home-highlights__wrapper .item-card:last-child {
        margin-right: 0; }
    .main-content .home-highlights__wrapper .main-swipe-item {
      height: 100%;
      box-sizing: border-box;
      border: 1px solid var(--color-light_gray);
      overflow: hidden;
      border-radius: var(--border-radius); }
    .main-content .home-highlights__wrapper p {
      font-size: 14px;
      padding-top: 8px;
      width: 95%;
      margin-bottom: 0; }
    .main-content .home-highlights__wrapper h2,
    .main-content .home-highlights__wrapper p {
      margin: 0;
      color: #122948; }
    .main-content .home-highlights__wrapper .type-btn__darkblue {
      width: fit-content;
      height: 45px;
      padding: 0 1.5rem;
      font-size: 14px;
      line-height: 18px;
      margin-top: 65px; }
    .main-content .home-highlights__wrapper .image-item {
      max-width: 450px;
      max-height: 450px; }
    .main-content .home-highlights__wrapper .footer_item {
      padding: 15px; }
      .main-content .home-highlights__wrapper .footer_item--list {
        display: flex;
        flex-flow: wrap;
        margin: 10px 0 0 0;
        padding: 0;
        list-style: none; }
        .main-content .home-highlights__wrapper .footer_item--list > *:last-child:after {
          content: ""; }
      .main-content .home-highlights__wrapper .footer_item--item {
        margin: 0 5px 0 0;
        padding: 0; }
        .main-content .home-highlights__wrapper .footer_item--item:after {
          content: "|";
          margin-left: 5px; }
        .main-content .home-highlights__wrapper .footer_item--item a {
          color: var(--color-sky_blue); }
  .main-content .home-highlights .gc-swipe-btn_prev,
  .main-content .home-highlights .gc-swipe-btn_next {
    display: none; }

@media only screen and (max-width: 991px) {
  .main-content .home-highlights__wrapper .font-size30 {
    font-size: 20px;
    line-height: 27px; } }

@media only screen and (max-width: 767px) {
  .page-main .main-content .root_home-card-block-with-btn {
    align-self: inherit; }
  .page-main .main-content .home-highlights {
    margin-bottom: 0; }
    .page-main .main-content .home-highlights__wrapper .item-card {
      width: 100%;
      max-width: 300px;
      margin-right: 15px; }
      .page-main .main-content .home-highlights__wrapper .item-card:last-child {
        padding: 0 0.5rem 0 0;
        margin-right: 0; }
    .page-main .main-content .home-highlights__wrapper .image-item {
      margin-bottom: 0; }
    .page-main .main-content .home-highlights__wrapper .footer_item {
      padding: 27px 0 20px 20px; }
    .page-main .main-content .home-highlights__wrapper .font-size30 {
      margin-top: -3px; }
    .page-main .main-content .home-highlights__wrapper .type-btn__darkblue {
      padding: 0 1.4rem;
      margin-top: 47px; }
    .page-main .main-content .home-highlights__wrapper p {
      width: 90%;
      padding-top: 11px; }
    .page-main .main-content .home-highlights .main-swipe-item {
      width: 300px;
      height: 100%; } }

.main-content .home-slider-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1640px; }
  .main-content .home-slider-block__container {
    position: relative;
    padding-bottom: 60px; }
    .main-content .home-slider-block__container .swiper-button-next,
    .main-content .home-slider-block__container .swiper-button-prev {
      top: calc(50% - 25px); }
    .main-content .home-slider-block__container .swiper-button-prev {
      left: -15px; }
    .main-content .home-slider-block__container .swiper-button-next {
      right: -15px; }
  .main-content .home-slider-block__title {
    margin: 0 0 40px 0;
    color: #122948; }
  .main-content .home-slider-block .gc-home-swiper {
    list-style: none;
    margin-bottom: 0;
    width: 100%;
    width: 100%;
    padding-bottom: 40px; }
    .main-content .home-slider-block .gc-home-swiper .item-card {
      max-width: 300px;
      width: 100%;
      background-color: #f7f7f7;
      overflow: hidden;
      border-radius: var(--border-radius); }
      .main-content .home-slider-block .gc-home-swiper .item-card .main-swipe-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        padding: 20px 0; }
      .main-content .home-slider-block .gc-home-swiper .item-card.first-card {
        height: auto;
        max-width: 400px;
        width: 100%;
        background-color: var(--color-light-gray-blue);
        box-sizing: border-box; }
      .main-content .home-slider-block .gc-home-swiper .item-card .image-item {
        max-height: 300px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .image-item img {
          mix-blend-mode: multiply;
          display: block;
          margin: 0 auto; }
      .main-content .home-slider-block .gc-home-swiper .item-card .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        height: 100%;
        padding: 0 40px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content h2 {
          margin: 30px 0 0 0; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content a {
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          height: 45px;
          padding: 0 20px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content .font-size30,
        .main-content .home-slider-block .gc-home-swiper .item-card .content p {
          color: #464646; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content p {
          font-size: 14px;
          line-height: 18px; }
      .main-content .home-slider-block .gc-home-swiper .item-card .type-btn__darkblue {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
        padding: 0 2rem;
        height: 45px;
        width: fit-content;
        align-self: center;
        z-index: 10; }
    .main-content .home-slider-block .gc-home-swiper .swiper-scrollbar {
      width: 98%;
      left: 1%;
      top: auto;
      bottom: 13px; }
    .main-content .home-slider-block .gc-home-swiper .swiper-button-disabled ~ .swiper-shadow {
      display: none; }

@media only screen and (max-width: 1200px) {
  .main-content .home-slider-block {
    margin-left: 0; }
    .main-content .home-slider-block__title {
      margin: 20px 0;
      font-size: 30px; }
    .main-content .home-slider-block .gc-home-swiper {
      width: 100%;
      margin: 0 0 -1px 0;
      padding-bottom: 20px;
      overflow: hidden; }
      .main-content .home-slider-block .gc-home-swiper .item-card {
        width: 300px; }
        .main-content .home-slider-block .gc-home-swiper .item-card.first-card {
          width: 300px; }
          .main-content .home-slider-block .gc-home-swiper .item-card.first-card p {
            width: 80%; }
          .main-content .home-slider-block .gc-home-swiper .item-card.first-card .content {
            padding: 0 20px; }
            .main-content .home-slider-block .gc-home-swiper .item-card.first-card .content h2 {
              margin-top: 0; }
      .main-content .home-slider-block .gc-home-swiper .swiper-scrollbar {
        bottom: 3px; } }

.main-content .home-slider-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1640px; }
  .main-content .home-slider-block__container {
    position: relative;
    padding-bottom: 60px; }
    .main-content .home-slider-block__container .swiper-button-next,
    .main-content .home-slider-block__container .swiper-button-prev {
      top: calc(50% - 25px); }
    .main-content .home-slider-block__container .swiper-button-prev {
      left: -15px; }
    .main-content .home-slider-block__container .swiper-button-next {
      right: -15px; }
  .main-content .home-slider-block__title {
    margin: 0 0 40px 0;
    color: #122948; }
  .main-content .home-slider-block .gc-home-swiper {
    list-style: none;
    margin-bottom: 0;
    width: 100%;
    width: 100%;
    padding-bottom: 40px; }
    .main-content .home-slider-block .gc-home-swiper .item-card {
      max-width: 300px;
      width: 100%;
      background-color: #f7f7f7;
      overflow: hidden;
      border-radius: var(--border-radius); }
      .main-content .home-slider-block .gc-home-swiper .item-card .main-swipe-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        padding: 20px 0; }
      .main-content .home-slider-block .gc-home-swiper .item-card.first-card {
        height: auto;
        max-width: 400px;
        width: 100%;
        background-color: var(--color-light-gray-blue);
        box-sizing: border-box; }
      .main-content .home-slider-block .gc-home-swiper .item-card .image-item {
        max-height: 300px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .image-item img {
          mix-blend-mode: multiply;
          display: block;
          margin: 0 auto; }
      .main-content .home-slider-block .gc-home-swiper .item-card .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        height: 100%;
        padding: 0 40px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content h2 {
          margin: 30px 0 0 0; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content a {
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          height: 45px;
          padding: 0 20px; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content .font-size30,
        .main-content .home-slider-block .gc-home-swiper .item-card .content p {
          color: #464646; }
        .main-content .home-slider-block .gc-home-swiper .item-card .content p {
          font-size: 14px;
          line-height: 18px; }
      .main-content .home-slider-block .gc-home-swiper .item-card .type-btn__darkblue {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
        padding: 0 2rem;
        height: 45px;
        width: fit-content;
        align-self: center;
        z-index: 10; }
    .main-content .home-slider-block .gc-home-swiper .swiper-scrollbar {
      width: 98%;
      left: 1%;
      top: auto;
      bottom: 13px; }
    .main-content .home-slider-block .gc-home-swiper .swiper-button-disabled ~ .swiper-shadow {
      display: none; }

@media only screen and (max-width: 1200px) {
  .main-content .home-slider-block {
    margin-left: 0; }
    .main-content .home-slider-block__title {
      margin: 20px 0;
      font-size: 30px; }
    .main-content .home-slider-block .gc-home-swiper {
      width: 100%;
      margin: 0 0 -1px 0;
      padding-bottom: 20px;
      overflow: hidden; }
      .main-content .home-slider-block .gc-home-swiper .item-card {
        width: 300px; }
        .main-content .home-slider-block .gc-home-swiper .item-card.first-card {
          width: 300px; }
          .main-content .home-slider-block .gc-home-swiper .item-card.first-card p {
            width: 80%; }
          .main-content .home-slider-block .gc-home-swiper .item-card.first-card .content {
            padding: 0 20px; }
            .main-content .home-slider-block .gc-home-swiper .item-card.first-card .content h2 {
              margin-top: 0; }
      .main-content .home-slider-block .gc-home-swiper .swiper-scrollbar {
        bottom: 3px; } }

.customer-account-login .page-main {
  width: 100%;
  max-width: inherit;
  margin: 0;
  padding: 0; }

.customer-account-login .page-header {
  display: none !important; }

.customer-account-login .column.main {
  position: relative;
  padding-bottom: 0; }

.customer-account-login .page-wrapper {
  padding-top: 0; }

.customer-account-login .page-title-wrapper {
  display: none; }

.page-title {
  color: #122948;
  font-size: 40px;
  line-height: 55px; }

.customer-account-login .main-login-container {
  display: grid;
  grid-template-areas: "left right" "bottom  right";
  grid-template-columns: minmax(62.1%, 1fr) 1fr;
  height: 100vh; }
  .customer-account-login .main-login-container::after {
    display: none; }
  .customer-account-login .main-login-container::before {
    display: none; }
  .customer-account-login .main-login-container .container-header {
    display: flex;
    align-items: center;
    align-self: center;
    margin-bottom: 12.5rem;
    margin-right: 31rem; }
    .customer-account-login .main-login-container .container-header .icon__left {
      margin-right: 76px;
      cursor: pointer; }
      .customer-account-login .main-login-container .container-header .icon__left .icon {
        width: 18px;
        height: 12px; }
    .customer-account-login .main-login-container .container-header .icon__logo {
      cursor: pointer; }
      .customer-account-login .main-login-container .container-header .icon__logo .icon {
        width: 271px;
        height: 54px; }
  .customer-account-login .main-login-container .block .block-title {
    border-bottom: unset;
    padding-bottom: 0; }
    .customer-account-login .main-login-container .block .block-title h1 {
      color: #122948;
      font-size: 40px;
      line-height: 55px;
      font-weight: bold;
      margin-bottom: 20px;
      margin-left: 2px; }
  .customer-account-login .main-login-container .primary a,
  .customer-account-login .main-login-container .primary button,
  .customer-account-login .main-login-container .primary .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper .customer-account-login .main-login-container .primary .actions-toolbar > .action,
  .customer-account-login .main-login-container .primary .action-gift {
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    background: #E6A532;
    border-radius: unset;
    border: unset; }
    .customer-account-login .main-login-container .primary a:hover,
    .customer-account-login .main-login-container .primary button:hover,
    .customer-account-login .main-login-container .primary .cart.table-wrapper .actions-toolbar > .action:hover,
    .cart.table-wrapper .customer-account-login .main-login-container .primary .actions-toolbar > .action:hover,
    .customer-account-login .main-login-container .primary .action-gift:hover {
      background-color: #CB9012; }
  .customer-account-login .main-login-container #block-customer-login-heading {
    color: #464646;
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    margin-left: 2px;
    margin-top: -1px; }
  .customer-account-login .main-login-container .block-new-customer,
  .customer-account-login .main-login-container .main-customer-login {
    height: 100%;
    width: inherit; }
  .customer-account-login .main-login-container .main-customer-login {
    grid-area: left;
    background-color: #EDF5F8;
    padding-top: 40px; }
    .customer-account-login .main-login-container .main-customer-login .block-content {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .customer-account-login .main-login-container .main-customer-login .block-content .control {
        position: relative; }
        .customer-account-login .main-login-container .main-customer-login .block-content .control > .icon {
          display: flex;
          align-items: center;
          top: 0;
          bottom: 0;
          margin-left: 10px;
          position: absolute; }
          .customer-account-login .main-login-container .main-customer-login .block-content .control > .icon.lock__icon, .customer-account-login .main-login-container .main-customer-login .block-content .control > .icon.email__icon {
            stroke: #B5B5B5; }
          .customer-account-login .main-login-container .main-customer-login .block-content .control > .icon.email__icon .icon {
            max-width: 18px;
            max-height: 12px; }
          .customer-account-login .main-login-container .main-customer-login .block-content .control > .icon.lock__icon .icon {
            max-width: 18px;
            height: 15px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .control input {
          color: #000000;
          font-size: 14px;
          line-height: 40px;
          padding: 0 0 0 40px;
          border: 1px solid #707070;
          height: 40px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .block-title {
        padding-bottom: 57px;
        margin-left: 31%;
        width: 70%; }
      .customer-account-login .main-login-container .main-customer-login .block-content .form-login {
        max-width: 480px;
        border-bottom: 1px solid #182846;
        padding: 1px 0;
        width: 100%;
        margin: -14px -2% -20px 1px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .fieldset {
        margin-bottom: 1rem; }
        .customer-account-login .main-login-container .main-customer-login .block-content .fieldset > .filed {
          margin: 0; }
        .customer-account-login .main-login-container .main-customer-login .block-content .fieldset .email {
          margin-bottom: 20px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .fieldset .password {
          margin-bottom: 50px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .fieldset:after {
        display: none; }
      .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar {
        margin-left: 0;
        padding-bottom: 39.5px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .remind {
          color: #00ADE7;
          text-decoration: underline;
          font-size: 14px;
          line-height: 18px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .primary button, .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .primary .actions-toolbar > .action, .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .primary .action-gift {
          height: 45px;
          width: 162px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .actions-toolbar .secondary {
          margin-top: 10px;
          margin-bottom: 10px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .login .choice {
        display: none; }
  .customer-account-login .main-login-container .block-new-customer {
    grid-area: right;
    background-color: #122948; }
    .customer-account-login .main-login-container .block-new-customer .block-title {
      margin-top: 41%;
      color: #FFFFFF;
      font-size: 20px;
      line-height: 27px; }
      .customer-account-login .main-login-container .block-new-customer .block-title strong {
        font-weight: bold; }
    .customer-account-login .main-login-container .block-new-customer .block-title,
    .customer-account-login .main-login-container .block-new-customer .block-content {
      margin-left: 19.4%; }
    .customer-account-login .main-login-container .block-new-customer p {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 18px;
      width: 56%;
      min-width: 200px;
      margin: 34px 0 66px 0; }
    .customer-account-login .main-login-container .block-new-customer .actions-toolbar {
      margin-top: 40px; }
      .customer-account-login .main-login-container .block-new-customer .actions-toolbar .primary a {
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 220px; }
        .customer-account-login .main-login-container .block-new-customer .actions-toolbar .primary a:hover {
          background-color: #CB9012;
          border: unset; }
  .customer-account-login .main-login-container .mstSocialLogin__login {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-area: bottom;
    flex-wrap: nowrap;
    background-color: #EDF5F8;
    width: 100%;
    padding: 3px 0 0 0; }
    .customer-account-login .main-login-container .mstSocialLogin__login._loginAfter {
      margin-top: 0; }
    .customer-account-login .main-login-container .mstSocialLogin__login a {
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      padding: 13px 0;
      border-radius: unset;
      margin: 3px -2% 13px 0;
      max-width: 480px;
      min-width: 480px;
      width: 30%; }
      .customer-account-login .main-login-container .mstSocialLogin__login a span {
        margin-left: -14px; }
      .customer-account-login .main-login-container .mstSocialLogin__login a::before {
        border: unset;
        margin: 0 26px 0 15px; }
    .customer-account-login .main-login-container .mstSocialLogin__login ._google {
      background-color: #F64135; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._google::before {
        margin: 0 29px 0 12px;
        background-color: #F64135; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._google:hover {
        background-color: #F64135; }
    .customer-account-login .main-login-container .mstSocialLogin__login ._facebook {
      background-color: #3A5999; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._facebook::before {
        background-color: #3A5999;
        margin: 0 28px 0 13px; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._facebook:hover {
        background-color: #3A5999; }
    .customer-account-login .main-login-container .mstSocialLogin__login ._twitter {
      background-color: #06ABED; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._twitter::before {
        background-color: #06ABED;
        background-size: 2.8rem; }
      .customer-account-login .main-login-container .mstSocialLogin__login ._twitter:hover {
        background-color: #06ABED; }

@media only screen and (max-width: 1439px) {
  .customer-account-login .main-login-container .main-customer-login {
    grid-area: left;
    background-color: #EDF5F8;
    padding-top: 40px; }
    .customer-account-login .main-login-container .main-customer-login .container-header {
      margin-right: 8rem; }
    .customer-account-login .main-login-container .main-customer-login .block-content .block-title {
      text-align: center;
      margin-left: 0; }
      .customer-account-login .main-login-container .main-customer-login .block-content .block-title h1 {
        font-size: 30px;
        line-height: 41px; }
    .customer-account-login .main-login-container .main-customer-login .form-login {
      margin: -14px 24% -20px 1px; } }

@media only screen and (max-width: 992px) {
  .customer-account-login .main-login-container {
    grid-template-areas: "left" "bottom" "right";
    grid-template-columns: 1fr; }
    .customer-account-login .main-login-container .mstSocialLogin__login {
      width: auto; }
      .customer-account-login .main-login-container .mstSocialLogin__login a {
        width: 100%;
        min-width: inherit;
        max-width: inherit;
        margin-right: 0; }
    .customer-account-login .main-login-container .main-customer-login .container-header {
      margin-right: 0;
      margin-top: 32px;
      margin-bottom: 35px;
      width: 100%;
      justify-content: space-between; }
      .customer-account-login .main-login-container .main-customer-login .container-header .icon__left {
        margin-right: 0; }
    .customer-account-login .main-login-container .main-customer-login .block-content .form-login {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0; }
    .customer-account-login .main-login-container .main-customer-login .block-content .block-title {
      text-align: inherit;
      width: 100%; }
    .customer-account-login .main-login-container .block-new-customer {
      min-height: 311px;
      max-height: 311px; }
      .customer-account-login .main-login-container .block-new-customer .block-title {
        margin-top: 35px; }
      .customer-account-login .main-login-container .block-new-customer .block-title,
      .customer-account-login .main-login-container .block-new-customer .block-content {
        margin-left: 0; }
    .customer-account-login .main-login-container .mstSocialLogin__login,
    .customer-account-login .main-login-container .block-new-customer,
    .customer-account-login .main-login-container .main-customer-login {
      padding: 0 2rem 0 2rem; } }

@media only screen and (max-width: 575px) {
  .customer-account-login .main-login-container {
    background-color: #EDF5F8; }
    .customer-account-login .main-login-container .main-customer-login .container-header .icon__logo .icon {
      width: 165px;
      height: 33px; }
    .customer-account-login .main-login-container .main-customer-login .container-header {
      margin-top: 32px;
      margin-bottom: 30px; }
    .customer-account-login .main-login-container .main-customer-login .block-content .form-login {
      padding: 0;
      margin: 15px 0 -18px 0; }
      .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar {
        padding-bottom: 10px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .primary button, .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .primary .actions-toolbar > .action, .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .primary .action-gift {
          width: 100%;
          height: 42px; }
        .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .secondary {
          text-align: left; }
          .customer-account-login .main-login-container .main-customer-login .block-content .form-login .actions-toolbar .secondary .action {
            color: #122948; }
    .customer-account-login .main-login-container .main-customer-login .block-content .block-title {
      padding-bottom: 0;
      margin-bottom: 0; }
      .customer-account-login .main-login-container .main-customer-login .block-content .block-title h1 {
        margin-bottom: 16px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .block-title strong {
        width: 80%; }
    .customer-account-login .main-login-container .main-customer-login .block-content .fieldset {
      margin-bottom: 6px; }
      .customer-account-login .main-login-container .main-customer-login .block-content .fieldset .password {
        margin-bottom: 20px; }
    .customer-account-login .main-login-container .block-new-customer p {
      width: 100%;
      margin: 0; }
    .customer-account-login .main-login-container .block-new-customer .block-title strong {
      margin-bottom: 8px; }
    .customer-account-login .main-login-container .block-new-customer .actions-toolbar {
      margin-top: 33px; }
      .customer-account-login .main-login-container .block-new-customer .actions-toolbar .primary a {
        width: 100%; }
    .customer-account-login .main-login-container .mstSocialLogin__login._loginAfter {
      margin: 0 0 20px 0; }
    .customer-account-login .main-login-container .mstSocialLogin__login a {
      height: 40px;
      font-size: 14px;
      padding: 0;
      margin: -2px 0 13px 0; }
      .customer-account-login .main-login-container .mstSocialLogin__login a::before {
        background-size: 2rem !important;
        margin: 0 33px 0 8px; }
    .customer-account-login .main-login-container .mstSocialLogin__login ._facebook::before, .customer-account-login .main-login-container .mstSocialLogin__login ._google::before {
      margin: 0 33px 0 8px; } }

.cat-card {
  color: var(--color-dark_blue);
  box-sizing: border-box;
  overflow: hidden;
  border-radius: var(--border-radius); }
  .cat-card__link {
    display: block; }
  .cat-card-sm {
    background-color: var(--color-light_gray);
    height: calc(100% - 1.5rem);
    transition: var(--default-transition);
    margin-bottom: 1.5rem;
    overflow: hidden;
    border-radius: var(--border-radius); }
    .cat-card-sm:hover .cat-card__image {
      transform: scale(1.05); }
    .cat-card-sm .cat-card__link {
      color: var(--color-dark_blue); }
      .cat-card-sm .cat-card__link:hover {
        color: var(--color-dark_blue); }
    .cat-card-sm .cat-card__picture {
      overflow: hidden; }
    .cat-card-sm .cat-card__image {
      transition: var(--default-transition); }
    .cat-card-sm .cat-card__meta {
      padding: 15px; }
      .cat-card-sm .cat-card__meta > *:last-child {
        margin-bottom: 0; }
    .cat-card-sm .cat-card__title {
      margin: 0 0 5px 0;
      color: var(--color-sky_blue); }
  .cat-card-lg {
    height: calc(100% - 3rem);
    transition: var(--default-transition);
    margin-bottom: 3rem;
    overflow: hidden;
    border-radius: var(--border-radius); }
    .cat-card-lg .cat-card__link {
      color: var(--color-dark_blue); }
      .cat-card-lg .cat-card__link:hover {
        color: var(--color-dark_blue); }
    .cat-card-lg .cat-card__picture {
      overflow: hidden;
      border-radius: var(--border-radius); }
    .cat-card-lg .cat-card__image {
      transition: var(--default-transition); }
    .cat-card-lg .cat-card__meta > *:last-child {
      margin-bottom: 0; }
    .cat-card-lg .cat-card__meta a {
      color: var(--color-sky_blue); }
      .cat-card-lg .cat-card__meta a:hover {
        color: var(--color-sky_blue);
        text-decoration: underline; }
    .cat-card-lg .cat-card__title {
      margin: 15px 0 15px 0;
      color: var(--color-dark_blue);
      font-size: 20px;
      font-weight: 600; }
  .cat-card-xxl {
    height: calc(100% - 1.5rem);
    transition: var(--default-transition);
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius); }
    .cat-card-xxl .cat-card__link {
      color: var(--color-dark_blue);
      height: 100%; }
      .cat-card-xxl .cat-card__link:hover {
        color: var(--color-dark_blue); }
    .cat-card-xxl .cat-card__wrapper {
      height: 100%; }
    .cat-card-xxl .cat-card__picture {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative; }
    .cat-card-xxl .cat-card__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute; }
    .cat-card-xxl .cat-card__slogan {
      position: absolute;
      left: 25px;
      bottom: 25px;
      background-color: var(--color-sky_blue);
      padding: 25px;
      pointer-events: none;
      overflow: hidden;
      border-radius: var(--border-radius); }
      .cat-card-xxl .cat-card__slogan > * {
        display: block;
        margin: 0;
        padding: 0;
        color: white; }
      .cat-card-xxl .cat-card__slogan--title {
        font-size: 24px;
        font-weight: 600; }
      .cat-card-xxl .cat-card__slogan--sub {
        margin-top: 5px; }

@media only screen and (max-width: 767px) {
  .cat-card-xxl {
    height: 100vw; } }

@media only screen and (max-width: 575px) {
  .cat-card-sm {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-light_gray);
    background-color: transparent;
    height: auto; }
    .cat-card-sm:hover .cat-card__image {
      transform: scale(1.05); }
    .cat-card-sm .cat-card__wrapper {
      display: flex; }
    .cat-card-sm .cat-card__media {
      width: 40%; }
    .cat-card-sm .cat-card__picture {
      height: 100%; }
    .cat-card-sm .cat-card__image {
      height: 100%;
      object-fit: cover; }
    .cat-card-sm .cat-card__meta {
      padding: 15px;
      width: 60%;
      box-sizing: border-box; }
    .cat-card-sm .cat-card__title {
      margin: 0 0 5px 0;
      color: var(--color-sky_blue); } }

.cat-product {
  position: relative;
  padding: 50px 0; }
  .cat-product-border-bottom {
    border-bottom: 1px solid var(--color-light_gray);
    margin-bottom: 50px; }
  .cat-product__container {
    display: flex;
    flex-wrap: wrap; }
    .cat-product__container .cat-product-card {
      width: calc((100% - 75px) / 6) !important;
      margin-right: 15px;
      margin-bottom: 15px; }
      .cat-product__container .cat-product-card:nth-child(6n) {
        margin-right: 0; }

.cat-product-card {
  width: calc((100% - 75px) / 6) !important;
  box-sizing: border-box;
  border: 1px solid var(--color-light_gray);
  transition: var(--default-transition);
  overflow: hidden;
  border-radius: var(--border-radius); }
  .cat-product-card__link {
    display: block;
    color: var(--color-dark_blue); }
    .cat-product-card__link:hover {
      color: var(--color-dark_blue);
      text-decoration: underline; }
  .cat-product-card__picture img {
    width: 100%; }
  .cat-product-card__meta {
    text-align: center;
    padding: 20px 10px; }

@media only screen and (max-width: 1439px) {
  .cat-product__container .cat-product-card {
    width: calc((100% - 45px) / 4) !important; }
    .cat-product__container .cat-product-card:nth-child(4n) {
      margin-right: 0; }
    .cat-product__container .cat-product-card:nth-child(6n) {
      margin-right: 15px; } }

@media only screen and (max-width: 991px) {
  .cat-product-card {
    width: calc((100% - 45px) / 4) !important; }
  .cat-product__container .cat-product-card {
    width: calc((100% - 30px) / 3) !important; }
    .cat-product__container .cat-product-card:nth-child(3n) {
      margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .cat-product-card {
    width: calc(100% / 1.6) !important; } }

@media only screen and (max-width: 639px) {
  .cat-product__container .cat-product-card {
    width: calc((100% - 15px) / 2) !important; }
    .cat-product__container .cat-product-card:nth-child(3n) {
      margin-right: 15px; }
    .cat-product__container .cat-product-card:nth-child(2n) {
      margin-right: 0; } }

.cat-intro__title {
  margin: 0 0 10px 0;
  font-weight: 700; }

.cat-intro > *:first-child {
  margin-top: 0; }

.cat-intro > *:last-child {
  margin-bottom: 0; }

.cat-usp {
  margin-top: 20px;
  margin-bottom: calc( 20px + 1.5rem);
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid var(--color-light_gray);
  border-bottom: 1px solid var(--color-light_gray); }
  .cat-usp__swiper {
    margin-left: 0;
    display: none; }
  .cat-usp__static {
    display: flex;
    justify-content: center; }

.cat-usp-card {
  width: auto;
  margin-right: 25px;
  font-size: 13px;
  line-height: 22px; }
  .cat-usp-card__link {
    color: var(--color-black); }
    .cat-usp-card__link strong {
      color: var(--color-sky_blue); }
    .cat-usp-card__link:hover {
      color: var(--color-black); }
      .cat-usp-card__link:hover strong {
        color: var(--color-sky_blue); }
    .cat-usp-card__link-noicon {
      color: var(--color-dark_blue);
      font-weight: bold; }
      .cat-usp-card__link-noicon:hover {
        color: var(--color-dark_blue);
        text-decoration: underline; }
    .cat-usp-card__link--customer-service {
      color: var(--color-sky_blue); }
      .cat-usp-card__link--customer-service:hover {
        color: var(--color-sky_blue); }
  .cat-usp-card__icon {
    width: auto;
    height: 16px;
    display: inline-block; }
    .cat-usp-card__icon > svg {
      width: auto;
      height: 16px; }
  .cat-usp-card__right {
    margin-left: auto;
    margin-right: 0;
    min-width: 300px;
    text-align: right; }
    .cat-usp-card__right > * {
      margin-left: 5px; }

@media only screen and (max-width: 991px) {
  .cat-usp__swiper {
    display: block; }
  .cat-usp__static {
    display: none; }
  .cat-usp-l-noborder {
    border-top: 0;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .cat-usp-card {
    width: 50%;
    margin-right: 0;
    text-align: center;
    font-size: 14px; }
    .cat-usp-card__right {
      display: none; } }

@media only screen and (max-width: 767px) {
  .cat-usp__swiper {
    padding-top: 20px;
    padding-bottom: 20px; }
  .cat-usp-card {
    width: 100%;
    margin-right: 0;
    text-align: center; }
    .cat-usp-card__right {
      display: none; } }

.not-found {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .not-found__container {
    max-width: 800px; }
  .not-found__title {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0; }
  .not-found__link {
    display: inline-block; }
  .not-found__actions {
    margin-top: 20px; }

.contactpage__col {
  width: 100%; }
  .contactpage__col > * {
    display: block !important; }

.contactpage__title {
  display: inline-block;
  margin-bottom: 13px;
  color: #122948;
  font-weight: 700;
  line-height: 1; }

.contactpage h2 {
  display: inline-block;
  margin-bottom: 13px;
  color: #122948;
  font-weight: 700;
  line-height: 1; }

.contactpage ul {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0; }

.contactpage .table-openingtimes tr:nth-child(odd) {
  background-color: var(--color-light-gray-blue); }

@media only screen and (min-width: 992px) {
  .contactpage {
    display: flex;
    column-gap: 25px; }
    .contactpage__col {
      width: 50%; }
      .contactpage__col > * {
        display: block !important; } }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.modals-overlay {
  z-index: 1000; }

.modal-slide,
.modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease; }
  .modal-slide._show,
  .modal-popup._show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease; }
    .modal-slide._show .modal-inner-wrap,
    .modal-popup._show .modal-inner-wrap {
      transform: translate(0, 0); }
  .modal-slide .modal-inner-wrap,
  .modal-popup .modal-inner-wrap {
    background-color: #ffffff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 14.8rem;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0); }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-header,
  .modal-slide._inner-scroll .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-header,
  .modal-slide .modal-content,
  .modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem; }
  .modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem; }

.modal-popup {
  z-index: 900;
  left: 0;
  overflow-y: auto; }
  .modal-popup._show .modal-inner-wrap {
    transform: translateY(0); }
  .modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition: transform 0.2s ease; }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie11 .modal-popup._inner-scroll,
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie11 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-header,
  .modal-popup .modal-content,
  .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem; }
  .modal-popup .modal-header,
  .modal-popup .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 1.2rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 3rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide {
    left: 14.8rem;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0; }
  .modal-custom .action-close:focus, .modal-custom .action-close:active,
  .modal-popup .action-close:focus,
  .modal-popup .action-close:active,
  .modal-slide .action-close:focus,
  .modal-slide .action-close:active {
    background: none;
    border: none; }
  .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover {
    background: none;
    border: none; }
  .modal-custom .action-close.disabled, .modal-custom .action-close[disabled],
  fieldset[disabled] .modal-custom .action-close,
  .modal-popup .action-close.disabled,
  .modal-popup .action-close[disabled],
  fieldset[disabled]
  .modal-popup .action-close,
  .modal-slide .action-close.disabled,
  .modal-slide .action-close[disabled],
  fieldset[disabled]
  .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .modal-custom .action-close > span,
  .modal-popup .action-close > span,
  .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: inherit; }
  .modal-custom .action-close:active:before,
  .modal-popup .action-close:active:before,
  .modal-slide .action-close:active:before {
    color: inherit; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: #1a1a1a; }

.modal-custom .action-close {
  margin: 25px; }

.modal-popup {
  pointer-events: none; }
  .modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word; }
  .modal-popup .action-close {
    padding: 10px; }

.modal-slide .action-close {
  padding: 1.1rem 2rem; }

.modal-slide .page-main-actions {
  margin-bottom: calc(2.1rem - 15px);
  margin-top: 2.1rem; }

.modals-overlay {
  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000; }

@media only screen and (max-width: 767px) {
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    left: 14.8rem;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .custom-slide._show .modal-inner-wrap {
        transform: translate(0, 0); }
    .custom-slide .modal-inner-wrap {
      background-color: #ffffff;
      box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #f4f4f4;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
    body._has-modal-custom .modal-custom-overlay {
      background-color: rgba(51, 51, 51, 0.55); } }

@media only screen and (max-width: 767px) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #f4f4f4; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 14px;
    font-weight: 700; } }

@media only screen and (min-width: 768px) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right; } }

.share-cart {
  float: left;
  width: 73%;
  margin-top: 20px; }
  .share-cart__container {
    background: var(--color-light_gray);
    padding: 20px; }
  .share-cart__title {
    font-size: 20px;
    margin: 0;
    font-weight: bold; }
  .share-cart__input {
    font-size: 20px;
    width: 100%;
    border: 0;
    background-color: transparent;
    transition: var(--default-transition); }
    .share-cart__input:active, .share-cart__input:visited, .share-cart__input:focus {
      box-shadow: none;
      background: transparent;
      border: none; }
    .share-cart__input.copied {
      color: var(--color-green); }
  .share-cart__body {
    display: flex; }
  .share-cart__copy {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background: transparent;
    cursor: pointer; }
    .share-cart__copy svg {
      width: 30px;
      height: 30px;
      fill: var(--color-dark_blue); }
    .share-cart__copy:active, .share-cart__copy:visited, .share-cart__copy:focus {
      box-shadow: none;
      background: transparent;
      border: none; }
    .share-cart__copy:hover {
      background: transparent;
      border: none; }
      .share-cart__copy:hover svg {
        fill: var(--color-sky_blue); }

.gcc-backbutton {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 99999;
  width: 100px;
  height: 100px;
  border-radius: 50%; }
  .gcc-backbutton__button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 0;
    background-color: var(--color-dark_blue);
    border: none;
    transition: var(--default-transition); }
    .gcc-backbutton__button:hover {
      background-color: var(--color-dark_blue); }
    .gcc-backbutton__button:focus {
      transform: scale(1.05); }
    .gcc-backbutton__button:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgNTEyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjMxLjI5MyA0NzMuODk5bDE5Ljc5OS0xOS43OTljNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDcwLjM5MyAyNTYgMjUxLjA5MiA3NC44N2M0LjY4Ni00LjY4NiA0LjY4Ni0xMi4yODQgMC0xNi45NzFMMjMxLjI5MyAzOC4xYy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNC45MDggMjQ3LjUxNWMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDIxNC4zMjIgNDczLjljNC42ODcgNC42ODYgMTIuMjg1IDQuNjg2IDE2Ljk3MS0uMDAxeiIvPjwvc3ZnPg==");
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      width: 60px;
      height: 60px;
      margin: 20px;
      display: block; }

.customerlogin {
  background-color: white;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 10px solid var(--color-dark_blue);
  width: 300px;
  z-index: 999;
  transform: translateX(300px);
  transition: var(--default-transition); }
  .customerlogin.js-active {
    transform: translateX(0); }
    .customerlogin.js-active .customerlogin__toggle-btn svg {
      transform: rotate(0); }
  .customerlogin__controls {
    width: 50px;
    height: 50px;
    background-color: var(--color-dark_blue);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    left: -60px;
    top: 20px; }
  .customerlogin__toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    width: 50px;
    height: 50px; }
    .customerlogin__toggle-btn:hover, .customerlogin__toggle-btn:focus {
      background: none;
      border: none; }
    .customerlogin__toggle-btn svg {
      max-width: 50px;
      max-height: 50px;
      transform: rotate(-180deg);
      transition: var(--default-transition); }
  .customerlogin__form {
    width: 100%; }
    .customerlogin__form > * {
      margin-bottom: 5px; }
      .customerlogin__form > *:first-child {
        margin-top: 0; }
      .customerlogin__form > *:last-child {
        margin-bottom: 0; }
  .customerlogin__input {
    width: 100%;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    background: var(--color-light_gray); }
    .customerlogin__input:focus, .customerlogin__input:hover {
      border: none;
      outline: none;
      box-shadow: none !important; }
  .customerlogin__button {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    background: var(--color-orange);
    color: white;
    font-weight: 400;
    text-transform: uppercase; }
    .customerlogin__button:hover, .customerlogin__button:focus {
      border: none;
      background: var(--color-dark_orange);
      color: white; }
    .customerlogin__button--pin {
      background: var(--color-dark_blue);
      margin-top: 10px; }
      .customerlogin__button--pin:hover, .customerlogin__button--pin:focus {
        background: var(--color-dark_blue);
        opacity: 0.8; }
  .customerlogin__wrapper {
    display: none; }
  .customerlogin__header {
    width: 100%;
    padding: 20px;
    position: absolute;
    box-sizing: border-box;
    z-index: 2;
    background-color: white; }
  .customerlogin__results {
    padding: 0 20px 20px 20px;
    margin-top: 170px;
    overflow: scroll;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .customerlogin a,
  .customerlogin a:link,
  .customerlogin a:hover,
  .customerlogin a:visited {
    color: black; }
  .customerlogin__auth {
    padding: 20px; }
    .customerlogin__auth--numbers {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
      .customerlogin__auth--numbers > *:nth-child(3),
      .customerlogin__auth--numbers > *:nth-child(6),
      .customerlogin__auth--numbers > *:nth-child(9),
      .customerlogin__auth--numbers > *:nth-child(11) {
        margin-right: 0; }
    .customerlogin__auth--nr {
      width: calc(33.333% - 4px);
      height: 50px;
      font-size: 24px;
      margin-right: 6px;
      margin-bottom: 6px;
      border: none;
      border-radius: 0;
      background-color: var(--color-light_gray); }
      .customerlogin__auth--nr:hover {
        box-shadow: none !important; }
      .customerlogin__auth--nr:focus {
        box-shadow: none !important;
        background-color: var(--color-medium_gray); }
    .customerlogin__auth--header {
      margin-bottom: 10px; }
    .customerlogin__auth--code {
      width: 100% !important;
      height: auto !important;
      padding: 20px !important;
      font-size: 32px !important;
      letter-spacing: 5px !important;
      text-align: center !important;
      border: none !important; }
      .customerlogin__auth--code:focus {
        box-shadow: none !important;
        border: none; }
    .customerlogin__auth--submit {
      padding: 20px !important;
      font-size: 18px !important;
      font-weight: 300px !important; }
    .customerlogin__auth--error {
      background-color: var(--color-red);
      padding: 10px;
      text-align: center;
      font-size: 20px;
      color: white;
      display: block;
      margin-top: 10px;
      opacity: 0;
      transition: var(--default-transition); }
      .customerlogin__auth--error-active {
        opacity: 1; }

.customerlogin-card {
  border: 1px solid var(--color-medium_gray);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  transition: var(--default-transition); }
  .customerlogin-card:hover {
    border: 1px solid var(--color-light_gray);
    background-color: var(--color-light_gray); }
  .customerlogin-card__link {
    color: black; }
    .customerlogin-card__link:visited, .customerlogin-card__link:hover, .customerlogin-card__link:focus {
      color: black; }
  .customerlogin-card__addresses > * {
    margin-bottom: 20px; }
    .customerlogin-card__addresses > *:last-child {
      margin-bottom: 0; }
  .customerlogin-card__addresses h3 {
    color: var(--color-dark_blue);
    font-weight: bold; }

.customerlogin-addresses__submit--address {
  background-color: var(--color-sky_blue);
  width: 100%;
  font-weight: bold;
  text-align: center;
  color: white; }
  .customerlogin-addresses__submit--address:hover {
    background-color: var(--color-dark_blue);
    color: white; }

.customerlogin-address__modal {
  position: fixed;
  top: 120px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-dark_blue);
  align-items: center;
  justify-content: center;
  display: none;
  padding: 10px; }
  .customerlogin-address__modal.is-active {
    display: flex; }
  .customerlogin-address__modal--inner {
    background-color: white;
    padding: 30px; }
    .customerlogin-address__modal--inner > * {
      margin-bottom: 20px; }
      .customerlogin-address__modal--inner > *:last-child {
        margin-bottom: 0; }
    .customerlogin-address__modal--inner button, .customerlogin-address__modal--inner .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .customerlogin-address__modal--inner .actions-toolbar > .action, .customerlogin-address__modal--inner .action-gift {
      background-color: var(--color-orange);
      width: 100%;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 1.5;
      text-align: center;
      color: white; }
      .customerlogin-address__modal--inner button:hover, .customerlogin-address__modal--inner .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .customerlogin-address__modal--inner .actions-toolbar > .action:hover, .customerlogin-address__modal--inner .action-gift:hover {
        background-color: var(--color-dark_orange); }

.column .block-addbysku .fieldset {
  margin: 0; }
  .column .block-addbysku .fieldset .fields {
    position: relative; }
    .column .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
    .column .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 4px; }

.column .block-addbysku .sku {
  margin-right: -155px;
  padding-right: 160px;
  width: 100%; }

.column .block-addbysku .skus > .label {
  margin-top: 10px; }

.column .block-addbysku .qty .qty + .mage-error {
  width: 80px; }

.column .block-addbysku .block-content .actions-toolbar {
  clear: both;
  margin: 0 0 20px;
  text-align: left; }
  .column .block-addbysku .block-content .actions-toolbar:before, .column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table; }
  .column .block-addbysku .block-content .actions-toolbar:after {
    clear: both; }
  .column .block-addbysku .block-content .actions-toolbar .primary {
    float: left; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block; }
    .column .block-addbysku .block-content .actions-toolbar .primary a.action,
    .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
      display: inline-block; }
  .column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none; }
  .column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left; }

.column .block-addbysku .reset {
  margin: 10px 0; }

.column .block-addbysku .note {
  display: block;
  margin: 0 0 20px; }

.block-cart-failed {
  margin-top: 40px; }
  .block-cart-failed .block-title {
    font-size: 2.6rem; }
    .block-cart-failed .block-title strong {
      font-weight: 300; }
  .block-cart-failed .actions {
    text-align: center; }
    .block-cart-failed .actions .action {
      margin-bottom: 10px; }

@media only screen and (max-width: 639px) {
  .column .block-addbysku .sku {
    margin-right: -85px;
    padding-right: 90px; }
  .column .block-addbysku .action.remove {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400; }
    .column .block-addbysku .action.remove > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .column .block-addbysku .action.remove:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 1px 0 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .column .block-addbysku .action.remove:hover:before {
      color: inherit; }
    .column .block-addbysku .action.remove:active:before {
      color: inherit; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; } }

@media only screen and (min-width: 640px) {
  .column .block-addbysku .action.remove {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857;
    margin: 0;
    padding: 0;
    color: var(--color-dark_blue);
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    margin-top: 2px; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .column .block-addbysku .action.remove:visited {
      color: #00ade7;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #00ade7;
      text-decoration: none; }
    .column .block-addbysku .action.remove:active {
      color: #00ADE7;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #00ade7; }
    .column .block-addbysku .action.remove:hover, .column .block-addbysku .action.remove:active, .column .block-addbysku .action.remove:focus {
      background: none;
      border: 0; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      color: var(--color-dark_blue);
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: underline; } }

@media only screen and (min-width: 768px) {
  .block-cart-failed .actions {
    text-align: left; }
  .block-cart-failed .actions.primary {
    float: right; } }

.payment-method-braintree .hosted-control {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  width: 225px; }
  .payment-method-braintree .hosted-control:disabled {
    opacity: 0.5; }
  .payment-method-braintree .hosted-control::-moz-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #00ade7; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27; }
    .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
      height: auto;
      opacity: 1; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400; }
  .payment-method-braintree .hosted-control.hosted-cid {
    width: 5rem; }
  .payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 6rem; }
    .payment-method-braintree .hosted-control.hosted-date:first-of-type {
      margin-right: 50px; }
      .payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
        content: '/';
        display: inline;
        padding-bottom: inherit;
        padding-top: inherit;
        position: absolute;
        right: -25px;
        top: 5px; }

.payment-method-braintree .field-tooltip {
  right: 0; }

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0; }

.braintree-paypal-logo {
  margin-top: 10px;
  text-align: center; }

.braintree-paypal-account {
  padding: 15px 0 15px 50px;
  position: relative;
  display: inline-block; }
  .braintree-paypal-account:before {
    background-image: url("../Magento_Braintree/images/paypal-small.png");
    background-position-x: 0;
    background-position-y: 0;
    line-height: 16px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    margin: ""; }
  .braintree-paypal-account:before {
    left: 17px;
    margin-top: -8px;
    position: absolute;
    top: 50%; }
  .braintree-paypal-account .payment-method-type {
    font-weight: 700; }
  .braintree-paypal-account .payment-method-description {
    color: #575757; }

.braintree-paypal-fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.action-braintree-paypal-logo {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .action-braintree-paypal-logo:focus, .action-braintree-paypal-logo:active {
    background: none;
    border: none; }
  .action-braintree-paypal-logo:hover {
    background: none;
    border: none; }
  .action-braintree-paypal-logo.disabled, .action-braintree-paypal-logo[disabled],
  fieldset[disabled] .action-braintree-paypal-logo {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .action-braintree-paypal-logo img {
    margin: 0;
    width: 114px; }

.payment-method .payment-method-content .actions-toolbar.braintree-paypal-actions {
  margin-left: 0; }

.account .table .col.paypal-account img {
  vertical-align: middle; }

.account .table .col.paypal-account + .actions {
  vertical-align: middle; }

@media only screen and (min-width: 768px) {
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem; }
  .braintree-paypal-account {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0;
    display: inline-block;
    width: 50%; }
  .account .table-credit-cards .col.actions {
    width: 100px; } }

.bundle-options-container {
  clear: both;
  margin-bottom: 40px; }
  .bundle-options-container .legend.title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .bundle-options-container .product-add-form {
    display: none; }
  .bundle-options-container .product-options-wrapper {
    margin-bottom: 30px; }
  .bundle-options-container .action.back {
    margin-bottom: 30px; }
  .bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700; }
  .bundle-options-container .block-bundle-summary {
    background: #e8e8e8;
    padding: 10px 20px; }
    .bundle-options-container .block-bundle-summary > .title > strong {
      font-weight: 300;
      line-height: 1.1;
      font-size: 2.6rem;
      margin-top: 2.5rem;
      margin-bottom: 2rem; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-top: 30px; }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
        font-weight: 300;
        line-height: 1.1;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        display: block; }
    .bundle-options-container .block-bundle-summary .bundle.items > li {
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .box-tocart .actions {
      display: inline-block;
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .product-addto-links > .action {
      vertical-align: top; }
  .bundle-options-container .nested .field.qty {
    margin-top: 20px; }
    .bundle-options-container .nested .field.qty .label {
      font-weight: 700;
      margin: 0 0 5px;
      display: inline-block; }
  .bundle-options-container p.required {
    color: #e02b27; }
  .bundle-options-container .product-options-bottom {
    clear: left; }

@media only screen and (min-width: 768px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%; }
  .bundle-options-container .block-bundle-summary {
    float: right;
    position: relative;
    width: 40%; }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left
  .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right
  .bundle-options-container .block-bundle-summary,
  .page-layout-3columns
  .bundle-options-container .block-bundle-summary {
    width: 48%; } }

.products {
  margin: 30px 0 0; }

.product-items > *:last-child {
  border-bottom: none; }

.product-item {
  vertical-align: top;
  border-bottom: 1px solid #f5f5f5; }
  .products-grid .product-item {
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px; }
  .product-item-name {
    display: block;
    margin: 0 0 10px 0;
    word-wrap: break-word;
    hyphens: auto;
    height: calc((1.4rem * (18/13)) * 2); }
  .product-item-subtitle {
    color: #a3a3a3;
    display: block;
    margin: 0 0 10px 0;
    word-wrap: break-word;
    hyphens: auto;
    height: calc(1.4rem * (18/13)); }
  .product-item-info {
    max-width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-row-gap: 20px;
    grid-column-gap: 20px; }
    .product-item-info .image-wrap {
      grid-column: span 4 /span 4; }
    .page-products .product-item-info {
      width: auto; }
  .product-item-details {
    grid-column: span 8/span 8; }
  .product-item-actions .actions-secondary > .action:before {
    margin: 0; }
  .product-item-description {
    margin: 25px 0; }
  .product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0; }
  .product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px;
    text-transform: lowercase; }
  .product-item .price-box {
    margin: 0;
    height: 23px; }
    .product-item .price-box .price {
      font-size: 18px;
      font-weight: normal;
      white-space: nowrap; }
    .product-item .price-box .map-show-info,
    .product-item .price-box .minimal-price-link,
    .product-item .price-box .price-label {
      display: none; }
    .product-item .price-box .minimal-price .old-price {
      display: none; }
    .product-item .price-box .price-label {
      font-size: 12px; }
      .product-item .price-box .price-label:after {
        content: ':'; }
  .product-item .special-price .price,
  .product-item .minimal-price .price {
    font-size: 18px;
    font-weight: normal; }
  .product-item .special-price .price-wrapper,
  .product-item .minimal-price .price-wrapper {
    display: inline-block; }
  .product-item .special-price .price-including-tax + .price-excluding-tax,
  .product-item .minimal-price .price-including-tax + .price-excluding-tax {
    display: block; }
  .product-item .special-price {
    display: block; }
  .product-item .old-price .price {
    font-weight: 400; }
  .product-item .minimal-price .price-container {
    display: block; }
  .product-item .minimal-price-link {
    margin-top: 5px; }
    .product-item .minimal-price-link .price-label {
      color: var(--color-dark_blue);
      font-size: 14px; }
    .product-item .minimal-price-link .price {
      font-weight: 400; }
  .product-item .minimal-price-link,
  .product-item .price-excluding-tax,
  .product-item .price-including-tax {
    display: block;
    white-space: nowrap; }
  .product-item .price-from,
  .product-item .price-to {
    margin: 0; }
  .product-item .action.tocompare:before {
    content: ""; }
  .product-item .tocart {
    white-space: nowrap; }

.product .image-link {
  width: 100%;
  display: block; }

.product .image-wrap div[class*="amlabel-position-"] {
  pointer-events: none; }

.product .image-wrap div[class*="amlabel-position-top-left"] {
  transform-origin: top left; }

.product .image-wrap div[class*="amlabel-position-top-right"] {
  transform-origin: top right; }

.product .image-wrap div[class*="amlabel-position-bottom-left"] {
  transform-origin: bottom left; }

.product .image-wrap div[class*="amlabel-position-bottom-right"] {
  transform-origin: bottom right; }

.product .image-figure {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--color-light_gray); }

.product .image-picture {
  width: 100%;
  display: block;
  mix-blend-mode: multiply; }

.price-container .price {
  font-size: 14px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
  margin-top: 5px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
  font-size: 11px; }

.price-container .weee:before {
  content: "(" attr(data-label) ": "; }

.price-container .weee:after {
  content: ')'; }

.price-container .weee + .price-excluding-tax:before {
  content: attr(data-label) ": "; }

.products-list .product-item {
  display: table;
  width: 100%; }
  .products-list .product-item-info {
    display: table-row; }
  .products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%; }
  .products-list .product-item-details {
    display: table-cell;
    vertical-align: top; }

@media only screen and (max-width: 639px) {
  .products-list .product-item {
    table-layout: fixed; }
    .products-list .product-item-photo {
      padding: 0 10px 10px 0;
      width: 30%; } }

@media only screen and (min-width: 640px) {
  .products-grid .product-item {
    margin-bottom: 17px; }
  .product-item-actions {
    display: block; }
    .products-grid .product-item-actions {
      margin: 0; }
    .product-item-actions .actions-primary + .actions-secondary {
      display: table-cell;
      white-space: nowrap;
      width: 50%; }
      .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal; }
    .product-item-actions .actions-primary {
      display: table-cell; } }

@media only screen and (min-width: 768px) {
  .page-products.page-layout-1column .products-grid .product-item {
    width: 25%; } }

@media only screen and (min-width: 992px) {
  .catalog-category-view .product-items .item:nth-last-child(-n+3) {
    margin-bottom: 9px; } }

@media only screen and (min-width: 992px) {
  .product-items {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr); }
  .products-grid .product-item {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none; }
  .product-item-info {
    max-width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-template-columns: repeat(12, minmax(0, 1fr)); }
    .product-item-info .image-wrap {
      grid-column: span 12 /span 12; }
      .product-item-info .image-wrap div[class*="amlabel-position-"] {
        transform: scale(1); }
    .page-products .product-item-info {
      width: auto; }
  .product-item-details {
    grid-column: span 12 /span 12; } }

@media only screen and (max-width: 639px) {
  .product .image-wrap div[class*="amlabel-position-"] {
    transform: scale(0.3); } }

@media only screen and (min-width: 640px) and (max-width: 992px) {
  .product .image-wrap div[class*="amlabel-position-"] {
    transform: scale(0.5); } }

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .product .image-wrap div[class*="amlabel-position-"] {
    transform: scale(0.7); } }

@media only screen and (min-width: 1440px) {
  .product .image-wrap div[class*="amlabel-position-"] {
    transform: scale(1); } }

.page-products .columns {
  position: relative; }

.toolbar-amount {
  display: block;
  position: relative;
  margin: 0;
  padding: 0 20px 0 0;
  margin-right: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #122948; }
  .products.wrapper ~ .toolbar .toolbar-amount {
    display: none; }

.toolbar-products {
  margin-bottom: 35px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .toolbar-products .pages {
    display: none; }
    .products.wrapper ~ .toolbar-products .pages {
      display: block; }
  .toolbar-products .limiter {
    display: none; }
    .toolbar-products .limiter .control {
      display: inline-block; }

.sorter {
  padding: 0; }
  .products.wrapper ~ .toolbar .sorter {
    display: none; }
  .sorter__text {
    display: inline-block;
    margin-right: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #122948; }

.sorter-options {
  margin: 0;
  padding: 10px 36px 10px 15px;
  width: 350px;
  height: 40px;
  border: 1px solid #a3a3a3;
  color: #122948;
  font-weight: 400;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1Ljg4MiAxLjIxN2wtLjIzOS0uMjM5YS40LjQgMCAwMC0uNTcyIDBsLTYuNTcgNi41NzNMMS45MjUuOTc5YS40LjQgMCAwMC0uNTcyIDBsLS4yMzkuMjM5YS40LjQgMCAwMDAgLjU3Mmw3LjEgNy4wOTVhLjQuNCAwIDAwLjU3MSAwbDcuMDk0LTcuMWEuNC40IDAgMDAuMDAzLS41Njh6IiBmaWxsPSIjMTgyODQ2Ii8+PC9zdmc+");
  background-position: center right 20px;
  background-repeat: no-repeat;
  appearance: none; }
  .sorter-options:focus {
    border-color: #4daae2;
    box-shadow: none !important; }

.sorter-action {
  vertical-align: top;
  display: inline-block;
  text-decoration: none; }
  .sorter-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sorter-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 32px;
    color: #8f8f8f;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sorter-action:hover:before {
    color: #333; }

.sorter .sort-desc:before {
  content: ""; }

.modes {
  display: none; }

.limiter-options {
  margin: 0 5px 0 7px;
  width: auto; }

.limiter-label {
  font-weight: 400; }

.page-products .toolbar .limiter {
  display: none; }

@media only screen and (min-width: 768px) {
  .page-products .columns {
    padding-top: 0;
    position: relative; }
  .products.wrapper ~ .toolbar .pages {
    float: right; }
  .modes {
    display: block;
    float: left;
    margin-right: 20px; }
    .products.wrapper ~ .toolbar .modes {
      display: none; }
  .modes-mode {
    color: #7d7d7d;
    border: 1px solid #d1d1d1;
    border-right: 0;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    display: block;
    text-decoration: none; }
    .modes-mode > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .modes-mode:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 26px;
      line-height: inherit;
      color: #7d7d7d;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .modes-mode:hover:before {
      color: #7d7d7d; }
    .modes-mode:not(.active):hover {
      color: #7d7d7d;
      background: #dedede; }
    .modes-mode:last-child {
      border-right: 1px solid #d1d1d1; }
    .modes-mode.active {
      color: #a6a6a6; }
  .mode-list:before {
    content: ""; }
  .limiter {
    float: right; }
    .products.wrapper ~ .toolbar .limiter {
      display: block; } }

@media only screen and (max-width: 1199px) {
  .toolbar-amount {
    display: none; } }

@media only screen and (max-width: 767px) {
  .sorter {
    float: left; }
    .sorter-options {
      height: 30px;
      padding: 4px 7px 7px; } }

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .sorter {
    float: right; }
    .sorter-options {
      height: 40px; } }

@media only screen and (min-width: 1280px) {
  .sorter {
    margin-left: 20px; }
  .catalog-category-view div.pages {
    width: 335px; }
    .catalog-category-view div.pages .items {
      display: flex;
      justify-content: space-between; } }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: grabbing; }

.fotorama-gpu, .fotorama__caption, .fotorama--fullscreen, .fotorama__wrap--css3 .fotorama__html, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full, .fotorama__wrap--css3 .fotorama__nav, .fotorama__nav:before, .fotorama__nav:after, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__stage:before, .fotorama__stage:after, .fotorama__wrap--css3 .fotorama__stage__frame {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame, .fotorama__zoom-in:focus,
.fotorama__zoom-out:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after {
  background-color: #00ade7;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__shaft {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: unset;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__wrap {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  display: inline;
  display: inline-block;
  vertical-align: middle;
  zoom: 1; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -1000; }

.fotorama-visible {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1;
  z-index: auto; }

.fotorama-no-tap, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: unset; }

.fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__active {
  box-shadow: 0 0 0 transparent; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 250ms ease-in-out; }
  .fotorama__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__arr:focus {
    box-shadow: none; }
    .fotorama__arr:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #00ade7;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__arr--prev {
    left: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
      transform: translate3d(-120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
      transform: translate3d(-120px, 0, 0); }
  .fotorama__arr--next {
    right: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
      transform: translate3d(120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
      transform: translate3d(120px, 0, 0); }
  .fotorama__arr--disabled {
    display: none;
    opacity: 0.1;
    pointer-events: none;
    cursor: default; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__arr {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr {
    display: none !important; }
  .fotorama__wrap--css3 .fotorama__arr:not(:focus) {
    transition-duration: 250ms;
    transition-property: transform, opacity, background-color; }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama__arr .fotorama__arr__arr {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .ie9 .fotorama__arr .fotorama__arr__arr {
    margin: -40px 0 0 -40px; }

.fotorama__arr--prev .fotorama__arr__arr {
  background-position: -80px -80px; }

.fotorama__arr--next .fotorama__arr__arr {
  background-position: -160px -80px; }

.fotorama__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000000;
  font-size: 14px;
  line-height: 1.5;
  z-index: 12; }
  .fotorama__caption a {
    color: #000000;
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: fade(#000000, 0.5); }
    .fotorama__caption a:hover {
      color: #000000;
      border-color: fade(#000000, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #ffffff; }

.fotorama__dot {
  display: block;
  position: relative;
  top: 12px;
  left: 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #858585;
  border-radius: 6px; }
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    box-shadow: none; }
    .fotorama__nav__frame--dot:focus .fotorama__dot:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #00ade7;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__dot:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .fotorama__active .fotorama__dot {
    background-color: #ff5501;
    border-color: #ff5501; }

.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama--fullscreen {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    background: #ffffff;
    z-index: 1000 !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }

.fotorama__fullscreen-icon {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: 0 0;
  cursor: pointer;
  z-index: 1000; }
  .fotorama__fullscreen-icon:focus {
    box-shadow: none; }
    .fotorama__fullscreen-icon:focus:after {
      border-radius: inherit;
      bottom: 3px;
      content: "";
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama--fullscreen-icons .fotorama__fullscreen-icon {
    display: none; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: inline-block;
    background-position: -80px 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__fullscreen-icon {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
    display: none !important; }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform: translate3d(80px, -80px, 0); }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important; }
  .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus) {
    transition-duration: 250ms;
    transition-property: transform, opacity, background-color; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 250ms ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active {
      background-color: rgba(213, 213, 213, 0.5); }

.gallery-placeholder .loading-mask {
  position: static;
  padding: 0 0 50%; }

.gallery-placeholder .loader img {
  position: absolute; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 250ms;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__img {
  max-width: inherit;
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }
  .fotorama__img--full {
    display: none; }
  .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
    opacity: 0; }
  .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }
  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    position: absolute;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%); }
  .fotorama__stage__frame .fotorama__img {
    position: absolute;
    top: 50%;
    left: calc(50% - 10px);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 250ms;
    transition-property: width, height, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    max-height: inherit; }
  .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
    display: none; }
    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
      display: block; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    display: block;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: default;
    transition: 250ms linear; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
      max-width: none;
      max-height: none;
      cursor: pointer;
      transition-property: width, height, bottom, right, top, left; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none; }
  .fotorama__product-video--loaded .fotorama__img,
  .fotorama__product-video--loaded .fotorama__img--full {
    display: none !important; }

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0; }

.magnifier-lens {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: solid 1px #bbb;
  z-index: 800; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  position: absolute;
  top: 215px;
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0; }
  .magnifier-preview:not(.hidden) {
    background-color: #ffffff; }
  .magnifier-preview img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: inherit; }

.magnify-lens {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  z-index: 1000; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.magnify-fullimage {
  display: none; }
  .fotorama__fullscreen .magnify-fullimage {
    display: inline-block; }

.fotorama__nav {
  position: relative;
  display: none;
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }
  .fotorama__nav--dots {
    display: flex;
    justify-content: center; }
  .fotorama__nav--thumbs {
    display: flex;
    justify-content: center; }
  .fotorama__nav:before, .fotorama__nav:after {
    position: absolute;
    display: block;
    content: "";
    text-decoration: none;
    background-repeat: no-repeat;
    z-index: 10;
    pointer-events: none; }
  .fotorama__nav:before {
    left: -10px;
    top: -10px; }
  .fotorama__nav:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__nav:after,
  .fotorama__wrap--no-shadows .fotorama__nav:before,
  .fotorama__wrap--no-shadows .fotorama__nav:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__nav {
    max-width: 99999px !important; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0; }

.fotorama__nav__frame {
  position: relative;
  box-sizing: content-box; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
    .fotorama__nav--dots .fotorama__nav__frame--thumb {
      display: none; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
      display: none; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
    width: 0;
    height: 0;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
      top: -3px;
      left: -3px;
      padding: 3px; }
  .fotorama__nav__frame.fotorama__active .fotorama__thumb {
    border: 1px solid var(--color-sky_blue); }
  .fotorama__nav__frame:focus {
    box-shadow: none; }
    .fotorama__nav__frame:focus :after {
      top: -1px;
      left: -1px;
      padding: 1px; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama__nav__shaft {
  position: relative;
  top: 0;
  left: 0;
  text-align: left; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    width: 100%; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__select {
  cursor: auto; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--right:after {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  background-size: 1px 100%, 5px 100%; }

.fotorama__nav.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--bottom:after {
  left: 0;
  right: 0;
  width: auto;
  height: 10px;
  background-size: 100% 1px, 100% 5px; }

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  left: 0;
  background-position: 0 0, 0 0; }

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  right: 0;
  background-position: 100% 0, 100% 0; }

.fotorama__nav.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--top:before {
  top: 0;
  background-position: 0 0, 0 0; }

.fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--bottom:after {
  bottom: 0;
  background-position: 0 100%, 0 100%; }

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0); }

.fotorama__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  width: 64px;
  margin: auto;
  background-image: url("../images/loader-1.gif");
  z-index: 100; }
  .fotorama__spinner--show {
    display: block; }
  .fotorama__wrap--css3 .fotorama__spinner {
    animation: spinner 24s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__stage {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .fotorama__stage:before, .fotorama__stage:after {
    position: absolute;
    display: block;
    content: '';
    background-repeat: no-repeat;
    pointer-events: none;
    text-decoration: none;
    z-index: 10; }
  .fotorama__stage:before {
    left: -10px;
    top: -10px; }
  .fotorama__stage:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
  .fotorama__wrap--no-shadows .fotorama__stage:before,
  .fotorama__wrap--no-shadows .fotorama__stage:after,
  .fotorama__wrap--fade .fotorama__stage:before,
  .fotorama__wrap--fade .fotorama__stage:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__stage {
    max-width: 99999px !important; }

.fotorama__stage__frame {
  overflow: hidden;
  text-align: center; }
  .fotorama__wrap--rtl .fotorama__stage__frame {
    direction: rtl; }
  .fotorama__wrap--slide .fotorama__stage__frame {
    opacity: 1 !important; }
  .fotorama__wrap--fade .fotorama__stage__frame {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame iframe {
    left: 80px;
    width: calc(100% - 80px * 2); }
  .fotorama__wrap--only-active .fotorama__stage__frame {
    visibility: hidden; }

.fotorama__stage__shaft {
  position: relative; }
  .fotorama__stage__shaft:focus {
    box-shadow: none; }
    .fotorama__stage__shaft:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #00ade7;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }

.fotorama__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid var(--color-light_gray);
  box-sizing: border-box;
  cursor: pointer; }
  .fotorama__thumb:hover {
    border: 1px solid var(--color-medium_gray); }
  .fotorama__nav__frame:focus .fotorama__thumb {
    box-shadow: none;
    z-index: 2; }
    .fotorama__nav__frame:focus .fotorama__thumb:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 3px 1px #00ade7;
      border-radius: inherit;
      content: '';
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__thumb {
    overflow: inherit; }
  .fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
    left: 1px; }
  .fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
    right: 1px; }
  .fotorama__thumb--icon {
    padding-bottom: 30px;
    font-size: 0.001px; }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
      .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
        margin: -15px 0 0 -15px; }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: auto;
      width: 30px;
      transform: rotate(90deg); }
    .fotorama__thumb__arr--left .fotorama__thumb--icon {
      background-position: -25px -265px; }
    .fotorama__thumb__arr--right .fotorama__thumb--icon {
      background-position: -25px -350px; }

.fotorama__thumb-border {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid var(--color-sky_blue);
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  backface-visibility: hidden;
  z-index: 9; }
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 250ms ease-in-out; }
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 1000;
    cursor: pointer; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    right: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    cursor: pointer;
    z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    top: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    bottom: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__video {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

.fotorama__video-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
  background-position: -80px 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 250ms ease-in-out; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
    top: 97px;
    right: 93px;
    opacity: 1; }
  .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
    transition-duration: 250ms;
    transition-property: transform, opacity, background-color; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      top: 10px;
      width: 40px;
      height: 40px;
      background-position: -100px -20px; } }

.fotorama__video-play {
  width: 160px;
  height: 160px;
  background-position: -80px -160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 80px;
    height: 80px;
    background-position: 0 -240px; }
  .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
    transition-duration: 250ms;
    transition-property: transform, opacity, background-color; }

.fotorama__wrap {
  position: relative;
  direction: ltr;
  text-size-adjust: 100%;
  z-index: 0; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama__wrap div[class*="amlabel-position-"] {
    pointer-events: none; }
  .fotorama__wrap div[class*="amlabel-position-top-left"] {
    transform-origin: top left; }
  .fotorama__wrap div[class*="amlabel-position-top-right"] {
    transform-origin: top right;
    margin-right: 100px !important; }
  .fotorama__wrap div[class*="amlabel-position-bottom-left"] {
    transform-origin: bottom left; }
  .fotorama__wrap div[class*="amlabel-position-bottom-right"] {
    transform-origin: bottom right;
    margin-right: 100px !important; }
  .fotorama__wrap--pan-y {
    touch-action: pan-y; }

@media only screen and (max-width: 639px) {
  .fotorama__wrap div[class*="amlabel-position-"] {
    transform: scale(0.7); }
  .fotorama__wrap div[class*="amlabel-position-top-right"] {
    transform-origin: top right;
    margin-right: inherit !important; }
  .fotorama__wrap div[class*="amlabel-position-bottom-right"] {
    transform-origin: bottom right;
    margin-right: inherit !important; } }

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .fotorama__wrap div[class*="amlabel-position-"] {
    transform: scale(1); }
  .fotorama__wrap div[class*="amlabel-position-top-right"] {
    transform-origin: top right;
    margin-right: inherit !important; }
  .fotorama__wrap div[class*="amlabel-position-bottom-right"] {
    transform-origin: bottom right;
    margin-right: inherit !important; } }

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .fotorama__wrap div[class*="amlabel-position-"] {
    transform: scale(0.7); } }

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .fotorama__wrap div[class*="amlabel-position-"] {
    transform: scale(0.85); } }

@media only screen and (min-width: 1440px) {
  .fotorama__wrap div[class*="amlabel-position-"] {
    transform: scale(1); } }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none;
  z-index: 1000; }
  .fotorama__zoom-in:focus,
  .fotorama__zoom-out:focus {
    box-shadow: none; }
    .fotorama__zoom-in:focus:after,
    .fotorama__zoom-out:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #00ade7;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
  .fotorama__zoom-out {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 250ms ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:active {
      background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen
  .fotorama__zoom-out {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer; }
    .fotorama__fullscreen .fotorama__zoom-in--disabled, .fotorama__fullscreen
    .fotorama__zoom-out--disabled {
      display: none; }

.fotorama__fullscreen .fotorama__zoom-out {
  top: 80px;
  background-position: 0 -80px !important; }

.fotorama__fullscreen .fotorama__zoom-in {
  background-position: 0 0 !important;
  top: 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 1.1rem; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image,
.category-description {
  color: #122948;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.4px; }

.product-image-container {
  display: block;
  max-width: 100%;
  width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #7d7d7d;
  font-size: 1.2rem;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #7d7d7d; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 1.4rem; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 1.4rem;
  border: none;
  display: inline-block;
  float: none;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
  margin-top: 10px; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 1.2rem;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 1.2rem;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 1.8rem;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 1.4rem; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 1.4rem;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #00ade7;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #00ade7;
    text-decoration: none; }
  .ui-dialog-titlebar-close:active {
    color: #00ADE7;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #00ade7; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }

.sidebar .product-items .product-item-info {
  position: relative;
  width: auto; }
  .sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0; }

.sidebar .product-items .product-item-name {
  margin-top: 0; }

.sidebar .product-items .product-item-details {
  margin: 0 0 0 85px; }

.sidebar .product-items .product-item-actions {
  display: block;
  margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #7d7d7d;
  font-size: 1.2rem;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

.header-title {
  font-size: 28px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0;
  color: #00ade7; }

.header-description {
  margin-top: 10px;
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 768px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      padding-top: 25px;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 768px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 48.5%; }
  .page-layout-1column .product.media {
    width: 48.5%; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #d1d1d1; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #d1d1d1; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 1.3rem; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.block-category-event {
  background: #f0f0f0;
  color: #333;
  padding: 10px 0 15px;
  text-align: center; }
  .block-category-event.block:last-child {
    margin-bottom: 10px; }
  .block-category-event .block-title {
    margin: 0 0 10px; }
    .block-category-event .block-title strong {
      font-size: 1.5rem;
      font-weight: 300; }
  .block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .block-category-event .ticker li {
      display: none;
      margin: 0 15px; }
    .block-category-event .ticker .value {
      font-size: 1.8rem;
      font-weight: 300; }
    .block-category-event .ticker .label {
      font-size: 1rem;
      display: block;
      text-transform: lowercase; }
  .block-category-event .dates {
    display: table;
    margin: 0 auto; }
    .block-category-event .dates .start,
    .block-category-event .dates .end {
      display: table-cell;
      vertical-align: top; }
    .block-category-event .dates .date {
      font-size: 1.8rem; }
    .block-category-event .dates .time {
      display: block; }
    .block-category-event .dates .start {
      padding-right: 40px;
      position: relative; }
      .block-category-event .dates .start:after {
        font-size: 1.8rem;
        content: '\2013';
        display: block;
        position: absolute;
        right: 15px;
        top: 0; }

@media only screen and (min-width: 768px) {
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px; }
  .block-category-event .block-title {
    margin: 0; }
    .block-category-event .block-title strong {
      font-size: 2.4rem; }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px; }
  .block-category-event .ticker .value {
    font-size: 6rem; }
  .block-category-event .ticker .label {
    font-size: 1.4rem;
    text-transform: none; }
  .block-category-event .dates .date {
    font-size: 5rem; }
  .block-category-event .dates .start {
    padding-right: 50px; }
    .block-category-event .dates .start:after {
      font-size: 5rem;
      right: 10px; } }

div.block-search {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 468px;
  padding-bottom: 2px;
  left: 48.9%;
  transform: translateX(-50%);
  z-index: 4;
  margin-bottom: 0;
  padding-left: 0; }
  div.block-search .block-title {
    display: none; }
  div.block-search input {
    width: 100%;
    height: 40px;
    margin: 0;
    padding-right: 35px;
    position: static;
    border: 1px solid #B5B5B5;
    border-radius: 3px; }
    div.block-search input::-webkit-input-placeholder {
      color: #c2c2c2; }
    div.block-search input:-moz-placeholder {
      color: #c2c2c2; }
    div.block-search input::-moz-placeholder {
      color: #c2c2c2; }
    div.block-search input:-ms-input-placeholder {
      color: #c2c2c2; }
  div.block-search .block-content {
    width: 100%;
    margin: 0; }
    div.block-search .block-content .search__icon {
      position: absolute;
      top: 12px;
      left: auto;
      right: 13px; }
      div.block-search .block-content .search__icon svg {
        width: 15px;
        height: 15px; }
  div.block-search form {
    position: relative;
    line-height: 0; }
  div.block-search .actions {
    display: none; }
  div.block-search .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  div.block-search .control {
    border-top: 0;
    margin: 0;
    padding: 0; }
  div.block-search .nested {
    display: block;
    padding-top: 5px;
    position: absolute; }
  div.block-search .action.search {
    display: inline-block;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    padding: 10px 0;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1; }
    div.block-search .action.search > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    div.block-search .action.search:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    div.block-search .action.search:hover:before {
      color: inherit; }
    div.block-search .action.search:active:before {
      color: inherit; }
    div.block-search .action.search:focus, div.block-search .action.search:active {
      background: none;
      border: none; }
    div.block-search .action.search:hover {
      background: none;
      border: none; }
    div.block-search .action.search.disabled, div.block-search .action.search[disabled],
    fieldset[disabled] div.block-search .action.search {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    div.block-search .action.search:focus:before {
      color: #333; }

.search-autocomplete {
  display: none;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  z-index: 3; }
  .search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .search-autocomplete ul li {
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      margin: 0;
      padding: 5px 40px 5px 10px;
      position: relative;
      text-align: left;
      white-space: normal; }
      .search-autocomplete ul li:not(:empty) {
        border-top: 0;
        border: 1px solid #c2c2c2;
        background: #ffffff; }
      .search-autocomplete ul li:first-child {
        border-top: none; }
      .search-autocomplete ul li:hover, .search-autocomplete ul li.selected {
        background: #e8e8e8; }
      .search-autocomplete ul li .amount {
        color: #999;
        position: absolute;
        right: 7px;
        top: 5px; }

.form.search.advanced .fields.range .field:first-child {
  position: relative; }
  .form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px; }
    .form.search.advanced .fields.range .field:first-child .control:after {
      content: ' \2013 ';
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: center;
      top: 6px;
      width: 25px; }

.form.search.advanced .fields.range .field:last-child {
  position: relative; }
  .form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px; }

.form.search.advanced .fields.range .field.with-addon .control {
  padding-right: 45px; }

.form.search.advanced .group.price .addon .addafter {
  background: none;
  border: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 0; }

.search.summary {
  margin-bottom: 10px; }

@media only screen and (max-width: 1024px) {
  html body div.block-search {
    right: 0;
    left: 380px; }
    html body div.block-search .block-content {
      width: 420px; } }

@media only screen and (max-width: 1144px) {
  body div.block-search .block-content {
    width: 300px; } }

@media only screen and (max-width: 1200px) {
  div.block-search {
    right: 0;
    left: 50%;
    padding-bottom: 3px; }
    div.block-search .block-content {
      width: 420px;
      margin: 0 0 3px 21px; } }

@media only screen and (max-width: 992px) {
  html body div.block-search {
    top: 13px;
    width: 100%;
    left: auto;
    right: 0;
    transform: none; }
    html body div.block-search .label {
      left: auto;
      right: 122px;
      top: 0;
      clip: inherit;
      height: auto;
      width: auto;
      overflow: visible; }
      html body div.block-search .label .search_mobile__icon {
        cursor: pointer;
        width: 30px;
        height: 30px; }
    html body div.block-search .block-content {
      width: 100%;
      margin: 0; }
      html body div.block-search .block-content .search__icon {
        position: absolute;
        top: 12px;
        left: auto;
        right: 13px;
        display: none; }
        html body div.block-search .block-content .search__icon svg {
          width: 15px;
          height: 15px; }
    html body div.block-search .control {
      border-top: none;
      position: absolute;
      top: 0;
      left: auto;
      z-index: 9999;
      overflow: hidden; }
    html body div.block-search input {
      left: -300%;
      margin: auto;
      position: absolute;
      border-color: #B5B5B5;
      border-radius: 3px; }
    html body div.block-search .active {
      height: 0px;
      background-color: #ffffff; }
      html body div.block-search .active .control {
        overflow: visible;
        right: 0;
        left: 0;
        margin: auto;
        width: 40%; }
      html body div.block-search .active .search__icon {
        display: block;
        top: 14px;
        right: 14px;
        z-index: 9999; }
      html body div.block-search .active input {
        left: 0;
        right: 0;
        padding: 0 14px; }
    html body div.block-search .label:before {
      color: #000000;
      font-size: 42px;
      width: 55px; } }

@media only screen and (max-width: 639px) {
  html body div.block-search .label {
    top: 2px; }
  html body div.block-search .control {
    top: 70px; }
  html body div.block-search .active {
    height: 149px; }
    html body div.block-search .active .control {
      width: 344px; } }

.checkout-cart-index .messages {
  display: block !important;
  height: auto !important; }

.cart-summary {
  margin-bottom: 25px;
  padding: 1px 15px 25px; }
  .cart-summary > .title {
    display: block;
    font-size: 1.65rem;
    font-weight: 700;
    color: #122948;
    margin-bottom: 10px; }
  .cart-summary .block {
    margin-bottom: 0; }
    .cart-summary .block .item-options {
      margin-left: 0; }
    .cart-summary .block .fieldset {
      padding: 15px; }
      .cart-summary .block .fieldset .field {
        margin: 0 0 10px; }
        .cart-summary .block .fieldset .field.note {
          font-size: 12px; }
      .cart-summary .block .fieldset .methods .field > .label {
        display: inline; }
    .cart-summary .block > .title {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: #122948; }
    .cart-summary .block.shipping {
      background-color: #f5f5f5;
      overflow: hidden;
      border-radius: var(--border-radius); }
    .cart-summary .block.discount {
      background-color: #f5f5f5;
      overflow: hidden;
      border-radius: var(--border-radius); }
  .cart-summary .actions-toolbar > .primary button, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action, .cart-summary .actions-toolbar > .primary .action-gift {
    background-color: #4daae2;
    transition: var(--default-transition);
    padding: 5px 0; }
    .cart-summary .actions-toolbar > .primary button:hover, .cart-summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .cart-summary .actions-toolbar > .primary .actions-toolbar > .action:hover, .cart-summary .actions-toolbar > .primary .action-gift:hover {
      opacity: 0.8; }

.cart-totals {
  padding-top: 10px;
  padding-bottom: 10px; }
  .cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit; }

.cart.table-wrapper .items thead + .item {
  border-top: 1px solid #b5b5b5; }

.cart.table-wrapper .items > .item {
  border-bottom: 1px solid #b5b5b5;
  position: relative; }

.cart.table-wrapper .col {
  padding-top: 20px; }
  .cart.table-wrapper .col.qty .input-text {
    margin-top: -5px; }

.cart.table-wrapper .item-actions td {
  padding-bottom: 10px;
  text-align: center;
  white-space: normal; }

.cart.table-wrapper .item .col.item {
  display: block;
  padding: 25px 0 10px 75px;
  position: relative; }

.cart.table-wrapper .actions-toolbar > .action {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px; }
  .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none; }
  .cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0; }

.cart.table-wrapper .action.help.map {
  font-weight: 400; }

.cart.table-wrapper .product-item-photo {
  display: block;
  left: 0;
  max-width: 60px;
  padding: 0;
  position: absolute;
  top: 15px;
  width: 100%; }

.cart.table-wrapper .product-item-details {
  white-space: normal; }

.cart.table-wrapper .product-item-name {
  display: inline-block;
  font-weight: 700;
  height: auto; }

.cart.table-wrapper .gift-registry-name-label:after {
  content: ":"; }

.cart.table-wrapper .item-options {
  font-size: 12px;
  margin-bottom: 10px; }

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 0 0 20px; }

.cart.table-wrapper .field.qty .control.qty {
  display: flex; }
  .cart.table-wrapper .field.qty .control.qty input {
    text-align: center; }
  .cart.table-wrapper .field.qty .control.qty .qty-step-up {
    color: #122948;
    width: 40px;
    min-width: auto;
    height: auto;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #b5b5b5;
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center; }
    .cart.table-wrapper .field.qty .control.qty .qty-step-up svg {
      fill: #122948;
      max-width: 16px;
      max-height: 16px; }
    .cart.table-wrapper .field.qty .control.qty .qty-step-up:hover {
      border-color: #9c9c9c; }
  .cart.table-wrapper .field.qty .control.qty .qty-step-down {
    color: #122948;
    width: 40px;
    min-width: auto;
    height: auto;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #b5b5b5;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center; }
    .cart.table-wrapper .field.qty .control.qty .qty-step-down svg {
      fill: #122948;
      max-width: 16px;
      max-height: 16px; }
    .cart.table-wrapper .field.qty .control.qty .qty-step-down:hover {
      border-color: #9c9c9c; }

.cart-container .checkout-methods-items {
  margin-top: 20px;
  text-align: center; }
  .cart-container .checkout-methods-items .action.primary {
    width: 100%; }
  .cart-container .checkout-methods-items .item + .item {
    margin-top: 20px; }

.cart-products-toolbar .toolbar-amount {
  margin: 25px 0 15px;
  padding: 0;
  text-align: center; }

.cart-products-toolbar .pages {
  margin: 0 0 25px;
  text-align: center; }
  .cart-products-toolbar .pages .items > .item {
    border-bottom: 0; }

.cart-products-toolbar-top {
  border-bottom: 1px solid #b5b5b5; }

.block.crosssell {
  margin-top: 70px; }

.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
  display: block; }

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.qty,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
  text-align: right; }

@media only screen and (max-width: 767px) {
  .cart.table-wrapper {
    overflow: inherit; }
    .cart.table-wrapper thead .col:not(.item) {
      display: none; }
    .cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {
      box-sizing: border-box;
      display: block;
      float: left;
      text-align: center;
      white-space: nowrap;
      width: 33%; }
      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        content: attr(data-th) ":";
        display: block;
        font-weight: 700;
        padding-bottom: 10px; }
    .cart.table-wrapper .col.msrp {
      white-space: normal; }
    .cart.table-wrapper .item .col.item {
      padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .cart-container .widget {
    float: left; }
  .cart-summary {
    float: right;
    position: relative;
    width: 23%; }
    .column.main .cart-summary .actions-toolbar > .secondary {
      float: none; }
    .cart-summary .block .fieldset .field {
      margin: 0 0 20px;
      margin: 0 0 10px; }
      .cart-summary .block .fieldset .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .cart-summary .block .fieldset .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .cart-summary .block .fieldset .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .cart-summary .block .fieldset .field.choice:before, .cart-summary .block .fieldset .field.no-label:before {
        display: none; }
  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto; }
  .cart.table-wrapper .item .col.item {
    padding: 27px 8px 10px; }
  .cart.table-wrapper .item-actions td {
    text-align: right; }
  .cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%; }
  .cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%; }
  .cart.table-wrapper .item-actions .actions-toolbar {
    display: flex;
    align-items: center; }
    .cart.table-wrapper .item-actions .actions-toolbar > * {
      margin-right: 10px; }
    .cart.table-wrapper .item-actions .actions-toolbar > *:last-child {
      margin-right: 0; }
    .cart.table-wrapper .item-actions .actions-toolbar .gift-options-cart-item {
      margin-right: 0; }
  .cart .action {
    margin-bottom: 10px;
    border-radius: var(--border-radius); }
  .cart-products-toolbar .toolbar-amount {
    line-height: 30px;
    margin: 0; }
  .cart-products-toolbar .pages {
    float: right;
    margin: 0 0 1px; }
    .cart-products-toolbar .pages .item:last-child {
      margin-right: 0; }
  .cart.table-wrapper .cart-products-toolbar + .cart thead tr th.col {
    padding-bottom: 7px;
    padding-top: 8px; }
  .cart.table-wrapper .cart + .cart-products-toolbar {
    margin-top: 25px; } }

.checkout-container {
  margin: 0 0 20px;
  max-width: 992px;
  margin: 0 auto; }

.opc-wrapper {
  margin: 0 0 20px; }
  .opc-wrapper .step-content {
    margin: 0 0 40px; }

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
  display: none; }

.checkout-index-index .logo {
  margin-left: 0; }

.checkout-onepage-success .print {
  display: none; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .step-title {
    font-size: 18px;
    border-bottom: 0;
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) {
  .opc-wrapper {
    width: 66.66667%;
    float: left;
    order: 1;
    padding-right: 30px; }
  .checkout-onepage-success .print {
    display: block;
    float: right;
    margin: 22px 0 0; } }

.opc-estimated-wrapper {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  padding: 18px 15px; }
  .opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
    float: left; }
    .opc-estimated-wrapper .estimated-block .estimated-label {
      display: block;
      margin: 0 0 5px; }
  .opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #333; }

@media only screen and (min-width: 768px) {
  .opc-estimated-wrapper {
    display: none; } }

.opc-progress-bar {
  margin: 0 0 20px;
  counter-reset: i;
  font-size: 0;
  width: 100%; }

.opc-progress-bar-item {
  margin: 0 0 10px;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 50%; }
  .opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 19px;
    content: "";
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%; }
  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px; }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0; }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-weight: 300;
    font-size: 1.8rem; }
    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
      background: #e4e4e4;
      height: 38px;
      margin-left: -19px;
      width: 38px;
      border-radius: 50%;
      content: "";
      left: 50%;
      position: absolute;
      top: 0; }
    .opc-progress-bar-item > span:after {
      background: #fff;
      height: 26px;
      margin-left: -13px;
      top: 6px;
      width: 26px;
      content: counter(i);
      counter-increment: i;
      color: #333;
      font-weight: 600;
      font-size: 1.8rem; }
  .opc-progress-bar-item._active:before {
    background: #ff5501; }
  .opc-progress-bar-item._active > span {
    color: #333; }
    .opc-progress-bar-item._active > span:before {
      background: #ff5501; }
    .opc-progress-bar-item._active > span:after {
      content: "";
      font-family: "icons-blank-theme";
      line-height: 1;
      font-size: 2.8rem; }
  .opc-progress-bar-item._complete {
    cursor: pointer; }
    .opc-progress-bar-item._complete > span {
      color: #333; }
      .opc-progress-bar-item._complete > span:after {
        content: "";
        font-family: "icons-blank-theme";
        line-height: 1;
        font-size: 2.8rem; }

@media only screen and (min-width: 768px) {
  .opc-progress-bar-item {
    width: 50%; } }

.field.choice .field-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px; }

.field.choice .field-tooltip-action {
  line-height: 21px; }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #ed8380; }

.opc-wrapper .fieldset > .field > .label {
  font-weight: 400; }

@media only screen and (min-width: 768px) {
  .opc-wrapper .fieldset > .field.required, .opc-wrapper .fieldset > .field._required {
    position: relative; }
    .opc-wrapper .fieldset > .field.required > label, .opc-wrapper .fieldset > .field._required > label {
      padding-right: 25px; }
      .opc-wrapper .fieldset > .field.required > label:after, .opc-wrapper .fieldset > .field._required > label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px; } }

@media only screen and (min-width: 768px) {
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px; }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 10px; } }

@media only screen and (min-width: 992px) {
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%; } }

.field-tooltip {
  cursor: pointer;
  display: inline-block; }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #fff;
      background: #00ADE7; }
  .field-tooltip .field-tooltip-action {
    width: 13px;
    height: 13px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #007be7;
    padding: 0 0 0;
    margin: 0 0 -4px;
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #fff; }
    .field-tooltip .field-tooltip-action:before {
      color: #fff;
      background: #007be7;
      width: 18px;
      position: absolute;
      left: -4px;
      top: -8px; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #fff;
        background: #007be7; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 575px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
  margin-top: 28px;
  margin-bottom: 20px; }
  .opc-wrapper .form-login .fieldset .note,
  .opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px; }

.opc-wrapper .shipping-address-items {
  font-size: 0; }

.opc-wrapper .shipping-address-item {
  border: 2px solid transparent;
  line-height: 30px;
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #ff5501; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background: #ff5501;
      color: #ffffff;
      content: "";
      font-family: "icons-blank-theme";
      height: 27px;
      width: 29px;
      font-size: 27px;
      line-height: 21px;
      padding-top: 2px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0; }
    .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      visibility: hidden; }

.opc-wrapper .action-show-popup {
  margin: 0 0 20px; }
  .opc-wrapper .action-show-popup > span:before {
    content: "+";
    padding-right: 5px; }

.opc-wrapper .action-select-shipping-item {
  float: right;
  margin: 20px 0 0; }

.opc-wrapper .edit-address-link {
  display: block;
  float: left;
  margin: 26px 5px 0 0; }

.checkout-shipping-method .step-title {
  margin-bottom: 0; }

.checkout-shipping-method .no-quotes-block {
  margin: 20px 0; }

.methods-shipping .actions-toolbar .action.primary {
  margin: 20px 0 0; }

.table-checkout-shipping-method thead th {
  display: none; }

.table-checkout-shipping-method tbody td {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px; }
  .table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px; }

.table-checkout-shipping-method tbody tr > * {
  width: auto; }

.table-checkout-shipping-method tbody tr:first-child td {
  border-top: none; }

.table-checkout-shipping-method tbody .row-error td {
  border-top: none;
  padding-bottom: 10px;
  padding-top: 0; }

.table-checkout-shipping-method tbody .row.amcheckout-method {
  margin: 0; }

@media only screen and (max-width: 639px) {
  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px; }
  .opc-wrapper .shipping-address-item {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%; }
    .opc-wrapper .shipping-address-item.selected-item {
      padding: 15px 35px 15px 18px;
      border-bottom-width: 2px; }
      .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px; }
  .opc-wrapper .action-select-shipping-item {
    float: none;
    margin-top: 10px;
    width: 100%; }
  .opc-wrapper .action-show-popup {
    width: 100%; }
  .opc-wrapper .edit-address-link {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px; }
    .opc-wrapper .edit-address-link > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-wrapper .edit-address-link:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-wrapper .edit-address-link:hover:before {
      color: #303030; }
    .opc-wrapper .edit-address-link:active:before {
      color: #303030; } }

@media only screen and (min-width: 768px) {
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right; }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    width: 100%; }
  .table-checkout-shipping-method {
    width: auto; } }

@media only screen and (min-width: 992px) {
  .opc-wrapper .shipping-address-item {
    width: 33.33333%; }
    .opc-wrapper .shipping-address-item:before {
      background: #ccc;
      height: calc(100% - 20px);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 1px; }
    .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item:before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
      display: none; }
  .table-checkout-shipping-method {
    min-width: 500px; } }

.checkout-shipping-method {
  position: relative; }

.shipping-policy-block.field-tooltip {
  top: 12px; }
  .shipping-policy-block.field-tooltip .field-tooltip-action {
    color: var(--color-dark_blue);
    cursor: pointer; }
    .shipping-policy-block.field-tooltip .field-tooltip-action:before {
      display: none; }
  .shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px; }

@media only screen and (max-width: 639px) {
  .shipping-policy-block.field-tooltip {
    margin-bottom: 20px;
    position: relative;
    right: auto;
    top: auto; }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
      width: 300px;
      right: auto; }
      .shipping-policy-block.field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto; } }

@media only screen and (min-width: 768px) {
  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333%;
    float: right;
    order: 2; } }

.opc-block-shipping-information {
  padding: 0 30px; }
  .opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px;
    position: relative; }
    .opc-block-shipping-information .shipping-information-title .action-edit {
      top: 2px;
      display: inline-block;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0; }
      .opc-block-shipping-information .shipping-information-title .action-edit > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .opc-block-shipping-information .shipping-information-title .action-edit:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
        color: #333; }
      .opc-block-shipping-information .shipping-information-title .action-edit:active:before {
        color: #8f8f8f; }
  .opc-block-shipping-information .shipping-information-content {
    line-height: 27px; }
    .opc-block-shipping-information .shipping-information-content .actions-toolbar {
      margin-left: 0; }
  .opc-block-shipping-information .ship-to,
  .opc-block-shipping-information .ship-via {
    margin: 0 0 20px; }

.opc-block-summary {
  background: #f5f5f5;
  padding: 22px 30px;
  margin: 0 0 20px; }
  .opc-block-summary > .title {
    display: block; }
  .opc-block-summary .mark .value {
    color: #999;
    display: block; }
  .opc-block-summary .grand.incl + .grand.excl .mark,
  .opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0; }
    .opc-block-summary .grand.incl + .grand.excl .mark strong,
    .opc-block-summary .grand.incl + .grand.excl .amount strong {
      font-weight: 400; }
  .opc-block-summary .not-calculated {
    font-style: italic; }
  .opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative; }
    .opc-block-summary .items-in-cart > .title > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-block-summary .items-in-cart > .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      line-height: 12px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 3px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-block-summary .items-in-cart > .title:after {
      position: absolute;
      right: 0;
      top: 10px; }
    .opc-block-summary .items-in-cart > .title strong {
      font-size: 1.8rem;
      font-weight: 300;
      margin: 0; }
  .opc-block-summary .items-in-cart.active > .title:after {
    content: ""; }
  .opc-block-summary .items-in-cart .product {
    position: relative; }
  .opc-block-summary .minicart-items-wrapper {
    margin: -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0; }
  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0; }
  .opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%; }
  .opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left; }
  .opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right; }
  .opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 400; }
  .opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0; }
    .opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
      font-size: 1rem; }
  .opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1; }
    .opc-block-summary .actions-toolbar .secondary {
      border-top: 1px solid #d1d1d1;
      display: block;
      float: none;
      margin: -1px 0 0;
      padding: 15px 0 0;
      text-align: left; }
      .opc-block-summary .actions-toolbar .secondary .action {
        margin: 0; }

@media only screen and (min-width: 768px) {
  .opc-summary-wrapper .modal-header .action-close {
    display: none; } }

.authentication-dropdown {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: var(--border-radius); }
  .authentication-dropdown .modal-inner-wrap {
    padding: 25px; }

.authentication-wrapper {
  float: right;
  margin-top: -60px;
  max-width: 50%;
  position: relative;
  z-index: 1; }
  ._has-auth-shown .authentication-wrapper {
    z-index: 900; }

.block-authentication .block-title {
  font-size: 1.8rem;
  border-bottom: 0;
  margin-bottom: 25px; }
  .block-authentication .block-title strong {
    font-weight: 300; }

.block-authentication .field .label {
  font-weight: 400; }

.block-authentication .actions-toolbar {
  margin-bottom: 5px; }
  .block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left; }

.block-authentication .block[class] {
  margin: 0; }
  .block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px; }
  .block-authentication .block[class] .field .control,
  .block-authentication .block[class] .field .label {
    float: none;
    width: auto; }
  .block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative; }
    .block-authentication .block[class] + .block::before {
      height: 36px;
      line-height: 34px;
      margin: -19px 0 -18px;
      min-width: 36px;
      background: #ffffff;
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      box-sizing: border-box;
      color: #c1c1c1;
      content: attr(data-label);
      display: inline-block;
      left: 50%;
      letter-spacing: normal;
      padding: 0 0.2rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media only screen and (min-width: 768px) {
  .authentication-dropdown {
    background-color: #ffffff;
    border: 1px solid #aeaeae;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.1s, visibility 0s linear 0.1s;
    visibility: hidden;
    width: 100%; }
    .authentication-dropdown._show {
      z-index: 100;
      transform: scale(1, 1);
      transition: transform linear 0.1s, visibility 0s linear 0s;
      visibility: visible; }
  .authentication-wrapper {
    width: 33.33333%;
    text-align: right; }
  .block-authentication .block-title {
    font-size: 2.6rem;
    border-bottom: 0;
    margin-bottom: 25px; }
  .block-authentication .actions-toolbar > .primary {
    display: inline;
    float: right;
    margin-right: 0; }
    .block-authentication .actions-toolbar > .primary .action {
      margin-right: 0; }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem; }
  .popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%; }
  .popup-authentication .block-authentication {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c1c1c1; }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%; }
    .popup-authentication .block[class] + .block {
      border-left: 1px solid #c1c1c1;
      border-top: 0;
      margin: 0;
      padding: 10px 0 0 40px; }
      .popup-authentication .block[class] + .block::before {
        left: 0;
        top: 50%; }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto; } }

.checkout-payment-method .step-title {
  border-bottom: 0;
  margin-bottom: 0; }

.checkout-payment-method .payment-method:first-child .payment-method-title {
  border-top: 0; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
  border-top: 1px solid #ccc; }

.checkout-payment-method .payment-method-title {
  padding: 20px 0;
  margin: 0; }
  .checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    vertical-align: middle; }
  .checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px; }

.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px; }
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .payment-group + .payment-group .step-title {
  margin: 20px 0 0; }

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
  max-width: 500px; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin: 0 0 10px; }

.checkout-payment-method .checkout-billing-address {
  margin: 0 0 20px; }
  .checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0; }
  .checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-note + .checkout-billing-address {
  margin-top: 20px; }

.checkout-payment-method .payment-method-iframe {
  background-color: transparent;
  display: none;
  width: 100%; }

.checkout-payment-method .no-payments-block {
  margin: 20px 0; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-methods {
    margin: -15px; }
  .checkout-payment-method .payment-method-title {
    padding: 15px; }
  .checkout-payment-method .payment-method-content {
    padding: 0 15px 20px; }
  .checkout-payment-method .checkout-billing-address .action-cancel {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .actions-toolbar .primary {
    float: right;
    margin: 0; }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%; }
  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
      margin-left: 25.8%; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
      margin-left: 0; }
  .checkout-billing-address .action-update {
    float: right; }
  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0; } }

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
  content: ""; }

.checkout-payment-method .payment-option._collapsible .payment-option-title {
  cursor: pointer; }

.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none; }

.checkout-payment-method .payment-option-title {
  padding: 20px 0; }
  .checkout-payment-method .payment-option-title .action-toggle {
    color: var(--color-dark_blue);
    display: inline-block;
    text-decoration: none; }
    .checkout-payment-method .payment-option-title .action-toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .checkout-payment-method .payment-option-title .action-toggle:hover:after {
      color: #333; }
    .checkout-payment-method .payment-option-title .action-toggle:active:after {
      color: #8f8f8f; }

.checkout-payment-method .payment-option-content {
  padding: 0 0 20px 22px; }

.checkout-payment-method .payment-option-inner {
  margin: 0 0 20px; }

.checkout-payment-method .credit-card-types {
  padding: 0; }
  .checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top; }
    .checkout-payment-method .credit-card-types .item._active {
      font-weight: 700; }
      .checkout-payment-method .credit-card-types .item._active img {
        filter: grayscale(0%); }
    .checkout-payment-method .credit-card-types .item._inactive {
      opacity: 0.4; }
    .checkout-payment-method .credit-card-types .item span {
      display: inline-block;
      padding-top: 6px;
      vertical-align: top; }
  .checkout-payment-method .credit-card-types img {
    filter: grayscale(100%);
    transition: all 0.6s ease; }

.checkout-payment-method .ccard .fields > .year {
  padding-left: 5px; }

.checkout-payment-method .ccard .fields .select {
  padding-left: 5px;
  padding-right: 5px; }

.checkout-payment-method .ccard .month .select {
  width: 140px; }

.checkout-payment-method .ccard .year .select {
  width: 80px; }

.checkout-payment-method .ccard .number .input-text {
  width: 225px; }

.checkout-payment-method .ccard > .field.cvv > .control {
  padding-right: 20px;
  width: auto; }

.checkout-payment-method .ccard .cvv .input-text {
  width: 55px; }

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
  width: auto !important; }

@media only screen and (max-width: 767px) {
  .checkout-payment-method .payment-option {
    margin: -15px; }
    .checkout-payment-method .payment-option .payment-option-title {
      padding: 15px; }
    .checkout-payment-method .payment-option .payment-option-content {
      padding: 0 15px 20px; } }

@media only screen and (min-width: 768px) {
  .checkout-payment-method .payment-option-title {
    padding-left: 22px; }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0; } }

.checkout-agreements-block .checkout-agreements {
  margin-bottom: 20px; }

.checkout-agreements-block .action-show {
  vertical-align: baseline; }

.checkout-agreements-items {
  padding-bottom: 30px; }
  .checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px; }
  .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0; }

.checkout-agreement-item-content {
  overflow: auto; }

.block-minicart .items-total {
  float: left;
  margin: 0 10px; }
  .block-minicart .items-total .count {
    font-weight: 700; }

.block-minicart .subtotal {
  margin: 0 10px 10px;
  text-align: right; }

.block-minicart .amount .price-wrapper:first-child .price {
  font-size: 18px;
  font-weight: 700; }

.block-minicart .subtitle {
  display: none; }
  .block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center; }

.block-minicart .text.empty {
  text-align: center; }

.block-minicart .block-content > .actions {
  margin-top: 15px;
  text-align: center; }
  .block-minicart .block-content > .actions > .primary {
    margin: 0 10px 15px; }
    .block-minicart .block-content > .actions > .primary .action.primary {
      display: block;
      margin-bottom: 15px;
      width: 100%; }
      .block-minicart .block-content > .actions > .primary .action.primary:last-child {
        margin-bottom: 0; }

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
  margin: 15px 0 0;
  text-align: center; }

.minicart-wrapper {
  display: inline-block;
  position: relative;
  float: right; }
  .minicart-wrapper:before, .minicart-wrapper:after {
    content: '';
    display: table; }
  .minicart-wrapper:after {
    clear: both; }
  .minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .minicart-wrapper .action.showcart:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 35px;
      line-height: 33px;
      color: #8f8f8f;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.showcart:hover:before {
      color: #333; }
    .minicart-wrapper .action.showcart:active:before {
      color: #8f8f8f; }
    .minicart-wrapper .action.showcart.active {
      display: inline-block;
      text-decoration: none; }
      .minicart-wrapper .action.showcart.active:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #8f8f8f;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .minicart-wrapper .action.showcart.active:hover:before {
        color: #333; }
      .minicart-wrapper .action.showcart.active:active:before {
        color: #8f8f8f; }
  .minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .minicart-wrapper .block-minicart li {
      margin: 0; }
      .minicart-wrapper .block-minicart li:hover {
        cursor: pointer; }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .minicart-wrapper .block-minicart:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .minicart-wrapper .block-minicart:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .minicart-wrapper .block-minicart:before {
      left: 10px;
      top: -12px; }
    .minicart-wrapper .block-minicart:after {
      left: 9px;
      top: -14px; }
  .minicart-wrapper.active {
    overflow: visible; }
    .minicart-wrapper.active .block-minicart {
      display: block; }
  .minicart-wrapper .block-minicart {
    padding: 25px 20px;
    right: 0;
    width: 320px;
    z-index: 101; }
    .minicart-wrapper .block-minicart .block-title {
      display: none; }
    .minicart-wrapper .block-minicart:after {
      left: auto;
      right: 25px; }
    .minicart-wrapper .block-minicart:before {
      left: auto;
      right: 26px; }
  .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
    text-align: right; }
    .minicart-wrapper .product .actions > .primary,
    .minicart-wrapper .product .actions > .secondary {
      display: inline; }
  .minicart-wrapper .action.close {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px; }
    .minicart-wrapper .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-wrapper .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 32px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.close:hover:before {
      color: inherit; }
    .minicart-wrapper .action.close:active:before {
      color: inherit; }
    .minicart-wrapper .action.close:focus, .minicart-wrapper .action.close:active {
      background: none;
      border: none; }
    .minicart-wrapper .action.close:hover {
      background: none;
      border: none; }
    .minicart-wrapper .action.close.disabled, .minicart-wrapper .action.close[disabled],
    fieldset[disabled] .minicart-wrapper .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .minicart-wrapper .action.showcart {
    white-space: nowrap; }
    .minicart-wrapper .action.showcart .counter.qty {
      background: #ff5501;
      color: #fff;
      height: 24px;
      line-height: 24px;
      border-radius: 2px;
      display: inline-block;
      margin: 3px 0 0;
      min-width: 18px;
      overflow: hidden;
      padding: 0 3px;
      text-align: center;
      white-space: normal; }
      .minicart-wrapper .action.showcart .counter.qty.empty {
        display: none; }
      .minicart-wrapper .action.showcart .counter.qty .loader > img {
        max-width: 24px; }
  .minicart-wrapper .minicart-widgets {
    margin-top: 15px; }

.minicart-items-wrapper {
  border: 1px solid #ccc;
  margin: 0 -20px;
  border-left: 0;
  border-right: 0;
  overflow-x: auto;
  padding: 15px; }

.minicart-items {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .minicart-items .product-item {
    padding: 20px 0; }
    .minicart-items .product-item:not(:first-child) {
      border-top: 1px solid #ccc; }
    .minicart-items .product-item:first-child {
      padding-top: 0; }
  .minicart-items .product-item-pricing .label {
    display: inline-block;
    width: 4.5rem; }
  .minicart-items .price-minicart {
    margin-bottom: 5px; }
  .minicart-items .product > .product-item-photo,
  .minicart-items .product > .product-image-container {
    float: left; }
  .minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative; }
    .minicart-items .product .toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product .toggle:after {
      position: static;
      right: 20px;
      top: 0; }
  .minicart-items .product.active > .toggle:after {
    content: ""; }
  .minicart-items .product-item-name {
    font-weight: 400;
    margin: 0 0 10px; }
    .minicart-items .product-item-name a {
      color: var(--color-dark_blue); }
  .minicart-items .product-item-details {
    padding-left: 88px; }
    .minicart-items .product-item-details .price {
      font-weight: 700; }
    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
      margin: 5px 0 0; }
    .minicart-items .product-item-details .weee[data-label] {
      font-size: 1.1rem; }
    .minicart-items .product-item-details .details-qty {
      margin-top: 10px; }
  .minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .product.options .tooltip.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .product.options .tooltip.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: -3px 0 0 7px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product.options .tooltip.toggle .details {
      display: none; }
  .minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .minicart-items .update-cart-item {
    font-size: 1.1rem;
    vertical-align: top; }
  .minicart-items .subtitle {
    display: none; }
  .minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .action.edit > span, .minicart-items .action.delete > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .action.edit:before, .minicart-items .action.delete:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
      color: #303030; }
    .minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
      color: #303030; }
  .minicart-items .action.delete:before {
    content: ""; }

@media only screen and (max-width: 575px) {
  .minicart-wrapper .block-minicart {
    width: 290px; } }

@media only screen and (max-width: 639px) {
  .minicart-wrapper {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .minicart-wrapper {
    margin-left: 13px; }
    .minicart-wrapper .block-minicart {
      right: -15px;
      width: 390px; } }

.login-container .block-new-customer .actions-toolbar {
  margin-top: 25px; }

.login-container .block .block-title {
  font-size: 1.8rem; }

.login-container .fieldset:after {
  margin: 10px 0 0;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-size: 1.2rem; }

.login-container .fieldset:after {
  margin-top: 35px; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 20px; }
  .block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0; }

.form-address-edit #region_id {
  display: none; }

.form-edit-account .fieldset.password {
  display: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  line-height: 26px; }

.fieldset .fullname.field > .label + .control {
  width: 100%; }

.account.page-layout-2columns-left .block.newsletter,
.account.page-layout-2columns-left .remove__notify_block {
  display: none; }

.account.page-layout-2columns-left .column.main .actions-toolbar .primary {
  max-width: 178px; }

.account.page-layout-2columns-left .column.main .actions-toolbar .secondary {
  display: block !important;
  color: #00ade7; }
  .account.page-layout-2columns-left .column.main .actions-toolbar .secondary a {
    margin-top: 15px;
    text-decoration: underline;
    font-size: 14px; }

.account.page-layout-2columns-left .my-address-edit .fieldset > .legend {
  margin: 0 0 41px; }

.account.page-layout-2columns-left .my-address-edit .field.additional {
  margin-top: 16px; }

.account.page-layout-2columns-left .my-address-edit .field.street {
  margin-bottom: -12px; }

.account.page-layout-2columns-left .my-address-edit .label span {
  font-weight: normal; }

.account.page-layout-2columns-left .page-title-wrapper {
  display: none; }

.account.page-layout-2columns-left .block-dashboard-addresses .block-title a.action {
  display: none; }

.account.page-layout-2columns-left .copyright span {
  padding: 4px 0px 0 0; }

.account.page-layout-2columns-left .page-main {
  align-items: center; }

.account.page-layout-2columns-left .columns {
  justify-content: start;
  max-width: var(--container-width);
  width: 100%;
  margin-bottom: 71px; }

.account.page-layout-2columns-left .sidebar-main {
  padding-right: 0;
  order: inherit; }

.account.page-layout-2columns-left .column.main .block .block-title-top,
.account.page-layout-2columns-left .column.main .block-title-top {
  margin: -13px 0 30px 0; }
  .account.page-layout-2columns-left .column.main .block .block-title-top > strong,
  .account.page-layout-2columns-left .column.main .block-title-top > strong {
    color: #122948;
    font-size: 40px;
    line-height: 55px;
    font-weight: bold; }

.account.page-layout-2columns-left .column.main .control div.mage-error {
  color: #c91717;
  font-size: 14px;
  font-weight: bold; }

.account.page-layout-2columns-left .column.main .control input.mage-error {
  border-color: #b82e25 !important; }

.account.page-layout-2columns-left .column.main .my-mage-error label span {
  color: #c91717 !important; }

.account.page-layout-2columns-left .column.main .my-mage-error label::after {
  color: #c91717; }

.account.page-layout-2columns-left .column.main .block h2 {
  margin-top: 0; }

.account.page-layout-2columns-left .column.main .block.block-dashboard-info {
  margin-bottom: 60px; }

.account.page-layout-2columns-left .column.main .block .box-content p,
.account.page-layout-2columns-left .column.main .block .box-title > span {
  color: #464646;
  font-size: 14px; }

.account.page-layout-2columns-left .column.main .block .box-content .box {
  margin-bottom: 6px; }

.account.page-layout-2columns-left .column.main .block .box-content address {
  font-size: 14px; }

.account.page-layout-2columns-left .column.main .block .block-title {
  margin-bottom: 30px; }
  .account.page-layout-2columns-left .column.main .block .block-title > strong {
    color: #464646;
    font-size: 20px;
    line-height: 27px;
    margin-top: 0;
    margin-bottom: 0; }
  .account.page-layout-2columns-left .column.main .block .block-title > span {
    font-weight: bold; }

.account.page-layout-2columns-left .column.main .block .box-title {
  margin-bottom: -6px;
  margin-top: 0px; }

.account.page-layout-2columns-left .column.main .block .box-actions a span,
.account.page-layout-2columns-left .column.main .block .box-actions a + a {
  color: #00ade7;
  font-size: 14px;
  text-decoration: underline; }

.account.page-layout-2columns-left .column.main .block .toolbar {
  text-align: center; }
  .account.page-layout-2columns-left .column.main .block .toolbar .limiter-options {
    width: auto; }

.account.page-layout-2columns-left .sidebar-additional {
  margin-top: 40px; }

.account.page-layout-2columns-left .table-wrapper:last-child {
  margin-bottom: 0; }

.account.page-layout-2columns-left .table-wrapper .action {
  margin-right: 15px; }
  .account.page-layout-2columns-left .table-wrapper .action:last-child {
    margin-right: 0; }

.account-nav .content {
  background-color: #122948;
  padding: 20px 0 18px 0; }

.account-nav .item {
  margin: 0 0 20px 0; }
  .account-nav .item:first-child {
    margin-top: 0; }
  .account-nav .item a,
  .account-nav .item > strong {
    border-left: 5px solid transparent;
    display: block;
    padding: 0 18px 0 15px; }
  .account-nav .item a {
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    text-transform: lowercase; }
    .account-nav .item a::first-letter {
      text-transform: uppercase; }
  .account-nav .item.current strong {
    border-left: 6px solid #00ade7;
    color: #ffffff;
    text-transform: lowercase;
    font-weight: bold; }
    .account-nav .item.current strong::first-letter {
      text-transform: uppercase; }
  .account-nav .item .delimiter {
    border-top: 1px solid #ffffff;
    display: block;
    margin: 10px 2rem; }

.column.main .block:last-child {
  margin-bottom: 0; }

.block .title {
  margin-bottom: 10px; }
  .block .title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .column.main .block .title strong {
      font-size: 18px; }

.block p:last-child {
  margin: 0; }

.block .box-actions {
  margin-top: 5px; }

.field.password .control {
  position: relative;
  display: flex;
  flex-direction: column; }
  .field.password .control .mage-error {
    order: 2; }
  .field.password .control .input-text.register-password {
    order: 0;
    padding: 0 50px 0 20px !important; }
  .field.password .control .choice-pass {
    margin-bottom: 0; }
    .field.password .control .choice-pass input[type="checkbox"] {
      display: none; }
    .field.password .control .choice-pass input[type="checkbox"] + label:before {
      content: "";
      display: block;
      height: 20px;
      width: 30px;
      position: absolute;
      cursor: pointer;
      left: auto;
      right: 0;
      top: 16px;
      margin-right: 12px;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDMwIDIwIj4KICA8cGF0aCBpZD0i0JrQvtC90YLRg9GAXzc0IiBkYXRhLW5hbWU9ItCa0L7QvdGC0YPRgCA3NCIgZD0iTTE1LDc1LjY2N0EzLjMzMywzLjMzMywwLDEsMCwxNSw2OWMtLjA1MiwwLS4xLjAxMi0uMTQ4LjAxNWEyLjQ3NCwyLjQ3NCwwLDAsMS0zLjE3LDMuMTdjMCwuMDUyLS4wMTUuMS0uMDE1LjE0OEEzLjMzMywzLjMzMywwLDAsMCwxNSw3NS42NjdaTTI5LjgxOSw3My4yNEExNi43LDE2LjcsMCwwLDAsMTUsNjQsMTYuNzA4LDE2LjcwOCwwLDAsMCwuMTgyLDczLjI0YTEuNjg1LDEuNjg1LDAsMCwwLDAsMS41MkExNi43LDE2LjcsMCwwLDAsMTUsODRhMTYuNzA4LDE2LjcwOCwwLDAsMCwxNC44MTktOS4yNEExLjY4NSwxLjY4NSwwLDAsMCwyOS44MTksNzMuMjRaTTE1LDY1LjY2N2E2LjY2Nyw2LjY2NywwLDEsMS02LjY2Nyw2LjY2N0E2LjY2Nyw2LjY2NywwLDAsMSwxNSw2NS42NjdabTAsMTYuNjY3QTE1LjAxMywxNS4wMTMsMCwwLDEsMS42NjcsNzRhMTUuMzUzLDE1LjM1MywwLDAsMSw2Ljc1OS02LjczNiw4LjMyLDguMzIsMCwxLDAsMTQuOTA3LDUuMDY5LDguMjU3LDguMjU3LDAsMCwwLTEuNzU5LTUuMDY5QTE1LjM1MywxNS4zNTMsMCwwLDEsMjguMzM0LDc0LDE1LjAxMywxNS4wMTMsMCwwLDEsMTUsODIuMzMzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC02NCkiIGZpbGw9IiNiNWI1YjUiLz4KPC9zdmc+Cg==") no-repeat center; }

.password-strength-meter {
  background-color: #464646;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  position: relative;
  font-size: 14px;
  color: #ffffff; }
  .password-strength-meter:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-none .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%; }
  .password-weak .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strong .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-very-strong .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.control.captcha-image {
  margin-top: 10px; }
  .control.captcha-image .captcha-img {
    vertical-align: middle; }

.customer-account-create #maincontent .page-title-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px; }
  .customer-account-create #maincontent .page-title-wrapper .page-title {
    font-weight: bold;
    color: #122948;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: 0;
    max-width: 680px;
    min-width: 600px;
    width: 55%;
    margin: -3px 0 17px 0; }

.customer-account-create #maincontent form {
  margin: 0 auto;
  max-width: 680px; }

.account #maincontent .subtitle,
.customer-account-create #maincontent .subtitle {
  font-weight: bold;
  color: #464646;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 54px; }

.account #maincontent form .secondary,
.customer-account-create #maincontent form .secondary {
  display: none; }

.account #maincontent form .additional_info,
.customer-account-create #maincontent form .additional_info {
  margin: 0 0 30px; }

.account #maincontent form .control,
.customer-account-create #maincontent form .control {
  width: 100%; }

.account #maincontent form .fieldset.create,
.customer-account-create #maincontent form .fieldset.create {
  margin: 0; }

.account #maincontent form .confirmation,
.customer-account-create #maincontent form .confirmation {
  margin-bottom: 0; }

.account #maincontent form .field:not(.choice),
.customer-account-create #maincontent form .field:not(.choice) {
  display: flex;
  flex-direction: column; }
  .account #maincontent form .field:not(.choice) input,
  .customer-account-create #maincontent form .field:not(.choice) input {
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    border-color: #707070;
    height: 40px; }
  .account #maincontent form .field:not(.choice) .label,
  .customer-account-create #maincontent form .field:not(.choice) .label {
    margin-bottom: 5px;
    padding: 0px 15px 0 0;
    white-space: nowrap; }
    .account #maincontent form .field:not(.choice) .label span,
    .customer-account-create #maincontent form .field:not(.choice) .label span {
      font-size: 14px;
      line-height: 18px;
      color: #122948; }

.account #maincontent form .choice:not(.choice-pass),
.customer-account-create #maincontent form .choice:not(.choice-pass) {
  display: none; }

.account #maincontent form .actions-toolbar,
.customer-account-create #maincontent form .actions-toolbar {
  margin-left: 0; }
  .account #maincontent form .actions-toolbar .primary,
  .customer-account-create #maincontent form .actions-toolbar .primary {
    width: 100%; }
    .account #maincontent form .actions-toolbar .primary button, .account #maincontent form .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .account #maincontent form .actions-toolbar .primary .actions-toolbar > .action, .account #maincontent form .actions-toolbar .primary .action-gift,
    .customer-account-create #maincontent form .actions-toolbar .primary button,
    .customer-account-create #maincontent form .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action,
    .cart.table-wrapper .customer-account-create #maincontent form .actions-toolbar .primary .actions-toolbar > .action,
    .customer-account-create #maincontent form .actions-toolbar .primary .action-gift {
      border: unset;
      border-radius: unset;
      font-size: 15px;
      font-weight: normal;
      line-height: 20px;
      padding: 12.5px 15px;
      color: #ffffff;
      background-color: #e6a532; }

@media only screen and (max-width: 639px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; }
  .customer-account-create #maincontent .page-title-wrapper .page-title {
    font-size: 30px;
    line-height: 27px;
    min-width: fit-content; }
  .customer-account-create #maincontent .subtitle {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .customer-account-create #maincontent {
    margin: 0 10px; }
  .block .box-actions {
    margin-bottom: 2rem; }
  .account.page-layout-2columns-left .columns .column.main .actions-toolbar .primary {
    max-width: inherit; }
  .login-container .fieldset:after {
    text-align: center; }
  .account .column.main {
    padding: 0 2rem; }
  .account .page.messages {
    margin-bottom: 40px; }
  .control.captcha-image .captcha-img {
    margin-bottom: 10px;
    display: block; }
  .customer-account-index .page-title-wrapper {
    position: relative; }
  .customer-address-form .column.main {
    padding: 0 2rem; } }

@media only screen and (max-width: 992px) {
  .account.page-layout-2columns-left .column.main {
    margin-right: 2rem; } }

@media only screen and (min-width: 768px) {
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px; }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left; }
  .login-container .fieldset > .field > .control {
    width: 100%; }
  .fieldset .fullname .field .label {
    padding: 6px 15px 0 0;
    text-align: right;
    width: 25.8%;
    box-sizing: border-box;
    float: left; }
  .fieldset .fullname .field .control {
    width: 100%;
    float: left; }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%; }
  .account.page-layout-2columns-left .columns {
    margin-top: 50px; }
  .account .column.main .block:not(.widget) .block-content .box {
    width: 47.8%; }
  .block-addresses-list .items.addresses {
    font-size: 0; }
    .block-addresses-list .items.addresses > .item {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 20px;
      vertical-align: top;
      width: 48.8%; }
      .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
        margin-bottom: 0; }
      .block-addresses-list .items.addresses > .item:nth-child(even) {
        margin-left: 2.4%; }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0; } }

.downloadable.samples {
  margin-bottom: 20px; }
  .downloadable.samples dd {
    margin: 0 0 10px; }

.table-downloadable-products .product-name {
  margin-right: 15px; }

.field.downloads .price-container {
  display: inline;
  white-space: nowrap; }
  .field.downloads .price-container:before {
    content: ' + ';
    display: inline; }

.field.downloads .price-excluding-tax {
  display: inline; }
  .field.downloads .price-excluding-tax:before {
    content: " (" attr(data-label) ": "; }
  .field.downloads .price-excluding-tax:after {
    content: ')'; }

.field.downloads .price-including-tax {
  font-size: 1.4rem;
  display: inline;
  font-weight: 600; }

.field.downloads .sample.link {
  float: right; }

.page-product-downloadable .product-add-form {
  clear: both;
  margin-bottom: 25px; }

.page-product-downloadable .product-options-bottom .field.qty {
  display: none !important; }

@media only screen and (min-width: 768px) {
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%; }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%; }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
      padding-top: 0; } }

.cart-summary .block.giftcard .action.check {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem;
  margin-top: 20px; }

.page-product-giftcard .giftcard-amount {
  margin-bottom: 0;
  text-align: left; }
  .page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px; }

.page-product-giftcard .product-options-wrapper .field:first-of-type {
  margin-top: 20px; }

.giftcard-account .please-wait {
  display: none; }

.giftcard-account .form-giftcard-redeem .giftcard.info {
  margin-bottom: 30px; }

.opc-wrapper .form-giftcard-account {
  max-width: 500px; }
  .opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px; }

.opc-wrapper .giftcard-account-info {
  margin: 0 0 20px; }
  .opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px; }

.gift-message .field {
  margin-bottom: 20px; }
  .gift-message .field .label {
    color: #666;
    font-weight: 400; }

.gift-options .actions-toolbar .action-cancel {
  display: none; }

.gift-options-title {
  margin: 0 0 20px; }

.gift-options-content .fieldset {
  margin: 0 0 20px; }

.gift-summary .actions-toolbar > .secondary {
  float: none; }
  .gift-summary .actions-toolbar > .secondary .action {
    margin: 10px 20px 0 0; }

.action-gift {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  text-decoration: none; }
  .action-gift:hover, .action-gift:active, .action-gift:focus {
    text-decoration: none; }
  .action-gift:last-child {
    margin-right: 0; }
  .action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action-gift:hover:after {
    color: inherit; }
  .action-gift:active:after {
    color: inherit; }
  .action-gift.active:after, .action-gift._active:after {
    content: ""; }

.cart.table-wrapper .gift-content {
  box-sizing: border-box;
  clear: left;
  display: none;
  float: left;
  margin: 10px 0 20px;
  text-align: left;
  width: 100%; }
  .cart.table-wrapper .gift-content._active {
    display: block; }

.gift-item-block {
  margin: 0; }
  .gift-item-block._active .title:after {
    content: ""; }
  .gift-item-block .title {
    font-weight: 700;
    border-radius: 3px;
    background-image: none;
    background: #f2f2f2;
    border: none;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    margin: 20px 0 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none; }
    .gift-item-block .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-item-block .title:hover:after {
      color: inherit; }
    .gift-item-block .title:active:after {
      color: inherit; }
    .gift-item-block .title:focus, .gift-item-block .title:active {
      background: #e2e2e2;
      border: none;
      color: #333; }
    .gift-item-block .title:hover {
      background: #e2e2e2;
      border: none;
      color: #555; }
    .gift-item-block .title.disabled, .gift-item-block .title[disabled],
    fieldset[disabled] .gift-item-block .title {
      opacity: 0.5;
      cursor: default;
      pointer-events: none; }
    .gift-item-block .title:active {
      box-shadow: none; }
  .gift-item-block .content {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px 10px 20px;
    position: relative; }

.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
  font-weight: 400;
  margin: 10px 0; }

.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
  content: ''; }

.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
  clear: left; }

.order-details-items .order-items .action.show {
  display: inline-block;
  text-decoration: none;
  padding-right: 20px;
  position: relative; }
  .order-details-items .order-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .order-details-items .order-items .action.show:after {
    position: absolute;
    right: 0;
    top: -4px; }
  .order-details-items .order-items .action.show.expanded:after {
    content: ""; }

.block-order-details-gift-message {
  border-top: 1px solid #d1d1d1;
  padding-top: 10px; }

@media only screen and (max-width: 767px) {
  .gift-options .actions-toolbar .action-update {
    font-size: 2rem;
    padding: 15px;
    width: 100%; }
  .gift-item-block {
    border-top: 1px solid #c1c1c1;
    border-bottom: 0; }
  .cart.table-wrapper .gift-content {
    margin-right: -10px; } }

@media only screen and (max-width: 639px) {
  .cart.table-wrapper .gift-content {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 20px; } }

@media only screen and (min-width: 768px) {
  .gift-message .field {
    margin-bottom: 20px; }
  .gift-options {
    position: relative;
    z-index: 1; }
    .gift-options .actions-toolbar {
      clear: both;
      float: right;
      position: static; }
      .gift-options .actions-toolbar .secondary {
        float: right; }
        .gift-options .actions-toolbar .secondary .action {
          float: right;
          margin-left: 20px; }
        .gift-options .actions-toolbar .secondary .action-cancel {
          display: block;
          float: left;
          margin-top: 6px; }
      .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0; }
  .gift-options-title {
    font-weight: 300;
    font-size: 1.8rem; }
  .cart.table-wrapper .action-gift {
    float: left; }
  .order-options .gift-wrapping,
  .table-order-review .gift-wrapping {
    max-width: 50%; } }

.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
  margin-bottom: 40px; }

.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
  width: 100%; }

.table-giftregistry-items .product-image {
  float: left;
  margin-right: 10px; }

.cart-summary .form.giftregistry .fieldset {
  margin: 25px 0 5px; }
  .cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px; }
    .cart-summary .form.giftregistry .fieldset .field .control {
      float: none;
      width: auto; }
  .cart-summary .form.giftregistry .fieldset .action.primary {
    margin-bottom: 0; }

.form-giftregistry-search {
  margin-bottom: 60px; }
  .form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0; }

.block-giftregistry-results .toolbar-giftregistry-results {
  text-align: center; }

.block-giftregistry .actions-toolbar {
  margin: 17px 0 0; }

.block-giftregistry-shared .item-options .item-value {
  margin: 0 0 25px; }

.block-giftregistry-shared-items .item {
  border-bottom: 1px solid #d1d1d1; }

.block-giftregistry-shared-items .col {
  padding: 20px 8px 10px; }
  .block-giftregistry-shared-items .col .price-box {
    display: inline-block; }
  .block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap; }

.block-giftregistry-shared-items .product .item-options {
  margin-bottom: 0; }

.block-giftregistry-shared-items .product-item-photo {
  display: block;
  max-width: 65px;
  padding: 10px 0; }

.block-giftregistry-shared-items .product-item-name {
  display: block;
  margin: 0 0 5px; }

@media only screen and (max-width: 639px) {
  .table-giftregistry-items .field.choice,
  .table-giftregistry-items .field.qty {
    display: inline-block; }
  .form-giftregistry-search .fieldset {
    margin-bottom: 15px; } }

@media only screen and (min-width: 768px) {
  .form-giftregistry-search .fieldset {
    margin-bottom: 29px; }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field {
      margin: 0 0 20px;
      box-sizing: border-box; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field > .label {
        margin: 0; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        content: '';
        display: table; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        clear: both; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field.choice:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field.no-label:before {
        padding: 6px 15px 0 0;
        width: 25.8%;
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field .description {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: start;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .control {
        width: 100%;
        float: left; }
  .block-giftregistry-shared-items .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding: 0 20px 0 0;
    vertical-align: top;
    width: 1%; }
  .block-giftregistry-shared-items .product-item-details {
    display: table-cell;
    vertical-align: top;
    width: 99%;
    word-break: normal; }
  .block-giftregistry-shared-items .col.product {
    width: 48%; }
  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center; }
  .block-giftregistry-shared-items .col.price {
    padding-top: 17px; }
  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px; } }

.gift-wrapping .label {
  margin: 0 0 5px;
  display: inline-block; }

.gift-wrapping .field {
  margin-bottom: 10px; }

.gift-wrapping-info {
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0; }

.gift-wrapping-list {
  list-style: none;
  margin: 20px 0 10px;
  padding: 0; }
  .gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none; }
    .gift-wrapping-list .no-image > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .gift-wrapping-list .no-image:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 38px;
      line-height: 4rem;
      color: #494949;
      content: "";
      font-family: "icons-blank-theme";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-wrapping-list .no-image:after {
      display: block; }

.gift-wrapping-preview,
.gift-wrapping-item > span {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.gift-wrapping-item {
  float: left;
  margin: 0 10px 10px 0; }
  .gift-wrapping-item:last-child {
    margin-right: 0; }
  .gift-wrapping-item._active span:before {
    border: 3px solid #333;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .gift-wrapping-item > span {
    height: 4rem;
    width: 4rem; }

.gift-wrapping-title {
  margin: 10px 0; }
  .gift-wrapping-title .action-remove {
    margin-left: 5px; }

.gift-options-content .fieldset > .field {
  margin: 0 0 5px; }
  .gift-options-content .fieldset > .field:before {
    display: none; }

.gift-wrapping-summary .gift-wrapping-title {
  border: 0;
  display: inline-block;
  padding: 0; }

.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping,
.table-order-review .gift-wrapping {
  margin: 20px 0; }
  .opc-wrapper .data.table .gift-wrapping .title,
  .table-order-items .gift-wrapping .title,
  .table-order-review .gift-wrapping .title {
    float: none; }
  .opc-wrapper .data.table .gift-wrapping .content,
  .table-order-items .gift-wrapping .content,
  .table-order-review .gift-wrapping .content {
    margin: 0; }
    .opc-wrapper .data.table .gift-wrapping .content > img,
    .table-order-items .gift-wrapping .content > img,
    .table-order-review .gift-wrapping .content > img {
      float: left;
      margin: 0 10px 0 0;
      max-height: 50px;
      max-width: 50px; }

.gift-summary .price-box,
.gift-options .price-box {
  margin-left: 22px; }

.gift-summary .regular-price:before,
.gift-options .regular-price:before {
  content: attr(data-label) ": "; }

.gift-summary .regular-price .price,
.gift-options .regular-price .price {
  font-weight: 700; }

.order-options .item {
  padding-bottom: 20px; }

.order-options .product {
  margin-bottom: 10px; }

.order-options .product-image-container {
  float: left;
  padding-right: 10px; }

.order-options .gift-wrapping {
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .gift-wrapping {
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  .gift-wrapping-list {
    width: 100%; }
  .gift-wrapping-preview img {
    width: 100%; }
  .item-actions .actions-toolbar .gift-options .gift-wrapping,
  .item-actions .actions-toolbar .gift-options .gift-message {
    display: block; } }

@media only screen and (min-width: 768px) {
  .gift-options-cart-item .gift-wrapping,
  .cart-gift-item .gift-wrapping {
    box-sizing: border-box;
    float: left;
    padding-right: 20px;
    width: 50%; }
    .gift-options-cart-item .gift-wrapping + .gift-message,
    .cart-gift-item .gift-wrapping + .gift-message {
      border-left: 1px solid #c1c1c1;
      box-sizing: border-box;
      float: left;
      padding-left: 4.5rem;
      width: 50%; } }

.table.grouped > thead > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > th,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > th,
.table.grouped > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table.grouped > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.table.grouped > thead > tr > th {
  border-bottom: 0; }

.table.grouped .product-item-name {
  font-weight: 400; }

.table.grouped .price-box .price {
  font-size: 14px; }

.table.grouped .stock.unavailable {
  font-weight: normal;
  margin: 0;
  text-transform: none;
  white-space: nowrap; }

.table.grouped .row-tier-price td {
  border-top: 0; }

.table.grouped .row-tier-price .prices-tier {
  margin: 0 -10px 10px; }

.form-add-invitations .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .form-add-invitations .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .form-add-invitations .action.add:hover:before {
    color: inherit; }
  .form-add-invitations .action.add:active:before {
    color: inherit; }

@media only screen and (max-width: 767px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 20px; } }

@media only screen and (min-width: 768px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 29px; }
  .table-invitations .col {
    width: 50%; } }

.block.filter .wrap {
  margin-bottom: 35px; }
  .block.filter .wrap .item {
    margin-bottom: 10px;
    padding-left: 18px;
    line-height: 16px; }
    .block.filter .wrap .item a {
      color: #333333; }
    .block.filter .wrap .item label:hover {
      cursor: pointer; }
    .block.filter .wrap .item input[type="checkbox"],
    .block.filter .wrap .item .count {
      display: none; }

.block.filter .filter-title strong {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #00ade7; }

.block.filter .block-title-description {
  padding-top: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #122948; }

.block-subtitle {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #00ade7; }

.filter-current .item {
  display: flex;
  justify-content: space-between; }

.filter-current .filter__label:after {
  content: ':'; }

.filter-current .action.remove {
  display: flex;
  align-items: center; }
  .filter-current .action.remove .icon {
    width: 12px;
    height: 12px;
    fill: #a3a3a3; }
    .filter-current .action.remove .icon:hover {
      fill: #4daae2; }

.filter-current + .filter-actions {
  margin-bottom: 30px; }

.filter-content input[type="checkbox"] {
  width: 14px;
  height: 14px;
  border-color: #b5b5b5;
  margin-right: 8px; }

.filter-content label {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #122948; }

.filter-content .action.remove,
.filter-content .action.clear span {
  color: #00ade7; }

.block-title strong {
  display: inline-block;
  margin-bottom: 18px; }

.block-title .filter-options-title {
  position: relative;
  line-height: 15px;
  margin-bottom: 8px; }
  .block-title .filter-options-title:hover {
    cursor: pointer; }

.block-title ul {
  padding-left: 18px; }
  .block-title ul li {
    list-style: none;
    line-height: 16px; }

.filter-options {
  margin: 0; }
  .filter-options .filter-options-item {
    padding-top: 20px;
    border-top: 1px solid var(--color-medium_gray); }
    .filter-options .filter-options-item.active .filter-options-title:after {
      transform: rotate(-180deg);
      transition: 250ms; }
  .filter-options .filter-options-line {
    width: 100%;
    height: 1px;
    background-color: #b5b5b5;
    margin-bottom: 22px; }
  .filter-options .filter-options-title {
    position: relative;
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin: 0 0 20px;
    word-break: break-all;
    font-size: 14px;
    line-height: 15px;
    color: #122948; }
    .filter-options .filter-options-title:after {
      position: absolute;
      top: 3px;
      right: 0;
      content: '';
      display: inline-block;
      width: 17px;
      height: 10px;
      background: url("../images/icons/arrow-option.svg") no-repeat;
      transition: 250ms;
      transform: rotate(-90deg); }
    .filter-options .filter-options-title:hover {
      cursor: pointer; }
  .filter-options .filter-options-content {
    margin: 0 0 25px; }
  .filter-options .count:before {
    content: '('; }
  .filter-options .count:after {
    content: ')'; }

.filtered .items {
  margin: 15px 0; }

.filtered .item {
  margin-bottom: 6px;
  padding-left: 22px;
  position: relative; }
  .filtered .item .label {
    font-weight: 700; }
  .filtered .item .action.remove {
    left: -6px;
    position: absolute;
    top: 0; }

.filtered + .actions {
  margin-bottom: 35px; }

@media screen and (max-width: 1279px) {
  .block-title .filter-options-line {
    width: 100%;
    height: 1px;
    margin-bottom: 22px; }
  .block-title .filter-options-title {
    position: relative;
    line-height: 15px;
    margin-bottom: 20px; }
    .block-title .filter-options-title:after {
      position: absolute;
      top: 3px;
      right: 0;
      content: '';
      display: inline-block;
      width: 17px;
      height: 10px;
      background: url("../images/icons/arrow-option.svg") no-repeat;
      transition: transform 250ms; }
    .block-title .filter-options-title.hide-filter:after {
      transform: rotate(-180deg);
      transition: transform 250ms; } }

@media screen and (min-width: 1280px) {
  .block-title .filter-options-title {
    margin-bottom: 9px; } }

.map-popup-wrapper.popup .action.close {
  top: 10px; }

.map-popup {
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  left: 0;
  margin-top: 10px;
  position: absolute;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0;
  box-sizing: border-box; }
  .map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .map-popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .map-popup .popup-content {
    margin: 10px 0 0; }
  .map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .map-popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .map-popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .map-popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:active:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:focus, .map-popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close.disabled, .map-popup .popup-actions .action.close[disabled],
    fieldset[disabled] .map-popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .map-popup.active {
    opacity: 1; }
  .map-popup .map-info-price {
    margin-bottom: 15px; }
    .map-popup .map-info-price .price-box {
      margin: 0 0 20px; }
      .map-popup .map-info-price .price-box .label:after {
        content: ': '; }
  .map-popup .regular-price .price-label {
    display: none; }

.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
  margin-right: 10px; }

.map-old-price {
  text-decoration: none; }
  .map-old-price .price-wrapper {
    text-decoration: line-through; }

.map-form-addtocart {
  display: inline-block;
  text-align: center; }
  .map-form-addtocart img {
    display: block;
    margin: 0 auto; }

button.map-show-info, .cart.table-wrapper .actions-toolbar > .map-show-info.action, .map-show-info.action-gift {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    background: none;
    border: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    background: none;
    border: none; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  button.map-show-info:visited, .cart.table-wrapper .actions-toolbar > .map-show-info.action:visited, .map-show-info.action-gift:visited {
    color: #00ade7;
    text-decoration: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #00ade7;
    text-decoration: none; }
  button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    color: #00ADE7;
    text-decoration: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #00ade7; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active, button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus {
    background: none;
    border: 0; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .map-popup {
    max-width: 100%; }
  .map-old-price,
  .map-show-info {
    display: inline-block; }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
      margin-bottom: 10px; } }

.wishlist.split.button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .wishlist.split.button:before, .wishlist.split.button:after {
    content: '';
    display: table; }
  .wishlist.split.button:after {
    clear: both; }
  .wishlist.split.button .action.split {
    float: left;
    margin: 0; }
  .wishlist.split.button .action.toggle {
    float: right;
    margin: 0; }
  .wishlist.split.button button.action.split, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action.split, .wishlist.split.button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .wishlist.split.button button + .action.toggle, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action + .action.toggle, .wishlist.split.button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none; }
    .wishlist.split.button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.split.button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: #333;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.split.button .action.toggle:hover:after {
      color: #00ade7; }
    .wishlist.split.button .action.toggle:active:after {
      color: inherit; }
    .wishlist.split.button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .wishlist.split.button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .wishlist.split.button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #333;
        content: "";
        font-family: "icons-blank-theme";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .wishlist.split.button .action.toggle.active:hover:after {
        color: #00ade7; }
      .wishlist.split.button .action.toggle.active:active:after {
        color: inherit; }
  .wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .wishlist.split.button .items li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .wishlist.split.button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .wishlist.split.button .items:before, .wishlist.split.button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .wishlist.split.button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .wishlist.split.button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .wishlist.split.button .items:before {
      left: 10px;
      top: -12px; }
    .wishlist.split.button .items:after {
      left: 9px;
      top: -14px; }
  .wishlist.split.button.active {
    overflow: visible; }
    .wishlist.split.button.active .items {
      display: block; }
  .wishlist.split.button .items {
    text-align: left; }
    .wishlist.split.button .items .item:last-child:hover {
      background: #e8e8e8; }
  .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before {
    content: ""; }
  .table-comparison .wishlist.split.button > .action:active, .table-comparison .wishlist.split.button > .action:focus, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:hover {
    color: #00ade7; }
  .table-comparison .wishlist.split.button > .action.toggle:before,
  .product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid gray;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px; }
  .product-info-main .wishlist.split.button .action.split, .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.split,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.split,
  .product-add-form .wishlist.split.button .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem; }
  .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.toggle {
    padding: 0; }
  .cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px; }
    .cart.table-wrapper .wishlist.split.button > .action.toggle {
      padding: 4px 5px; }

.wishlist.window.popup {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0; }
  .wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .wishlist.window.popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .wishlist.window.popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.window.popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "icons-blank-theme";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.window.popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:active:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:focus, .wishlist.window.popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close.disabled, .wishlist.window.popup .popup-actions .action.close[disabled],
    fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .wishlist.window.popup.active {
    opacity: 1; }
  .wishlist.window.popup.active {
    display: block; }

.wishlist.overlay {
  transition: opacity 0.15s linear;
  background: #000;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .wishlist.overlay.active {
    opacity: 0.5;
    filter: alpha(opacity=50); }
  .wishlist.overlay.active {
    display: block; }

.block-wishlist-management {
  position: relative; }
  .block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600; }
  .block-wishlist-management .wishlist-title {
    margin-bottom: 10px; }
    .block-wishlist-management .wishlist-title strong {
      font-weight: 300;
      margin-right: 10px; }
  .block-wishlist-management .wishlist-notice {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 20px; }
  .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block; }
  .block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px; }
  .block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px; }

.block-wishlist-search-form .form-wishlist-search {
  margin-bottom: 60px;
  max-width: 500px; }

.block-wishlist-search-results .toolbar-wishlist-results {
  text-align: center; }

.block-wishlist-info-items .block-title {
  margin-bottom: 20px;
  font-size: 2.2rem; }
  .block-wishlist-info-items .block-title > strong {
    font-weight: 300; }

.block-wishlist-info-items .product-item {
  width: 100%; }

.block-wishlist-info-items .product-item-photo {
  margin-left: 20px; }

.block-wishlist-info-items .product-item-info {
  width: auto; }
  .block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible; }

.block-wishlist-info-items .price-wrapper {
  display: block;
  margin: 0 0 10px; }

.block-wishlist-info-items .split {
  clear: both;
  padding: 10px 0; }

.block-wishlist-info-items .action.towishlist {
  padding: 10px 0; }

.block-wishlist-info-items .input-text.qty {
  margin-right: 10px; }

@media only screen and (max-width: 767px) {
  .block-wishlist-management {
    clear: both;
    margin-top: -21px; }
    .block-wishlist-management .wishlist-select {
      margin: 0 -15px 20px; }
      .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #d1d1d1;
        background: #f5f5f5;
        display: none;
        padding: 15px 0; }
        .block-wishlist-management .wishlist-select-items.active {
          display: block; }
        .block-wishlist-management .wishlist-select-items .item {
          margin: 3px 0 0; }
          .block-wishlist-management .wishlist-select-items .item:first-child {
            margin-top: 0; }
          .block-wishlist-management .wishlist-select-items .item.current {
            display: none; }
          .block-wishlist-management .wishlist-select-items .item a {
            display: block;
            padding: 5px 18px;
            text-decoration: none; }
            .block-wishlist-management .wishlist-select-items .item a:hover {
              background: #e8e8e8; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 2.6rem; }
    .block-wishlist-management .wishlist-info {
      margin-bottom: 15px; }
    .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
      margin-bottom: 15px; }
  .products-grid.wishlist .product-item-checkbox {
    left: 20px;
    position: absolute;
    top: 20px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
    margin-left: 25px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-name, .page-multiple-wishlist .products-grid.wishlist .product-item-description, .page-multiple-wishlist
  .products-grid.wishlist .product-item .price-box, .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
    margin-left: 115px; }
  .products-grid.wishlist .wishlist-dropdown {
    display: none; } }

@media only screen and (min-width: 768px) {
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    margin-left: -212px;
    width: 380px;
    right: auto; }
    .wishlist.window.popup .field {
      margin: 0 0 20px; }
      .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .wishlist.window.popup .field.choice:before, .wishlist.window.popup .field.no-label:before {
        display: none; }
  .block-wishlist-management {
    margin-bottom: 20px; }
    .block-wishlist-management .wishlist-select {
      border-bottom: 1px solid #e8e8e8;
      display: table;
      margin-bottom: 15px;
      width: 100%; }
      .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%; }
      .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top; }
        .block-wishlist-management .wishlist-select-items .item {
          display: inline-block;
          margin-right: 10px;
          padding: 5px 10px 10px; }
          .block-wishlist-management .wishlist-select-items .item:last-child {
            margin-right: 0; }
        .block-wishlist-management .wishlist-select-items .current {
          border-bottom: 3px solid #ff5501;
          font-weight: 600; }
      .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none; }
    .block-wishlist-management .wishlist-add.item {
      position: absolute;
      right: 0;
      top: 0; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 4rem; }
    .block-wishlist-management .wishlist-info {
      float: left; }
    .block-wishlist-management .wishlist-toolbar {
      float: right; }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0; }
  .products-grid.wishlist .product-item-checkbox {
    float: left; }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
      margin-left: 25px; } }

.multicheckout .title {
  margin-bottom: 30px; }
  .multicheckout .title strong {
    font-weight: 400; }

.multicheckout .table-wrapper {
  margin-bottom: 0; }
  .multicheckout .table-wrapper .action.delete {
    display: inline-block; }
  .multicheckout .table-wrapper .col .qty {
    display: inline-block; }
  .multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px; }

.multicheckout:not(.address) .table-wrapper .product-item-name {
  margin: 0; }

.multicheckout > .actions-toolbar {
  margin-top: 40px; }

.multicheckout .actions-toolbar > .secondary {
  display: block; }
  .multicheckout .actions-toolbar > .secondary .action {
    margin-bottom: 25px; }
    .multicheckout .actions-toolbar > .secondary .action.back {
      display: block;
      margin-left: 0; }

.multicheckout .actions-toolbar > .primary {
  margin-right: 10px; }

.multicheckout .item-options {
  margin: 10px 0 0; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field:before {
  display: none; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field .control {
  display: inline-block; }

.multicheckout .block-title,
.multicheckout .block-content .title {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px; }
  .multicheckout .block-title strong,
  .multicheckout .block-content .title strong {
    font-weight: 400; }
    .multicheckout .block-title strong span,
    .multicheckout .block-content .title strong span {
      color: #a6a6a6; }

.multicheckout .block-content .title {
  border-bottom: none;
  padding-bottom: 0; }

.multicheckout.order-review .block-title > strong {
  font-size: 2.4rem; }

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
  margin-bottom: 40px; }

.multicheckout .box-title span {
  margin-right: 10px; }

.multicheckout .box-title > .action {
  margin: 0; }

.multicheckout .box-shipping-method .price {
  font-weight: 700; }

.multicheckout .box-billing-method .fieldset {
  margin: 0; }
  .multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px; }

.multicheckout .checkout-review .grand.totals {
  font-size: 2.1rem;
  margin-bottom: 40px; }
  .multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400; }

[class^='multishipping-'] .logo {
  margin-left: 0; }

.multishipping-checkout-success .nav-sections {
  display: block; }

@media only screen and (max-width: 639px) {
  .multicheckout .data.table .address:before {
    margin-bottom: 5px; }
  .multicheckout .product-item-name,
  .multicheckout .price-including-tax,
  .multicheckout .price-excluding-tax {
    display: inline-block; }
  .multicheckout .block-content .box:not(:last-child) {
    margin-bottom: 40px; }
  .multicheckout .block-content .box:last-child {
    margin-bottom: 0; }
  .multicheckout.order-review .box-items .data.table thead {
    display: block; }
    .multicheckout.order-review .box-items .data.table thead tr {
      display: block; }
    .multicheckout.order-review .box-items .data.table thead .col.item {
      display: block;
      padding: 0; }
  .multicheckout .actions-toolbar .action {
    margin-bottom: 25px; }
  .multicheckout .actions-toolbar > .primary {
    margin-bottom: 25px;
    margin-right: 0; } }

@media only screen and (min-width: 640px) {
  .multicheckout .actions-toolbar .secondary {
    float: none;
    margin-top: 11px;
    text-align: right; }
    .multicheckout .actions-toolbar .secondary .action {
      margin-left: 10px; }
      .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left; }
  .multicheckout .item-options {
    margin: 20px 0 0; }
  .multicheckout .block-content .box {
    margin-bottom: 0; }
  .multicheckout .block-shipping .box {
    float: left;
    width: 25%; }
  .multicheckout .block-shipping .box-shipping-method {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%; }
    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
      display: none; }
  .multicheckout .block-billing .box-billing-address {
    float: left;
    width: 25%; }
  .multicheckout .block-billing .box-billing-method {
    float: left;
    padding-left: 25px;
    width: 50%; }
  .multicheckout.form.address .table-wrapper .applicable {
    margin: 7px 0 0; }
  .multicheckout.order-review .box-items {
    clear: left;
    float: none;
    padding-top: 40px;
    width: auto; }
  .multicheckout.order-review .col.item {
    width: 75%; }
  .multicheckout .methods-payment .item-content > .fieldset {
    width: auto; }
    .multicheckout .methods-payment .item-content > .fieldset .field.cvv {
      display: inline-block;
      width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice) > .label {
    float: none;
    margin-bottom: 8px;
    text-align: left;
    width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice):not(.cvv) .control {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .multishipping-checkout-success .nav-toggle {
    display: block; }
  .multishipping-checkout-success .logo {
    margin-left: 40px; } }

.block.newsletter {
  background-color: #B5B5B5; }
  .block.newsletter .form.subscribe {
    display: flex;
    width: 100%; }
  .block.newsletter .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top; }
  .block.newsletter .field {
    margin: 0;
    max-width: 485px;
    width: 85%;
    min-width: 230px; }
    .block.newsletter .field .control {
      display: inline-block;
      text-decoration: none;
      display: block;
      width: 100%; }
      .block.newsletter .field .control:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #c2c2c2;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block.newsletter .field .control:before {
        display: none;
        position: absolute; }
    .block.newsletter .field input {
      height: 60px;
      padding-left: 22px;
      font: normal normal normal 18px/24px Open Sans; }
  .block.newsletter .title {
    display: block;
    max-width: 701px;
    margin-bottom: 0;
    text-align: left;
    font: normal normal bold 30px/41px Open Sans;
    letter-spacing: 0px;
    color: #122948;
    padding-right: 19px; }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 26%;
    max-width: 170px;
    min-width: 120px; }
    .block.newsletter .actions button, .block.newsletter .actions .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .block.newsletter .actions .actions-toolbar > .action, .block.newsletter .actions .action-gift {
      width: 100%;
      height: 60px;
      font: normal normal normal 18px/24px Open Sans;
      background-color: #00ADE7;
      color: #FFFFFF;
      border: unset;
      letter-spacing: 0px; }
  .block.newsletter .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px; }

@media only screen and (max-width: 575px) {
  div.block.newsletter .field {
    width: 100%;
    min-width: inherit; }
  div.block.newsletter .actions {
    width: 100%;
    max-width: inherit; }
  div.block.newsletter .content .subscribe {
    margin-left: 0; }
  div.block.newsletter .content .form.subscribe {
    width: 100%;
    flex-direction: column; } }

@media only screen and (max-width: 992px) {
  .catalog-product-view .page-main .block.newsletter {
    margin-left: 2rem;
    margin-right: 2rem; }
  div.block.newsletter {
    display: grid;
    position: relative;
    grid-template-columns: repeat(1, 1fr);
    max-height: 280px;
    margin-bottom: 18px;
    padding: 0 !important; }
    div.block.newsletter .desc-v {
      display: none; }
    div.block.newsletter div.mage-error[generated] {
      margin-top: 0;
      position: absolute;
      bottom: 0; }
    div.block.newsletter .mobile-v {
      display: block; }
    div.block.newsletter .content,
    div.block.newsletter .title {
      padding-left: 13px !important;
      padding-right: 8px !important; }
    div.block.newsletter .title {
      font: normal normal bold 20px/27px Open Sans;
      max-width: inherit;
      padding: 15px 0; }
    div.block.newsletter .content {
      padding: 0 0 23px; } }

@media only screen and (min-width: 768px) {
  .catalog-product-view .page-main .newsletter {
    margin-left: 0;
    margin-right: 0; }
  .block.newsletter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    height: 350px;
    margin-top: 68px;
    margin-bottom: 17px;
    padding: 0 3.9rem; }
    .block.newsletter .desc-v {
      display: block; }
    .block.newsletter .mobile-v {
      display: none; }
    .block.newsletter .content {
      max-width: 660px;
      width: 100%; } }

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 5px 5px 10px;
  text-transform: uppercase; }

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 10px 5px;
  text-align: center;
  text-transform: uppercase; }

.paypal.acceptance {
  display: block;
  margin: 0 0 20px; }
  .paypal.acceptance img {
    max-width: 100%; }

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto; }

.paypal-review .paypa-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .items-qty .item {
  white-space: nowrap; }

.paypal-review .items-qty .title:after {
  content: ': '; }

.paypal-review .paypal-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .actions-toolbar {
  margin-top: 10px; }

.paypal-review .item-options dt {
  display: inline-block; }
  .paypal-review .item-options dt:after {
    content: ': '; }

.paypal-review .item-options dd {
  margin: 0; }

.paypal-review-discount {
  border-bottom: 1px solid #d1d1d1;
  margin: 20px 0; }
  .paypal-review-discount .block {
    margin-bottom: 0; }
    .paypal-review-discount .block .fieldset {
      padding: 10px 0 20px; }

@media only screen and (min-width: 768px) {
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #d1d1d1; }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%; }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%; }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right; } }

.fotorama-video-container:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: 0.6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama__product-video--loading:after {
  visibility: hidden; }

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%; } }

.rating-summary {
  overflow: hidden;
  white-space: nowrap; }
  .rating-summary .rating-result {
    width: 100px;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .rating-summary .rating-result:before {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      color: #c7c7c7;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      content: "";
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none; }
    .rating-summary .rating-result > span {
      display: block;
      overflow: hidden; }
      .rating-summary .rating-result > span:before {
        position: relative;
        z-index: 2;
        -webkit-font-smoothing: antialiased;
        color: #ff5501;
        font-family: "icons-blank-theme";
        font-size: 28px;
        height: 28px;
        letter-spacing: -10px;
        line-height: 28px;
        content: "";
        display: block;
        font-style: normal;
        font-weight: normal;
        speak: none; }
      .rating-summary .rating-result > span span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
  .rating-summary .rating-result {
    margin-left: -5px; }

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.review-control-vote {
  overflow: hidden; }
  .review-control-vote:before {
    color: #c7c7c7;
    font-family: "icons-blank-theme";
    font-size: 28px;
    height: 28px;
    letter-spacing: -10px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    content: "";
    display: block;
    position: absolute;
    z-index: 1; }
  .review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .review-control-vote input[type="radio"]:focus + label:before, .review-control-vote input[type="radio"]:checked + label:before {
      opacity: 1; }
  .review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute; }
    .review-control-vote label span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .review-control-vote label:before {
      color: #ff5501;
      font-family: "icons-blank-theme";
      font-size: 28px;
      height: 28px;
      letter-spacing: -10px;
      line-height: 28px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      vertical-align: top;
      -webkit-font-smoothing: antialiased;
      opacity: 0; }
    .review-control-vote label:hover:before {
      opacity: 1; }
    .review-control-vote label:hover ~ label:before {
      opacity: 0; }
  .review-control-vote .rating-5 {
    z-index: 2; }
    .review-control-vote .rating-5:before {
      content: ""; }
  .review-control-vote .rating-4 {
    z-index: 3; }
    .review-control-vote .rating-4:before {
      content: ""; }
  .review-control-vote .rating-3 {
    z-index: 4; }
    .review-control-vote .rating-3:before {
      content: ""; }
  .review-control-vote .rating-2 {
    z-index: 5; }
    .review-control-vote .rating-2:before {
      content: ""; }
  .review-control-vote .rating-1 {
    z-index: 6; }
    .review-control-vote .rating-1:before {
      content: ""; }

.block.add.review {
  margin-bottom: 40px; }
  .block.add.review > .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .block.add.review .legend {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin: 0 0 20px; }
  .block.add.review .field.rating {
    display: table-row; }
    .block.add.review .field.rating .label {
      display: table-cell;
      padding: 5px 0;
      vertical-align: middle; }
    .block.add.review .field.rating .control {
      display: table-cell;
      vertical-align: top; }

.block.reviews.list > .title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

.block.reviews.list .review.item {
  margin-bottom: 40px; }

.block.reviews.list .review.title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

.data.table.reviews .rating-summary {
  margin-top: -4px; }

.customer-review.view {
  margin-bottom: 20px; }
  .customer-review.view .review.title,
  .customer-review.view .ratings-summary,
  .customer-review.view .product-details {
    margin-bottom: 20px; }

.product-reviews-summary {
  display: table;
  margin: 10px 0 12px; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto; }
  .product-reviews-summary.empty {
    margin-left: 0; }
  .product-reviews-summary .rating-summary {
    display: table-cell;
    left: -5px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .product-reviews-summary .reviews-actions {
    display: table-cell;
    line-height: 28px;
    vertical-align: middle; }
    .product-reviews-summary .reviews-actions a {
      margin-right: 5px; }

@media only screen and (min-width: 768px) {
  .products.wrapper.list .product-reviews-summary {
    margin: 0; } }

.customer-review .product-details {
  margin-bottom: 40px; }

.customer-review .product-media {
  float: left;
  margin-right: 3%;
  max-width: 285px;
  width: 30%; }

.customer-review .review-details .customer-review-rating {
  margin-bottom: 20px; }
  .customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px; }
    .customer-review .review-details .customer-review-rating .item:last-child {
      margin-bottom: 0; }

.customer-review .review-details .review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-bottom: 20px; }

.customer-review .review-details .review-content {
  margin-bottom: 20px; }

.customer-review .product-reviews-summary .rating-summary,
.customer-review .product-reviews-summary .reviews-actions {
  display: block; }

.review-field-ratings .nested {
  display: table; }

.review-field-ratings .review-field-rating {
  display: table-row; }
  .review-field-ratings .review-field-rating .label,
  .review-field-ratings .review-field-rating .control {
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top; }
  .review-field-ratings .review-field-rating .label {
    padding-right: 10px;
    padding-top: 6px; }
  .review-field-ratings .review-field-rating .control {
    padding-top: 2px; }

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  text-align: center; }
  .review-toolbar:first-child {
    display: none; }
  .review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center; }
  .review-toolbar .limiter {
    display: none; }

.review-add .block-title {
  display: none; }

.review-form .action.submit.primary {
  background: #f2f2f2;
  border: none;
  color: #333; }
  .review-form .action.submit.primary:focus, .review-form .action.submit.primary:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  .review-form .action.submit.primary:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }

.fieldset .review-legend.legend {
  margin-bottom: 20px; }
  .fieldset .review-legend.legend strong {
    display: block;
    margin-left: 0; }

.review-field-rating .control {
  margin-bottom: 48px;
  margin-top: 10px; }

.review-list {
  margin-bottom: 30px; }
  .review-list .block-title strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }

.review-item {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  padding: 20px 0; }
  .review-item:after {
    clear: both;
    content: '';
    display: table; }
  .review-item:last-child {
    border-width: 0; }

.review-ratings {
  display: table;
  margin-bottom: 10px;
  max-width: 100%; }

.review-author {
  display: inline; }

.review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin: 0 0 20px; }

.review-content {
  margin-bottom: 20px; }

@media only screen and (min-width: 640px) {
  .review-form {
    max-width: 500px; }
  .review-ratings {
    float: left;
    margin-bottom: 0;
    max-width: 240px; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 280px; }
  .review-toolbar {
    margin: 0 0 30px; }
    .review-toolbar .pages {
      padding: 30px 0; }
  .fieldset .review-legend.legend {
    margin-bottom: 30px; }
  .review-item {
    padding: 30px 0; }
  .review-title {
    margin: 0 0 30px; } }

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
  margin-bottom: 25px; }

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
  display: inline-block;
  margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .reward-settings + .actions-toolbar {
    margin-top: 10px; } }

@media only screen and (min-width: 768px) {
  .reward-settings + .actions-toolbar {
    margin-top: -32px; } }

.form-create-return .field .control > span,
.form-create-return .field address {
  display: inline-block;
  padding-top: 6px; }

.form-create-return .additional:first-child {
  margin-top: 0; }

.form-create-return .additional .field:nth-last-child(2) {
  margin-bottom: 0; }

.form-create-return .additional .field:last-child {
  margin-top: 20px; }

.form-create-return .field.comment {
  margin-top: 55px; }

.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
  margin: 0; }

.block-returns-comments .returns-comments dt {
  font-weight: 400; }

.block-returns-comments .returns-comments dd {
  margin-bottom: 15px; }
  .block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0; }

.block-returns-comments .fieldset {
  margin-bottom: 20px; }

.magento-rma-returns-returns .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-title-wrapper .page-title,
.magento-rma-returns-view .page-title-wrapper .page-title {
  margin-right: 25px; }

@media only screen and (max-width: 767px) {
  .account .column.main .returns-details-items .block-returns-tracking .block-title > .action.track {
    display: block;
    float: none;
    margin: 10px 0 0; } }

@media only screen and (min-width: 768px) {
  .form-create-return .additional .field:last-child {
    margin-top: 29px; }
  .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; }
  .block-returns-tracking .block-title .action {
    margin: 0 0 0 30px; }
  .block-returns-tracking .block-title .actions-track {
    float: right;
    margin-top: 12px; } }

.order-links {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 10px; }
  .order-links .item {
    display: inline-block;
    margin-right: 20px; }

.order-actions-toolbar .action {
  margin: 0 20px 0 0; }

.order-details-items {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block; }
  .order-details-items .items-qty .item {
    white-space: nowrap; }
  .order-details-items .items-qty .title:after {
    content: ': '; }
  .order-details-items .table-order-items .product-item-name {
    margin-top: 0; }
  .order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #d1d1d1; }
  .order-details-items .item-options dt {
    margin: 0; }
  .order-details-items .item-options dd {
    margin: 0 0 15px; }
  .order-details-items .item-options.links dt {
    display: inline-block; }
    .order-details-items .item-options.links dt:after {
      content: ': '; }
  .order-details-items .item-options.links dd {
    margin: 0; }

.order-pager-wrapper .toolbar-amount {
  padding-top: 3px; }

.order-pager-wrapper .pages-items .item:first-child, .order-pager-wrapper .pages-items .item:last-child {
  margin-right: 0; }

.order-actions-toolbar {
  margin-bottom: 25px; }
  .order-actions-toolbar .action {
    margin-right: 30px; }
    .order-actions-toolbar .action.print {
      margin: 0; }

.page-title-wrapper .order-date {
  margin: 0 0 20px; }

.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
  margin-right: 25px; }

.form-orders-search .field.email, .form-orders-search .field.zip {
  margin-bottom: 0; }

.block-reorder .product-item .checkbox {
  position: relative;
  top: 2px;
  vertical-align: baseline; }

.block-reorder .product-item .field.item {
  display: inline-block; }

.block-reorder .product-item-name {
  display: inline-block; }

.block-reorder .actions-toolbar {
  margin: 17px 0;
  max-width: 178px; }

.block-order-details-comments {
  margin: 0 0 40px; }
  .block-order-details-comments .comment-date {
    font-weight: 600; }
  .block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px; }

.block-order-details-view .box-content .payment-method .title {
  font-weight: 400; }

.block-order-details-view .box-content .payment-method .content {
  margin: 0; }
  .block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400; }
    .block-order-details-view .box-content .payment-method .content > strong:after {
      content: ': '; }

.order-tracking {
  border-bottom: 1px solid #d1d1d1;
  margin: 0;
  padding: 20px 0; }
  .order-tracking .tracking-title {
    display: inline-block; }
  .order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px; }

@media only screen and (max-width: 639px) {
  .account .order-details-items .table-order-items .product-item-name,
  .account .order-details-items .table-order-items .price-including-tax,
  .account .order-details-items .table-order-items .price-excluding-tax,
  .account .order-details-items .table-order-items .items-qty {
    display: inline-block;
    vertical-align: top; }
  .account .toolbar .pages {
    float: right; }
  .account .toolbar .limiter {
    clear: both; }
  .order-details-items thead {
    display: block; }
    .order-details-items thead tr,
    .order-details-items thead td {
      display: block; }
  .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .order-pager-wrapper .toolbar-amount {
    left: inherit;
    position: relative;
    text-align: center;
    top: inherit; }
  .order-pager-wrapper .pages {
    text-align: center; }
  .order-pager-wrapper .action.previous,
  .order-pager-wrapper .action.next {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .table-order-items .subtotal,
  .table-order-items .amount {
    text-align: right; }
  .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center; }
  .table-order-items.creditmemo .col.total {
    text-align: right; }
  .order-pager-wrapper .order-pager-wrapper-top {
    padding-left: 0;
    padding-right: 0; }
  .order-pager-wrapper .pages {
    float: right; }
  .order-actions-toolbar .action.print {
    display: block;
    float: right; }
  .page-title-wrapper .order-date {
    margin-top: -20px; }
  [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; } }

.opc-wrapper .form-discount {
  max-width: 500px; }

.action.mailto.friend {
  margin-left: -7px;
  display: inline-block;
  text-decoration: none; }
  .action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }

.swatch-attribute-label.required {
  padding-right: 10px; }

.swatch-attribute-label[data-required="1"]:after {
  content: '*';
  color: red;
  position: absolute;
  right: -11px;
  top: -2px;
  font-weight: bold;
  font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  margin-top: 10px; }

.swatch-option {
  padding: 1px 2px;
  min-width: 30px;
  max-width: 90px;
  height: 20px;
  float: left;
  margin: 0 10px 5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
  text-overflow: ellipsis; }

.swatch-option.text {
  background: #f0f0f0;
  color: #686868;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px;
  min-width: 22px;
  margin-right: 7px; }

.swatch-option.selected {
  outline: 2px solid #ff5501;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.text.selected {
  background-color: #fff !important; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
  background: #f0f0f0;
  color: #949494; }

.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
  color: black;
  background: #fff;
  border: 1px solid #fff; }

.swatch-option:not(.disabled):hover {
  outline: 1px solid #999;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
  outline: 2px solid #ee0000;
  border: 1px solid #fff; }

.swatch-option.disabled {
  cursor: default; }

.swatch-option.disabled:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-option-tooltip {
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: 0;
  height: 8px; }

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
  content: '';
  position: relative;
  top: 1px;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #fff transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
  content: '';
  position: relative;
  top: 2px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8.5px 0 8.5px;
  border-color: #adadad transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  height: 130px;
  width: 130px;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center;
  display: block;
  overflow: hidden; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-option-link-layered {
  position: relative;
  margin: 0 !important;
  padding: 0 !important; }

.swatch-option-link-layered:focus > div {
  box-shadow: 0 0 3px 1px #68a8e0; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

.cookie-status-message {
  display: none; }

.sale {
  color: #DD0707 !important;
  font-weight: bold !important; }

.header__contact_area {
  position: absolute;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 0;
  align-items: center;
  margin: 0; }
  .header__contact_area > div {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px; }
  .header__contact_area .icon__email {
    width: 20px;
    height: 20px; }
  .header__contact_area .icon__phone {
    width: 19px;
    height: 15px;
    padding-bottom: 9px; }
  .header__contact_area .icon__phone + a {
    padding-bottom: 5px; }
  .header__contact_area a {
    color: #000000;
    opacity: 0.5;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px; }

@media only screen and (min-width: 768px) {
  .page-main > .page-title-wrapper .page-title {
    display: inline-block;
    margin-bottom: 13px;
    color: #122948;
    font-weight: 700;
    line-height: 1;
    font-size: 3rem; } }

@media only screen and (max-width: 992px) {
  div.header__contact_area {
    display: none; } }

@media only screen and (max-width: 1024px) {
  div.header__contact_area {
    top: 4px; } }

@media only screen and (max-width: 1200px) {
  .header__contact_area {
    align-items: center;
    height: 100%;
    right: 183px;
    top: 4px; }
    .header__contact_area > div {
      gap: 16px; }
    .header__contact_area .contact_phone {
      padding-bottom: 6px; } }

@media only screen and (min-width: 1201px) {
  .header__contact_area {
    row-gap: 0;
    right: 167px; } }

.my-credit-cards .status {
  font-style: italic; }

.my-credit-cards .action.delete {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .my-credit-cards .action.delete:focus, .my-credit-cards .action.delete:active {
    background: none;
    border: none; }
  .my-credit-cards .action.delete:hover {
    background: none;
    border: none; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards .action.delete:visited {
    color: #00ade7;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #00ade7;
    text-decoration: none; }
  .my-credit-cards .action.delete:active {
    color: #00ADE7;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #00ade7; }
  .my-credit-cards .action.delete:hover, .my-credit-cards .action.delete:active, .my-credit-cards .action.delete:focus {
    background: none;
    border: 0; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.my-credit-cards-popup .modal-footer {
  text-align: right; }

.my-credit-cards-popup .action.secondary {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  margin-right: 20px; }
  .my-credit-cards-popup .action.secondary:focus, .my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards-popup .action.secondary:visited {
    color: #00ade7;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #00ade7;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:active {
    color: #00ADE7;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #00ade7; }
  .my-credit-cards-popup .action.secondary:hover, .my-credit-cards-popup .action.secondary:active, .my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
      display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
      display: block; }
  .my-credit-cards .card-type img {
    vertical-align: middle; } }

@media only screen and (min-width: 768px) {
  .my-credit-cards .card-type img {
    display: block; } }

.minilist .weee {
  display: table-row;
  font-size: 1.1rem; }
  .minilist .weee:before {
    display: none; }

.price-container.weee:before {
  display: none; }

.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
  content: ""; }

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
  border-top: 0; }

.account .table-wrapper .data.table.wishlist > tbody + tbody {
  border-top: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist thead > tr > th {
  border-bottom: 0; }

.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
  border-bottom: 1px solid #d1d1d1; }

.account .table-wrapper .data.table.wishlist .product.name {
  display: inline-block;
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .box-tocart {
  margin: 10px 0; }
  .account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle; }

.account .table-wrapper .data.table.wishlist .col.item {
  width: 50%; }

.account .table-wrapper .data.table.wishlist .col.photo {
  max-width: 150px; }

.account .table-wrapper .data.table.wishlist .col.selector {
  max-width: 15px; }

.account .table-wrapper .data.table.wishlist textarea {
  margin: 10px 0; }

.account .table-wrapper .data.table.wishlist .input-text.qty {
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .action.primary {
  vertical-align: top; }

.account .table-wrapper .data.table.wishlist .price {
  font-weight: 700; }

.block-wishlist .product-item-name {
  margin-right: 25px; }

.products-grid.wishlist .product-item-photo {
  display: block;
  margin-bottom: 10px; }

.products-grid.wishlist .product-item-name {
  margin-top: 0; }

.products-grid.wishlist .product-item .price-box {
  margin: 0; }

.products-grid.wishlist .product-item-comment {
  display: block;
  height: 42px;
  margin: 10px 0; }

.products-grid.wishlist .product-item-actions > * {
  margin-right: 15px; }
  .products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0; }

.products-grid.wishlist .product-item .box-tocart input.qty {
  height: 32px; }

@media only screen and (max-width: 639px) {
  .products-grid.wishlist .product-item {
    border-bottom: 1px solid #e8e8e8; }
    .products-grid.wishlist .product-item:first-child {
      border-top: 1px solid #e8e8e8; } }

@media only screen and (max-width: 767px) {
  .products-grid.wishlist {
    margin-bottom: 30px;
    margin-right: -10px; }
    .products-grid.wishlist .product-item {
      padding: 20px 10px 20px 20px;
      position: relative; }
      .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px; }
      .products-grid.wishlist .product-item-name {
        font-size: 1.6rem; }
      .products-grid.wishlist .product-item-actions {
        display: block; }
        .products-grid.wishlist .product-item-actions .action {
          margin-right: 15px; }
          .products-grid.wishlist .product-item-actions .action:last-child {
            margin-right: 0; }
          .products-grid.wishlist .product-item-actions .action.edit {
            float: left; }
          .products-grid.wishlist .product-item-actions .action.delete {
            float: right; }
          .products-grid.wishlist .product-item-actions .action.edit, .products-grid.wishlist .product-item-actions .action.delete {
            margin-top: 7px; }
      .products-grid.wishlist .product-item-name, .products-grid.wishlist .product-item-description,
      .products-grid.wishlist .product-item .price-box, .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px; }
      .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px; }
        .products-grid.wishlist .product-item .box-tocart .stock {
          margin-top: 7px; }
      .products-grid.wishlist .product-item .giftregisty-dropdown,
      .products-grid.wishlist .product-item .field.qty {
        display: none; }
    .products-grid.wishlist .product-image-container {
      max-width: 80px; }
  .wishlist-index-index .product-item {
    width: 100%; }
    .wishlist-index-index .product-item-info {
      width: auto; } }

@media only screen and (min-width: 640px) {
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px; }
  .wishlist-index-index .products-grid .product-item-actions {
    margin: 0; } }

@media only screen and (min-width: 768px) {
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block; }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0; }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    margin-bottom: 10px;
    padding-right: 10px; }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
      width: auto; }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0; }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0; }
  .wishlist-index-index .product-item-info {
    width: 240px; } }

@media only screen and (min-width: 992px) {
  .wishlist-index-index .products-grid .product-items {
    margin: 0; }
  .wishlist-index-index .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

@font-face {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  src: url("${icons-pagebuilder__font-path}.eot");
  src: url("${icons-pagebuilder__font-path}.eot?#iefix") format("embedded-opentype"), url("${icons-pagebuilder__font-path}.woff") format("woff"), url("${icons-pagebuilder__font-path}.ttf") format("truetype"); }

.pagebuilder-icon, .slick-prev:before, .slick-prev:after,
.slick-next:before,
.slick-next:after {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none; }

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
  display: inline-block;
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle; }

.pagebuilder-icon-down:before {
  content: ""; }

.pagebuilder-icon-next:before {
  content: ""; }

.pagebuilder-icon-prev:before {
  content: ""; }

.pagebuilder-icon-up:before {
  content: ""; }

.product-full-width-section {
  padding: 0.5em 0; }
  .product-full-width-section .block.review-add {
    margin-top: 2.7rem; }

.page-layout-product-full-width .block.related {
  margin-top: 2.7rem; }

.page-main-details .product-section-title {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 15px;
  padding-bottom: 12px; }

.additional-attributes-wrapper .additional-attributes {
  border: none;
  width: auto; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > th {
    border: none;
    padding: 5.5px 30px 10px 0; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > td {
    border: none;
    padding: 5.5px 5px 10px; }

.cms-index-index.page-layout-cms-full-width .nav-sections {
  margin-bottom: 0; }

.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none; }

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  [dir='rtl'] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev {
  left: 2rem; }
  .slick-prev:before {
    content: "";
    padding-right: 5px; }

.slick-next {
  right: 2rem; }
  .slick-next:before {
    content: "";
    padding-left: 5px; }

.slick-prev,
.slick-next {
  background: rgba(252, 252, 252, 0.6);
  border: none;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 60px;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 60px;
  z-index: 101; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.2; }
  .slick-prev:hover,
  .slick-next:hover {
    background: #fcfcfc;
    border: none; }
    .slick-prev:hover.slick-disabled,
    .slick-next:hover.slick-disabled {
      opacity: 0.2; }
    .slick-prev:hover:before,
    .slick-next:hover:before {
      color: #494949; }
  .slick-prev:before, .slick-prev:after,
  .slick-next:before,
  .slick-next:after {
    color: #555;
    font-size: 38px;
    opacity: 1; }
  .slick-prev:active, .slick-prev:focus, .slick-prev:not(.primary),
  .slick-next:active,
  .slick-next:focus,
  .slick-next:not(.primary) {
    background: rgba(252, 252, 252, 0.7);
    border: none;
    box-shadow: none; }
    .slick-prev:active:active, .slick-prev:focus:active, .slick-prev:not(.primary):active,
    .slick-next:active:active,
    .slick-next:focus:active,
    .slick-next:not(.primary):active {
      box-shadow: none; }

.slick-dots {
  font-size: 1.4rem;
  font-size: 14px;
  letter-spacing: normal;
  line-height: normal;
  display: block;
  list-style: none;
  margin: 0;
  padding: 15px 0;
  text-align: center;
  width: 100%; }
  .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 14px; }
    .slick-dots li button, .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li .actions-toolbar > .action, .slick-dots li .action-gift {
      background: #ffffff;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: none;
      cursor: pointer;
      display: block;
      height: 14px;
      padding: 0;
      text-indent: -99999px;
      transition: 250ms;
      width: 14px; }
      .slick-dots li button:hover, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover, .slick-dots li .action-gift:hover {
        border: 1px solid #b3b3b3; }
      .slick-dots li button:active, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:active, .slick-dots li .action-gift:active, .slick-dots li button:focus, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus, .slick-dots li .action-gift:focus, .slick-dots li button:not(.primary), .slick-dots li .cart.table-wrapper .actions-toolbar > .action:not(.primary), .cart.table-wrapper .slick-dots li .actions-toolbar > .action:not(.primary), .slick-dots li .action-gift:not(.primary) {
        box-shadow: none; }
    .slick-dots li.slick-active button, .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li.slick-active .actions-toolbar > .action, .slick-dots li.slick-active .action-gift {
      background: #1c1918; }

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
  color: inherit;
  text-decoration: inherit; }
  [data-content-type='banner'] > [data-element='link']:hover,
  [data-content-type='banner'] > [data-element='empty_link']:hover {
    color: inherit;
    text-decoration: inherit; }

.pagebuilder-banner-wrapper {
  background-clip: padding-box;
  border-radius: inherit;
  overflow-wrap: break-word;
  box-sizing: border-box;
  word-wrap: break-word; }
  .pagebuilder-banner-wrapper .pagebuilder-overlay {
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-banner-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-banner-wrapper .pagebuilder-banner-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 766px) {
  .pagebuilder-banner-wrapper {
    background-attachment: scroll !important; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: none; } }

.pagebuilder-banner-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto; }

[data-content-type$='block'] .block p:last-child {
  margin-bottom: 1rem;
  margin-top: 0; }

[data-content-type='buttons'] {
  max-width: 100%; }

[data-content-type='button-item'] {
  max-width: 100%; }
  [data-content-type='button-item'] [data-element='link'],
  [data-content-type='button-item'] [data-element='empty_link'] {
    max-width: 100%;
    word-wrap: break-word; }
  [data-content-type='button-item'] [data-element='empty_link'] {
    cursor: default; }

a.pagebuilder-button-link,
button.pagebuilder-button-link,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
.pagebuilder-button-link.action-gift,
div.pagebuilder-button-link {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  line-height: 1.42857;
  margin: 0 10px 10px 0;
  padding: 14px 17px;
  color: var(--color-dark_blue);
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  font-weight: 600;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block; }
  a.pagebuilder-button-link:visited,
  button.pagebuilder-button-link:visited,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:visited,
  .pagebuilder-button-link.action-gift:visited,
  div.pagebuilder-button-link:visited {
    color: #00ade7;
    text-decoration: none; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #00ade7;
    text-decoration: none; }
  a.pagebuilder-button-link:active,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  div.pagebuilder-button-link:active {
    color: #00ADE7;
    text-decoration: none; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #00ade7; }
  a.pagebuilder-button-link:hover, a.pagebuilder-button-link:active, a.pagebuilder-button-link:focus,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  button.pagebuilder-button-link:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:focus,
  .pagebuilder-button-link.action-gift:focus,
  div.pagebuilder-button-link:hover,
  div.pagebuilder-button-link:active,
  div.pagebuilder-button-link:focus {
    background: none;
    border: 0; }
  a.pagebuilder-button-link.disabled, a.pagebuilder-button-link[disabled],
  fieldset[disabled] a.pagebuilder-button-link,
  button.pagebuilder-button-link.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.disabled.action,
  .pagebuilder-button-link.disabled.action-gift,
  button.pagebuilder-button-link[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action[disabled],
  .pagebuilder-button-link.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-link,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-link.action,
  fieldset[disabled] .pagebuilder-button-link.action-gift,
  div.pagebuilder-button-link.disabled,
  div.pagebuilder-button-link[disabled],
  fieldset[disabled]
  div.pagebuilder-button-link {
    color: var(--color-dark_blue);
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
.pagebuilder-button-primary.action-gift,
div.pagebuilder-button-primary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #00ade7;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 10px 10px 0;
  padding: 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-primary:hover, a.pagebuilder-button-primary:active, a.pagebuilder-button-primary:focus,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  div.pagebuilder-button-primary:hover,
  div.pagebuilder-button-primary:active,
  div.pagebuilder-button-primary:focus {
    text-decoration: none; }
  a.pagebuilder-button-primary:focus, a.pagebuilder-button-primary:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  div.pagebuilder-button-primary:focus,
  div.pagebuilder-button-primary:active {
    background: #00ade7;
    border: none;
    color: #fff; }
  a.pagebuilder-button-primary:hover,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  div.pagebuilder-button-primary:hover {
    background: #00ade7;
    border: none;
    color: #fff; }
  a.pagebuilder-button-primary.disabled, a.pagebuilder-button-primary[disabled],
  fieldset[disabled] a.pagebuilder-button-primary,
  button.pagebuilder-button-primary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.disabled.action,
  .pagebuilder-button-primary.disabled.action-gift,
  button.pagebuilder-button-primary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action[disabled],
  .pagebuilder-button-primary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-primary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-primary.action,
  fieldset[disabled] .pagebuilder-button-primary.action-gift,
  div.pagebuilder-button-primary.disabled,
  div.pagebuilder-button-primary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
.pagebuilder-button-secondary.action-gift,
div.pagebuilder-button-secondary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #f2f2f2;
  border: none;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 10px 10px 0;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-secondary:hover, a.pagebuilder-button-secondary:active, a.pagebuilder-button-secondary:focus,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  div.pagebuilder-button-secondary:hover,
  div.pagebuilder-button-secondary:active,
  div.pagebuilder-button-secondary:focus {
    text-decoration: none; }
  a.pagebuilder-button-secondary:focus, a.pagebuilder-button-secondary:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  div.pagebuilder-button-secondary:focus,
  div.pagebuilder-button-secondary:active {
    background: #e2e2e2;
    border: none;
    color: #333; }
  a.pagebuilder-button-secondary:hover,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  div.pagebuilder-button-secondary:hover {
    background: #e2e2e2;
    border: none;
    color: #555; }
  a.pagebuilder-button-secondary.disabled, a.pagebuilder-button-secondary[disabled],
  fieldset[disabled] a.pagebuilder-button-secondary,
  button.pagebuilder-button-secondary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.disabled.action,
  .pagebuilder-button-secondary.disabled.action-gift,
  button.pagebuilder-button-secondary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action[disabled],
  .pagebuilder-button-secondary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-secondary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-secondary.action,
  fieldset[disabled] .pagebuilder-button-secondary.action-gift,
  div.pagebuilder-button-secondary.disabled,
  div.pagebuilder-button-secondary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-secondary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.pagebuilder-column {
  box-sizing: border-box; }

@media only screen and (max-width: 766px) {
  .pagebuilder-column {
    background-attachment: scroll !important;
    flex-basis: 100%; } }

@media only screen and (max-width: 766px) {
  .pagebuilder-column-group {
    flex-wrap: wrap; } }

[data-content-type='heading'] {
  word-wrap: break-word; }

[data-content-type='html'] {
  word-wrap: break-word; }

figure[data-content-type='image'] {
  box-sizing: border-box; }
  figure[data-content-type='image'] > [data-element='link'],
  figure[data-content-type='image'] > [data-element='link'] img {
    border-radius: inherit; }
  figure[data-content-type='image'] figcaption {
    word-wrap: break-word; }

[data-content-type='map'] {
  box-sizing: border-box;
  height: 300px; }

.pagebuilder-column .widget-product-grid .product-item {
  max-width: 100%;
  min-width: 50px; }
  .pagebuilder-column .widget-product-grid .product-item .price-box {
    word-wrap: break-word; }
    .pagebuilder-column .widget-product-grid .product-item .price-box .price {
      white-space: normal; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
    display: inline-block;
    max-width: 100%; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
    display: inline-block;
    width: auto; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
    max-width: 100%;
    white-space: normal; }

.pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 200px; }

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block; }
  [data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
    visibility: visible; }

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto; }

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none; }
  [data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
    display: block;
    visibility: hidden; }

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: 0.5;
  transition: all 300ms ease; }
  [data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
    opacity: 1; }

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1; }

[data-content-type='row'][data-appearance='contained'] {
  box-sizing: border-box;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1920px; }
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    box-sizing: border-box; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-bleed'] {
  box-sizing: border-box; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='full-bleed'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-width'] {
  box-sizing: border-box; }
  [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
    position: relative;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }

@media only screen and (max-width: 766px) {
  [data-content-type='row'][data-appearance='full-width'] {
    background-attachment: scroll !important; } }

[data-content-type='slide'] {
  box-sizing: border-box;
  line-height: 20px;
  min-height: inherit;
  overflow: hidden; }
  [data-content-type='slide'] > [data-element='link'],
  [data-content-type='slide'] > [data-element='empty_link'] {
    color: inherit;
    min-height: inherit;
    text-decoration: inherit; }
    [data-content-type='slide'] > [data-element='link']:hover,
    [data-content-type='slide'] > [data-element='empty_link']:hover {
      color: inherit;
      text-decoration: inherit; }

.pagebuilder-slide-wrapper {
  border-radius: inherit;
  box-sizing: border-box;
  min-height: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word; }
  .pagebuilder-slide-wrapper .pagebuilder-overlay {
    border-radius: inherit;
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: inherit; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-slide-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-slide-wrapper .pagebuilder-slide-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 766px) {
  .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: none; } }

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto; }

div[data-content-type='slider'] {
  visibility: hidden; }
  div[data-content-type='slider'].slick-initialized {
    visibility: visible; }
  div[data-content-type='slider'] .slick-list,
  div[data-content-type='slider'] .slick-track,
  div[data-content-type='slider'] .slick-slide {
    min-height: inherit; }
    div[data-content-type='slider'] .slick-list > div,
    div[data-content-type='slider'] .slick-track > div,
    div[data-content-type='slider'] .slick-slide > div {
      line-height: 0;
      min-height: inherit;
      overflow: hidden;
      width: 100%; }

a.button {
  appearance: button;
  color: initial;
  padding: 10px;
  text-decoration: none; }

div[data-content-type='tabs'] .tabs-navigation {
  display: block;
  font-size: 0;
  margin: 0 0 -1px;
  padding: 0; }
  div[data-content-type='tabs'] .tabs-navigation li:first-child {
    margin-left: 0 !important; }
  div[data-content-type='tabs'] .tabs-navigation li.tab-header {
    background: #f6f6f6;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow-wrap: break-word;
    position: relative;
    word-wrap: break-word;
    z-index: 1; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:not(:first-child) {
      margin-left: -1px; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title {
      border-right: 0;
      color: #000000;
      cursor: pointer !important;
      display: block;
      font-size: 14px;
      font-weight: 600;
      padding: 1.4rem 2rem;
      position: relative;
      transition: all 250ms;
      vertical-align: middle;
      white-space: normal; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title span.tab-title {
        display: block; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title:hover {
        text-decoration: none; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:last-child {
      border-right: 1px solid #ccc; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active {
      background: #ffffff;
      z-index: 19; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active a.tab-title {
        position: relative;
        transition: all 250ms; }

div[data-content-type='tabs'] .tabs-content {
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 9; }
  div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
    box-sizing: border-box;
    min-height: inherit; }
    div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item']:not(:first-child) {
      display: none; }

div[data-content-type='tabs'].tab-align-left .tabs-content {
  border-top-left-radius: 0 !important; }

div[data-content-type='tabs'].tab-align-right .tabs-content {
  border-top-right-radius: 0 !important; }

@media only screen and (max-width: 766px) {
  [data-content-type='tab-item'] {
    background-attachment: scroll !important; } }

div[data-content-type='text'] {
  word-wrap: break-word; }

div[data-content-type='video'] {
  font-size: 0; }
  div[data-content-type='video'] .pagebuilder-video-inner {
    box-sizing: border-box;
    display: inline-block;
    width: 100%; }
  div[data-content-type='video'] .pagebuilder-video-container {
    border-radius: inherit;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative; }
  div[data-content-type='video'] iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.cms-content-important {
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 35px -20px;
  padding: 20px; }

.pagebuilder-full-width {
  float: left;
  width: 100%; }

.pagebuilder-content-type {
  box-sizing: border-box;
  margin-bottom: 20px; }

.pagebuilder-accordion {
  margin: 0;
  padding: 0; }
  .pagebuilder-accordion > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .pagebuilder-accordion > .item.title > .switch {
      display: block; }
  .pagebuilder-accordion > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .pagebuilder-accordion > .item.content:before, .pagebuilder-accordion > .item.content:after {
      content: '';
      display: table; }
    .pagebuilder-accordion > .item.content:after {
      clear: both; }
    .pagebuilder-accordion > .item.content.active {
      display: block; }
  .pagebuilder-accordion > .item.title {
    margin: 0 0 5px; }
  .pagebuilder-accordion > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #7d7d7d;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #7d7d7d;
    text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:visited {
      color: #7d7d7d;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:hover {
      color: #7d7d7d;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:active {
      color: #333;
      text-decoration: none; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
  .pagebuilder-accordion > .item.title.active > .switch,
  .pagebuilder-accordion > .item.title.active > .switch:focus,
  .pagebuilder-accordion > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .pagebuilder-accordion > .item.content {
    background: white;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 768px) {
  .pagebuilder-mobile-only {
    display: none !important; } }

@media only screen and (max-width: 766px) {
  .pagebuilder-mobile-hidden {
    display: none !important; } }

.nav-off-canvas div.nav-sections {
  display: none;
  left: -100%;
  width: calc(100% - 54px); }

.nav-open .nav-off-canvas .nav-toggle::before {
  display: none; }

.title-menu-mob {
  display: none; }

.nav-open .nav-off-canvas .nav-sections .nav-sections-item-title {
  display: none; }

.page-wrapper.nav-off-canvas .nav-sections .nav-sections-item-content {
  margin-top: 0;
  padding: 0; }

.page-wrapper.nav-off-canvas .nav-sections .nav-sections-item-title {
  display: none; }

.page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown {
  display: flex;
  align-items: center;
  justify-content: start;
  width: auto;
  margin: 0;
  padding: 5px 20px; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .btn-drilldown__close-nav {
    border: unset;
    background: transparent;
    padding: 0;
    order: 2; }
    .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .btn-drilldown__close-nav::after {
      content: "\2715";
      display: block;
      font-size: 25px; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .btn-back {
    display: flex;
    align-items: baseline;
    width: 100%;
    font-weight: 700 !important;
    line-height: 16px;
    color: var(--color-dark_blue);
    order: 1;
    padding: 0; }
    .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .btn-back:before {
      content: ''; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .icon__arrow-left {
    display: none; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown > .btn-drilldown .btn-back-icon .icon__arrow-left {
    display: block;
    width: 18px;
    height: 10px;
    padding-right: 5px; }

.page-wrapper.nav-off-canvas .nav-sections ul.level0 li.mega .mega.has-child span.menu-title:after {
  content: '\e608'; }

.page-wrapper.nav-off-canvas .nav-sections .active-childs > .drilldown-sub {
  display: block; }

.page-wrapper.nav-off-canvas .nav-sections .active-childs > a span.menu-title:after,
.page-wrapper.nav-off-canvas .nav-sections .active-childs > span span.menu-title:after {
  transform: rotate(270deg) !important; }

.page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob span.menu-title:after {
    transform: rotate(90deg); }
  .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob > .drilldown-sub {
    width: 100%; }
    .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob > .drilldown-sub ul {
      width: 100%; }
    .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob > .drilldown-sub li {
      padding: 0; }
      .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob > .drilldown-sub li > a {
        padding-left: 40px; }

.page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob + li, .page-wrapper.nav-off-canvas .nav-sections .menu-login .my-account-mob + li + li {
  display: none; }

.page-wrapper.nav-off-canvas .nav-sections .menu-logout .my-account-mob {
  pointer-events: none; }
  .page-wrapper.nav-off-canvas .nav-sections .menu-logout .my-account-mob span.menu-title:after {
    display: none; }

.page-wrapper.nav-off-canvas .nav-sections .drilldown-root ul.level1 li.mega .mega.has-child span.menu-title:after {
  transform: rotate(90deg); }

.page-wrapper.nav-off-canvas .nav-sections .drilldown-root ul.level1 > li.mega.has-child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown-root ul.level1 > li.mega.has-child > .drilldown-sub {
    width: 100%; }
    .page-wrapper.nav-off-canvas .nav-sections .drilldown-root ul.level1 > li.mega.has-child > .drilldown-sub li.mega {
      padding: 0; }
      .page-wrapper.nav-off-canvas .nav-sections .drilldown-root ul.level1 > li.mega.has-child > .drilldown-sub li.mega > a {
        padding-left: 40px; }

.page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.title-menu-mob .my-account-mob,
.page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.title-menu-mob .klantenservice-mob {
  font-weight: 700 !important;
  padding: 40px 0 21px 20px !important; }

.page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega {
  padding: 0; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega li.mega:not(.has-child):not(.banner-item) .title-menu-mob,
  .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega li.mega:not(.has-child):not(.banner-item) > a.mega {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega li.mega:not(.has-child):not(.banner-item) .title-menu-mob:after,
    .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega li.mega:not(.has-child):not(.banner-item) > a.mega:after {
      content: '\e608';
      font-family: 'icons-blank-theme';
      font-style: normal;
      font-size: 50px;
      width: 35px;
      color: inherit;
      line-height: inherit;
      vertical-align: middle;
      display: inline-block;
      overflow: hidden; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega .mega.has-child span.menu-title:after {
    font-size: 50px;
    right: 3px; }
  .page-wrapper.nav-off-canvas .nav-sections .drilldown-root li.mega .mega {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-dark_blue);
    padding: 20px;
    text-transform: capitalize; }

.page-wrapper.nav-off-canvas .nav-sections .drilldown-root .parent-item {
  display: none; }

.sections.nav-sections ul.level0 > li.mega > .mega {
  padding: 16px 0 14px 0; }

ul.level0.horizontal-root {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;
  white-space: nowrap;
  position: static !important; }
  ul.level0.horizontal-root > li.mega.has-child .child-content {
    max-width: 1920px;
    overflow-y: auto;
    padding: 0;
    left: 0;
    right: 0;
    border: unset;
    border-bottom: 1px solid #f5f5f5;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s; }
    ul.level0.horizontal-root > li.mega.has-child .child-content > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 0 auto;
      max-width: var(--container-width);
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0 20px !important;
      width: auto !important; }
  ul.level0.horizontal-root .banners-area {
    display: grid;
    justify-content: end;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 37px;
    grid-row-gap: 30px; }
    ul.level0.horizontal-root .banners-area .banner-item {
      display: flex;
      flex-direction: column-reverse; }
      ul.level0.horizontal-root .banners-area .banner-item .menu-desc + img {
        display: none; }
    ul.level0.horizontal-root .banners-area li.mega {
      display: flex;
      flex-direction: column-reverse;
      flex: 1 0 auto;
      max-width: 180px;
      height: 221px; }
      ul.level0.horizontal-root .banners-area li.mega a.mega {
        padding: 11px 0 11px 15px !important;
        width: initial !important; }
      ul.level0.horizontal-root .banners-area li.mega .menu-title {
        line-height: initial;
        font-weight: bold;
        font-size: 14px; }
      ul.level0.horizontal-root .banners-area li.mega div.menu-desc {
        display: block;
        position: static;
        max-width: 180px;
        max-height: 180px; }
        ul.level0.horizontal-root .banners-area li.mega div.menu-desc > p {
          margin-bottom: 0; }
        ul.level0.horizontal-root .banners-area li.mega div.menu-desc img {
          object-fit: cover;
          width: 100%;
          height: 100%; }
  ul.level0.horizontal-root .level2.drilldown-sub {
    flex-wrap: wrap; }
  ul.level0.horizontal-root > li.mega.has-child.mega-hover > .mega {
    background: transparent; }
  ul.level0.horizontal-root li.mega:last-of-type {
    padding: 0; }
  ul.level0.horizontal-root .mega.menu-col-3 ul.level1 {
    column-count: 3; }
  ul.level0.horizontal-root .mega.menu-col-4 ul.level1 {
    column-count: 4; }
  ul.level0.horizontal-root .mega.menu-col-5 ul.level1 {
    column-count: 5; }
  ul.level0.horizontal-root .mega.menu-col-6 ul.level1 {
    column-count: 6; }
  ul.level0.horizontal-root ul.level1 {
    column-count: 4;
    padding: 27px 0 42px 0; }
    ul.level0.horizontal-root ul.level1 > li.mega {
      text-align: start;
      padding: 0; }
      ul.level0.horizontal-root ul.level1 > li.mega.menu-col-end {
        break-before: column; }
      ul.level0.horizontal-root ul.level1 > li.mega .spacer, ul.level0.horizontal-root ul.level1 > li.mega.spacer {
        margin-top: 26px;
        display: inline-block; }
      ul.level0.horizontal-root ul.level1 > li.mega.title-items .mega {
        color: var(--color-sky_blue);
        font-weight: 700; }
      ul.level0.horizontal-root ul.level1 > li.mega .mega {
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: var(--color-dark_blue); }
        ul.level0.horizontal-root ul.level1 > li.mega .mega:focus {
          background: transparent; }
        ul.level0.horizontal-root ul.level1 > li.mega .mega:hover {
          background-color: transparent; }
        ul.level0.horizontal-root ul.level1 > li.mega .mega.ui-state-active, ul.level0.horizontal-root ul.level1 > li.mega .mega:active, ul.level0.horizontal-root ul.level1 > li.mega .mega:focus, ul.level0.horizontal-root ul.level1 > li.mega .mega:hover {
          text-decoration: underline; }
  ul.level0.horizontal-root > li.mega {
    position: static !important;
    color: var(--color-dark_blue); }
    ul.level0.horizontal-root > li.mega > .mega:hover {
      background-color: transparent; }
    ul.level0.horizontal-root > li.mega.mega-hover > .mega {
      border-bottom: 2px solid #00ADE7; }
    ul.level0.horizontal-root > li.mega.mega-hover.sale > .mega {
      border-bottom: 2px solid #DD0707; }
    ul.level0.horizontal-root > li.mega > .mega {
      padding: 16px 0 14px 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      border-bottom: 2px solid #edf9fd;
      cursor: pointer; }
      ul.level0.horizontal-root > li.mega > .mega .menu-title {
        display: flex !important;
        flex-direction: column; }
        ul.level0.horizontal-root > li.mega > .mega .menu-title::after {
          display: none !important; }
  ul.level0.horizontal-root li.mega {
    padding: 0 5px 0 0;
    text-align: center; }
    ul.level0.horizontal-root li.mega .menu-title {
      display: flex !important; }
    ul.level0.horizontal-root li.mega .mega-hover.child-content {
      display: block; }

.drilldown {
  max-width: 100% !important; }
  .drilldown li.mega.has-child {
    cursor: pointer; }
  .drilldown .drilldown-container .banners-area {
    display: flex;
    padding: 0; }
    .drilldown .drilldown-container .banners-area > ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 36px;
      row-gap: 20px;
      width: 55%; }
      .drilldown .drilldown-container .banners-area > ul li.mega {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        min-width: 160px; }
        .drilldown .drilldown-container .banners-area > ul li.mega p {
          margin-bottom: 0;
          max-height: 160px; }
        .drilldown .drilldown-container .banners-area > ul li.mega img {
          width: 160px;
          height: 160px;
          line-height: inherit; }
        .drilldown .drilldown-container .banners-area > ul li.mega .banner-item {
          background-color: #122948;
          color: #FFFFFF !important;
          text-align: left;
          text-transform: capitalize;
          padding: 0 !important;
          border-bottom: unset; }
          .drilldown .drilldown-container .banners-area > ul li.mega .banner-item .menu-title {
            padding: 10px 14px; }
            .drilldown .drilldown-container .banners-area > ul li.mega .banner-item .menu-title::after {
              display: none;
              content: ''; }

@media only screen and (max-width: 1200px) {
  .title-menu-mob {
    display: inline-block; }
  .nav-off-canvas div.nav-sections {
    display: block;
    width: 100%;
    max-width: 380px; }
    .nav-off-canvas div.nav-sections .drilldown .drilldown-container .banners-area > ul {
      grid-template-columns: repeat(2, 160px);
      justify-content: space-between;
      column-gap: 0;
      row-gap: 20px;
      width: 100%;
      padding: 20px; }
      .nav-off-canvas div.nav-sections .drilldown .drilldown-container .banners-area > ul li {
        padding: 0 !important; } }

@media only screen and (min-width: 320px) and (max-width: 379px) {
  .drilldown .drilldown-container .banners-area > ul {
    padding: 0 !important; } }

.sidebar .block-addbysku .fieldset {
  margin: 0; }
  .sidebar .block-addbysku .fieldset .fields {
    position: relative; }
    .sidebar .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
      .sidebar .block-addbysku .fieldset .fields .field.sku {
        margin-right: -85px;
        padding-right: 90px;
        width: 100%; }
      .sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
        width: 80px; }
    .sidebar .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 6px; }

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
  margin-bottom: 20px; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left; }
    .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
      width: auto; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left; }

.sidebar .block-addbysku .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.add:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.add:active:before {
    color: inherit; }

.sidebar .block-addbysku .action.links {
  display: inline-block;
  margin: 20px 0 0; }

.sidebar .block-addbysku .action.reset {
  display: block;
  margin: 10px 0; }

.sidebar .block-addbysku .action.remove {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.remove:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:active:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:focus, .sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove.disabled, .sidebar .block-addbysku .action.remove[disabled],
  fieldset[disabled] .sidebar .block-addbysku .action.remove {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.block-banners .banner-item,
.block-banners-inline .banner-item {
  display: block; }

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
  margin-bottom: 20px; }
  .block-banners .banner-item-content img,
  .block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto; }

.block-product-link.widget,
.block-category-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-product-link-inline.widget {
  margin: 0; }

.block.widget .product-item-info {
  width: auto; }

.block.widget .pager {
  padding: 0; }
  .block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem; }
  .block.widget .pager .pages-item-previous {
    padding-left: 0; }
  .block.widget .pager .pages-item-next {
    position: relative; }
  .block.widget .pager .items {
    white-space: nowrap; }

@media only screen and (min-width: 640px) {
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 33.33333%; } }

@media only screen and (min-width: 768px) {
  .block.widget .products-grid .product-item {
    width: 33.33333%; }
    .sidebar .block.widget .products-grid .product-item {
      margin-left: 0;
      width: 100%; }
      .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 992px) {
  .block.widget .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    margin-left: calc((100% - 5 * (100% / 6)) / 4);
    width: 16.66667%; }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
      margin-left: calc((100% - 5 * (100% / 6)) / 4); }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n + 1) {
      margin-left: 0; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%; }
  .block.widget .products-grid .product-items {
    margin: 0; }
  .block.widget .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .block.widget .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

@media only screen and (min-width: 768px) {
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0; }
    .sidebar .block.widget .pager .pages-item-next .action {
      margin: 0; } }

.columns .block-event {
  position: relative;
  z-index: 2; }

.block-event .block-content {
  position: relative; }
  .block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3; }
    .block-event .block-content > .action.backward, .block-event .block-content > .action.forward {
      opacity: 0.5;
      overflow: hidden;
      width: 20px;
      display: inline-block;
      text-decoration: none; }
      .block-event .block-content > .action.backward > span, .block-event .block-content > .action.forward > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 55px;
        line-height: 30px;
        color: inherit;
        content: "";
        font-family: "icons-blank-theme";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        margin-left: -15px; }
    .block-event .block-content > .action.backward {
      left: 0; }
    .block-event .block-content > .action.forward {
      right: 0; }
      .block-event .block-content > .action.forward:before {
        content: ""; }
      .block-event .block-content > .action.forward:before {
        margin-left: -20px; }
    .block-event .block-content > .action.inactive {
      cursor: default;
      display: none; }
    .block-event .block-content > .action:hover {
      opacity: 1; }

.block-event .slider-panel {
  overflow: hidden;
  position: relative;
  z-index: 2; }
  .block-event .slider-panel .slider {
    white-space: nowrap; }
    .block-event .slider-panel .slider .item {
      background: #f0f0f0;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      white-space: normal;
      width: 16.35%; }
      .sidebar-main .block-event .slider-panel .slider .item,
      .sidebar-additional .block-event .slider-panel .slider .item {
        width: 100%; }
      .block-event .slider-panel .slider .item img {
        max-width: 100%; }
      .block-event .slider-panel .slider .item .category-name {
        display: inline-block;
        font-size: 1.8rem;
        padding: 15px 5px 0;
        word-break: break-all; }
      .block-event .slider-panel .slider .item .ticker {
        margin: 0;
        padding: 0;
        list-style: none none; }
        .block-event .slider-panel .slider .item .ticker li {
          display: none;
          margin: 0 5px; }
        .block-event .slider-panel .slider .item .ticker .value {
          font-size: 2.6rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .ticker .label {
          display: block;
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .dates {
        font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .start,
        .block-event .slider-panel .slider .item .dates .end,
        .block-event .slider-panel .slider .item .dates .date {
          display: block; }
        .block-event .slider-panel .slider .item .dates .start:after {
          font-size: 1.8rem;
          content: '\2013';
          display: block; }
        .block-event .slider-panel .slider .item .dates .date {
          font-size: 1.8rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .time {
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .box-event .box-content {
        padding: 5px 0 10px; }
      .block-event .slider-panel .slider .item .box-event .box-title {
        display: block;
        font-weight: 300;
        margin: 10px 0 0; }
      .block-event .slider-panel .slider .item .action.show {
        display: block;
        padding: 0; }
        .block-event .slider-panel .slider .item .action.show .category.name {
          display: inline-block;
          padding: 30px 10px 0; }
        .block-event .slider-panel .slider .item .action.show img {
          display: block; }
      .block-event .slider-panel .slider .item .action.event {
        background-image: none;
        background: #00ade7;
        border: none;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        padding: 15px;
        font-size: 1.4rem;
        box-sizing: border-box;
        vertical-align: middle;
        margin-top: 15px; }
        .block-event .slider-panel .slider .item .action.event:focus, .block-event .slider-panel .slider .item .action.event:active {
          background: #00ade7;
          border: none;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event:hover {
          background: #00ade7;
          border: none;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event.disabled, .block-event .slider-panel .slider .item .action.event[disabled],
        fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; }
        .block-event .slider-panel .slider .item .action.event:hover {
          text-decoration: none; }

.block-static-block.widget,
.block-cms-link.widget {
  margin-bottom: 0; }
  .links .block-static-block.widget, .links
  .block-cms-link.widget {
    margin-bottom: 0; }

.custom-notify-block > div {
  margin: 0 auto;
  max-width: var(--container-width);
  box-sizing: border-box;
  height: 100%; }
  @media only screen and (max-width: 1200px) {
    .custom-notify-block > div {
      padding-left: 20px; } }

.custom-notify-block.first-block {
  justify-content: center !important; }
  @media only screen and (max-width: 639px) {
    .custom-notify-block.first-block p, .custom-notify-block.first-block span {
      font-size: 10px;
      line-height: 14px; }
    .custom-notify-block.first-block p {
      padding-right: 25px; } }
  @media only screen and (min-width: 640px) {
    .custom-notify-block.first-block span {
      font-size: 12px;
      line-height: 17px; } }

.block-cms-link-inline.widget {
  margin: 0; }

body > div.block-static-block [data-content-type='row'] {
  position: relative;
  min-height: 30px;
  overflow: hidden;
  max-height: max-content; }

body > div.block-static-block ~ div.block-static-block [data-content-type='row'] {
  height: 40px;
  overflow: hidden;
  max-height: 40px;
  font-size: 11px;
  line-height: 5px; }
  body > div.block-static-block ~ div.block-static-block [data-content-type='row'] div.custom-notify-column {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    height: 40px; }
    body > div.block-static-block ~ div.block-static-block [data-content-type='row'] div.custom-notify-column figure {
      padding-right: 8.75px; }
    body > div.block-static-block ~ div.block-static-block [data-content-type='row'] div.custom-notify-column > div {
      padding-top: 2px; }
  body > div.block-static-block ~ div.block-static-block [data-content-type='row'] .custom-notify-column:nth-child(2) {
    justify-content: center !important; }
  body > div.block-static-block ~ div.block-static-block [data-content-type='row'] .custom-notify-column:nth-last-child(-n+2) {
    justify-content: end !important; }
    body > div.block-static-block ~ div.block-static-block [data-content-type='row'] .custom-notify-column:nth-last-child(-n+2) figure {
      padding-right: 10.95px; }
  body > div.block-static-block ~ div.block-static-block [data-content-type='row'] .custom-notify-column:last-of-type figure {
    padding-right: 8px; }
  @media only screen and (max-width: 1200px) {
    body > div.block-static-block ~ div.block-static-block [data-content-type='row'] {
      display: none !important; } }

.remove__notify_block {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  border: none;
  right: 0;
  padding: 0;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (max-width: 1200px) {
    .remove__notify_block {
      padding-right: 20px; } }
  .remove__notify_block::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f00d';
    font-size: 10px; }
  .remove__notify_block:hover {
    background-color: transparent;
    border: none; }

.block-wishlist-search .form-wishlist-search {
  margin: 20px 0 0; }

.block-cms-hierarchy-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-cms-hierarchy-link-inline.widget {
  margin: 0; }

.header.content {
  margin: 0 auto;
  max-width: var(--container-width);
  position: relative;
  display: grid;
  align-items: center;
  justify-content: start;
  padding: 10px 20px;
  box-sizing: border-box; }
  .header.content .icon__mobile_logo {
    display: none; }
  .header.content .icon__login,
  .header.content .icon__cart {
    padding-bottom: 6px; }
  .header.content .logo {
    max-height: 54px;
    z-index: 5; }
    .header.content .logo .icon__desc_logo {
      width: 271px;
      height: 54px; }
  .header.content .icon-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #000000; }
  .header.content .icon__login {
    width: 26.25px; }
  .header.content .icon__cart {
    width: 33.75px; }
  .header.content .header__last-block {
    position: absolute;
    display: flex;
    justify-content: space-between; }
    .header.content .header__last-block .icon__login,
    .header.content .header__last-block .icon__cart {
      height: 30px;
      padding: 0 3px 6px 0; }
    .header.content .header__last-block .minicart-wrapper {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .header.content .header__last-block .authorization-link .mobile-auth-icon {
      display: none; }
    .header.content .header__last-block .authorization-link .icon-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .header.content .header__last-block .authorization-link,
    .header.content .header__last-block .action.showcart {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      padding: 0 0; }
      .header.content .header__last-block .authorization-link:before,
      .header.content .header__last-block .action.showcart:before {
        display: none; }
    .header.content .header__last-block .authorization-link {
      padding-right: 15px; }
    .header.content .header__last-block .counter.qty {
      position: absolute;
      top: 0;
      right: 20px;
      visibility: visible;
      background-color: #4daae2;
      color: #ffffff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 10px;
      text-align: center;
      line-height: 18px;
      padding: 0;
      margin: 0; }
      .header.content .header__last-block .counter.qty.empty {
        display: none; }

.checkout-index-index .header.content {
  grid-template-columns: auto;
  justify-content: center;
  padding: 10px 0; }

.customer-header {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
  margin: 0; }
  .customer-header > li {
    list-style: none;
    margin: 0; }
  .customer-header .customer-welcome .customer-name {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%; }
  .customer-header .customer-welcome .greet.welcome {
    padding: 5px;
    margin: -5px;
    display: inline-block; }
    .customer-header .customer-welcome .greet.welcome:hover {
      background: #f2fbfe; }
    .customer-header .customer-welcome .greet.welcome > a {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      white-space: nowrap; }
    .customer-header .customer-welcome .greet.welcome svg.icon.icon__login {
      display: inline-block;
      vertical-align: top;
      padding-right: 0; }
  .customer-header .customer-welcome.active + .customer-menu {
    display: block; }
  .customer-header .customer-welcome.active .greet.welcome {
    background: #00ade7;
    position: relative;
    z-index: 105; }
    .customer-header .customer-welcome.active .greet.welcome .icon-text {
      color: #fff; }
    .customer-header .customer-welcome.active .greet.welcome .icon__login {
      filter: invert(1); }
  .customer-header .customer-welcome.active:hover .greet.welcome {
    background: #00ade7; }
    .customer-header .customer-welcome.active:hover .greet.welcome .icon-text {
      color: #fff; }
    .customer-header .customer-welcome.active:hover .greet.welcome .icon__login {
      filter: invert(1); }
  .customer-header .customer-welcome:hover .greet.welcome {
    background: #f2fbfe; }
  .customer-header .customer-menu {
    display: none;
    position: absolute;
    background: #fff;
    padding: 20px 20px 10px;
    border-top: 13px solid #00ade7;
    z-index: 100;
    right: 5px;
    top: calc(100% + 5px);
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16); }
    .customer-header .customer-menu .header.links {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .customer-header .customer-menu .header.links > li {
        list-style: none;
        order: 1; }
        .customer-header .customer-menu .header.links > li.link.authorization-link {
          align-items: flex-start;
          order: 2; }
        .customer-header .customer-menu .header.links > li a:visited {
          color: #000; }

.minicart-wrapper .block-minicart {
  padding: 10px 20px;
  border: none;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15); }
  .minicart-wrapper .block-minicart:after {
    border-color: transparent transparent #ddd transparent; }
  .minicart-wrapper .block-minicart .block-title {
    display: block; }
    .minicart-wrapper .block-minicart .block-title strong {
      margin-bottom: 10px;
      color: #122948;
      font-size: 1.8rem; }
      .minicart-wrapper .block-minicart .block-title strong .qty {
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        background: #02ace7;
        color: #fff;
        text-align: center;
        line-height: 22px;
        font-size: 1.4rem; }
  .minicart-wrapper .block-minicart .subtotal {
    margin-right: 0;
    margin-left: 0; }

.minicart-wrapper .minicart-widgets {
  margin: 0; }

.minicart-wrapper .action.showcart::before {
  content: "";
  display: none; }

.minicart-wrapper .action.showcart .counter.qty .loader > img {
  width: 18px;
  height: 18px; }

.minicart-items .product-item {
  padding: 10px 0;
  border-bottom-color: #ccc; }
  .minicart-items .product-item:not(:first-child) {
    border-top: none; }
  .minicart-items .product-item .wrap {
    display: flex;
    position: relative; }
  .minicart-items .product-item .product-item-photo {
    padding-right: 20px; }
  .minicart-items .product-item .in-cart {
    position: absolute;
    right: -10px;
    top: -5px; }
  .minicart-items .product-item .product-item-details {
    padding: 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-between; }
    .minicart-items .product-item .product-item-details .details-qty {
      margin-top: 5px; }
  .minicart-items .product-item .product-item-name {
    height: auto;
    margin-bottom: 5px;
    margin-right: 5px; }
    .minicart-items .product-item .product-item-name a {
      color: #00ade7; }
      .minicart-items .product-item .product-item-name a:hover {
        text-decoration: none; }

.minicart-top .minicart-items-wrapper {
  border: none;
  padding-bottom: 0; }

.minicart-top .buttons .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px; }

.minicart-top .buttons .action.primary {
  min-width: auto;
  background: #f1a200;
  color: #fff;
  font-weight: 600; }

.minicart-top .buttons .action.viewcart {
  color: #00ade7; }

.block-minicart .subtotal {
  margin: 10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  justify-content: flex-end; }

.address-autofill-formatted-output {
  background-color: var(--color-light_gray);
  font-weight: bold;
  padding: 30px; }

@media only screen and (max-width: 767px) {
  .minicart-items .product-item .product-image-container {
    max-width: 50px; } }

@media only screen and (max-width: 992px) {
  .icon-text {
    display: none; }
  div.header.content {
    padding: 10px 20px; }
    div.header.content div.header__last-block {
      justify-content: space-between;
      right: 19px;
      top: 13px;
      z-index: 999; }
      div.header.content div.header__last-block .authorization-link .mobile-auth-icon {
        display: block; }
      div.header.content div.header__last-block .authorization-link .mobile-auth-icon + .icon-text {
        display: none; }
    div.header.content .icon__mobile_logo {
      display: block;
      width: 91px;
      height: 55px; }
  .header.content .header__last-block .counter.qty {
    right: 8px; } }

@media only screen and (max-width: 1024px) {
  .header.content .icon__desc_logo {
    display: none; }
  .header.content .icon__mobile_logo {
    display: block;
    width: 60px;
    height: 54px; } }

@media only screen and (max-width: 1200px) {
  .header.content {
    grid-template-columns: repeat(2, auto) 420px 0.5fr auto;
    padding: 10px 20px; }
    .header.content div.header__last-block {
      right: 38px; }
    .header.content .logo .icon__desc_logo {
      padding-left: 4px; } }

@media only screen and (min-width: 1201px) {
  .header.content {
    grid-template-columns: auto 1fr repeat(2, auto); }
    .header.content div.header__last-block {
      justify-content: space-between;
      right: 20px; }
      .header.content div.header__last-block .authorization-link .mobile-auth-icon {
        display: none; }
      .header.content div.header__last-block .icon__login {
        width: 26.25px; }
      .header.content div.header__last-block .icon__cart {
        width: 33.75px; }
      .header.content div.header__last-block .counter.qty {
        right: 10px; }
    .header.content .logo .icon__desc_logo {
      padding-left: 0;
      margin-left: -2px; } }

@media only screen and (min-width: 1440px) {
  .header.content .header__last-block {
    padding-top: 10px;
    padding-bottom: 10px; } }

.nav-sections__fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 1000; }
  .nav-sections__fixed.is-hidden {
    opacity: 0;
    transform: translate(0, -60px);
    transition: -webkit-transform 0.2s, background 0.3s, color 0.3s, opacity 0 0.3s;
    background-color: #ffffff; }
  .nav-sections__fixed.is-visible {
    opacity: 1;
    transform: translate(0, 0);
    transition: -webkit-transform 0.2s, background 0.3s, color 0.3s;
    background-color: #ffffff;
    margin-top: 0px; }

:root {
  --default-transition: all 250ms;
  --color-black: #000;
  --color-dark_blue: #182846;
  --color-white: #ffffff;
  --color-sky_blue: #00ade7;
  --color-gray: #8f8f8f;
  --color-light_gray: #f7f7f7;
  --color-light-gray-blue: #f2f7fc;
  --color-medium_gray: #b5b5b5;
  --color-dark_gray: #333333;
  --color-orange: #e6a532;
  --color-dark_orange: #cb9012;
  --color-red: #c91717;
  --color-green: #00bb00;
  --border-radius: 0;
  --header-height: 168px; }

.breadcrumbs .item {
  color: #122948;
  font-size: 14px;
  line-height: 15px; }
  .breadcrumbs .item a {
    color: #182846;
    font-weight: 400;
    display: inline-block; }
    .breadcrumbs .item a:hover {
      text-decoration: underline; }
  .breadcrumbs .item:not(:last-child):after {
    content: ">";
    margin-left: 10px;
    margin-right: 10px;
    color: #182846;
    line-height: 14px; }

.breadcrumbs strong {
  font-weight: 700;
  margin-left: 1px; }

.filter-mobile {
  display: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  background-color: #182846;
  border: 1px solid #182846;
  color: #ffffff;
  font-size: 14px;
  line-height: 23px;
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
  width: calc(100% - 30px);
  z-index: 99; }
  .filter-mobile:hover {
    background-color: #182846;
    border: 1px solid #182846;
    color: #ffffff; }
  .filter-mobile .icon__filter {
    width: 17px;
    height: 15px;
    margin-right: 10px; }

.widget__popular-products .top-container .block,
.catalogsearch-result-index .top-container .block,
.catalog-category-view .top-container .block {
  margin: 0 0 17px; }

.widget__popular-products .search.results .block,
.catalogsearch-result-index .search.results .block,
.catalog-category-view .search.results .block {
  display: none; }

.widget__popular-products .category-view,
.catalogsearch-result-index .category-view,
.catalog-category-view .category-view {
  margin-bottom: 41px; }

.widget__popular-products .abs-product-link, .widget__popular-products .product-item-name, .widget__popular-products .product.name a, .product.name .widget__popular-products a,
.widget__popular-products .product-item-name,
.widget__popular-products .product.name a,
.catalogsearch-result-index .abs-product-link,
.catalogsearch-result-index .product-item-name,
.catalogsearch-result-index .product.name a,
.product.name .catalogsearch-result-index a,
.catalogsearch-result-index .product-item-name,
.catalogsearch-result-index .product.name a,
.catalog-category-view .abs-product-link,
.catalog-category-view .product-item-name,
.catalog-category-view .product.name a,
.product.name .catalog-category-view a,
.catalog-category-view .product-item-name,
.catalog-category-view .product.name a {
  line-height: calc(18 / 13);
  font-weight: 700; }

.widget__popular-products .product-item-actions .actions-primary,
.catalogsearch-result-index .product-item-actions .actions-primary,
.catalog-category-view .product-item-actions .actions-primary {
  display: block; }

.widget__popular-products .category-item-name, .widget__popular-products .category-item-size, .widget__popular-products .category-item-stock,
.catalogsearch-result-index .category-item-name,
.catalogsearch-result-index .category-item-size,
.catalogsearch-result-index .category-item-stock,
.catalog-category-view .category-item-name,
.catalog-category-view .category-item-size,
.catalog-category-view .category-item-stock {
  font-size: 14px;
  line-height: 15px; }

.widget__popular-products .category-item-name a,
.catalogsearch-result-index .category-item-name a,
.catalog-category-view .category-item-name a {
  font-weight: 700;
  color: #122948; }

.widget__popular-products .category-item-size,
.catalogsearch-result-index .category-item-size,
.catalog-category-view .category-item-size {
  height: 15px;
  color: #b5b5b5;
  font-weight: 400;
  margin-bottom: 13px; }

.widget__popular-products .category-item-stock,
.catalogsearch-result-index .category-item-stock,
.catalog-category-view .category-item-stock {
  margin-bottom: 10px;
  font-weight: 400; }
  .widget__popular-products .category-item-stock.in,
  .catalogsearch-result-index .category-item-stock.in,
  .catalog-category-view .category-item-stock.in {
    color: #00bb00; }
  .widget__popular-products .category-item-stock.out,
  .catalogsearch-result-index .category-item-stock.out,
  .catalog-category-view .category-item-stock.out {
    color: #c91717; }

.widget__popular-products .category-item-prices,
.catalogsearch-result-index .category-item-prices,
.catalog-category-view .category-item-prices {
  display: flex;
  align-items: end;
  margin-bottom: 20px; }
  .widget__popular-products .category-item-prices .newPrice,
  .widget__popular-products .category-item-prices .oldPrice,
  .catalogsearch-result-index .category-item-prices .newPrice,
  .catalogsearch-result-index .category-item-prices .oldPrice,
  .catalog-category-view .category-item-prices .newPrice,
  .catalog-category-view .category-item-prices .oldPrice {
    display: inline-block;
    margin-right: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px; }
  .widget__popular-products .category-item-prices .newPrice,
  .catalogsearch-result-index .category-item-prices .newPrice,
  .catalog-category-view .category-item-prices .newPrice {
    color: #c91717; }
  .widget__popular-products .category-item-prices .oldPrice,
  .catalogsearch-result-index .category-item-prices .oldPrice,
  .catalog-category-view .category-item-prices .oldPrice {
    color: #122948;
    text-decoration: line-through; }
  .widget__popular-products .category-item-prices .vanaf,
  .catalogsearch-result-index .category-item-prices .vanaf,
  .catalog-category-view .category-item-prices .vanaf {
    display: inline-block;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #b5b5b5; }

.widget__popular-products .category-item-toCart,
.catalogsearch-result-index .category-item-toCart,
.catalog-category-view .category-item-toCart {
  width: 100%; }

.widget__popular-products .product-item .action.primary,
.catalogsearch-result-index .product-item .action.primary,
.catalog-category-view .product-item .action.primary {
  background-color: #e6a532;
  border: 1px solid #e6a532;
  border-radius: var(--border-radius);
  font-weight: 400; }
  .widget__popular-products .product-item .action.primary:hover,
  .catalogsearch-result-index .product-item .action.primary:hover,
  .catalog-category-view .product-item .action.primary:hover {
    background: #cb9013;
    border: 1px solid #cb9013; }

.widget__popular-products div.pages .action,
.catalogsearch-result-index div.pages .action,
.catalog-category-view div.pages .action {
  width: 39px;
  height: 39px;
  border: 1px solid #4daae2;
  color: #4daae2; }
  .widget__popular-products div.pages .action:hover,
  .catalogsearch-result-index div.pages .action:hover,
  .catalog-category-view div.pages .action:hover {
    color: #4daae2; }
  .widget__popular-products div.pages .action.previous:before,
  .catalogsearch-result-index div.pages .action.previous:before,
  .catalog-category-view div.pages .action.previous:before {
    margin: 3px 0 0 -3px;
    color: #4daae2; }
  .widget__popular-products div.pages .action.next,
  .catalogsearch-result-index div.pages .action.next,
  .catalog-category-view div.pages .action.next {
    margin-left: 0; }
    .widget__popular-products div.pages .action.next:before,
    .catalogsearch-result-index div.pages .action.next:before,
    .catalog-category-view div.pages .action.next:before {
      margin: 3px 0 0 -3px;
      color: #4daae2; }

.widget__popular-products div.pages .items,
.catalogsearch-result-index div.pages .items,
.catalog-category-view div.pages .items {
  font-weight: 400;
  justify-content: end; }
  .widget__popular-products div.pages .items.full-width,
  .catalogsearch-result-index div.pages .items.full-width,
  .catalog-category-view div.pages .items.full-width {
    justify-content: space-between; }

.widget__popular-products div.pages .item,
.catalogsearch-result-index div.pages .item,
.catalog-category-view div.pages .item {
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 37px;
  margin: 0 9px 0 0; }
  .widget__popular-products div.pages .item:last-child,
  .catalogsearch-result-index div.pages .item:last-child,
  .catalog-category-view div.pages .item:last-child {
    margin: 0; }
  .widget__popular-products div.pages .item.current,
  .catalogsearch-result-index div.pages .item.current,
  .catalog-category-view div.pages .item.current {
    width: 39px;
    height: 39px;
    background-color: #122948;
    border: 1px solid #122948; }

.widget__popular-products div.pages strong.page,
.catalogsearch-result-index div.pages strong.page,
.catalog-category-view div.pages strong.page {
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px; }

.widget__popular-products div.pages a.page,
.catalogsearch-result-index div.pages a.page,
.catalog-category-view div.pages a.page {
  color: #00ade7; }

.widget__popular-products .pages .action.previous:hover:before,
.widget__popular-products .pages .action.next:hover:before,
.catalogsearch-result-index .pages .action.previous:hover:before,
.catalogsearch-result-index .pages .action.next:hover:before,
.catalog-category-view .pages .action.previous:hover:before,
.catalog-category-view .pages .action.next:hover:before {
  color: #00ade7; }

.widget__popular-products .page-bottom h3,
.catalogsearch-result-index .page-bottom h3,
.catalog-category-view .page-bottom h3 {
  margin: 0 0 34px;
  color: #122948;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 1px; }

.widget__popular-products .page-bottom p,
.catalogsearch-result-index .page-bottom p,
.catalog-category-view .page-bottom p {
  color: #122948;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px; }
  .widget__popular-products .page-bottom plast-child,
  .catalogsearch-result-index .page-bottom plast-child,
  .catalog-category-view .page-bottom plast-child {
    margin-bottom: 25px; }

.widget__popular-products .page-bottom .pagebuilder-column-group,
.catalogsearch-result-index .page-bottom .pagebuilder-column-group,
.catalog-category-view .page-bottom .pagebuilder-column-group {
  display: flex;
  justify-content: space-between;
  margin-top: 46px; }
  .widget__popular-products .page-bottom .pagebuilder-column-group .pagebuilder-column,
  .catalogsearch-result-index .page-bottom .pagebuilder-column-group .pagebuilder-column,
  .catalog-category-view .page-bottom .pagebuilder-column-group .pagebuilder-column {
    margin-right: 20px; }
    .widget__popular-products .page-bottom .pagebuilder-column-group .pagebuilder-column:last-child,
    .catalogsearch-result-index .page-bottom .pagebuilder-column-group .pagebuilder-column:last-child,
    .catalog-category-view .page-bottom .pagebuilder-column-group .pagebuilder-column:last-child {
      margin-right: 0; }

.widget__popular-products .page-main > .block-static-block.widget,
.catalogsearch-result-index .page-main > .block-static-block.widget,
.catalog-category-view .page-main > .block-static-block.widget {
  order: 1; }
  .widget__popular-products .page-main > .block-static-block.widget [data-content-type="row"][data-appearance="contained"],
  .catalogsearch-result-index .page-main > .block-static-block.widget [data-content-type="row"][data-appearance="contained"],
  .catalog-category-view .page-main > .block-static-block.widget [data-content-type="row"][data-appearance="contained"] {
    max-width: inherit; }

.widget__popular-products .page-main .block.newsletter,
.catalogsearch-result-index .page-main .block.newsletter,
.catalog-category-view .page-main .block.newsletter {
  order: 2;
  margin: 1rem 0 2rem 0; }
  .widget__popular-products .page-main .block.newsletter .form.subscribe,
  .catalogsearch-result-index .page-main .block.newsletter .form.subscribe,
  .catalog-category-view .page-main .block.newsletter .form.subscribe {
    align-items: center; }
  .widget__popular-products .page-main .block.newsletter .action.subscribe,
  .catalogsearch-result-index .page-main .block.newsletter .action.subscribe,
  .catalog-category-view .page-main .block.newsletter .action.subscribe {
    margin-left: 0; }

.widget__popular-products .page-main .home-video__cms-block,
.catalogsearch-result-index .page-main .home-video__cms-block,
.catalog-category-view .page-main .home-video__cms-block {
  padding-bottom: 1rem; }

@media only screen and (min-width: 1280px) {
  .catalogsearch-result-index [data-content-type="row"][data-appearance="contained"],
  .catalog-category-view [data-content-type="row"][data-appearance="contained"],
  .catalog-product-view [data-content-type="row"][data-appearance="contained"] {
    max-width: 1280px;
    padding-left: 0;
    padding-right: 0; }
  .catalogsearch-result-index .top-container [data-content-type="row"][data-appearance="contained"],
  .catalogsearch-result-index .category-description [data-content-type="row"][data-appearance="contained"],
  .catalog-category-view .top-container [data-content-type="row"][data-appearance="contained"],
  .catalog-category-view .category-description [data-content-type="row"][data-appearance="contained"] {
    max-width: 100%; }
  .page-wrapper > .page-bottom .top {
    max-width: 1280px;
    margin: 0 auto; } }

@media only screen and (min-width: 1450px) {
  .catalogsearch-result-index .navigation,
  .catalogsearch-result-index .breadcrumbs,
  .catalogsearch-result-index .page-header .header.panel,
  .catalogsearch-result-index .header.content,
  .catalogsearch-result-index .page-wrapper > .widget,
  .catalogsearch-result-index .page-wrapper > .page-bottom,
  .catalogsearch-result-index .block.category.event,
  .catalogsearch-result-index .top-container,
  .catalogsearch-result-index .page-main,
  .catalogsearch-result-index [data-content-type="row"][data-appearance="contained"],
  .catalog-category-view .navigation,
  .catalog-category-view .breadcrumbs,
  .catalog-category-view .page-header .header.panel,
  .catalog-category-view .header.content,
  .catalog-category-view .page-wrapper > .widget,
  .catalog-category-view .page-wrapper > .page-bottom,
  .catalog-category-view .block.category.event,
  .catalog-category-view .top-container,
  .catalog-category-view .page-main,
  .catalog-category-view [data-content-type="row"][data-appearance="contained"],
  .catalog-product-view .navigation,
  .catalog-product-view .breadcrumbs,
  .catalog-product-view .page-header .header.panel,
  .catalog-product-view .header.content,
  .catalog-product-view .page-wrapper > .widget,
  .catalog-product-view .page-wrapper > .page-bottom,
  .catalog-product-view .block.category.event,
  .catalog-product-view .top-container,
  .catalog-product-view .page-main,
  .catalog-product-view [data-content-type="row"][data-appearance="contained"] {
    max-width: var(--container-width); }
  .catalogsearch-result-index .product-items .item,
  .catalog-category-view .product-items .item,
  .catalog-product-view .product-items .item {
    width: 335px; }
  .catalogsearch-result-index .page-main > .page-title-wrapper .page-title,
  .catalog-category-view .page-main > .page-title-wrapper .page-title {
    margin-top: 7px; }
  .page-wrapper > .page-bottom .top {
    max-width: var(--container-width);
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0; }
  .page-wrapper > .page-bottom .bottom {
    padding-left: 10px;
    padding-right: 10px; } }

@media only screen and (min-width: 769px) {
  .catalogsearch-result-index .read-more,
  .catalog-category-view .read-more {
    display: none; }
  .catalogsearch-result-index .page-wrapper > .page-bottom,
  .catalogsearch-result-index .footer.content,
  .catalog-category-view .page-wrapper > .page-bottom,
  .catalog-category-view .footer.content {
    width: 100%;
    max-width: 100%; }
    .catalogsearch-result-index .page-wrapper > .page-bottom [data-content-type="row"][data-appearance="contained"],
    .catalogsearch-result-index .footer.content [data-content-type="row"][data-appearance="contained"],
    .catalog-category-view .page-wrapper > .page-bottom [data-content-type="row"][data-appearance="contained"],
    .catalog-category-view .footer.content [data-content-type="row"][data-appearance="contained"] {
      max-width: 100%; } }

@media only screen and (max-width: 768px) {
  .category-view {
    position: relative; }
  .category-description.show {
    height: auto;
    overflow: visible; }
  .read-wrap {
    position: absolute;
    width: 90px;
    right: 10px;
    bottom: -9px;
    background-color: #ffffff; }
  .read-more {
    visibility: hidden;
    color: #00ade7; }
    .read-more:before {
      content: "... ";
      color: #122948; } }

@media only screen and (max-width: 768px) {
  body.filters-active {
    overflow: hidden; }
    body.filters-active header {
      display: none; }
    body.filters-active .nav-sections__fixed {
      z-index: 1; }
    body.filters-active .block.filter.active {
      z-index: 2; }
      body.filters-active .block.filter.active .filters-overlay {
        background: rgba(0, 0, 0, 0.65);
        content: "";
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 100; }
  .sidebar.sidebar-main .block.filter .blocks {
    position: fixed;
    display: block;
    box-sizing: border-box;
    width: 70vw;
    height: 100vh;
    padding: 40px 20px 20px;
    top: 0;
    left: -70vw;
    transition: left 250ms;
    background-color: #ffffff;
    z-index: 1002;
    overflow: auto; }
  .sidebar.sidebar-main .block.filter .close-filters {
    position: absolute;
    right: 28px;
    top: 28px;
    width: 28px;
    height: 28px;
    opacity: 1;
    cursor: pointer; }
    .sidebar.sidebar-main .block.filter .close-filters:hover {
      opacity: 0.3; }
    .sidebar.sidebar-main .block.filter .close-filters:before, .sidebar.sidebar-main .block.filter .close-filters:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 28px;
      width: 2px;
      background-color: #000000; }
    .sidebar.sidebar-main .block.filter .close-filters:before {
      transform: rotate(45deg); }
    .sidebar.sidebar-main .block.filter .close-filters:after {
      transform: rotate(-45deg); }
  .sidebar.sidebar-main .block.filter.active .blocks {
    left: 0; }
    .sidebar.sidebar-main .block.filter.active .blocks .close-filters {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 20px; }
  .page-layout-2columns-left .column.main {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .top-container {
    padding-left: 0;
    padding-right: 0; }
  .breadcrumbs .item {
    display: none;
    width: 0; }
    .breadcrumbs .item:nth-last-child(2) {
      display: flex; }
      .breadcrumbs .item:nth-last-child(2):before {
        content: "<";
        margin-left: 0;
        margin-right: 10px;
        color: #182846;
        line-height: 14px; }
      .breadcrumbs .item:nth-last-child(2):after {
        display: none; }
  .filter-mobile {
    display: flex; }
  .catalogsearch-result-index .category-view,
  .catalog-category-view .category-view {
    margin-bottom: 25px; }
  .catalogsearch-result-index .category-item-name,
  .catalog-category-view .category-item-name {
    height: auto; }
  .catalogsearch-result-index .product-item .action.primary,
  .catalog-category-view .product-item .action.primary {
    font-size: 14px;
    line-height: 15px; }
  .catalogsearch-result-index .columns .column.main,
  .catalog-category-view .columns .column.main {
    padding-bottom: 0; }
  .catalogsearch-result-index .page-bottom h3,
  .catalog-category-view .page-bottom h3 {
    margin: 0 0 16px; }
  .catalogsearch-result-index .page-bottom .pagebuilder-column-group,
  .catalog-category-view .page-bottom .pagebuilder-column-group {
    margin-top: 90px; }
  .catalogsearch-result-index div.pages,
  .catalog-category-view div.pages {
    position: relative; }
    .catalogsearch-result-index div.pages:before,
    .catalog-category-view div.pages:before {
      content: "";
      position: absolute;
      right: 0;
      top: -31px;
      width: 100%;
      height: 1px;
      background-color: #8f8f8f; }
    .catalogsearch-result-index div.pages .items,
    .catalog-category-view div.pages .items {
      display: flex;
      justify-content: center; }
    .catalogsearch-result-index div.pages .item,
    .catalog-category-view div.pages .item {
      margin: 0; }
  .products {
    margin: 30px 0 32px; } }

@media only screen and (max-width: 879px) {
  .page-title-wrapper .page-title,
  .catalogsearch-result-index .page-bottom h3,
  .catalog-category-view .page-bottom h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px; }
  .catalogsearch-result-index .page-bottom .pagebuilder-column-group .pagebuilder-column,
  .catalog-category-view .page-bottom .pagebuilder-column-group .pagebuilder-column {
    margin-right: 0;
    margin-bottom: 18px; } }

@media only screen and (max-width: 992px) {
  .widget__popular-products .page-main .block.newsletter,
  .widget__popular-products .page-main .root_category-widget-block,
  .catalogsearch-result-index .page-main .block.newsletter,
  .catalogsearch-result-index .page-main .root_category-widget-block,
  .catalog-category-view .page-main .block.newsletter,
  .catalog-category-view .page-main .root_category-widget-block {
    margin: 0 2rem 0 2rem; }
    .widget__popular-products .page-main .block.newsletter .home-video__cms-block div[data-content-type="video"] iframe,
    .widget__popular-products .page-main .root_category-widget-block .home-video__cms-block div[data-content-type="video"] iframe,
    .catalogsearch-result-index .page-main .block.newsletter .home-video__cms-block div[data-content-type="video"] iframe,
    .catalogsearch-result-index .page-main .root_category-widget-block .home-video__cms-block div[data-content-type="video"] iframe,
    .catalog-category-view .page-main .block.newsletter .home-video__cms-block div[data-content-type="video"] iframe,
    .catalog-category-view .page-main .root_category-widget-block .home-video__cms-block div[data-content-type="video"] iframe {
      width: 100%;
      padding: 0 0 2rem 0; }
  .widget__popular-products .page-main .block.newsletter,
  .catalogsearch-result-index .page-main .block.newsletter,
  .catalog-category-view .page-main .block.newsletter {
    margin-bottom: 2rem;
    margin-top: 0; } }

.catalog-product-view .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  right: 0;
  left: inherit; }

.catalog-product-view .fotorama__nav-wrap--vertical .fotorama__stage__frame .fotorama__img {
  left: 20%; }

.catalog-product-view .fotorama__nav-wrap--vertical .fotorama__nav__frame {
  margin-bottom: 10px; }

.catalog-product-view .fotorama__nav-wrap--vertical .fotorama__thumb-border {
  display: none; }

.catalog-product-view .fotorama__stage {
  left: 0 !important; }

.catalog-product-view .product-info-main .page-title-wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0; }

.catalog-product-view .product-info-main .page-title {
  color: #122948;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px; }

.catalog-product-view .product-propportions {
  color: #122948;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 20px; }

.catalog-product-view .product.attribute.sku .value {
  color: #b5b5b5;
  font-size: 14px;
  line-height: 20px; }

.catalog-product-view .product.attribute.overview {
  margin: 19px 0 17px;
  color: #122948;
  font-size: 14px;
  line-height: 20px; }
  .catalog-product-view .product.attribute.overview p {
    margin-bottom: 0; }

.catalog-product-view .product-stock {
  display: flex;
  padding-top: 18px;
  border-top: 1px solid #b5b5b5; }
  .catalog-product-view .product-stock__label {
    color: #122948;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px; }
  .catalog-product-view .product-stock__value.in {
    margin-left: 52px;
    color: #00bb00;
    font-size: 14px;
    line-height: 20px; }
  .catalog-product-view .product-stock__value.out {
    color: #c91717;
    font-size: 14px;
    line-height: 20px; }

.catalog-product-view .product-werkdagen {
  display: flex; }
  .catalog-product-view .product-werkdagen__label {
    color: #122948;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px; }
  .catalog-product-view .product-werkdagen__value {
    margin-left: 37px;
    color: #00ade7;
    font-size: 14px;
    line-height: 20px; }

.catalog-product-view .prices-tier {
  margin: 10px 0;
  padding: 18px;
  background: #122948; }
  .catalog-product-view .prices-tier .item {
    color: #ffffff;
    font-size: 14px; }
    .catalog-product-view .prices-tier .item .price {
      font-weight: 400;
      font-size: 14px; }

.catalog-product-view .price-box {
  display: flex;
  align-items: baseline; }
  .catalog-product-view .price-box .price-final_price .price {
    color: #122948;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px; }
  .catalog-product-view .price-box .oldPrice .price {
    color: #b5b5b5;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px; }
  .catalog-product-view .price-box .special-price {
    margin: 10px 10px 13px 0; }

.catalog-product-view .box-tocart {
  width: 100%;
  margin: 0 0 20px; }
  .catalog-product-view .box-tocart .field.qty {
    padding-right: 10px; }
  .catalog-product-view .box-tocart .control {
    display: flex; }
  .catalog-product-view .box-tocart .increase,
  .catalog-product-view .box-tocart .decrease {
    color: #122948;
    width: 40px;
    min-width: auto;
    height: auto;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #b5b5b5;
    font-size: 2.2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; }
    .catalog-product-view .box-tocart .increase svg,
    .catalog-product-view .box-tocart .decrease svg {
      fill: #122948;
      max-width: 16px;
      max-height: 16px; }
    .catalog-product-view .box-tocart .increase:hover,
    .catalog-product-view .box-tocart .decrease:hover {
      border-color: #9c9c9c; }
  .catalog-product-view .box-tocart .increase {
    line-height: 48px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0; }
    .catalog-product-view .box-tocart .increase.disabled {
      pointer-events: none; }
  .catalog-product-view .box-tocart .decrease {
    line-height: 48px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 0; }
  .catalog-product-view .box-tocart .input-text.qty {
    width: 90px;
    height: 50px;
    font-size: 20px;
    line-height: 27px; }
  .catalog-product-view .box-tocart .actions {
    width: 100%;
    padding-top: 0; }
    .catalog-product-view .box-tocart .actions .tocart {
      width: 100%;
      border: none;
      border-radius: 0;
      display: flex;
      justify-content: center; }
      .catalog-product-view .box-tocart .actions .tocart:hover {
        border: none; }

.catalog-product-view .product-video__title {
  margin-bottom: 21px;
  color: #122948;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center; }

.catalog-product-view .product-video__row {
  width: 320px;
  height: 192px;
  margin: 0 auto;
  border: 10px solid #f5f5f5; }

.catalog-product-view .meer-informatie {
  display: inline-block;
  margin-bottom: 52px; }
  .catalog-product-view .meer-informatie__title {
    margin: 0 0 20px;
    color: #122948;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px; }
  .catalog-product-view .meer-informatie__article {
    font-size: 14px;
    line-height: 20px; }

.catalog-product-view .video-container {
  width: 100%;
  display: inline-block;
  margin-bottom: 52px; }

.catalog-product-view .product-specificaties {
  margin-bottom: 36px; }

.catalog-product-view .specificaties-title {
  margin-top: 0;
  margin-bottom: 22px;
  color: #122948;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px; }

.catalog-product-view .specificaties-item {
  display: flex;
  padding: 10px 20px; }
  .catalog-product-view .specificaties-item__label {
    width: 50%;
    margin-bottom: 0;
    color: #122948;
    font-size: 14px;
    line-height: 20px; }
  .catalog-product-view .specificaties-item__value {
    margin-bottom: 0;
    color: #122948;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px; }

.catalog-product-view .block.related,
.catalog-product-view .block.upsell {
  margin-bottom: 24px; }
  .catalog-product-view .block.related .block-title,
  .catalog-product-view .block.upsell .block-title {
    color: #122948;
    font-weight: 700;
    text-align: center; }
    .catalog-product-view .block.related .block-title strong,
    .catalog-product-view .block.upsell .block-title strong {
      margin: 0 auto;
      font-size: 40px;
      line-height: 55px; }
  .catalog-product-view .block.related .block-short-desc,
  .catalog-product-view .block.upsell .block-short-desc {
    color: #122948;
    font-size: 14px;
    line-height: 18px;
    text-align: center; }
  .catalog-product-view .block.related .abs-product-link, .catalog-product-view .block.related .product-item-name, .catalog-product-view .block.related .product.name a, .product.name .catalog-product-view .block.related a,
  .catalog-product-view .block.related .product-item-name,
  .catalog-product-view .block.related .product.name a,
  .catalog-product-view .block.upsell .abs-product-link,
  .catalog-product-view .block.upsell .product-item-name,
  .catalog-product-view .block.upsell .product.name a,
  .product.name .catalog-product-view .block.upsell a,
  .catalog-product-view .block.upsell .product-item-name,
  .catalog-product-view .block.upsell .product.name a {
    font-weight: 700; }
  .catalog-product-view .block.related .product-item-actions .actions-primary,
  .catalog-product-view .block.upsell .product-item-actions .actions-primary {
    display: block; }
  .catalog-product-view .block.related .image-wrap,
  .catalog-product-view .block.upsell .image-wrap {
    border: 1px solid #e5e5e5;
    background-color: #a3a3a3; }
  .catalog-product-view .block.related .product-items,
  .catalog-product-view .block.upsell .product-items {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0; }
  .catalog-product-view .block.related .product-item .product-item-details,
  .catalog-product-view .block.upsell .product-item .product-item-details {
    margin-top: 27px; }
  .catalog-product-view .block.related .product-item-name, .catalog-product-view .block.related .product-item-size, .catalog-product-view .block.related .product-item-stock,
  .catalog-product-view .block.upsell .product-item-name,
  .catalog-product-view .block.upsell .product-item-size,
  .catalog-product-view .block.upsell .product-item-stock {
    font-size: 14px;
    line-height: 15px; }
  .catalog-product-view .block.related .product-item-name,
  .catalog-product-view .block.upsell .product-item-name {
    height: 40px; }
    .catalog-product-view .block.related .product-item-name a,
    .catalog-product-view .block.upsell .product-item-name a {
      font-weight: 700;
      color: #122948; }
  .catalog-product-view .block.related .product-item-size,
  .catalog-product-view .block.upsell .product-item-size {
    height: 15px;
    color: #b5b5b5;
    font-weight: 400;
    margin-bottom: 13px; }
  .catalog-product-view .block.related .product-item-stock,
  .catalog-product-view .block.upsell .product-item-stock {
    margin-bottom: 3px;
    font-weight: 400; }
    .catalog-product-view .block.related .product-item-stock.in,
    .catalog-product-view .block.upsell .product-item-stock.in {
      color: #00bb00; }
    .catalog-product-view .block.related .product-item-stock.out,
    .catalog-product-view .block.upsell .product-item-stock.out {
      color: #c91717; }
  .catalog-product-view .block.related .product-item-prices,
  .catalog-product-view .block.upsell .product-item-prices {
    display: flex;
    align-items: baseline; }
    .catalog-product-view .block.related .product-item-prices .price-final_price .price,
    .catalog-product-view .block.upsell .product-item-prices .price-final_price .price {
      display: inline-block;
      margin-right: 10px;
      color: #c91717;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px; }
    .catalog-product-view .block.related .product-item-prices .oldPrice .price,
    .catalog-product-view .block.upsell .product-item-prices .oldPrice .price {
      color: #122948;
      text-decoration: line-through; }
    .catalog-product-view .block.related .product-item-prices .vanaf,
    .catalog-product-view .block.upsell .product-item-prices .vanaf {
      display: inline-block;
      margin-bottom: 5px;
      font-style: italic;
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      color: #b5b5b5;
      white-space: nowrap; }
  .catalog-product-view .block.related .product-item .price-box,
  .catalog-product-view .block.upsell .product-item .price-box {
    margin: 9px 0 0; }
    .catalog-product-view .block.related .product-item .price-box .special-price,
    .catalog-product-view .block.upsell .product-item .price-box .special-price {
      margin: 0; }
  .catalog-product-view .block.related .product-item-toCart,
  .catalog-product-view .block.upsell .product-item-toCart {
    width: 100%; }
  .catalog-product-view .block.related .product-item .action.primary,
  .catalog-product-view .block.upsell .product-item .action.primary {
    width: 100%;
    height: 35px;
    background-color: #e6a532;
    border: 1px solid #e6a532;
    border-radius: 0;
    font-weight: 400; }
    .catalog-product-view .block.related .product-item .action.primary:hover,
    .catalog-product-view .block.upsell .product-item .action.primary:hover {
      background: #e6a532;
      border: 1px solid #e6a532; }
  .catalog-product-view .block.related .product-item-info:hover .action.primary,
  .catalog-product-view .block.upsell .product-item-info:hover .action.primary {
    visibility: visible; }

.catalog-product-view button[title="Download PDF"], .catalog-product-view .cart.table-wrapper .actions-toolbar > .action[title="Download PDF"], .cart.table-wrapper .catalog-product-view .actions-toolbar > .action[title="Download PDF"], .catalog-product-view .action-gift[title="Download PDF"] {
  width: 100%;
  margin-top: 10px;
  background-color: var(--color-orange);
  color: white;
  transition: var(--default-transition); }
  .catalog-product-view button[title="Download PDF"]:hover, .catalog-product-view .cart.table-wrapper .actions-toolbar > .action[title="Download PDF"]:hover, .cart.table-wrapper .catalog-product-view .actions-toolbar > .action[title="Download PDF"]:hover, .catalog-product-view .action-gift[title="Download PDF"]:hover {
    background-color: var(--color-dark_orange); }

@media only screen and (min-width: 768px) {
  .catalog-product-view .breadcrumbs {
    margin-top: 28px; }
  .catalog-product-view .product-video__row {
    width: 613px;
    height: 368px;
    border: 19px solid #f5f5f5; }
  .meer-informatie__article {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px; }
  .specificaties-items {
    display: grid;
    grid-column: 2;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px; }
  .item-background {
    background-color: #f5f5f5; }
  .catalog-product-view .block.related .product-item-info,
  .catalog-product-view .block.upsell .product-item-info {
    width: 200px; } }

@media only screen and (min-width: 992px) {
  .catalog-product-view .block.related .product-items,
  .catalog-product-view .block.upsell .product-items {
    grid-template-columns: repeat(4, 1fr); }
  .product-item .action.primary {
    visibility: hidden; }
  .product-item-info:hover .action.primary {
    visibility: visible; } }

@media only screen and (min-width: 1400px) {
  .catalog-product-view .block.related .product-item-info,
  .catalog-product-view .block.upsell .product-item-info {
    width: 335px; } }

@media only screen and (max-width: 768px) {
  .catalog-product-view .fotorama__stage {
    margin-bottom: 11px; }
  .catalog-product-view .fotorama__nav-wrap {
    margin-bottom: 8px; }
  .catalog-product-view .fotorama__thumb-border {
    display: none; }
  .catalog-product-view .product-info-main .page-title-wrapper {
    margin-bottom: 15px; }
  .catalog-product-view .product-propportions {
    margin-bottom: 16px; }
  .catalog-product-view .product.attribute.overview {
    margin: 14px 0 19px; }
  .catalog-product-view .prices-tier {
    margin: 9px 0; }
  .catalog-product-view .box-tocart .field.qty {
    padding-right: 0; }
    .catalog-product-view .box-tocart .field.qty .input-text.qty {
      width: 80%; }
  .catalog-product-view .info-price {
    flex-direction: column;
    margin-bottom: 14px; }
    .catalog-product-view .info-price .right-info .item {
      padding-left: 70px; }
      .catalog-product-view .info-price .right-info .item:before {
        left: 42px; }
      .catalog-product-view .info-price .right-info .item-space {
        padding-left: 71px;
        margin-top: -12px;
        margin-bottom: 10px; }
  .catalog-product-view .video-container {
    margin-bottom: 20px; }
  .catalog-product-view .product-video__title {
    margin-bottom: 11px;
    text-align: left; }
  .catalog-product-view .meer-informatie {
    margin-bottom: 45px; }
  .catalog-product-view .product-specificaties {
    margin-bottom: 23px; }
  .catalog-product-view .specificaties-item {
    line-height: 20px; }
    .catalog-product-view .specificaties-item:nth-child(odd) {
      background-color: #f5f5f5; }
  .catalog-product-view .block.related .block-title,
  .catalog-product-view .block.upsell .block-title {
    text-align: left; }
    .catalog-product-view .block.related .block-title strong,
    .catalog-product-view .block.upsell .block-title strong {
      font-size: 20px;
      line-height: 27px; }
  .catalog-product-view .block.related .block-short-desc,
  .catalog-product-view .block.upsell .block-short-desc {
    text-align: left; }
  .catalog-product-view .block.related .products,
  .catalog-product-view .block.upsell .products {
    margin: 23px 0 32px; }
    .catalog-product-view .block.related .products .product-item-details,
    .catalog-product-view .block.upsell .products .product-item-details {
      margin-top: 7px; }
  .catalog-product-view .block.related .product-item-actions,
  .catalog-product-view .block.upsell .product-item-actions {
    margin-bottom: 20px; }
  .catalog-product-view .block.related .product-item-info,
  .catalog-product-view .block.upsell .product-item-info {
    width: 160px;
    max-width: none; }
  .catalog-product-view .block.related .product-item-name, .catalog-product-view .block.related .product-item-size, .catalog-product-view .block.related .product-item-stock,
  .catalog-product-view .block.upsell .product-item-name,
  .catalog-product-view .block.upsell .product-item-size,
  .catalog-product-view .block.upsell .product-item-stock {
    font-size: 12px;
    line-height: 17px; }
  .catalog-product-view .block.related .product-item-name,
  .catalog-product-view .block.upsell .product-item-name {
    height: 75px; }
  .catalog-product-view .block.related .product-item .price-box,
  .catalog-product-view .block.upsell .product-item .price-box {
    margin: 0;
    height: auto; }
  .catalog-product-view .block.related .product-item-prices,
  .catalog-product-view .block.upsell .product-item-prices {
    flex-wrap: wrap;
    height: 60px;
    margin: 9px 0 11px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .catalog-product-view .block.upsell .product-item-info {
    margin: 0 auto; } }

@media only screen and (max-width: 1449px) {
  .catalog-product-view .column.main {
    padding: 0 20px 15px; } }

.bundle-options-container .product-add-form {
  display: block; }

.page-product-bundle .product-werkdagen {
  margin-bottom: 7px; }

.page-product-bundle .page-main {
  margin-top: 0 !important; }

.page-product-bundle #additional {
  display: none; }

.bundle-options-wrapper {
  padding: 20px 0;
  border-top: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5; }
  .bundle-options-wrapper .field.choice:hover .options-wrap {
    background-color: #a3a3a3; }
  .bundle-options-wrapper .product-name {
    font-weight: 700; }
  .bundle-options-wrapper .options-title {
    color: #122948;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px; }
  .bundle-options-wrapper .label.title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #b5b5b5; }
    .bundle-options-wrapper .label.title:after {
      display: none; }
  .bundle-options-wrapper .single-bundle {
    display: flex; }
  .bundle-options-wrapper .options-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px; }
  .bundle-options-wrapper .control label {
    display: flex;
    width: 100%;
    height: 80px;
    color: #122948; }
    .bundle-options-wrapper .control label:hover {
      opacity: 0.75;
      cursor: pointer; }
    .bundle-options-wrapper .control label .product-name {
      font-size: 14px;
      line-height: 20px; }
    .bundle-options-wrapper .control label .price-notice {
      display: flex; }
      .bundle-options-wrapper .control label .price-notice .price-container {
        margin-left: 5px; }
    .bundle-options-wrapper .control label .price-container .price {
      font-size: 14px;
      line-height: 20px;
      color: #122948; }
  .bundle-options-wrapper .control input[type="checkbox"]:checked + label {
    opacity: 1;
    border: 1px solid black; }
  .bundle-options-wrapper .control input[type="checkbox"]:disabled + label {
    opacity: 0.2; }
  .bundle-options-wrapper .control .checkbox {
    display: none; }
  .bundle-options-wrapper .fieldset {
    margin: 0; }
  .bundle-options-wrapper .fieldset > .field.required > .label:after {
    display: none; }

.block-bundle-summary {
  margin-top: 20px; }
  .block-bundle-summary .bundle-info {
    position: relative; }
    .block-bundle-summary .bundle-info .product.name {
      display: block;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #122948; }
    .block-bundle-summary .bundle-info .price-box {
      position: absolute;
      top: -10px;
      right: 0; }
      .block-bundle-summary .bundle-info .price-box .price {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #122948; }

@media only screen and (max-width: 767px) {
  .page-product-bundle .product-werkdagen {
    margin-bottom: 10px; }
  .bundle-options-wrapper {
    margin-bottom: 70px; }
    .bundle-options-wrapper .fieldset > .legend {
      margin: 0 0 15px; }
  .block-bundle-summary .bundle-info .product.name {
    display: none; }
  .block-bundle-summary .bundle-info .price-box {
    top: -55px;
    left: 0; } }
