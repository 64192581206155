.main-content {
    .widget__popular-products {

        .content-heading {

            &::before {
                display: none;
            }

            .title {
                font-size: 40px;
                line-height: 55px;
                font-weight: bold;
                color: #122948;
            }

            .info {
                color: #122948;
                font-size: 14px;
                line-height: 27px;
            }
        }

        .block-content {
            margin: 0 auto;
        }

        .product-items {
            display: grid;
            justify-content: space-between;

            .product-item-info {
                width: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
    
            li.product-item {
                width: 100%!important;
                margin: 0!important;
                padding-bottom: 20px;

                .product-item-name {
                    margin: 5px 0;
                    max-width: 273px;
                    max-height: 36px;
                    overflow: hidden;

                    a {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: #122948;
                    }
                }


                .price-box {
                    margin: 10px 0 0;
                    height: auto;
                    display: grid;
                    grid-auto-flow: column;
                    column-gap: 1rem;
                    align-items: baseline;

                    .newPrice, .oldPrice {
                        margin: 0;
                    }

                    .minimal-price {
                        margin-bottom: 0;
                    }

                    .price-msrp_price,
                    .price:not(.vanaf) {
                        font-weight: normal;
                        margin: 0;
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                .minimal-price-link.vanaf {
                    margin-left: 1px;
                    font-style: italic;
                    line-height: 15px;
                }

                .product-image-container {
                    width: 100%!important;
                }

                .product-image-wrapper {
                    padding-bottom: 0!important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border: 1px solid #e5e5e5;
                    background-color: #F7F7F7;

                    img {
                        mix-blend-mode: multiply;
                    }
                }

                .product-item-details {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;

                    // .product-item-inner,
                    // .product-reviews-summary,
                    // div[class*="swatch-opt-"] {
                    //     display: none;
                    // }

                }
            }
        }
    }
}
// desktop 
@include min-screen($screen__xs) {
    .main-content {
        .widget__popular-products {
            .product-items {                
                grid-gap: 2rem;
                grid-template-columns: repeat(auto-fill, minmax(160px, 335px));
                // grid-auto-rows: 534px;

                li.product-item {
                    .product-image-wrapper {
                        max-width: 335px;
                        margin-bottom: 20px;
                        height: 100%;

                        img {
                            position: static;
                            width: 100%;
                            height: 335px;
                            object-fit: cover;
                        }
                    }

                    .product-item-actions {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xl) {
    .main-content {
        .widget__popular-products {
            .block-content {
                width: 100%;
            }

            .product-items {
                grid-template-columns: repeat(auto-fill, minmax(200px, 23.5%));

                li.product-item {
                    .product-image-wrapper { 
                        width: 100%;
                        max-height: 235px;
                        max-width: 235px;

                        img {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .main-content {
        .widget__popular-products {
            padding-top: 0;

            .content-heading {

                .title {
                    font-size: 20px;
                    line-height: 27px;
                    font-weight: bold;
                    margin: 0 0 5px -11px;
                    color: #122948;
                }

                .info {
                    margin-right: 13px;
                }
    
            }

            .product-items {
                grid-template-columns: repeat(2, 160px);
                grid-column-gap: 15px;
                grid-row-gap: 20px;
                // grid-auto-rows: 388px;

                a {
                    max-height: 160px;
                }

                li.product-item {
                    p,
                    .product-item-name,
                    .product-item-subtitle {
                        font-size: 12px;
                        line-height: 17px;
                        color: $color-light_gray;
                    }

                    .price-box {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .product-item-name {
                        min-height: 68px;
                        overflow: hidden;
                        margin: 17px 0 0px 0;
                    }

                    .minimal-price-link.vanaf {
                        margin-left: 0;
                    }

                    .product-item-subtitle {
                        height: 17px;
                        margin: 10px 0;
                        color: $color-light_gray;
                    }

                    .category-item-prices {
                        margin-bottom: 10px;
                    }

                    .product-image-wrapper {
                        max-height: 160px;
                        width: 158px;
                        height: 158px;
                        margin-top: -2px;
                        margin-bottom: -14px;

                        img {
                            width: 100%;
                        }
                    }
                }    
            }
        }
    }
}
