.block.filter {
    .wrap {
        margin-bottom: 35px;
        .item {
            margin-bottom: 10px;
            padding-left: 18px;
            line-height: 16px;
            a {
                color: $color-dark_gray;
            }

            label:hover {
                cursor: pointer;
            }

            input[type="checkbox"],
            .count {
                display: none;
            }
        }
    }

    .filter-title {
        strong {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: $color-sky_blue;
        }
    }

    .block-title-description {
        padding-top: 24px;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        color: $color-main;
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $color-sky_blue;
}

.filter-current {

    .item {
        display: flex;
        justify-content: space-between;
    }

    .filter {
        
        &__label {
            
            &:after {
                content: ':';
            }
        }

        // &__value {

        // }


    }

    .action {

        &.remove {
            display: flex;
            align-items: center;

            .icon {
                width: 12px;
                height: 12px;
                fill: $color-light_gray;

                &:hover {
                    fill: $color-light_blue;
                }
            }
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-content {
    input[type="checkbox"] {
        width: 14px;
        height: 14px;
        border-color: $color-medium_gray;
        margin-right: 8px;
    }

    label {
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: $color-main;
    }

    .action {
        &.remove,
        &.clear span {
            color: $color-sky_blue;
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.block-title {
    strong {
        display: inline-block;
        margin-bottom: 18px;
    }

    .filter-options-title {
        position: relative;
        line-height: 15px;
        margin-bottom: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    ul {
        padding-left: 18px;
        li {
            list-style: none;
            line-height: 16px;
        }
    }
}

.filter-options {
    margin: 0;

    .filter-options {
        &-item {
            padding-top: 20px;
            border-top: 1px solid var(--color-medium_gray);

            &.active {
                .filter-options-title:after {
                    transform: rotate(-180deg);
                    transition: 250ms;
                }
            }
        }

        &-line {
            width: 100%;
            height: 1px;
            background-color: $color-medium_gray;
            margin-bottom: 22px;
        }

        &-title {
            position: relative;
            @include lib-heading(h4);
            margin: 0 0 20px;
            word-break: break-all;
            font-size: 14px;
            line-height: 15px;
            color: $color-main;

            &:after {
                position: absolute;
                top: 3px;
                right: 0;
                content: '';
                display: inline-block;
                width: 17px;
                height: 10px;
                background: url("../images/icons/arrow-option.svg") no-repeat;
                transition: 250ms;
                transform: rotate(-90deg);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .filter-options-content {
        margin: 0 0 $indent__m;

        // .item {
        //     margin-bottom: 10px;
        // }
    }

    .count {

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}




//
//  Mobile
//  _____________________________________________

@media screen and (max-width: 1279px) {
    .block-title {
        .filter-options-line {
            width: 100%;
            height: 1px;
            // background-color: $color-medium_gray;
            margin-bottom: 22px;
        }

        .filter-options-title {
            position: relative;
            line-height: 15px;
            margin-bottom: 20px;
            &:after {
                position: absolute;
                top: 3px;
                right: 0;
                content: '';
                display: inline-block;
                width: 17px;
                height: 10px;
                background: url("../images/icons/arrow-option.svg") no-repeat;
                transition: transform 250ms;
            }

            &.hide-filter:after {
                transform: rotate(-180deg);
                transition: transform 250ms;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .block-title .filter-options-title {
        margin-bottom: 9px;
    }
}
