// .main-content {
    .root_home-video_cms-block {
        margin-top: 20px;
    }

    .home-video__cms-block {
        margin: 0 auto;
        padding-top: 35px;
        padding-bottom: 58px;

        .pagebuilder-column-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .pagebuilder-column {
            width: 32.1%!important;    
        }

        .pagebuilder-video-container {
            padding-top: 0!important;
            width: 100%;
            height: 270px;
        }

        div[data-content-type='video'] {
            position: static;
        }
    }
// }

@include max-screen($screen__m) {
    // .main-content {
        .root_home-video_cms-block {
            margin-top: 20px;
        }
    
        .home-video__cms-block {
            width: 100%;
            padding: 2rem 0 0;


            .pagebuilder-video-container {
                height: 201px;
                display: flex;
                justify-content: center;
            }
    
            div[data-content-type='video'] iframe {
                position: static;
                width: 98%;
                padding: 0 0 2rem 0;
            }
        }
    // }
}

@include max-screen($screen__xs) {
    // .main-content {
        .home-video__cms-block {
            div[data-content-type='video'] iframe {
                position: static;
                width: 93%;
                padding: 0 9px 2rem 6px;
            }
        }
    // }
}