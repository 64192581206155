
.cat-usp {
    margin-top: 20px;
    margin-bottom: calc( 20px + 1.5rem );
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid var(--color-light_gray);
    border-bottom: 1px solid var(--color-light_gray);

    &__swiper {
        margin-left: 0;
        display: none;
    }
    &__static {
        display: flex;
        justify-content: center;
    }
}

.cat-usp-card {
    width: auto;
    margin-right: 25px;
    font-size: 13px;
    line-height: 22px;
    
    &__link {
        color: var(--color-black);

        strong {
            color: var(--color-sky_blue);
        }

        &:hover {
            color: var(--color-black);

            strong {
                color: var(--color-sky_blue);
            }
        }

        &-noicon {
            color: var(--color-dark_blue);
            font-weight: bold;

            &:hover {
                color: var(--color-dark_blue);
                text-decoration: underline;
            }
        }

        &--route {
        }

        &--customer-service {
            color: var(--color-sky_blue);

            &:hover {
                color: var(--color-sky_blue);
            }
        }
    }

    &__icon {
        width: auto;
        height: 16px;
        display: inline-block;

        & > svg {
            width: auto;
            height: 16px;
        }
    }

    &__right {
        margin-left: auto;
        margin-right: 0;
        min-width: 300px;
        text-align: right;

        & > * {
            margin-left: 5px;
        }
    }
}


// @include max-screen($screen__xl) {   
// }

@include max-screen($screen__l) {

    .cat-usp {
        &__swiper {
            display: block;
        }
        &__static {
            display: none;
        }

        &-l-noborder {
            border-top: 0;
            border-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .cat-usp-card {
        width: 50%;
        margin-right: 0;
        text-align: center;
        font-size: 14px;
        
        &__right {
            display: none;
        }
    }

}

@include max-screen($screen__m) {

    .cat-usp {
        &__swiper {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .cat-usp-card {
        width: 100%;
        margin-right: 0;
        text-align: center;
        
        &__right {
            display: none;
        }
    }


}
