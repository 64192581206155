.page-main,
.main-content {
    .fptop {
        padding-bottom: 20px;

        &__container {
            @include container;
        }

        &__row {
            display: flex;
        }
    }

    .fptop-banner {
        &__link {
            display: block;
        }

        &__content {
        }

        &__container {
            display: flex;
        }

        &__information {
            width: 50%;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            color: var(--color-dark_blue);
            box-sizing: border-box;
        }

        &__image {
            width: 50%;
            img {
                display: block;
                height: 200px;
                width: 100%;
                object-fit: contain;
                object-position: bottom right;
            }
        }

        &__title {
            margin-top: 0;
            font-weight: bold;
            color: var(--color-sky_blue);
        }
    }

    .fptop-usp {
        &__swiper {
            margin-left: 0;
            display: none;
        }
        &__static {
            display: flex;
        }

        &-customerservice {
            &__nav {
                cursor: pointer;
                font-weight: bold;
                color: var(--color-orange);

                &:hover {
                    color: var(--color-dark_orange);
                }
            }

            &__list {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            &__item {
                position: relative;

                &.js-open {
                    & > .fptop-usp-customerservice-child__list {
                        display: block;
                    }

                    & > .fptop-usp-customerservice__nav {
                        color: var(--color-dark_orange);
                    }
                }
            }

            &-child {
                &__list {
                    list-style: none;
                    margin: 0;
                    padding: 5px 0;
                    position: absolute;
                    top: 35px;
                    right: 0;
                    z-index: 99;
                    background-color: black;
                    background-color: var(--color-light-gray-blue);
                    text-align: left;
                    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
                    display: none;
                    min-width: 160px;

                    &:before {
                        content: "";
                        position: absolute;
                        right: 10px;
                        top: -8px;
                        z-index: 98;
                        width: 16px;
                        height: 16px;
                        transform: rotate(45deg);
                        background-color: var(--color-light-gray-blue);
                        border-radius: 2px;
                    }
                }

                &__item {
                    margin: 0;

                    a {
                        padding: 2px 10px;
                        display: block;
                    }
                }
            }
        }
    }

    .fptop-usp-card {
        width: auto;
        margin-right: 25px;
        font-size: 13px;
        line-height: 22px;
        display: flex;

        &__link {
            color: var(--color-black);

            strong {
                color: var(--color-sky_blue);
            }

            &:hover {
                color: var(--color-sky_blue);

                strong {
                    color: var(--color-sky_blue);
                }
            }

            &-noicon {
                color: var(--color-dark_blue);
                font-weight: bold;

                &:hover {
                    color: var(--color-dark_blue);
                    text-decoration: underline;
                }
            }

            &--route {
            }

            &--customer-service {
                color: var(--color-sky_blue);

                &:hover {
                    color: var(--color-sky_blue);
                }
            }
        }

        &__icon {
            width: auto;
            height: 16px;
            display: inline-block;

            & > svg {
                width: auto;
                height: 16px;
            }
        }

        &__right {
            margin-left: auto;
            margin-right: 0;
            min-width: 300px;
            text-align: right;
            justify-content: flex-end;

            & > * {
                margin-left: 5px;
            }
        }
    }

    .fptop-hero {
        &__swiper {
            margin-bottom: 10px;
            width: 100%;

            &--pagination {
                display: none;
                bottom: 0 !important;

                .swiper-pagination-bullet {
                    width: calc((100% / 2));
                    height: 4px;
                    border-radius: 0;
                    position: relative;
                    overflow: hidden;
                    margin: 0;
                    background: var(--color-gray);
                    // transition: background 500ms;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        // transition: opacity 500ms;
                    }
                }

                .swiper-pagination-bullet-active {
                    // background: rgba(#000, 0.2);
                    background: var(--color-gray);

                    &::before {
                        background-color: var(--color-dark_blue);
                        animation: slide-progress 2s cubic-bezier(0.3, 0, 0.3, 1) forwards;
                        animation-duration: 5000ms;

                        .swiper-paused & {
                            // opacity: 0;
                            animation-play-state: paused;
                        }
                    }
                }

                @keyframes slide-progress {
                    0% {
                        transform: translateX(-100%);
                        // width: 0;
                    }

                    100% {
                        transform: translateX(0);
                        // width: 100%;
                    }
                }
            }
        }
    }

    .fptop-info {
        &__swiper {
            width: 100%;
        }
    }

    .fptop-hero-card {
        width: calc((100% / 2) - 5px);
        height: 340px;
        border-radius: var(--border-radius);
        overflow: hidden;

        &:hover {
            .fptop-hero-card__bg {
                transform: scale(1.05);
            }
        }

        &__bg {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transition: var(--default-transition);
        }

        &__link {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 10px;
            position: relative;
            z-index: 1;
        }

        &__content {
            width: 100%;
            height: 100%;
        }

        &__meta {
            color: $color-white;
            background-color: rgba($color-dark_blue, 0.85);
            width: 45%;
            padding: 20px;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-diap {
                background-color: rgba($color-white, 0.85);

                .fptop-hero-card__title,
                .fptop-hero-card__subtitle,
                .fptop-hero-card__text {
                    color: var(--color-dark_blue);
                }
            }
        }

        &__title {
            @include clear-margin;
            word-wrap: normal;
        }

        &__subtitle {
            @include clear-margin;
        }

        &__text {
            color: var(--color-light_gray);
            font-size: 16px;
            line-height: calc(27 / 20);
        }
    }

    .fptop-info-card {
        width: calc((100% / 3) - (20px / 3));
        box-sizing: border-box;
        background: var(--color-light_gray);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: var(--border-radius);
        // height: 200px;
    }
}

// @include max-screen($screen__xl) {
// }

@include max-screen($screen__xl) {
    .page-main,
    .main-content {
        .fptop-usp {
            &__swiper {
                display: block;
            }
            &__static {
                display: none;
            }
        }

        .fptop-usp-card {
            font-size: 11px;
            width: 33.333%;
            margin-right: 0;
        }
        .fptop-hero-card {
            &__meta {
                width: 60%;
                padding: 20px 0;
                overflow: hidden;
                border-radius: var(--border-radius);
            }

            &__title,
            &__subtitle,
            &__text,
            &__button {
                transform: scale(0.9);
            }

            .fptop-info-card {
                height: auto;

                .text_block__1,
                .text_block__2,
                .text_block__3 {
                    * {
                        // transform: scale(0.9);
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .page-main,
    .main-content {
        .fptop-usp-card {
            width: 50%;
            margin-right: 0;
            text-align: center;
            font-size: 14px;

            &__right {
                display: none;
            }
        }

        .fptop-hero-card {
            &__meta {
                width: 70%;
                padding: 20px 0;
            }

            &__title,
            &__subtitle,
            &__text,
            &__button {
                transform: scale(0.8);
            }
        }

        .fptop-info-card {
            width: calc(100% / 2.2);

            .text_block__1,
            .text_block__2,
            .text_block__3 {
                * {
                    // transform: scale(0.8);
                }
            }
        }

        .fptop-banner {
            font-size: 12px;

            &__title {
                font-size: 13px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .page-main,
    .main-content {
        .fptop-usp {
            &__swiper {
                // padding-top: 20px;
                // padding-bottom: 20px;
            }
        }

        .fptop-usp-card {
            width: 100%;
            margin-right: 0;
            text-align: center;

            &__right {
                display: none;
            }
        }

        .fptop-hero {
            &__swiper {
                padding-bottom: 20px;

                &--pagination {
                    display: flex;
                }
            }
        }

        .fptop-hero-card {
            width: 100%;

            &__meta {
                width: 75%;
                padding: 20px;
            }

            &__title,
            &__subtitle,
            &__text,
            &__button {
                transform: scale(0.85);
            }
        }

        .fptop-info {
            &__swiper {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }

        .fptop-info-card {
            width: calc(100% / 1.2);

            .text_block__1,
            .text_block__2,
            .text_block__3 {
                * {
                    // transform: scale(0.9);
                }
            }
        }
    }
}
