//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;
$color-link-menu: $link_navigation_menu__color;

// .scroll-down .nav-sections__fixed {
//     transform: translate3d(0, -100%, 0);
//     margin-top: 0 !important;
// }

// .scroll-up .nav-sections__fixed {
//     transform: none;

//     border-bottom: 1px solid #B5B5B5;
//     margin-top: 0 !important;
//     padding: 5px 0 5px 0 !important;
//     z-index: 1000;
//     height: inherit;

//     @include max-screen($screen_nav__xl) {
//         padding: 10px 0 10px 0;
//     }
// }

// .nav-sections__fixed {
//     left: 0;
//     right: 0;
//     top: 0px;
//     background-color: #ffffff;
//     transition: transform 0.4s;
// }

.navigation-bar {
    background-color: var(--color-light-gray-blue);
}

.ub-bg-overlay {
    background-color: $dark-blue_color;
    opacity: 0;
    transition-delay: 500ms;
}

body.nav-active .ub-bg-overlay {
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: $dark-blue_color;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: opacity 250ms ease-in-out;
}

.nav-open {
    .page-header {
        height: 100%;
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: $color-white;
}

@include max-screen($screen_nav__xs) {
    .page-header {
        background-color: $color-white;
    }
}

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-toggle {
    display: flex;
    position: static;
    cursor: pointer;
    font-size: 0;
    z-index: 14;
    height: 100%;
    width: 40px;
    padding-right: 30px;

    .icon__bg_menu {
        max-height: 33px;
        width: 100%;
        display: inline-block;
    }

    &:before {
        content: "";
        display: none;
    }
}

@include min-screen($screen__l) {
    .page-header {
        // padding-bottom: 20px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen_nav__xs) {
    header.page-header {
        // margin: 7px 0;
    }

    span.nav-toggle {
        padding-right: 0;

        span {
            top: 42px;
            left: 22px;
        }
    }
}

@include max-screen($screen_nav__xxl) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol($_icon-font-content: $icon-up, $_icon-font-position: after);
                }
            }
        }
    }

    // .page-main {
    //     margin-top: 80px;
    // }

    // .page-header {
    //     top: 43px;
    // }

    .nav-toggle {
        padding-right: 25px;

        span {
            font-size: 14px;
            position: absolute;
            top: 40px;
            left: 22px;
        }
    }

    .nav-sections {
        display: none;
        -webkit-overflow-scrolling: touch;
        background: #ffffff;
        @include lib-css(transition, left 250ms, 1);
        height: 100%;
        overflow: auto;
        position: fixed;
        top: 0;

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 42px,
                    $_icon-font-position: after,
                    $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol($_icon-font-content: $icon-up, $_icon-font-position: after);
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 250ms, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        // .page-wrapper {
        //     left: 80%;
        //     left: calc(100% - #{$active-nav-indent});
        // }

        .nav-sections {
            display: block;
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: "";
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid $link_navigation_menu__border-color;
        box-sizing: border-box;
        padding: 20px;

        .icon__close-mobile-menu {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        a {
            @include menu-mobile-link;
            font-weight: 700;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;

        ul {
            list-style: none;
            padding: 0;

            li:first-of-type {
                padding-top: 0;
            }

            li {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $link_navigation_menu__border-color;
                padding: 20px;
                margin: 0;

                a {
                    @include menu-mobile-link;
                }
            }
        }
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen_nav__xxl) {
    ul.level0.horizontal-root {
        display: flex !important;
        flex-wrap: wrap;
    }

    .drilldown,
    .nav-toggle {
        display: none !important;
    }

    .drilldown {
        overflow: hidden;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include container;
        padding: 0 20px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .nav-sections-item-title {
        display: none;
    }

    div.nav-sections-item-content {
        display: block !important;
        padding: 0;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}
