.usp-payments {
    width: 100%;
    background-color: $color-white_light;
    color: $color-main;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: var(--border-radius);

    .usp {
        &__list {
            margin: 0;
            padding: 0;
            list-style: none;

            & > *:last-child {
                margin-bottom: 0;
            }
        }

        &__item {
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 0 0 30px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                display: inline-block;
                width: 20px;
                height: 15px;
                background: url("../images/icons/arrow-check.svg") no-repeat;
            }
        }
    }

    .payments {
        margin-top: 20px;

        &__list {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            list-style: none;

            & > *:last-child {
                margin-right: 0;
            }
        }

        &__item {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            height: 40px;
            margin-right: 10px;

            &.ideal {
                width: 44px;
                background-image: url("../images/icons/payment-ideal.svg");
            }

            &.visa {
                width: 60px;
                background-image: url("../images/icons/payment-visa.svg");
            }

            &.mastercard {
                width: 60px;
                background-image: url("../images/icons/payment-mastercard.svg");
            }

            &.maestro {
                width: 60px;
                background-image: url("../images/icons/payment-maestro.svg");
            }
        }
    }
}

.cart-container {
    .usp-payments {
        display: block !important;
        background-color: transparent;
    }
}

.product-info-main {
    .usp-payments {
        padding: 3rem;
    }

    .usp {
        &__list {
            column-count: 2;
        }
    }
}
