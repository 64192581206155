.bundle-options-container .product-add-form {
    display: block;
}

.page-product-bundle {
    .product-werkdagen {
        margin-bottom: 7px;
    }

    .page-main {
        margin-top: 0 !important;
    }

    #additional {
        display: none;
    }
}

.bundle-options-wrapper {
    padding: 20px 0;
    border-top: 1px solid $color-medium_gray;
    border-bottom: 1px solid $color-medium_gray;
    .field.choice:hover .options-wrap {
        background-color: $color-light_gray;
    }

    .product-name {
        font-weight: 700;
    }

    .options-title {
        color: $color-main;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
    }
    .label.title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $color-medium_gray;
        &:after {
            display: none;
        }
    }

    .single-bundle {
        display: flex;
    }

    .options-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-right: 10px;
    }

    .control {
        label {
            display: flex;
            width: 100%;
            height: 80px;
            color: $color-main;
            &:hover {
                opacity: 0.75;
                cursor: pointer;
            }

            .product-name {
                font-size: 14px;
                line-height: 20px;
            }

            .price-notice {
                display: flex;
                .price-container {
                    margin-left: 5px;
                }
            }

            .price-container .price {
                font-size: 14px;
                line-height: 20px;
                color: $color-main;
            }
        }

        input[type="checkbox"]:checked + label {
            opacity: 1;
            border: 1px solid black;
        }

        input[type="checkbox"]:disabled + label {
            opacity: 0.2;
        }

        .checkbox {
            display: none;
        }
    }

    .fieldset {
        margin: 0;
    }

    .fieldset > .field.required > .label:after {
        display: none;
    }
}

.block-bundle-summary {
    margin-top: 20px;
    .bundle-info {
        position: relative;
        .product.name {
            display: block;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: $color-main;
        }
        .price-box {
            position: absolute;
            top: -10px;
            right: 0;
            .price {
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                color: $color-main;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .page-product-bundle .product-werkdagen {
        margin-bottom: 10px;
    }

    .bundle-options-wrapper {
        margin-bottom: 70px;
        .fieldset > .legend {
            margin: 0 0 15px;
        }
    }

    .block-bundle-summary .bundle-info {
        .product.name {
            display: none;
        }

        .price-box {
            top: -55px;
            left: 0;
        }
    }
}
