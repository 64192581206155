.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(22, 35, 47, 0.85);
    z-index: 1000;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: var(--default-transition);

    &.show {
        visibility: visible;
        opacity: 1;
    }

    &__inner {
        width: auto;
        max-height: calc(100vh - 20px);
        max-width: var(--container-width);
        background-color: white;
        position: relative;
        margin: 10px;
        overflow: auto;
        position: relative;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
        display: inline-block;
        background: 0;
        border: none;
        cursor: pointer;
        padding: 5px;
        background-color: white;

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {
            background-color: white;
        }

        &:focus {
            outline: 0;
        }
    }
}
