.main-content,
.column.main {
    .gcswiper {
        &:after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            right: 0;
            // width: calc( (100% - 1400px)  / 2);
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 1;
        }

        &__wrapper {
            width: 100%;
            padding-bottom: 40px;
        }

        &__slide {
            &:first-child {
                // margin-left: calc( (100% - 1400px)  / 2);
            }
            &:last-child {
                // margin-right: calc( (100% - 1400px)  / 2);
            }
        }

        &__ctr {
            &--scrollbar {
                height: 2px !important;
                position: absolute;
                background-color: #b5b5b5;
                // left: 0 !important;
                // margin-left: calc( (100% - 1400px)  / 2);
                // max-width: var(--container-width);

                .swiper-scrollbar-drag {
                    border-radius: unset;
                    height: 6px;
                    background-color: #464646;
                    margin-top: -2px;
                }
            }

            &--prev,
            &--next {
                height: 50px;
                width: 50px;
                color: #ffffff;
                background-color: #122948;
                border-radius: var(--border-radius);

                &:after {
                    font-size: 30px;
                }

                &.swiper-button-disabled {
                    display: none;
                }
            }

            &--prev {
                // left: calc( ((100% - 1400px)  / 2) - 25px);
                left: -15px;
            }

            &--next {
                right: -15px;
                // right: calc( ((100% - 1400px)  / 2) - 25px);
            }
        }
    }
}

@include max-screen($screen__xl) {
    .main-content,
    .column.main {
        .gcswiper {
            &__slide {
                &:first-child {
                    // margin-left: 20px;
                }
                &:last-child {
                    // margin-right: 20px;
                }
            }

            &__ctr {
                &--scrollbar {
                    margin-left: 0;
                    left: 1% !important;
                }

                &--prev {
                    left: -15px;
                }

                &--next {
                    right: -15px;
                }
            }
        }
    }
}
