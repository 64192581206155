.main-content {
    div.gc-swipe-scrollbar {
        height: 2px;
        position: absolute;
        background-color: #b5b5b5;
        left: 0;

        .swiper-scrollbar-drag {
            border-radius: unset;
            height: 6px;
            background-color: #464646;
            margin-top: -2px;
        }
    }

    .gc-swipe-btn_prev,
    .gc-swipe-btn_next {
        height: 50px;
        width: 50px;
        color: #ffffff;
        background-color: #122948;
        overflow: hidden;
        border-radius: var(--border-radius);

        &:after {
            font-size: 30px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .home-highlights {
        padding-bottom: 60px;

        &__wrapper {
            width: 100%;
            justify-content: space-between;

            .item-card {
                max-width: 450px;
                height: auto;

                &:last-child {
                    margin-right: 0;
                }
            }

            .main-swipe-item {
                height: 100%;
                box-sizing: border-box;
                border: 1px solid var(--color-light_gray);
                overflow: hidden;
                border-radius: var(--border-radius);
            }

            p {
                font-size: 14px;
                padding-top: 8px;
                width: 95%;
                margin-bottom: 0;
            }

            h2,
            p {
                margin: 0;
                color: $color__darkblue;
            }

            .type-btn__darkblue {
                width: fit-content;
                height: 45px;
                padding: 0 1.5rem;
                font-size: 14px;
                line-height: 18px;
                margin-top: 65px;
            }

            .image-item {
                max-width: 450px;
                max-height: 450px;
            }

            .footer_item {
                padding: 15px;

                &--list {
                    display: flex;
                    flex-flow: wrap;
                    margin: 10px 0 0 0;
                    padding: 0;
                    list-style: none;

                    & > *:last-child {
                        &:after {
                            content: "";
                        }
                    }
                }

                &--item {
                    margin: 0 5px 0 0;
                    padding: 0;

                    &:after {
                        content: "|";
                        margin-left: 5px;
                    }

                    a {
                        color: var(--color-sky_blue);
                    }
                }
            }
        }

        .gc-swipe-btn_prev,
        .gc-swipe-btn_next {
            display: none;
        }
    }
}

@include max-screen($screen__l) {
    .main-content {
        .home-highlights {
            &__wrapper {
                .font-size30 {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }
    }
}

@include max-screen($screen_nav__m) {
    .page-main .main-content {
        .root_home-card-block-with-btn {
            align-self: inherit;
        }

        .home-highlights {
            margin-bottom: 0;

            &__wrapper {
                // display: flex;
                // justify-content: inherit;
                // column-gap: inherit;

                .item-card {
                    width: 100%;
                    max-width: 300px;
                    margin-right: 15px;

                    &:last-child {
                        padding: 0 0.5rem 0 0;
                        margin-right: 0;
                    }
                }

                .image-item {
                    margin-bottom: 0;
                }

                .footer_item {
                    padding: 27px 0 20px 20px;
                }

                .font-size30 {
                    margin-top: -3px;
                }

                .type-btn__darkblue {
                    padding: 0 1.4rem;
                    margin-top: 47px;
                }

                p {
                    width: 90%;
                    padding-top: 11px;
                }
            }

            // .gc-swipe-scrollbar {
            //     bottom: 3px;
            // }

            .main-swipe-item {
                width: 300px;
                height: 100%;
            }
        }
    }
}
