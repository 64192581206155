.fotorama__wrap {
    @extend .fotorama-no-select;
    position: relative;
    direction: ltr;
    text-size-adjust: 100%;
    z-index: 0;

    .fotorama--fullscreen & {
        max-width: 100% !important;
    }

    div[class*="amlabel-position-"] {
        pointer-events: none;
    }

    div[class*="amlabel-position-top-left"] {
        transform-origin: top left;
    }

    div[class*="amlabel-position-top-right"] {
        transform-origin: top right;
        margin-right: 100px !important;
    }

    div[class*="amlabel-position-bottom-left"] {
        transform-origin: bottom left;
    }

    div[class*="amlabel-position-bottom-right"] {
        transform-origin: bottom right;
        margin-right: 100px !important;
    }


    &--pan-y {
        touch-action: pan-y;
    }
}




@include max-screen($screen__s) {
    .fotorama__wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.7);
            }

            div[class*="amlabel-position-top-right"] {
                transform-origin: top right;
                margin-right: inherit !important;
            }

            div[class*="amlabel-position-bottom-right"] {
                transform-origin: bottom right;
                margin-right: inherit !important;
            }
    }
}


@include screen($screen__s,$screen__m) {
    .fotorama__wrap {
            div[class*="amlabel-position-"] {
                transform: scale(1);
            }

            div[class*="amlabel-position-top-right"] {
                transform-origin: top right;
                margin-right: inherit !important;
            }

            div[class*="amlabel-position-bottom-right"] {
                transform-origin: bottom right;
                margin-right: inherit !important;
            }
    }
}


@include screen($screen__m,$screen__l) {
    .fotorama__wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.7);
            }
    }
}

@include screen($screen__l,$screen__xl) {
    .fotorama__wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.85);
            }
    }
}


@include min-screen($screen__xl) {
    .fotorama__wrap {
            div[class*="amlabel-position-"] {
                transform: scale(1);
            }
    }
}

