a.btn,
button.btn {
    border-radius: 0;
    border: 0;
    height: 45px;
    padding: 0 15px;
    font-size: 14px;
    color: var(--color-white) !important;
    font-weight: normal;
    line-height: 45px;
    background-color: var(--color-dark_blue) !important;
    outline: 0;
    transition: var(--default-transition);
    border-radius: var(--border-radius);

    &:hover {
        border: none;
    }

    // Colors
    &-orange {
        background-color: var(--color-orange) !important;

        &:hover {
            background-color: var(--color-dark_orange) !important;
        }
    }

    // Size
    &-l {
        font-size: 18px;
    }
}
