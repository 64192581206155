.gcc-backbutton {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 99999;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    &__button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        padding: 0;
        background-color: var(--color-dark_blue);
        border: none;
        transition: var(--default-transition);

        &:hover {
            background-color: var(--color-dark_blue);
        }

        &:focus {
            transform: scale(1.05);
        }

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgNTEyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjMxLjI5MyA0NzMuODk5bDE5Ljc5OS0xOS43OTljNC42ODYtNC42ODYgNC42ODYtMTIuMjg0IDAtMTYuOTcxTDcwLjM5MyAyNTYgMjUxLjA5MiA3NC44N2M0LjY4Ni00LjY4NiA0LjY4Ni0xMi4yODQgMC0xNi45NzFMMjMxLjI5MyAzOC4xYy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNC45MDggMjQ3LjUxNWMtNC42ODYgNC42ODYtNC42ODYgMTIuMjg0IDAgMTYuOTcxTDIxNC4zMjIgNDczLjljNC42ODcgNC42ODYgMTIuMjg1IDQuNjg2IDE2Ljk3MS0uMDAxeiIvPjwvc3ZnPg==');
            background-repeat: no-repeat;
            background-position: center center;
            content: '';
            width: 60px;
            height: 60px;
            margin: 20px;
            display: block;
        }
    }
}
