.share-cart {
    float: left;
    width: 73%;
    margin-top: 20px;

    &__container {
        background: var(--color-light_gray);
        padding: 20px;
    }

    &__title {
        font-size: 20px;
        margin: 0;
        font-weight: bold;
    }

    &__input {
        font-size: 20px;
        width: 100%;
        border: 0;
        background-color: transparent;
        transition: var(--default-transition);

        &:active,
        &:visited,
        &:focus {
            box-shadow: none;
            background: transparent;
            border: none;
        }

        &.copied {
            color: var(--color-green);
        }
    }

    &__body {
        display: flex;
    }

    &__copy {
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;

        svg {
            width: 30px;
            height: 30px;
            fill: var(--color-dark_blue);
        }

        &:active,
        &:visited,
        &:focus {
            box-shadow: none;
            background: transparent;
            border: none;
        }

        &:hover {
            background: transparent;
            border: none;

            svg {
                fill: var(--color-sky_blue);
            }
        }
    }
}