//
//  Product Lists
//  _____________________________________________

.products {
    margin: 30px 0 0;
}

.product {
    &-items {
        @extend .abs-reset-list;

        & > *:last-child {
            border-bottom: none;
        }
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        border-bottom: 1px solid $color-white_light;

        .products-grid & {
            display: inline-block;
            padding-top: 15px;
            padding-bottom: 15px;
        }


        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 0 0 10px 0;
            word-wrap: break-word;
            hyphens: auto;
            height: calc((1.4rem * (18/13)) * 2);
        }

        &-subtitle {
            color: $color-light_gray;
            display: block;
            margin: 0 0 10px 0;
            word-wrap: break-word;
            hyphens: auto;
            height: calc(1.4rem * (18/13));
        }

        &-info {
            max-width: 100%;
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: row;
            grid-template-columns: repeat(12,minmax(0,1fr));
            grid-row-gap: 20px;
            grid-column-gap: 20px;

            .image-wrap {
                grid-column: span 4 /span 4;
            }

            .page-products & {
                width: auto;
            }
        }

        &-details {
            grid-column: span 8/span 8;
        }

        &-actions {

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: 0;
            height: 23px;

            .price {
                font-size: 18px;
                font-weight: normal;
                white-space: nowrap;
            }

            .map-show-info,
            .minimal-price-link,
            .price-label {
                display: none;
            }

            .minimal-price {
                .old-price {
                    display: none;
                }
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 18px;
                font-weight: normal;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }

    .image {

        &-link {
            width: 100%;
            display: block;
        }

        &-wrap {

            div[class*="amlabel-position-"] {
                pointer-events: none;
            }

            div[class*="amlabel-position-top-left"] {
                transform-origin: top left;
            }

            div[class*="amlabel-position-top-right"] {
                transform-origin: top right;
            }

            div[class*="amlabel-position-bottom-left"] {
                transform-origin: bottom left;
            }

            div[class*="amlabel-position-bottom-right"] {
                transform-origin: bottom right;
            }

        }

        &-figure {
            width: 100%;
            padding: 0;
            margin: 0;
            background-color: var(--color-light_gray);
        }

        &-picture {
            width: 100%;
            display: block;
            mix-blend-mode: multiply;
        }

    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: 17px;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .catalog-category-view {
        .product-items .item:nth-last-child(-n+3) {
            margin-bottom: 9px;
        }
    }
}

@include min-screen($screen__l) {
    .product {
        &-items {
            display: grid;
            grid-column-gap: 20px;
            grid-template-columns: repeat(3, 1fr);
        }

        &-item {

            .products-grid & {
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            
            &-info {
                max-width: 100%;
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: row;
                grid-template-columns: repeat(12,minmax(0,1fr));

                .image-wrap {
                    grid-column: span 12 /span 12;

                    div[class*="amlabel-position-"] {
                        transform: scale(1);
                    }
                }

                .page-products & {
                    width: auto;
                }
            }

            &-details {
                grid-column: span 12 /span 12;
            }
        }
    }
}


@include max-screen($screen__s) {
    .product {
        .image-wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.3);
            }
        }
    }
}


@include screen($screen__s,$screen__l) {
    .product {
        .image-wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.5);
            }
        }
    }
}

@include screen($screen__l,$screen__xl) {
    .product {
        .image-wrap {
            div[class*="amlabel-position-"] {
                transform: scale(0.7);
            }
        }
    }
}


@include min-screen($screen__xl) {
    .product {
        .image-wrap {
            div[class*="amlabel-position-"] {
                transform: scale(1);
            }
        }
    }
}

