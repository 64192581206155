.main-content {
    .home-slider-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1640px;

        &__container {
            position: relative;
            padding-bottom: 60px;

            .swiper-button-next,
            .swiper-button-prev {
                top: calc(50% - 25px);
            }

            .swiper-button-prev {
                left: -15px;
            }

            .swiper-button-next {
                right: -15px;
            }
        }

        &__title {
            margin: 0 0 40px 0;
            color: #122948;
        }

        .gc-home-swiper {
            list-style: none;
            margin-bottom: 0;
            width: 100%;
            width: 100%;
            padding-bottom: 40px;

            .item-card {
                // height: 380px;
                max-width: 300px;
                width: 100%;
                background-color: #f7f7f7;
                overflow: hidden;
                border-radius: var(--border-radius);

                .main-swipe-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    box-sizing: border-box;
                    height: 100%;
                    padding: 20px 0;
                }

                &.first-card {
                    height: auto;
                    max-width: 400px;
                    width: 100%;
                    background-color: var(--color-light-gray-blue);
                    box-sizing: border-box;
                }

                .image-item {
                    max-height: 300px;

                    img {
                        mix-blend-mode: multiply;
                        display: block;
                        margin: 0 auto;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    text-align: left;
                    height: 100%;
                    padding: 0 40px;

                    h2 {
                        margin: 30px 0 0 0;
                    }

                    a {
                        text-align: center;
                        font-size: 14px;
                        line-height: 45px;
                        height: 45px;
                        padding: 0 20px;
                    }

                    .font-size30,
                    p {
                        color: #464646;
                    }

                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                .type-btn__darkblue {
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 0 2rem;
                    height: 45px;
                    width: fit-content;
                    align-self: center;
                    z-index: 10;
                }
            }

            .swiper-scrollbar {
                width: 98%;
                left: 1%;
                top: auto;
                bottom: 13px;
            }

            .swiper-button-disabled ~ .swiper-shadow {
                display: none;
            }
        }
    }
}

@include max-screen($screen_nav__xxl) {
    .main-content {
        .home-slider-block {
            margin-left: 0;

            &__title {
                margin: 20px 0;
                font-size: 30px;
            }

            .gc-home-swiper {
                width: 100%;
                margin: 0 0 -1px 0;
                padding-bottom: 20px;
                overflow: hidden;

                .item-card {
                    width: 300px;

                    &.first-card {
                        width: 300px;

                        p {
                            width: 80%;
                        }

                        .content {
                            padding: 0 20px;

                            h2 {
                                margin-top: 0;
                            }
                        }
                    }
                }

                .swiper-scrollbar {
                    bottom: 3px;
                }
            }
        }
    }
}
